import React, { useCallback, useEffect, useRef, useState } from 'react';

/**
 * This method takes a ref object and will return three variables,
 * sampled, width and height. Whenever a window resize event happens, these
 * variables will be updated automatically.
 *
 * Sampled - True if the height has been sampled, false if it has not yet been sampled.
 *           This is useful if you want to defer rendering a thing until the content
 *           area has been calculated.
 * Width - The width of the ref bounding box.
 * Height - The height of the ref bounding box.
 */
export const useBoundingRect = (ref: React.RefObject<HTMLElement>) => {
    const [sampled, setSampled] = useState(false);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const loops = useRef(0);

    const handleResizeEvent = useCallback(() => {
        setSampled(false);
        if (ref && ref.current) {
            const rect = ref.current.getBoundingClientRect();
            if (rect.width !== 0 && rect.height !== 0) {
                setWidth(rect.width);
                setHeight(rect.height);
                setSampled(true);
            }
        }
    }, [ref, setWidth, setHeight, setSampled]);

    // This effect is dedicated to retrying every n ms
    // until we have a valid reference.
    // There's some "oh snap" loop break logic as well.
    useEffect(() => {
        const timerId = setInterval(() => {
            loops.current += 1;
            if (loops.current > 50) {
                clearInterval(timerId);
                return;
            } else if (ref.current === null) return;

            handleResizeEvent();
            clearInterval(timerId);
        }, 50);

        return () => {
            clearInterval(timerId);
        };
    }, [ref, loops]);

    useEffect(() => {
        window.addEventListener('resize', handleResizeEvent);

        return () => {
            window.removeEventListener('resize', handleResizeEvent);
        };
    }, []);

    return [sampled, width, height] as [boolean, number, number];
};
