import { Box, Divider, Heading, IconSwitch, OSKThemeType, RatioLayout, RatioPanel } from 'oskcomponents';
import { noop } from 'oskcore';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { Breadcrumb } from '~/atoms';
import { ActionBar } from '~/atoms/ActionBar';
import { AppNames } from '~/constants';
import DateRangePicker from '~/molecules/DateRangePicker';
import { IconHeader } from '~/molecules/Monitor';
import AlertsSection from '~/organisms/Monitor/AlertsSection';
import AssetDetailsSection from '~/organisms/Monitor/AssetDetailsSection';
import AssetsTable from '~/organisms/Monitor/AssetsTable';
import { Bell } from '~/organisms/Monitor/Notifications';
import { DataProvider } from '~/redux';
import { Asset } from '~/redux/modules/monitor/app';
import { RootState } from '~/redux/store';
import { getProgramId } from '~/utils';

type AssetDashboardViewProps = {
    selectedAssetId?: string;
    /** The asset to display details for */
    selectedAsset?: Asset;
    /** Filter start date retrieved from redux */
    filterStartDate?: Date;
    /** Filter end date retrieved from redux */
    filterEndDate?: Date;
};

export type FilterOptions = 'default' | 'alerts' | 'reports';

const AssetDashboardView = ({ selectedAsset, selectedAssetId, ...props }: AssetDashboardViewProps) => {
    const navigate = useNavigate();
    const { programId } = useParams();
    const [filter, setFilter] = useState<FilterOptions>('default');
    const theme = useTheme() as OSKThemeType;

    return (
        <DataProvider programId={getProgramId()} assetId={selectedAssetId} features={['asset', 'reports']}>
            <Box data-testid="Monitor-Dashboard" style={{ height: '100%' }} col grow {...props}>
                <ActionBar
                    left={<Breadcrumb nodes={[{ title: AppNames['monitor'], url: '/' }, { title: 'Grid' }]} />}
                    right={
                        <React.Fragment>
                            <DateRangePicker />
                            <IconSwitch
                                options={[
                                    {
                                        value: 'grid',
                                        icon: 'list',
                                    },
                                    {
                                        value: 'map',
                                        icon: 'map',
                                        disabled: !selectedAsset,
                                    },
                                ]}
                                selectedValue="grid"
                                onSelect={(value: any) => {
                                    if (value === 'map') {
                                        navigate(`/program/${programId}/monitor/${selectedAsset?.id}/map`);
                                    }
                                }}
                            />
                            <Bell />
                        </React.Fragment>
                    }
                />
                <RatioLayout m={14} grow style={{ height: '0px' }}>
                    <RatioPanel
                        style={{ borderRadius: '30px', margin: '0px 9px' }}
                        bg={theme.colors.primary.transBg}
                        data-testid="Assets-Section"
                        p={24}
                        weight={2}
                    >
                        <IconHeader title="Assets" iconCode="assets" />
                        <Divider m={12} />
                        <Heading variant="tiny" style={{ padding: '14px 0px' }}>
                            Alerts &amp; Reports
                        </Heading>
                        <AlertsSection selectedAssetId={selectedAssetId} setFilter={setFilter} activeFilter={filter} />
                        <Divider m={12} />
                        <Heading variant="tiny" style={{ padding: '14px 0px' }}>
                            Assets
                        </Heading>
                        <Box style={{ width: '100%', height: '100%', overflow: 'scoll', paddingBottom: '20px' }}>
                            <AssetsTable selectedAsset={selectedAsset} />
                        </Box>
                    </RatioPanel>
                    <RatioPanel
                        style={{ borderRadius: '30px', margin: '0px 9px' }}
                        bg={theme.colors.primary.transBg}
                        data-testid="Asset-Details-Section"
                        key="asset-details-panel"
                        weight={1}
                    >
                        <IconHeader
                            pt={24}
                            pl={24}
                            title={selectedAsset ? selectedAsset.name : '--'}
                            iconCode="pipeline"
                        />
                        <Divider m={12} />
                        <AssetDetailsSection selectedAssetId={parseInt(`${selectedAssetId ?? -1}`)} />
                    </RatioPanel>
                </RatioLayout>
            </Box>
        </DataProvider>
    );
};

export { AssetDashboardView };
export type { AssetDashboardViewProps };

const mapStateToProps = (state: RootState, props: Partial<AssetDashboardViewProps>) => {
    const { assets } = state.monitor.app;
    return {
        selectedAsset: assets[props.selectedAssetId ?? -1],
    };
};

export default connect(mapStateToProps, noop)(AssetDashboardView);
