import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Box, Text, OSKThemeType } from 'oskcomponents';
import { FaShoppingCart } from 'react-icons/fa';
import { NavItem } from 'oskcore';
import { IconButton } from '~/atoms';

export type CartIndicatorProps = {
    itemCount?: number;
    onClick?: () => void;
};

export const CartIndicator = styled(({ itemCount, onClick, ...props }: CartIndicatorProps) => {
    const theme = useTheme() as OSKThemeType;
    return (
        <Box data-testid="CartIcon" style={{ alignItems: 'center' }} onClick={onClick} {...props}>
            <NavItem>
                <IconButton
                    p={0}
                    iconSize={2}
                    variant={(itemCount ?? 0) > 0 ? 'action' : 'disabled'}
                    icon={FaShoppingCart}
                />

                {(itemCount ?? 0) > 0 && (
                    <Box
                        style={{
                            position: 'absolute',
                            top: '10px',
                            marginLeft: '15px',
                            backgroundColor: theme.colors.darkAccent,
                            height: '20px',
                            width: '20px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            border: `1px solid ${theme.colors.darkAccent}`,
                            borderRadius: '64px',
                        }}
                    >
                        <Text variant="tiny" color="white" style={{ fontWeight: 'bold' }}>
                            {itemCount ?? 0}
                        </Text>
                    </Box>
                )}
            </NavItem>
        </Box>
    );
})`
    &:hover {
        color: ${(props: any) => (props.itemCount > 0 ? props.theme.colors.accent : 'white')};
        cursor: ${(props: any) => (props.itemCount > 0 ? 'pointer' : 'default')};
    }
`;
