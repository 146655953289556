import React from 'react';
import { Box, BoxProps } from '../Box';
import styled from 'styled-components';
import { range } from 'lodash';

export type PaginationProps = {
    /** How many items are present per page */
    pageSize?: number;
    /*** The amount of items we've already fetched */
    offset: number;
    /** The total number of items */
    count: number;
    /** The css class to apply to the component */
    className?: string;
    /** The method to invoke when a page is selected */
    onChange?: (pageNumber: number) => void;
} & Omit<BoxProps, 'ref'>;

export const Pagination = styled(
    ({ pageSize = 100, onChange, className, offset, count, ...props }: PaginationProps) => {
        // How many numbers to show on either side of "current page". (more than 2 looks weird).
        const seekRange = 2;

        const totalPages = Math.ceil(count / pageSize);
        const currentPage = Math.floor(offset / pageSize);

        // The lowest number page (0-based) that we want to show
        const earlierPage = Math.max(currentPage - seekRange, 0);
        // The highest number page (0-based) that we want tos how
        const latterPage = Math.min(currentPage + seekRange + 1, totalPages);
        // At the upper bound, this lists how many numbers would be hidden
        // by the seekRange.
        const overflow = Math.max(currentPage + seekRange - totalPages, 0);
        // True if we should show the ellipse. False if the last page is already part of the range.
        const showLastPage = latterPage !== totalPages;
        // An array of numbers between the lower bound and the upper bound.
        const pages = range(Math.max(earlierPage - overflow, 0), latterPage, 1);

        function handleChange(page: number) {
            if (page >= 0 && page <= totalPages - 1) {
                onChange && onChange(page);
            }
        }

        return (
            <Box data-testid={`pagination-component`} className={className} {...props}>
                <button onClick={handleChange.bind(this, currentPage - 1)}>&lt;</button>
                {pages.map((x) => (
                    <button
                        className="page-item"
                        data-current={x === currentPage}
                        data-testid={`pagination-item-${x + 1}`}
                        onClick={handleChange.bind(this, x)}
                        key={`item-${x}`}
                    >
                        {x + 1}
                    </button>
                ))}
                {showLastPage && <Box className="page-item">&nbsp;...&nbsp;</Box>}
                {showLastPage && (
                    <button
                        data-testid={`pagination-item-${totalPages}`}
                        onClick={handleChange.bind(this, totalPages - 1)}
                        className="page-item"
                    >
                        {totalPages}
                    </button>
                )}

                <button onClick={handleChange.bind(this, currentPage + 1)}>&gt;</button>
            </Box>
        );
    },
)`
    & {
        border-radius: 15px;
        background-color: ${(props: any) => props.theme.colors.primary.bg};
        color: ${(props: any) => props.theme.colors.primary.fg};
        padding: 8px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px 0px;
    }

    button {
        background: none;
        outline: none;
        border: none;
        color: ${(props: any) => props.theme.colors.primary.fg};
        font-size: 2rem;
        text-align: middle;
        border-radius: 5px;
        padding: 3px 10px;
        margin: 0px 4px;
        cursor: pointer;
    }

    & .page-item[data-current='true'] {
        background-color: ${(props: any) => props.theme.colors.accent};
    }

    .page-item {
        font-size: 1.25rem;
    }
`;
