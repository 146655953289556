import React from 'react';
import { NavItem } from 'oskcore';
import { FaFileDownload } from 'react-icons/fa';
import { IconButton, IconButtonProps } from '~/atoms';
import { useTheme } from 'styled-components';
import { OSKThemeType } from 'oskcomponents';

export type DownloadPageIndicatorProps = Omit<IconButtonProps, 'variant'>;

export const DownloadPageIndicator = ({ ...props }: DownloadPageIndicatorProps) => {
    const theme = useTheme() as OSKThemeType;
    return (
        <NavItem>
            <IconButton
                p={0}
                icon={FaFileDownload}
                iconSize={theme.navIconSize}
                variant="action"
                hoverable
                {...props}
            />
        </NavItem>
    );
};
