import React from 'react';
import { format } from 'date-fns';
import { Box, Checkbox, Text } from 'oskcomponents';
import { Capture } from 'oskcore';
import { Image } from '~/atoms/Image';

export type CartRowItemProps = {
    item?: Capture;
    thumbnailUri?: string;
    checked: boolean;
    onToggle: () => void;
};

export const CartRowItem = ({ checked, item, onToggle, thumbnailUri }: CartRowItemProps) => {
    if (item === undefined) {
        return <React.Fragment />;
    } else {
        return (
            <Box style={{ color: 'white', padding: '20px' }}>
                <Checkbox
                    id={`${item.id}_toggle`}
                    name={`${item.id}_toggle`}
                    defaultChecked={checked}
                    onChange={onToggle}
                >
                    <Box style={{ paddingLeft: '10px' }}>
                        <Image w={100} h={75} cover src={'/images/satimg2.png'} />
                    </Box>
                    <Box style={{ paddingLeft: '10px' }} col>
                        <Text>{format(new Date(item.collect_start_time), 'yyyy-MM-dd')}</Text>
                        <Text>File {item.id}</Text>
                    </Box>
                </Checkbox>
            </Box>
        );
    }
};
