import React from 'react';
import { useTheme } from 'styled-components';
import { Box, BoxProps } from '../Box';
import { GlobalZIndex } from '../constants';
import { OSKThemeType } from '../DefaultThemeProvider';

export type SidePanelProps = {
    /** Children components to render inside the SidePanel */
    children?: React.ReactNode;
    /** Whether to show the SidePanel or not */
    visible: boolean;
} & Omit<BoxProps, 'ref'>;

/**
 *The SidePanel component is composed with
 * <SidePanelBody /> <SidePanelFooter /> and <SidePanelHeader>. Doing so will create
 * a standardized popup which can be used for any blocking user action.
 */
const SidePanel = React.forwardRef<HTMLDivElement, SidePanelProps>(
    ({ children, visible, style, ...props }: SidePanelProps, ref) => {
        const theme = useTheme() as OSKThemeType;

        return visible ? (
            <Box
                ref={ref}
                style={{
                    width: '311px',
                    backgroundColor: theme.colors.primary.invertedFg,
                    position: 'relative',
                    zIndex: GlobalZIndex.Overlay,
                    height: '100%',
                    ...style,
                }}
                {...props}
                col
            >
                {children}
            </Box>
        ) : (
            <React.Fragment />
        );
    },
);

export { SidePanel };
