import React from 'react';
import { Box, BoxProps } from '../Box';
import CSS from 'csstype';

export type SidePanelBodyProps = {
    /** Children elements to render in the SidePanel body */
    children?: React.ReactNode;
    /** Styles to override */
    style?: CSS.Properties;
} & Omit<BoxProps, 'ref'>;

/**
 * This is a SidePanelBody component which wraps the content area
 * of a SidePanel with standardized padding and height requirements.
 */
const SidePanelBody = ({ children, style, ...props }: SidePanelBodyProps) => {
    return (
        <Box
            style={{
                ...style,
            }}
            {...props}
        >
            {children}
        </Box>
    );
};

export { SidePanelBody };
