import React, { useEffect, useRef, useState } from 'react';
import { SidePanel, SidePanelHeader, TabView } from 'oskcomponents';
import Search from './Search';
import { useDisableFeatureOnMouseOver } from '~/hooks';
import { Capture, noop } from 'oskcore';
import { RootState } from '~/redux/store';
import { connect } from 'react-redux';
import FootprintLayers from './FootprintLayers';

type SearchSidePanelProps = { results: Array<Capture>; searchPanel: boolean };

const SearchSidePanel = ({ results, searchPanel }: SearchSidePanelProps) => {
    const ref = useRef<HTMLDivElement>(null);
    // used to toggle active tab after a search is done
    const [active, switchActive] = useState<number>(0);
    useDisableFeatureOnMouseOver(ref, 'Zoom', true);
    useDisableFeatureOnMouseOver(ref, 'Drag', true);

    useEffect(() => {
        if (results?.length > 0) {
            switchActive(1);
        }
    }, [results]);

    return (
        <SidePanel
            style={{ height: '100%', overflow: 'hidden' }}
            data-testid="Search-SidePanel"
            id="Search-SidePanel"
            ref={ref}
            visible={searchPanel}
        >
            <SidePanelHeader p={15} variant="primary">
                Avaliable Data
            </SidePanelHeader>
            <TabView
                defaultTab={active}
                tabItems={[
                    { label: 'Search', component: <Search /> },
                    { label: 'Results', component: <FootprintLayers /> },
                ]}
                contentStyle={{ height: 0 }}
                tabStyle={{ margin: '0 10px' }}
            />
        </SidePanel>
    );
};

const mapStateToProps = (state: RootState) => {
    const { results, searchPanel } = state.data.search;

    return {
        results,
        searchPanel,
    };
};

export { SearchSidePanel };
export default connect(mapStateToProps, noop)(SearchSidePanel);
