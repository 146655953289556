import { Box, BoxProps, OSKThemeType } from 'oskcomponents';
import React from 'react';
import { useTheme } from 'styled-components';
import { WizardStepProps } from '..';
import { WizardStep } from '../WizardStep';

export type WizardStepListProps = {
    /// An array of steps to render
    steps?: Array<WizardStepProps>;
} & Omit<BoxProps, 'ref'>;

/// This component is a simple white line that divides
/// different steps within a wizard.
const Divider = () => {
    return (
        <Box
            style={{
                justifySelf: 'center',
                alignSelf: 'center',
                margin: '5px',
                backgroundColor: 'white',
                width: '40px',
                height: '1px',
            }}
        />
    );
};

/// The WizardStepList will take an array of steps and render them
/// along with a line separating each step to provide a cohesive
/// container around them.
export const WizardStepList = ({ steps, ...props }: WizardStepListProps) => {
    const theme = useTheme() as OSKThemeType;

    return (
        <Box {...props} bg={theme.colors.primary.transBg} grow>
            {(steps || []).map((step, idx) => {
                return (
                    <Box key={`${step.label}`}>
                        <WizardStep {...step} stepNumber={idx + 1} />
                        {idx + 1 < (steps || []).length && <Divider />}
                    </Box>
                );
            })}
        </Box>
    );
};
