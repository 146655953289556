import React from 'react';
import { Box } from 'oskcomponents';
import { AlertsCard } from '~/molecules/Monitor';
import { FilterOptions } from '../AssetDashboardView';
import { RootState } from '~/redux/store';
import { connect } from 'react-redux';
import { noop } from 'oskcore';
import { AssetDetection, filterAlertsByAsset, filterReportsByAsset } from '~/redux/modules/monitor/app';
import { ProgramReport } from '~/redux/modules/monitor/reports';
import { useNavigate } from 'react-router-dom';
import { getProgramId } from '~/utils';

type AlertsSectionProps = {
    /** Callback to set filter */
    setFilter: (filter: FilterOptions) => void;
    /** Active filter in asset dashboard */
    activeFilter: FilterOptions;
    /** A dictionary of all our dections, with the associated asset id as the key */
    alerts?: Array<AssetDetection>;
    /** A dictionary of all our reports, with the associated asset id as the key */
    reports?: Array<ProgramReport>;
    /** Is fectching asset detections */
    fetching: boolean;
    /** Is fetching selected asset */
    fetchingAsset: boolean;
    /** Selected asset id in url */
    selectedAssetId: string | undefined;
};

const AlertsSection = ({
    fetching,
    fetchingAsset,
    alerts,
    reports,
    setFilter,
    activeFilter,
    selectedAssetId,
}: AlertsSectionProps) => {
    const navigate = useNavigate();

    return (
        <Box data-testid="Alerts-Section">
            <Box grow>
                <AlertsCard
                    onClick={() => {
                        if ((alerts ?? []).length > 0) {
                            navigate(`/program/${getProgramId()}/monitor/alerts/asset/${selectedAssetId}`);
                        }
                    }}
                    label="New Alerts Available"
                    value={alerts?.length.toString() ?? '0'}
                    iconOptions={{ code: 'alert-marker', fill: '#FF0000' }}
                    spinner={fetching || fetchingAsset}
                />
                <Box w={24} />
                <AlertsCard
                    onClick={() => {
                        if ((reports ?? []).length > 0) {
                            navigate(`/program/${getProgramId()}/monitor/reports`);
                        }
                    }}
                    label="New Reports Available"
                    value={reports?.length.toString() ?? '0'}
                    iconOptions={{ code: 'reports', fill: '#4249B3' }}
                    spinner={fetching || fetchingAsset}
                />
            </Box>
        </Box>
    );
};

const mapStateToProps = (state: RootState, props: Partial<AlertsSectionProps>) => {
    const { fetchingDetections, fetchingAsset } = state.monitor.app;
    const { selectedAssetId } = props;
    return {
        alerts: filterAlertsByAsset(state, parseInt(selectedAssetId || '-1')),
        reports: filterReportsByAsset(state, parseInt(selectedAssetId || '-1')),
        fetching: fetchingDetections,
        fetchingAsset,
    };
};

export default connect(mapStateToProps, noop)(AlertsSection);
