import React, { useEffect, useState, useRef } from 'react';

/**
 * This hook will take a ref object and transpose the mouse scrollwheel from
 * a vertical delta to a horizontal delta. Effectively making it so you can
 * scroll length-wise instead of height-wise.
 *
 * @param ref A reference to the container which will be scrolled
 * @param watchers An array of variables to watch for changes which will cause the event to be reapplied
 */
export const useHorizontalScroll = (ref: React.MutableRefObject<any>, watchers: Array<any>) => {
    useEffect(() => {
        const current = ref.current as any;
        const handleMouseWheel = (evt: WheelEvent) => {
            current.scrollLeft += evt.deltaY;
        };

        if (ref && ref.current) {
            current?.addEventListener('mousewheel', handleMouseWheel);
            current?.addEventListener('wheel', handleMouseWheel);
        }

        return () => {
            current?.removeEventListener('mousewheel', handleMouseWheel);
            current?.removeEventListener('wheel', handleMouseWheel);
        };
    }, [ref, ...watchers]);
};

/**
 * This hook willt ake a ref object and interpolate the mouse scrollwheel
 * and apply the delta to the scroll container. This is a stop-gap
 * solution to allow scroll-wheel scroll without a scrollbar present.
 *
 * You should very likely not be using this class without good reason.
 *
 * An okay reason for example is if we have drag-to-scroll enabled
 * and also want to support scroll-wheel-scroll.
 *
 * @param ref A reference to the container which will be scrolled
 * @param watchers An array of variables to watch for changes which will cause the event to be reapplied
 */
export const useVerticalScroll = (ref: React.MutableRefObject<any>, watchers: Array<any>) => {
    useEffect(() => {
        const current = ref.current as any;
        const handleScrollEvent = (evt: WheelEvent) => {
            current.scrollTop += evt.deltaY;
        };

        if (ref && ref.current) {
            current?.addEventListener('mousewheel', handleScrollEvent);
            current?.addEventListener('wheel', handleScrollEvent);
        }

        return () => {
            if (ref && ref.current) {
                current?.removeEventListener('mousewheel', handleScrollEvent);
                current?.removeEventListener('wheel', handleScrollEvent);
            }
        };
    }, [ref, ...watchers]);
};

export type ScrollDirection = 'Horizontal' | 'Vertitcal';
export const useDragScroll = (
    direction: ScrollDirection,
    ref: React.MutableRefObject<HTMLElement | null>,
    watchers: Array<any>,
) => {
    const [dragging, setDragging] = useState(false);

    // This tuple consists of four elements, [scrollX, scrollY, mouseX, mouseY]
    const origin = useRef([0.0, 0.0, 0.0, 0.0]);
    useEffect(() => {
        if (ref && ref.current && ref.current !== null) {
            const current = ref.current as any;

            /**
             * This method will take an event and update the current reference state with
             * a snapshot of all pixel positions including current scroll position and mouse position.
             */
            const updatePosition = (evt: MouseEvent) => {
                if (ref.current !== null) {
                    origin.current = [ref.current.scrollLeft, ref.current.scrollTop, evt.clientX, evt.clientY];
                }
            };

            /** This method will return the distance the mouse has traveled since the origin point */
            const getDistanceTraveled = (evt: MouseEvent) => {
                if (ref.current) {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const [_scrollLeft, _scrollTop, originMouseX, originMouseY] = origin.current;
                    if (direction === 'Vertitcal') {
                        return originMouseY - evt.clientY;
                    } else {
                        return originMouseX - evt.clientX;
                    }
                }

                return 0;
            };

            /** This method will be invoked when the mouse is in transit across the screen. */
            const handleMouseMove = (evt: MouseEvent) => {
                if (ref.current) {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const [scrollLeft, scrollTop, _originMouseX, _originMouseY] = origin.current;
                    const dist = getDistanceTraveled(evt);

                    if (direction === 'Vertitcal') {
                        ref.current.scrollTop = scrollTop + dist;
                    } else if (direction === 'Horizontal') {
                        ref.current.scrollLeft = scrollLeft + dist;
                    }

                    // If we are beyond a given threshold, set the drag state to true
                    // so downstream operations can be aware that we are actively in transit.
                    if (Math.abs(dist) > 10 && !dragging) {
                        setDragging(true);
                    }
                }
            };

            /** This method will be invoked when the mouse has been pressed. */
            const handleMouseDown = (evt: MouseEvent) => {
                if (ref.current) {
                    updatePosition(evt);
                    document.addEventListener('mousemove', handleMouseMove);
                }
            };

            /** This method will be invoked when the mouse has been released after transiting across the screen */
            const handleMouseUp = (evt: MouseEvent) => {
                if (ref.current) {
                    updatePosition(evt);
                    document.removeEventListener('mousemove', handleMouseMove);

                    // Revert dragging state so downstream operations can be aware
                    // that we are no longer dragging. Give this a few milliseconds
                    // buffer to prevent race conditions and possibly allow physics
                    // effects to complete (if applicable).
                    setTimeout(() => {
                        setDragging(false);
                    }, 100);
                }
            };

            current?.addEventListener('mousedown', handleMouseDown);
            document.addEventListener('mouseup', handleMouseUp);

            return () => {
                current?.removeEventListener('mousedown', handleMouseDown);
                document?.removeEventListener('mouseup', handleMouseUp);
                document?.removeEventListener('mousemove', handleMouseMove);
            };
        }
    }, [ref, ...watchers]);

    return [dragging];
};
