import React, { useContext, useEffect, useState } from 'react';
import { ChoiceCard } from '~/atoms/ChoiceCard';
import { WizardContext } from '~/templates';
import { StepScaffold } from '../StepScaffold';

export const DataSourceStep = () => {
    const [selectedPlatforms, setSelectedPlatforms] = useState<Record<string, boolean>>({});
    const ctx = useContext(WizardContext);

    useEffect(() => {
        if (selectedPlatforms['ghost']) {
            ctx.setValidity(true);
        } else {
            ctx.setValidity(false);
        }

        // NOTE: DO NOT ADD `ctx` to the dependency list below, despite suggestions to do so
        // eslint-disable-next-line
    }, [selectedPlatforms]);

    return (
        <StepScaffold title="Select Platform" subTitle="Choose how you would like this request to be fulfilled.">
            <ChoiceCard
                style={{ height: '75px' }}
                selected={selectedPlatforms['ghost'] === true}
                onClick={() => {
                    const nextPlatforms = { ...selectedPlatforms };
                    nextPlatforms['ghost'] = !(selectedPlatforms['ghost'] ?? false);
                    setSelectedPlatforms(nextPlatforms);
                }}
                label="Ghost Constellation"
            />
        </StepScaffold>
    );
};
