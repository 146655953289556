import { noop, OSKGeoJson } from 'oskcore';
import React from 'react';
import { connect } from 'react-redux';
import { AssetDetection, filterDetectionsByAsset } from '~/redux/modules/monitor/app';
import { RootState } from '~/redux/store';
import DetectionMarker from '../DetectionMarker';

export type DetectionMarkerListProps = {
    /** The currently active assetId */
    selectedAssetId?: number;
    /** The detections to render */
    detections?: AssetDetection[];
};

export const DetectionMarkerList = ({ detections }: DetectionMarkerListProps) => {
    return (
        <>
            {(detections ?? []).map((detection, idx) => (
                <DetectionMarker
                    key={`detection-${detection.id}-${idx}`}
                    position={OSKGeoJson.fromAPIGeometry(detection.coordinates)}
                    detection={detection}
                    assetId={detection.asset + ''}
                    detectionId={detection.id + ''}
                />
            ))}
        </>
    );
};

const mapStateToProps = (state: RootState, ownProps: Partial<DetectionMarkerListProps>) => {
    const { selectedAssetId } = ownProps;

    return {
        detections: filterDetectionsByAsset(state, selectedAssetId ?? -1),
    };
};
export default connect(mapStateToProps, noop)(DetectionMarkerList);
