import React from 'react';
import {
    Box,
    Button,
    Form,
    PageScaffold,
    Heading,
    OSKIcon,
    Text,
    OSKThemeType,
    Select,
    ColorMode,
    ThemeModeStorageKey,
} from 'oskcomponents';
import { useTheme } from 'styled-components';
import { PortalContentView } from '~/atoms';
import { AppNames } from '~/constants';

export const AppSettingsDashboard = () => {
    const theme = useTheme() as OSKThemeType;
    const currentTheme = localStorage.getItem(ThemeModeStorageKey) as ColorMode;

    const handleSubmit = (data: any) => {
        localStorage.setItem(ThemeModeStorageKey, data.theme);

        // Refresh the page to load the new theme
        window.location.reload();
    };

    return (
        <PortalContentView
            crumbs={[
                { title: AppNames['monitor'], url: '/' },
                { title: 'Settings', url: '/settings' },
                { title: 'App' },
            ]}
        >
            <PageScaffold
                title={
                    <Box center="vertical">
                        <OSKIcon code="sigma" style={{ paddingRight: '8px' }} />
                        <Heading>SIGMA Data: App Settings</Heading>
                    </Box>
                }
            >
                <Box p={10} col>
                    <Form onSubmit={handleSubmit}>
                        <Box col>
                            <Text>Theme</Text>
                            <Select
                                bg={theme.colors.primary.bg}
                                name="theme"
                                variant="primary"
                                items={[
                                    { label: 'Light', value: 'light' },
                                    { label: 'Dark', value: 'dark' },
                                ]}
                                defaultValue={currentTheme ?? 'dark'}
                            />
                        </Box>
                        <Box style={{ justifyContent: 'flex-end' }}>
                            <Button label="Save Changes" submit variant="neutral" inverted />
                        </Box>
                    </Form>
                </Box>
            </PageScaffold>
        </PortalContentView>
    );
};
