import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { map } from 'lodash';
import store, { AppDispatch, RootState } from '~/redux/store';
import { Capture, OSKGeoJson } from 'oskcore';
import FileFootprint from '../FileFootprint';
import { useMap } from '~/hooks';
import { clearHighlightedFootprint, getFootprints, setHighlightedFootprint } from '~/redux/modules/data/map';
import { dequeueFile, enqueueFile } from '~/redux/modules/data/cart';

type FootprintListProps = {
    /** The list of Data Pipeline Result files to render footprints for */
    files: Array<Capture>;
    /** The list of footprint geometries that will be rendered */
    footprints?: Array<OSKGeoJson>;
    /** If true, results are actively being searched */
    isSearching?: boolean;
    /** The method to invoke when a footprint is clicked */
    onFootprintSelected?: (file: Capture) => void;
    /** The method to invoke when the mouse enters the footprint area */
    onFootprintHoverStart?: (fileId: string) => void;
    /** The method to invoke when the mouse exits the footprint area */
    onFootprintHoverEnd?: (fileId: string) => void;
};

const FootprintList = ({
    onFootprintSelected,
    onFootprintHoverStart,
    onFootprintHoverEnd,
    isSearching,
    files,
    footprints,
}: FootprintListProps) => {
    const leafletMap = useMap();

    useEffect(() => {
        if (isSearching === false) {
            if (footprints && footprints.length > 0) {
                leafletMap.fitCoordinates(footprints);
            }
        }
        // DO NOT ADD leafletMap to this list!!!!!!
        // That object gets recreated each render.
    }, [isSearching, footprints]);

    return (
        <React.Fragment>
            {files &&
                map(files, (file) => {
                    return (
                        <FileFootprint
                            onClick={() => onFootprintSelected && onFootprintSelected(file)}
                            onMouseOver={() => onFootprintHoverStart && onFootprintHoverStart(file.id)}
                            onMouseOut={() => onFootprintHoverEnd && onFootprintHoverEnd(file.id)}
                            key={file.id}
                            fileId={file.id}
                        />
                    );
                })}
        </React.Fragment>
    );
};

const mapStateToProps = (state: RootState) => {
    const { isSearching, results } = state.data.search;

    return {
        isSearching: isSearching,
        files: results,
        footprints: getFootprints(state),
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        onFootprintSelected: (file: Capture) => {
            const { id: fileId, collect_id: collectId } = file;
            if (store.getState().data.cart.enqueued[fileId]) {
                dispatch(dequeueFile(fileId));
            } else {
                dispatch(enqueueFile({ fileId, collectId }, file));
            }
        },
        onFootprintHoverStart: (fileId: string) => {
            dispatch(setHighlightedFootprint(fileId));
        },
        onFootprintHoverEnd: (fileId: string) => {
            dispatch(clearHighlightedFootprint(fileId));
        },
    };
};

export type { FootprintListProps };
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(FootprintList));
