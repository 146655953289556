/* Actions */
export const SET_SHOW_DOWNLOADS = 'SET_SHOW_DOWNLOADS';
export function setShowDownloads(show: boolean) {
    return {
        type: SET_SHOW_DOWNLOADS,
        payload: {
            show,
        },
    };
}

/* Reducer */
type DownloadStateType = {
    showDownloads: boolean;
};

const initialState: DownloadStateType = {
    showDownloads: false,
};

export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        case SET_SHOW_DOWNLOADS: {
            const { show } = action.payload;
            return {
                ...state,
                showDownloads: show,
            };
        }

        default:
            return { ...state };
    }
}
