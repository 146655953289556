import { LatLng } from 'leaflet';
import { isArray } from 'lodash';
import {
    ArrayMapOf,
    Detection,
    DetectionDetectionClassEnum,
    Geometry,
    MapOf,
    OSKGeoJson,
    PublicAsset,
    PublicAssetDetail,
    RasterArtifact,
    SigmaAPI,
} from 'oskcore';
import { AppDispatch, RootState } from '~/redux/store';
import { ProgramReport } from './reports';

/* Types */
export type Asset = PublicAssetDetail | PublicAsset;
export type AssetDetection = Detection & {
    /** A unique external-facing id for this detection */
    external_id: string;
    /** A list of artifacts (files, other data) associated with this detection */
    artifacts: Array<RasterArtifact>;
};

export type AssetReport = {
    /** Unique detection identifier */
    id: number;
    /** ID of the associated asset */
    assetId: number;
    /** Description of the report */
    message: string;
    /** Timestamp of the report */
    date: number;
    /** Download url for the report */
    file: string;
};

/* Actions */

export const SET_FETCHING_ASSETS = 'SET_FETCHING_ASSETS';
export function setFetchingAssets(fetchingAssets: boolean) {
    return { type: SET_FETCHING_ASSETS, payload: { fetchingAssets } };
}

export const SET_FETCHING_ASSET = 'SET_FETCHING_ASSET';
export function setFetchingAsset(fetchingAsset: boolean) {
    return { type: SET_FETCHING_ASSET, payload: { fetchingAsset } };
}

export const ADD_ASSETS = 'ADD_ASSETS';
export function addAssets(assets: Array<Asset>) {
    return {
        type: ADD_ASSETS,
        payload: {
            assets,
        },
    };
}

export const SET_ASSETS = 'SET_ASSETS';
export function setAssets(assets: Array<Asset>) {
    return {
        type: SET_ASSETS,
        payload: {
            assets,
        },
    };
}

export const SET_ASSET_DETAIL = 'SET_ASSET_DETAIL';
export function setAssetDetail(assetDetail: Asset) {
    return {
        type: SET_ASSET_DETAIL,
        payload: {
            assetDetail,
        },
    };
}

export const SET_REPORTS = 'SET_REPORTS';
export function setReports(reports: Array<AssetReport>) {
    return {
        type: SET_REPORTS,
        payload: {
            reports,
        },
    };
}

export const SET_FETCHING_DETECTIONS = 'SET_FETCHING_DETECTIONS';
export function setFetchingDetections(fetchingDetections: boolean) {
    return { type: SET_FETCHING_DETECTIONS, payload: { fetchingDetections } };
}

export const ADD_DETECTIONS = 'ADD_DETECTIONS';
export function addDetections(detections: Array<AssetDetection>) {
    return {
        type: ADD_DETECTIONS,
        payload: {
            detections,
        },
    };
}

export const SET_DETECTIONS = 'SET_DETECTIONS';
export function setDetections(detections: Array<AssetDetection>) {
    return {
        type: SET_DETECTIONS,
        payload: {
            detections,
        },
    };
}

export const TOGGLE_VISIBLE_DETECTION = 'TOGGLE_VISIBLE_DETECTION';
export function toggleVisibleDetection(detectionId: string, visible: boolean) {
    return {
        type: TOGGLE_VISIBLE_DETECTION,
        payload: {
            detectionId,
            visible,
        },
    };
}

export const SET_VISIBLE_DETECTIONS = 'SET_VISIBLE_DETECTIONS';
export function setVisibleDetections(visibleDetections: MapOf<boolean>) {
    return {
        type: SET_VISIBLE_DETECTIONS,
        payload: {
            visibleDetections,
        },
    };
}

export const SET_FETCH_ERROR = 'SET_FETCH_ERROR';
export function setFetchError(error: boolean) {
    return {
        type: SET_FETCH_ERROR,
        payload: {
            error,
        },
    };
}

export const SET_FILTER_DATES = 'SET_FILTER_DATES';
export function setFilterDates(start: Date | undefined, end: Date | undefined) {
    return {
        type: SET_FILTER_DATES,
        payload: {
            start,
            end,
        },
    };
}

export function getDetectionsAsync(
    programId: number,
    aoi?: Geometry | undefined,
    asset?: string | undefined,
    callType?: string | undefined,
    filterStartDate?: Date,
    filterEndDate?: Date,
) {
    return async (dispatch: AppDispatch) => {
        dispatch(setFetchingDetections(true));
        const assetId = asset ? parseInt(asset) : undefined;
        SigmaAPI.listDetections({
            program: programId,
            aoi,
            asset: assetId,
            callType,
            detectedAfter: filterStartDate?.toISOString(),
            detectedBefore: filterEndDate?.toISOString(),
            limit: 500,
        })
            .then((response) => {
                const {
                    data: { results },
                } = response;

                if (results && results.length > 0) {
                    dispatch(addDetections(results as AssetDetection[]));
                    // Mark all of them as visible.
                    const visibleAssets: MapOf<boolean> = {};
                    for (const detection of results as AssetDetection[]) {
                        visibleAssets[detection.id] = true;
                    }
                    dispatch(setVisibleDetections(visibleAssets));
                    dispatch(setFetchingDetections(false));
                } else {
                    dispatch(addDetections([]));
                    dispatch(setFetchingDetections(false));
                }
            })
            .catch((err) => {
                console.error(err);
                dispatch(addDetections([]));
                setFetchingDetections(false);
            });
    };
}

export function getAllDetectionsAsync(programId: number) {
    return async (dispatch: AppDispatch) => {
        dispatch(setFetchingDetections(true));

        const promises = [];
        const assetList = (await SigmaAPI.listAssets({ program: programId }).then((resp) => resp.data.results)) ?? [];

        for (const asset of assetList) {
            promises.push(
                SigmaAPI.listDetections({ program: programId, asset: asset.id, limit: 500 }).then((response) => {
                    dispatch(addDetections(response.data.results as AssetDetection[]));
                }),
            );
        }
        await Promise.all(promises);
    };
}

// SigmaAPI.getThumbnail().then((resp)=> resp.data.)
export function getDetectionZip(): Promise<AssetDetection> {
    const dummyData: AssetDetection = {
        id: '0',
        external_id: '',
        coordinates: toGeoJson(32.094988, -103.718724),
        call_type: 'Leak Detection',
        detection_type: 'Methane Plume',
        capture: 1,
        created_at: '1560150000000',
        asset: 0,
        artifacts: [
            {
                id: 0,
                files: ['artifact1.jpg'],
                processing_level: 0,
                artifact_type: 'image/jpeg',
            },
        ],
    } as any;

    return new Promise((resolve) => {
        setTimeout(() => resolve(dummyData), Math.random() * 500);
    });
}

function toGeoJson(lat: number, lng: number) {
    return OSKGeoJson.fromCoordinate(new LatLng(lat, lng)).toAPIGeometry();
}

export function fetchAssetsAsync(programId: number) {
    return (dispatch: AppDispatch) => {
        dispatch(setFetchingAssets(true));

        SigmaAPI.listAssets({ program: programId })
            .then((response) => {
                if (response.data.results) {
                    dispatch(addAssets(response.data.results as Asset[]));
                }
            })
            .catch((er) => {
                console.log('Error fetching assets', er);
                dispatch(addAssets([]));
                dispatch(setFetchError(true));
                setTimeout(() => {
                    dispatch(setFetchError(false));
                }, 8000);
            })
            .finally(() => {
                dispatch(setFetchingAssets(false));
            });
    };
}

export function fetchAssetAsync(programId: number, assetId: number) {
    return (dispatch: AppDispatch) => {
        dispatch(setFetchingAsset(true));

        SigmaAPI.getAsset({ id: assetId, program: programId }).then((resp) => {
            const { data } = resp;
            if (data) {
                dispatch(setAssetDetail(data));
            }
        }),
            dispatch(setFetchingAsset(false));
    };
}

/* Reducer */
type MonitorAppReducerType = {
    fetchingAssets: boolean;
    fetchingAsset: boolean;
    fetchingDetections: boolean;

    assets: MapOf<Asset>;
    reports: ArrayMapOf<AssetReport>;
    detections: ArrayMapOf<AssetDetection>;

    visibleDetections: MapOf<boolean>;
    fetchError: boolean;

    filterStartDate?: Date;
    filterEndDate?: Date;
};

const initialState: MonitorAppReducerType = {
    fetchingAssets: false,
    fetchingAsset: false,
    fetchingDetections: false,

    assets: {},
    reports: {},
    detections: {},

    visibleDetections: {},
    fetchError: false,

    filterStartDate: undefined,
    filterEndDate: undefined,
};

export default function reducer(state = initialState, action: any): MonitorAppReducerType {
    switch (action.type) {
        case SET_FETCHING_ASSETS: {
            const { fetchingAssets } = action.payload;
            return {
                ...state,
                fetchingAssets,
            };
        }

        case SET_FETCHING_ASSET: {
            const { fetchingAsset } = action.payload;
            return {
                ...state,
                fetchingAsset,
            };
        }

        case ADD_ASSETS: {
            const { assets } = action.payload;
            const assetMap: MapOf<Asset> = {};

            for (const asset of assets) {
                assetMap[asset.id] = asset;
                console.info('adding asset', asset.id, asset, asset.aoi);
            }

            return {
                ...state,
                assets: {
                    ...state.assets,
                    ...assetMap,
                },
            };
        }

        case SET_ASSETS: {
            const { assets } = action.payload;
            const assetMap: MapOf<Asset> = {};

            for (const asset of assets) {
                assetMap[asset.id] = asset;
            }

            return {
                ...state,
                assets: {
                    ...assetMap,
                },
            };
        }

        case SET_ASSET_DETAIL: {
            const { assetDetail } = action.payload;
            const { assets } = state;

            console.info('setting asset detail', assetDetail.id, assetDetail, assetDetail.aoi);

            return {
                ...state,
                assets: {
                    ...assets,
                    [assetDetail.id]: assetDetail,
                },
            };
        }

        case SET_REPORTS: {
            const { reports } = action.payload;
            const reportsMap: ArrayMapOf<AssetReport> = {};

            Object.keys(reports).forEach((id: string) => {
                const detection = reports[id];
                const { assetId } = detection;

                if (!isArray(reportsMap[assetId])) {
                    reportsMap[assetId] = [];
                }

                reportsMap[assetId].push(detection);
            });

            return {
                ...state,
                reports: reportsMap,
            };
        }

        case SET_FETCHING_DETECTIONS: {
            const { fetchingDetections } = action.payload;
            return {
                ...state,
                fetchingDetections,
            };
        }

        case ADD_DETECTIONS: {
            const { detections } = action.payload;
            const detectionsMap: ArrayMapOf<AssetDetection> = {};

            Object.keys(detections).forEach((id: string) => {
                const detection = detections[id];
                const { asset } = detection;

                if (!isArray(detectionsMap[asset])) {
                    detectionsMap[asset] = [];
                }

                detectionsMap[asset].push(detection);
            });

            return {
                ...state,
                detections: {
                    ...state.detections,
                    ...detectionsMap,
                },
            };
        }

        case SET_DETECTIONS: {
            const { detections } = action.payload;
            const detectionsMap: ArrayMapOf<AssetDetection> = {};

            Object.keys(detections).forEach((id: string) => {
                const detection = detections[id];
                const { asset } = detection;

                if (!isArray(detectionsMap[asset])) {
                    detectionsMap[asset] = [];
                }

                detectionsMap[asset].push(detection);
            });

            return {
                ...state,
                detections: {
                    ...detectionsMap,
                },
            };
        }

        case SET_VISIBLE_DETECTIONS: {
            const { visibleDetections } = action.payload;
            return {
                ...state,
                visibleDetections: { ...state.visibleDetections, ...visibleDetections },
            };
        }

        case TOGGLE_VISIBLE_DETECTION: {
            const visibleDetections = Object.assign({}, state.visibleDetections);
            const { detectionId, visible } = action.payload;

            visibleDetections[detectionId] = visible;

            return {
                ...state,
                visibleDetections,
            };
        }

        case SET_FETCH_ERROR: {
            const { error } = action.payload;
            return {
                ...state,
                fetchError: error,
            };
        }

        case SET_FILTER_DATES: {
            const { start, end } = action.payload;
            console.info('setting filter dates', action.payload);
            return {
                ...state,
                filterStartDate: start,
                filterEndDate: end,
            };
        }

        default:
            return { ...state };
    }
}

function filterDetectionByDate(state: RootState, detection: Detection) {
    const hasStartFilter = state.monitor.app.filterStartDate ?? false;
    const hasEndFilter = state.monitor.app.filterEndDate ?? false;

    if (hasStartFilter || hasEndFilter) {
        const startFilteredIn = hasStartFilter
            ? new Date(detection.created_at) > (state.monitor.app.filterStartDate ?? new Date())
            : true;

        const endFilteredIn = hasEndFilter
            ? new Date(detection.created_at) < (state.monitor.app.filterEndDate ?? new Date())
            : true;

        return startFilteredIn && endFilteredIn;
    } else {
        return true;
    }
}

/** Selectors */
export const getDetections = (state: RootState) => state.monitor.app.detections;
export function filterDetectionsBy(state: RootState, assetId: number, detectionClass: string): Array<AssetDetection> {
    if (state.monitor.app.detections[assetId]) {
        return state.monitor.app.detections[assetId]
            .filter((detection) => detection.detection_class === detectionClass)
            .filter((detection) => filterDetectionByDate(state, detection));
    } else {
        return [];
    }
}

export function filterDetectionsByAsset(state: RootState, assetId: number): Array<AssetDetection> {
    return filterDetectionsBy(state, assetId, 'default');
}

export function filterAlertsByAsset(state: RootState, assetId: number): Array<AssetDetection> {
    return filterDetectionsBy(state, assetId, 'alert');
}

export function filterReportsByAsset(state: RootState, assetId: number): Array<ProgramReport> {
    const reports = state.monitor.reports.reports ?? [];
    return (reports as Array<ProgramReport>).filter((x) => x.assets.includes(assetId));
}

export function filterDetectionsByClass(
    state: RootState,
    detectionClass: DetectionDetectionClassEnum,
): Array<AssetDetection> {
    const { detections } = state.monitor.app;

    return Object.keys(detections)
        .flatMap((assetId) => detections[assetId])
        .filter((detection) => detection.detection_class === detectionClass)
        .filter((detection) => filterDetectionByDate(state, detection));
}
