import React, { useRef } from 'react';
import { Header, Content, ContentProps } from 'oskcomponents';
import { OSKNav } from '../../organisms/OSKNav';
import { useNavigate } from 'react-router-dom';
import CSS from 'csstype';

export type OSKViewProps = {
    children?: React.ReactNode;
    /** The style to pass into the <Header /> component */
    headerStyle?: CSS.Properties;
    nopadding?: boolean;
    /** If true, there won't be a header bar at all */
    noheader?: boolean;
    /** Optional flag to hide the gradient */
    nogradient?: boolean;
} & Omit<ContentProps, 'ref'>;

export type OSKHeaderContextProps = {
    ref?: React.MutableRefObject<any>;
};

export const OSKHeaderContext = React.createContext<OSKHeaderContextProps>({
    ref: undefined,
});

export const OSKView = ({ children, headerStyle, noheader, ...props }: OSKViewProps) => {
    const ref = useRef(null);
    const navigate = useNavigate();
    const handleLogoClick = () => {
        navigate('/');
    };

    return (
        <OSKHeaderContext.Provider value={{ ref: ref }}>
            {(noheader === false || noheader === null || noheader === undefined) && (
                <Header style={headerStyle} onLogoClick={handleLogoClick}>
                    <OSKNav ref={ref} />
                </Header>
            )}
            <Content {...props}>{children}</Content>
        </OSKHeaderContext.Provider>
    );
};
