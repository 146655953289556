import React, { Fragment, useState } from 'react';
import { Box, BoxProps } from 'oskcomponents';
import { connect } from 'react-redux';
import { AppDispatch, RootState } from '~/redux/store';
import { OSKIcon } from 'oskcomponents/src/OSKIcon';
import { Capture } from 'oskcore';
import { clearHighlightedFootprint, setHighlightedFootprint } from '~/redux/modules/data/map';
import FootprintRadio from '../FootprintRadio';

export type FootprintLayerGroupPropsRequired = {
    /** ID for the a collection of footprints */
    captureId: string;
    /** List of footprints from on Collection */
    captures: Capture[];
};

export type FootprintLayerGroupProps = {
    /** The method to invoke when a footprint is clicked */
    onFootprintSelected?: (file: Capture) => void;
    /** The method to invoke when the mouse enters the footprint area */
    onFootprintHoverStart?: (fileId: string) => void;
    /** The method to invoke when the mouse exits the footprint area */
    onFootprintHoverEnd?: (fileId: string) => void;
} & FootprintLayerGroupPropsRequired &
    Omit<BoxProps, 'ref'>;

const FootprintLayerGroup = ({
    captures,
    captureId,
    onFootprintHoverStart,
    onFootprintHoverEnd,
    ...props
}: FootprintLayerGroupProps) => {
    const [open, toggleOpen] = useState<boolean>(true);

    return (
        <Fragment>
            <Box mb={5} style={{ justifyContent: 'space-between' }} {...props}>
                <OSKIcon code="AOI" />
                <FootprintRadio
                    grow
                    header={true}
                    name={`Collection ${captureId}`}
                    fileId={'-1'}
                    style={{ marginLeft: '20px', alignSelf: 'center' }}
                />
                <OSKIcon mt={3} onClick={() => toggleOpen(!open)} code={open ? 'visibility' : 'visibility-off'} />
            </Box>
            {open ? (
                <Box col style={{ width: '133px', alignSelf: 'center' }}>
                    {captures &&
                        captures.map((capture, idx) => {
                            const { collect_start_time, id } = capture;

                            return (
                                <FootprintRadio
                                    key={`${id}_${idx}`}
                                    fileId={id}
                                    date={collect_start_time}
                                    name={`Footprint ${id}`}
                                    onMouseOver={() => onFootprintHoverStart && onFootprintHoverStart(id)}
                                    onMouseOut={() => onFootprintHoverEnd && onFootprintHoverEnd(id)}
                                />
                            );
                        })}
                </Box>
            ) : (
                <Box />
            )}
        </Fragment>
    );
};

const mapStateToProps = (state: RootState, ownProps: FootprintLayerGroupPropsRequired) => {
    const { captures, captureId } = ownProps;
    return {
        captureId,
        captures,
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        onFootprintHoverStart: (fileId: string) => {
            dispatch(setHighlightedFootprint(fileId));
        },
        onFootprintHoverEnd: (fileId: string) => {
            dispatch(clearHighlightedFootprint(fileId));
        },
    };
};

export { FootprintLayerGroup };
export default connect(mapStateToProps, mapDispatchToProps)(FootprintLayerGroup);
