import React from 'react';
import ProgramAlertsGrid from '~/organisms/ProgramAlertsGrid';
import { OSKContentView } from '~/molecules/OSKContentView';
import { useEnsureProgram } from '~/hooks';
import { connect } from 'react-redux';
import { noop } from 'oskcore';
import { DataProvider } from '~/redux';
import { getProgramId } from '~/utils';

const ProgramAlertsView = () => {
    useEnsureProgram();

    return (
        <DataProvider programId={getProgramId()} features={['alerts', 'assets']}>
            <OSKContentView>
                <ProgramAlertsGrid />
            </OSKContentView>
        </DataProvider>
    );
};

export default connect(noop, noop)(ProgramAlertsView);
