import { Opportunity, OSKGeoJson, SigmaAPI } from 'oskcore';
import { Position } from 'geojson';
import { AppDispatch } from '../../store';

export type TaskingInstance = {
    taskId: number;
    userTaskName: string;
    createdOn: number;
    dataSource: string;
    startDate: number;
    endDate: number;
    coverage: number;
    coordinates: string;
    status: string;
    fileId: number;
};

export type AOIProgress = {
    name: string;
    steps: AOIStep[];
    aoi?: OSKGeoJson;
    errorReason?: string;
};

export type AOIStep = {
    name: string;
    timestamp?: number;
    current?: boolean;
};

export type Tick = {
    start_time: Date;
    end_time: Date;
    nadir_fov: OSKGeoJson;
    sensor: number;
};

/********************
 Actions
 ********************/

const SET_LOADING_AOI_LIST = 'SET_LOADING_AOI_LIST';
export function setLoadingAoiList(isLoading: boolean) {
    return {
        type: SET_LOADING_AOI_LIST,
        payload: { isLoading },
    };
}

const SET_LOADING_TASKING_LIST = 'SET_LOADING_TASKING_LIST';
export function setLoadingTaskingList(isLoading: boolean) {
    return {
        type: SET_LOADING_TASKING_LIST,
        payload: { isLoading },
    };
}

const SET_TASKING_LIST = 'SET_TASKING_LIST';
export function setTaskingList(taskingList: Array<TaskingInstance>) {
    return {
        type: SET_TASKING_LIST,
        payload: { taskingList },
    };
}

const SET_ACTIVE_TASKING_INSTANCE = 'SET_ACTIVE_TASKING_INSTANCE';
export function setActiveTaskingInstance(taskingInstance: TaskingInstance) {
    return {
        type: SET_ACTIVE_TASKING_INSTANCE,
        payload: { taskingInstance },
    };
}

const SET_ACTIVE_AOI_LIST = 'SET_ACTIVE_AOI_LIST';
export function setActiveAoiList(aoiList: Array<AOIProgress>) {
    return {
        type: SET_ACTIVE_AOI_LIST,
        payload: { aoiList },
    };
}

export function loadTaskingInstanceWithAssociatedData(instance: TaskingInstance) {
    return (dispatch: AppDispatch) => {
        dispatch(setActiveTaskingInstance(instance));
        dispatch(setLoadingAoiList(true));

        getAOIProgress(instance)
            .then((aoiList) => {
                dispatch(setActiveAoiList(aoiList));
            })
            .finally(() => {
                dispatch(setLoadingAoiList(false));
            });
    };
}

/**
 * An opportunity window represents a group of ticks returned from the propagator
 * which have been aggregated based on start_time and one orbital period for the given
 * sensor which generated the ticks.
 */
export type OpportunityWindow = {
    /** An array of ticks associated with this opportunity. */
    ticks: Array<Opportunity>;
    /** The sensor which corresponds with the ticks. */
    sensor: number;
    /** The moment in which the sensor will pass over the first tick in the list. */
    start_time: Date;
    /** The moment in which the sensor will stop passing over the last tick in the list. */
    end_time: Date;
    /** The TLE of the datasource for this opportunity. */
    tle: string;
};

/**
 * The TLE map. Key is sensor_id, value is the associated TLE.
 */
export const SensorTLEMap: Record<number, string> = {
    1: `DEMO9 (AURORA)
1 48956U 21059CF  22137.58563099  .00007241  00000+0  38541-3 0  9996
2 48956  97.5518 268.0314 0016615 203.3094 156.7387 15.15421030 49308`,
    2: `DEMO9 (AURORA)
1 48956U 21059CF  22137.58563099  .00007241  00000+0  38541-3 0  9996
2 48956  97.5518 268.0314 0016615 203.3094 156.7387 15.15421030 49308`,
};

/** Fake data */
function generateGeoJson(paths: Position[][]) {
    return OSKGeoJson.fromGeoJSON({
        type: 'FeatureCollection',
        features: [
            {
                type: 'Feature',
                properties: {},
                geometry: {
                    type: 'Polygon',
                    coordinates: paths,
                },
            },
        ],
    });
}

const pass_1 = new Date('May 22 2022 01:09:09 GMT-0000');
const pass_2 = new Date('May 22 2022 08:09:09 GMT-0000');
function generateTick(date: Date, coordinates: Position[][]): Tick {
    return {
        start_time: date,
        end_time: new Date(date.getTime() + 1000 * 10),
        sensor: 1,
        nadir_fov: generateGeoJson(coordinates),
    };
}

export const fetchOpportunityAsync = (area: OSKGeoJson) => {
    return new Promise<Array<Opportunity>>((resolve, reject) => {
        SigmaAPI.searchOrbits({
            aoi: JSON.stringify(area.toAPIGeometry()) as any,
            limit: 100000,
        })
            .then((resp) => {
                resolve(resp.data.results ?? []);
            })
            .catch((ex) => {
                console.error(ex);
                reject(ex);
            });
    });
};

export function getTaskingItems(): Promise<TaskingInstance[]> {
    let incr = 0;
    const dummyData: TaskingInstance[] = [
        {
            taskId: ++incr,
            userTaskName: 'Crop Field',
            createdOn: 1560150000000,
            dataSource: 'Ghost 1&2',
            startDate: 1656963000000,
            endDate: 1657827000000,
            coverage: 0.85,
            coordinates: '43.125043,-99.773712',
            status: 'Failed',
            fileId: ++incr,
        },
        {
            taskId: ++incr,
            userTaskName: 'Oil Field',
            createdOn: 1560150000000,
            dataSource: 'Ghost 1&2',
            startDate: 1656963000000,
            endDate: 1657827000000,
            coverage: 0.85,
            coordinates: '43.125043,-99.773712',
            status: 'Done',
            fileId: ++incr,
        },
        {
            taskId: ++incr,
            userTaskName: 'Oil Pipeline',
            createdOn: 1560150000000,
            dataSource: 'Ghost 1&2',
            startDate: 1656963000000,
            endDate: 1657827000000,
            coverage: 0.85,
            coordinates: '43.125043,-99.773712',
            status: 'Pending',
            fileId: ++incr,
        },
        {
            taskId: ++incr,
            userTaskName: 'Crop Field',
            createdOn: 1560150000000,
            dataSource: 'Ghost 1&2',
            startDate: 1656963000000,
            endDate: 1657827000000,
            coverage: 0.85,
            coordinates: '43.125043,-99.773712',
            status: 'Failed',
            fileId: ++incr,
        },
        {
            taskId: ++incr,
            userTaskName: 'Oil Pipeline',
            createdOn: 1560150000000,
            dataSource: 'Ghost 1&2',
            startDate: 1656963000000,
            endDate: 1657827000000,
            coverage: 0.85,
            coordinates: '43.125043,-99.773712',
            status: 'Pending',
            fileId: ++incr,
        },
        {
            taskId: ++incr,
            userTaskName: 'Crop Field',
            createdOn: 1560150000000,
            dataSource: 'Ghost 1&2',
            startDate: 1656963000000,
            endDate: 1657827000000,
            coverage: 0.85,
            coordinates: '43.125043,-99.773712',
            status: 'Failed',
            fileId: ++incr,
        },
        {
            taskId: ++incr,
            userTaskName: 'Oil Pipeline',
            createdOn: 1560150000000,
            dataSource: 'Ghost 1&2',
            startDate: 1656963000000,
            endDate: 1657827000000,
            coverage: 0.85,
            coordinates: '43.125043,-99.773712',
            status: 'Pending',
            fileId: ++incr,
        },
        {
            taskId: 7,
            userTaskName: 'Crop Field',
            createdOn: 1560150000000,
            dataSource: 'Ghost 1&2',
            startDate: 1656963000000,
            endDate: 1657827000000,
            coverage: 0.85,
            coordinates: '43.125043,-99.773712',
            status: 'Failed',
            fileId: ++incr,
        },
        {
            taskId: 8,
            userTaskName: 'Oil Pipeline',
            createdOn: 1560150000000,
            dataSource: 'Ghost 1&2',
            startDate: 1656963000000,
            endDate: 1657827000000,
            coverage: 0.85,
            coordinates: '43.125043,-99.773712',
            status: 'Pending',
            fileId: ++incr,
        },
    ];

    return new Promise((resolve) => {
        setTimeout(() => resolve(dummyData), Math.random() * 500);
    });
}

export function getAOIProgress(task: TaskingInstance): Promise<AOIProgress[]> {
    const CropField1: AOIProgress = {
        name: 'Crop Field 1',
        aoi: generateGeoJson([
            [
                [-77.69411087036133, 36.44752296079999],
                [-77.69685745239258, 36.44089461503619],
                [-77.66321182250977, 36.43412759468366],
                [-77.6633834838867, 36.44089461503619],
                [-77.69411087036133, 36.44752296079999],
            ],
        ]),
        steps: [
            {
                name: 'Created',
                timestamp: new Date('09/10/2019').getTime(),
            },
            {
                name: 'Pending',
                timestamp: new Date('09/10/2019').getTime(),
                current: true,
            },
            {
                name: 'Done',
            },
        ],
    };
    const CropField2: AOIProgress = {
        name: 'Crop Field 2',
        aoi: generateGeoJson([
            [
                [-77.72466659545898, 36.45387459411652],
                [-77.72380828857422, 36.445589751779174],
                [-77.69685745239258, 36.44130890323576],
                [-77.6949691772461, 36.44752296079999],
                [-77.72466659545898, 36.45387459411652],
            ],
        ]),
        steps: [
            {
                name: 'Created',
                timestamp: new Date('09/10/2019').getTime(),
            },
            {
                name: 'Pending',
                timestamp: new Date('09/10/2019').getTime(),
            },
            {
                name: 'Done',
                current: true,
            },
        ],
    };

    const OilField: AOIProgress = {
        name: 'Oil Field 1',
        errorReason: 'Too many clouds',
        aoi: generateGeoJson([
            [
                [-77.69411087036133, 36.44752296079999],
                [-77.69685745239258, 36.44089461503619],
                [-77.66321182250977, 36.43412759468366],
                [-77.6633834838867, 36.44089461503619],
                [-77.69411087036133, 36.44752296079999],
            ],
        ]),
        steps: [
            {
                name: 'Created',
                timestamp: new Date('09/10/2019').getTime(),
            },
            {
                name: 'Pending',
                timestamp: new Date('09/10/2019').getTime(),
                current: true,
            },
            {
                name: 'Done',
            },
        ],
    };

    const OilPipeline1: AOIProgress = {
        name: 'Pipeline Segment 1',
        aoi: generateGeoJson([
            [
                [-80.16311645507812, 36.03466342844732],
                [-80.17135620117188, 35.99356320483023],
                [-79.79644775390625, 35.94465937365276],
                [-79.79919433593749, 35.99578538642032],
                [-80.16311645507812, 36.03466342844732],
            ],
        ]),
        steps: [
            {
                name: 'Created',
                timestamp: new Date('09/10/2019').getTime(),
            },
            {
                name: 'Tasked',
                timestamp: new Date('09/10/2019').getTime(),
                current: true,
            },
            {
                name: 'Uplink Telemetry',
            },
            {
                name: 'In Orbit',
            },
            {
                name: 'Downlink Telemetry',
            },
            {
                name: 'Done',
            },
        ],
    };

    const OilPipeline2: AOIProgress = {
        name: 'Pipeline Segment 2',
        aoi: generateGeoJson([
            [
                [-80.6561279296875, 36.061311571582486],
                [-80.70281982421875, 36.02133597448135],
                [-80.2001953125, 35.98467385243838],
                [-80.19195556640625, 36.03244234269516],
                [-80.6561279296875, 36.061311571582486],
            ],
        ]),
        steps: [
            {
                name: 'Created',
                timestamp: new Date('09/10/2019').getTime(),
            },
            {
                name: 'Tasked',
                timestamp: new Date('09/10/2019').getTime(),
            },
            {
                name: 'Uplink Telemetry',
                timestamp: new Date('09/10/2019').getTime(),
            },
            {
                name: 'In Orbit',
                timestamp: new Date('09/10/2019').getTime(),
                current: true,
            },
            {
                name: 'Downlink Telemetry',
            },
            {
                name: 'Done',
            },
        ],
    };

    const OilPipeline3: AOIProgress = {
        name: 'Pipeline Segment 3',
        aoi: generateGeoJson([
            [
                [-81.27822875976562, 36.09682839442643],
                [-81.30844116210938, 36.05798104702501],
                [-80.71929931640624, 36.02688935430189],
                [-80.70144653320312, 36.06908224732973],
                [-81.27822875976562, 36.09682839442643],
            ],
        ]),
        steps: [
            {
                name: 'Created',
                timestamp: new Date('09/10/2019').getTime(),
            },
            {
                name: 'Tasked',
                timestamp: new Date('09/10/2019').getTime(),
            },
            {
                name: 'Uplink Telemetry',
                timestamp: new Date('09/10/2019').getTime(),
            },
            {
                name: 'In Orbit',
                timestamp: new Date('09/10/2019').getTime(),
            },
            {
                name: 'Downlink Telemetry',
                timestamp: new Date('09/10/2019').getTime(),
            },
            {
                name: 'Done',
                timestamp: new Date('09/10/2019').getTime(),
                current: true,
            },
        ],
    };

    const OilPipeline4: AOIProgress = {
        name: 'Pipeline Segment 4',
        aoi: generateGeoJson([
            [
                [-81.28646850585938, 36.10237644873644],
                [-81.73828125, 36.12900165569652],
                [-81.94290161132811, 36.136765640434554],
                [-81.94015502929688, 36.06908224732973],
                [-81.3262939453125, 36.05687084084707],
                [-81.28646850585938, 36.10237644873644],
            ],
        ]),
        errorReason: 'Uplink failed 9/10/2019',
        steps: [
            {
                name: 'Created',
                timestamp: new Date('09/10/2019').getTime(),
            },
            {
                name: 'Tasked',
                timestamp: new Date('09/10/2019').getTime(),
            },
            {
                name: 'Uplink Telemetry',
                timestamp: new Date('09/10/2019').getTime(),
                current: true,
            },
            {
                name: 'In Orbit',
            },
            {
                name: 'Downlink Telemetry',
            },
            {
                name: 'Done',
            },
        ],
    };

    const cropFieldResults = [CropField1, CropField2];
    const oilFieldResults = [OilField];
    const oilPipelineResults = [OilPipeline1, OilPipeline2, OilPipeline3, OilPipeline4];

    let dummyData: AOIProgress[] = [];
    switch (task.userTaskName) {
        case 'Crop Field':
            dummyData = cropFieldResults;
            break;
        case 'Oil Field':
            dummyData = oilFieldResults;
            break;
        case 'Oil Pipeline':
            dummyData = oilPipelineResults;
            break;
    }

    return new Promise((resolve) => {
        setTimeout(() => resolve(dummyData), 800 + Math.random() * 500);
    });
}

/* Reducer */
type TaskingReducerType = {
    isLoadingTaskingList: boolean;
    isLoadingAois: boolean;
    taskingList: Array<TaskingInstance>;
    activeTaskingInstance: TaskingInstance | null;
    activeAoiList: Array<AOIProgress>;
};

const initialState: TaskingReducerType = {
    isLoadingTaskingList: false,
    isLoadingAois: false,
    activeTaskingInstance: null,
    taskingList: [],
    activeAoiList: [],
};
export default function reducer(state = initialState, action: any): TaskingReducerType {
    switch (action.type) {
        case SET_LOADING_AOI_LIST: {
            const { isLoading } = action.payload;
            return {
                ...state,
                isLoadingAois: isLoading,
            };
        }

        case SET_ACTIVE_TASKING_INSTANCE: {
            const { taskingInstance } = action.payload;
            return {
                ...state,
                activeTaskingInstance: taskingInstance,
            };
        }

        case SET_LOADING_TASKING_LIST: {
            const { isLoading } = action.payload;
            return {
                ...state,
                isLoadingTaskingList: isLoading,
            };
        }

        case SET_TASKING_LIST: {
            const { taskingList } = action.payload;
            return {
                ...state,
                isLoadingTaskingList: false,
                taskingList,
            };
        }

        case SET_ACTIVE_AOI_LIST: {
            const { aoiList } = action.payload;
            return {
                ...state,
                isLoadingAois: false,
                activeAoiList: aoiList,
            };
        }

        default:
            return { ...state };
    }
}
