import { noop } from 'oskcore';
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { fetchAssetAsync, fetchAssetsAsync, getAllDetectionsAsync, getDetectionsAsync } from './modules/monitor/app';
import { fetchProgramReportsAsync } from './modules/monitor/reports';
import { RootState } from './store';

const latch: Record<string, boolean> = {};
/**
 * This method will take a cache key. If the key has never been used before,
 * it will populate the cache and then fetch the data
 * by invoking the second parameter.
 */
export function fetchDataOnce(key: string, doFetch: () => void) {
    if (latch[key] === undefined) {
        latch[key] = true;
        doFetch();
    }
}

/** The feature you would like to request from the backend */
export type DataFeatures = 'asset' | 'assets' | 'alerts' | 'detections' | 'reports';
export type DataProviderProps = {
    /** The children component to render. This should be your main content. */
    children: React.ReactNode;
    /** Optional programId, necessary for some features */
    programId?: number;
    /** Optional assetId, necessary for some features */
    assetId?: string;
    /** The list of features you are requested from the backend */
    features: DataFeatures[];
    /** Optional date filter from redux */
    filterStartDate?: Date;
    /** Optional date filter from redux */
    filterEndDate?: Date;
};

/**
 * DataProvider is a component which you can wrap any view or content with, specify some
 * optional parameters, and a list of features. It will take care of efficiently fetching
 * the necessary data (if it hasn't already been fetched). This is useful in case you want
 * access to information. It uses a layer of caching, so the data will only be fetched once.
 *
 * Example usage:
 * ```
 * <DataProvider programId={6} features={['assets']}>
 *  <AssetView />
 * </DataProvider>
 * ```
 */
const DataProvider = ({
    children,
    assetId,
    programId,
    features,
    filterStartDate,
    filterEndDate,
}: DataProviderProps) => {
    const dispatch = useDispatch();

    useEffect(() => {
        features.includes('asset') &&
            programId &&
            assetId &&
            fetchDataOnce(`fetch_asset_${assetId}`, () => dispatch(fetchAssetAsync(programId, parseInt(assetId))));

        features.includes('assets') &&
            programId &&
            fetchDataOnce(`fetch_assets_for_program_${programId}`, () => dispatch(fetchAssetsAsync(programId)));

        features.includes('detections') &&
            programId &&
            assetId &&
            fetchDataOnce(`fetch_detections_for_map_${programId}_${assetId}_${filterStartDate}_${filterEndDate}`, () =>
                dispatch(getDetectionsAsync(programId, undefined, assetId, undefined, filterStartDate, filterEndDate)),
            );

        features.includes('alerts') &&
            programId &&
            fetchDataOnce(`fetch_alerts_for_map_${programId}`, () => dispatch(getAllDetectionsAsync(programId)));

        features.includes('reports') &&
            programId &&
            fetchDataOnce(`fetch_reports_for_program_${programId}`, () =>
                dispatch(fetchProgramReportsAsync(programId)),
            );
    }, [programId, assetId, filterStartDate, filterEndDate, dispatch, features]);

    return <React.Fragment>{children}</React.Fragment>;
};

const mapStateToProps = (state: RootState) => {
    const { filterStartDate, filterEndDate } = state.monitor.app;

    return {
        filterStartDate,
        filterEndDate,
    };
};

const ConnectedProvider = connect(mapStateToProps, noop)(DataProvider);
export { ConnectedProvider as DataProvider };
