import React from 'react';
import { Slider, SliderItem, SliderProps } from '~/atoms';
import { format } from 'date-fns';
import { reverse } from 'lodash';
import { TimelineMode } from '~/redux/modules/data/search';
import { Box } from 'oskcomponents';
import { PartitionBlurb } from './PartitionBlurb';
import { interpolate } from './timeUtils';

export type TimeSliderProps = {
    /** The TimelineMode which is currently active. This will affect the ganularity of interpolation
     * as well as the format of the partitions. */
    mode: TimelineMode;
    /** The earliest date to consider. If null, it will be defaulted to a reasonable value. */
    earliestDate?: Date;
    /** The latest date to consider. */
    latestDate: Date;
    /** The currently selected date */
    selectedDate?: Date;
    /** The method to invoke when a particular entry is selected. */
    onSelect?: (item: SliderItem | undefined, idx: number) => boolean;
} & SliderProps;

function computeLabel(day: Date, mode: TimelineMode) {
    if (mode === 'yearly') {
        return '';
    } else if (mode === 'monthly') {
        return format(day, 'LLL');
    } else {
        return format(day, 'dd');
    }
}

const PartitionStyle = {
    minWidth: '50px',
};

export const TimeSlider = ({ mode, earliestDate, latestDate, onSelect, selectedDate, ...props }: TimeSliderProps) => {
    const options: Array<SliderItem> = [{ label: 'All', value: null }];

    const steps = reverse(interpolate(earliestDate, latestDate, mode));
    let previousDay = new Date(); //.getFullYear();

    // Create the partitions in the timeline
    steps.forEach((day, idx) => {
        const elements = [];

        if (idx === 0 || day.getFullYear() !== previousDay.getFullYear()) {
            elements.push(
                <PartitionBlurb
                    style={PartitionStyle}
                    key={`partition_blurb_year_${idx}`}
                    label={format(day, 'yyyy')}
                />,
            );
        }

        if (mode === 'daily' && (idx === 0 || day.getMonth() !== previousDay.getMonth())) {
            elements.push(
                <PartitionBlurb
                    style={PartitionStyle}
                    key={`partition_blurb_month_${idx}`}
                    label={format(day, 'LLL')}
                />,
            );
        }

        if (elements.length > 0) {
            options.push({
                element: (
                    <Box key={`partition_container_${idx}`} col>
                        {elements}
                    </Box>
                ),
            });
        }

        previousDay = day;

        // Add the actual interpolated entry.
        options.push({
            label: computeLabel(day, mode),
            value: day,
        });
    });

    // Compute the selected index and propagate
    const selectedIndex = options.reduce((prev, item, idx) => {
        if (`${item.value}` === `${selectedDate}`) {
            return idx;
        } else {
            return prev;
        }
    }, 0);

    return <Slider onSelect={onSelect} selectedIdx={selectedIndex} options={options} {...props} />;
};
