import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { AdminPortal } from './views/AdminPortal';
import { HomeView } from './views/HomeView';
import NotFoundView from './views/NotFoundView';
import { ProgramView } from './views/ProgramView';
import { UserSettingsPortal } from './views/UserSettingsPortal';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { OSKThemeType } from 'oskcomponents';
import { getBaseApi, isDev } from 'oskcore';
import { useProfileMetadata } from 'oskcore/src/hooks/useProfileMetadata';
import { createRoutesFromChildren, matchRoutes, Route, Routes, useLocation, useNavigationType } from 'react-router-dom';
import { createGlobalStyle, useTheme } from 'styled-components';
import { ProfileSchema } from './organisms/forms/ProfileForm';
import { doPopulateAppAsync } from './redux/modules/osk';
import { configureUserSession, setSessionProgramId } from './redux/modules/session';
import { AppDispatch, RootState } from './redux/store';
import MonitorDashboardView from './views/MonitorDashboardView';
import MonitorMapView from './views/MonitorMapView';
import MonitorReportsView from './views/MonitorReportsView';
import { ProfileSetupView } from './views/ProfileSetupView';
import { RedirectToMonitorView } from './views/RedirectToMonitorView';
import { RedirectToHomeView } from './views/RedirectToHomeView';
import TaskingDashboardView from './views/TaskingDashboardView';
import TaskingView from './views/TaskingView';
import ProgramAlertsView from './views/ProgramAlertsView';
import AssetsView from './views/ProgramView/AssetsView';
import { getProgramId } from './utils';

const GlobalStyle = createGlobalStyle`
    body {
        background-color: ${(props: any) => props.theme.colors.primary.bg};
        color: ${(props: any) => props.theme.colors.primary.fg};
    }
`;

let SentryRoutes = Routes;

if (!isDev()) {
    // Compute the base api path, without the protocol
    const apiPath = getBaseApi().replace(/^https?:\/\//, '');
    Sentry.init({
        dsn: 'https://af2032e9c1524a0198e925499fe10105@o1355244.ingest.sentry.io/6639454',
        integrations: [
            new BrowserTracing({
                tracingOrigins: ['localhost', apiPath, /^\//],
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                    React.useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes,
                ),
            }),
        ],
        tracesSampleRate: 1.0,
    });

    SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
}

type AppProps = {
    initializing: boolean;
    initializeData: () => void;
};

function App({ initializing, initializeData }: AppProps) {
    const dispatch = useDispatch();
    const initialized = useRef(false);
    const theme = useTheme() as OSKThemeType;

    // Fetch user profile
    const [profile] = useProfileMetadata();
    const [profileValidated, setProfileValidated] = useState<boolean | null>(null);

    // This effect will handle all one-shot processing operations
    // on-load.
    useEffect(() => {
        if (initialized.current === false) {
            initialized.current = true;
            // Initialize data
            initializeData();
        }

        // Check whether the profile is validated
        ProfileSchema.validate(profile)
            .then(() => {
                setProfileValidated(true);
                // Configure the user details in redux
                dispatch(configureUserSession(profile));
                // Configure the programId
                const activeProgramId = getProgramId();
                const programList = profile.programs ?? [];
                const activeProgramList = programList.filter((program) => program.active);

                // Configure the program_id if applicable.
                if (activeProgramId !== null && activeProgramId >= 0) {
                    // Configure active program_id based on local storage.
                    dispatch(setSessionProgramId(activeProgramId));
                } else if (activeProgramList.length > 0) {
                    dispatch(setSessionProgramId(activeProgramList[0].id));
                } else if (programList.length > 0) {
                    dispatch(setSessionProgramId(programList[0].id));
                }
            })
            .catch(() => setProfileValidated(false));
    }, [profile, dispatch, initializeData]);

    return (
        <React.Fragment>
            <GlobalStyle theme={theme} />
            {/* If the profile is validated, we can render the main app */}
            {profileValidated && !initializing && (
                <React.Fragment>
                    <SentryRoutes>
                        <Route path="/map" element={<RedirectToHomeView />} />
                        <Route path="/map/:position/" element={<HomeView />} />
                        {/* <Route path="/" element={<RedirectToHomeView />} /> */}
                        <Route path="/" element={<RedirectToMonitorView />} />
                        <Route path={'/admin'} element={<AdminPortal />} />
                        <Route path={'/admin/:view'} element={<AdminPortal />} />
                        <Route path="/settings" element={<UserSettingsPortal />} />
                        <Route path={'/settings/:view'} element={<UserSettingsPortal />} />
                        <Route path="/program/:id/*" element={<ProgramView />} />
                        <Route path="/tasking" element={<TaskingView />} />
                        <Route path="/tasking/dashboard" element={<TaskingDashboardView />} />
                        <Route path={'/monitor'} element={<MonitorDashboardView />} />
                        <Route path={'/program/:programId/monitor'} element={<MonitorDashboardView />} />
                        <Route path={'/program/:programId/monitor/:assetId/grid'} element={<MonitorDashboardView />} />
                        <Route path="/program/:programId/monitor/:assetId/map" element={<MonitorMapView />} />
                        <Route
                            path="/program/:programId/monitor/:assetId/map/alert/:alertId"
                            element={<MonitorMapView />}
                        />
                        <Route path={'/program/:programId/monitor/reports'} element={<MonitorReportsView />} />
                        <Route path={'/program/:programId/monitor/alerts'} element={<ProgramAlertsView />} />
                        <Route
                            path={'/program/:programId/monitor/alerts/asset/:assetId'}
                            element={<ProgramAlertsView />}
                        />
                        <Route path={'/monitor/alerts'} element={<ProgramAlertsView />} />
                        <Route path={'/program/:programId/asset/:assetId'} element={<AssetsView />} />
                        <Route element={<NotFoundView />} />
                    </SentryRoutes>
                </React.Fragment>
            )}

            {/* If the profile is not validated, we must require the user to fill it out */}
            {profileValidated === false && <ProfileSetupView />}
        </React.Fragment>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        initializing: state.osk.initializing,
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        initializeData: () => {
            dispatch<any>(doPopulateAppAsync());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
