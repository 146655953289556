import React, { useContext } from 'react';
import { Box, BoxProps } from 'oskcomponents';
import { ViewContainerContext } from '../ViewContainer';

export type ViewContentProps = {
    /** The view key that, when active, will cause this content box to render */
    viewKey: string;
    /** The children component to render */
    children?: React.ReactNode;
} & Omit<BoxProps, 'ref'>;

export const ViewContent = ({ children, viewKey, ...props }: ViewContentProps) => {
    const context = useContext(ViewContainerContext);
    if (context.activeView === viewKey) {
        return <Box {...props}>{children}</Box>;
    } else {
        return <React.Fragment />;
    }
};
