import React from 'react';
import { Box, OSKThemeType, Spinner } from 'oskcomponents/src';
import { OSKIcon, IconProps } from 'oskcomponents/src/OSKIcon';
import { Text } from 'oskcomponents/src/Typography';
import { useTheme } from 'styled-components';

type AlertsCardProps = {
    iconOptions: IconProps;
    label: string;
    value: string;
    onClick?: () => void;
    isActive?: boolean;
    spinner?: boolean;
};

const AlertsCard = ({ label, value, spinner, isActive, iconOptions, onClick, ...props }: AlertsCardProps) => {
    const theme = useTheme() as OSKThemeType;

    return (
        <Box
            p={8}
            mt={12}
            mb={12}
            style={{
                backgroundColor: theme.colors.primary.bg,
                border: `1px solid ${theme.colors.primary.borderAlt}`,
                borderRadius: '9px',
                placeContent: 'space-between',
                cursor: onClick ? 'pointer' : 'default',
            }}
            grow
            onClick={onClick}
            {...props}
        >
            <Box col>
                <OSKIcon
                    code={iconOptions?.code}
                    stroke={iconOptions?.stroke}
                    fill={iconOptions?.fill}
                    width={36}
                    height={36}
                    style={{ paddingBottom: '8px' }}
                />
                <Text variant="tiny">{label}</Text>
            </Box>
            {!spinner ? (
                <Text variant="huge" style={{ width: '48px', textAlign: 'center' }} strong>
                    {value}
                </Text>
            ) : (
                <Box center="all">
                    <Spinner />
                </Box>
            )}
        </Box>
    );
};

export { AlertsCard };
export type { AlertsCardProps };
