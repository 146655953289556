import React from 'react';
import { Box, Text, TabView, Spinner, OSKThemeType } from 'oskcomponents';
import { AOIProgress, TaskingInstance } from '~/redux/modules/tasking/app';
import { TimelineBarList } from '~/organisms/TimelineBarList';
import { connect } from 'react-redux';
import { RootState } from '~/redux/store';
import { TaskCoverageTab } from './TaskCoverageTab';
import { noop } from 'oskcore';
import DownloadButton from '~/organisms/DownloadButton';
import { useTheme } from 'styled-components';

export type TaskDetailViewProps = {
    /** The currently active tasking instance */
    selectedTaskingInstance: TaskingInstance | null;
    /** The currently active list of aoi's for the tasking instance */
    aoiList: Array<AOIProgress>;
    /** Whether the task details page is loading a new task instance or not */
    isLoading?: boolean;
};

/**
 * Internal component which simply renders a spinner
 * optimized for the spacing within the tab pane.
 */
const TabLoading = () => {
    return (
        <Box
            style={{
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: '20px',
            }}
            grow
        >
            <Spinner variant="Box" size="Large" />
        </Box>
    );
};

export const TaskDetailView = ({ selectedTaskingInstance, aoiList, isLoading }: TaskDetailViewProps) => {
    const theme = useTheme() as OSKThemeType;

    return (
        <Box col grow h={1000} style={{ zIndex: 1, backgroundColor: theme.colors.primary.bg, overflow: 'scroll' }}>
            {selectedTaskingInstance ? (
                <React.Fragment>
                    <Box row style={{ placeContent: 'space-between', padding: '24px' }}>
                        <Text variant="large">{selectedTaskingInstance.userTaskName}</Text>
                        <Box col>
                            <DownloadButton fileId={selectedTaskingInstance.fileId} />
                        </Box>
                    </Box>

                    <TabView
                        tabStyle={{ marginLeft: '30px' }}
                        defaultTab={0}
                        tabItems={[
                            {
                                label: 'Progress',
                                component: (
                                    <Box col p={24}>
                                        {isLoading ? <TabLoading /> : <TimelineBarList data={aoiList} />}
                                    </Box>
                                ),
                            },
                            {
                                label: 'Coverage Details',
                                component: (
                                    <Box col p={24} style={{ height: '100%' }} grow>
                                        {isLoading ? <TabLoading /> : <TaskCoverageTab aoiList={aoiList} />}
                                    </Box>
                                ),
                            },
                        ]}
                    />
                </React.Fragment>
            ) : (
                <Box p={24}>
                    <Text>No tasking instance selected.</Text>
                </Box>
            )}
        </Box>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        isLoading: state.tasking.app.isLoadingAois,
        aoiList: state.tasking.app.activeAoiList,
        selectedTaskingInstance: state.tasking.app.activeTaskingInstance,
    };
};

export default connect(mapStateToProps, noop)(TaskDetailView);
