import { Box, Text } from 'oskcomponents';
import { noop, OSKGeoJson, PublicAssetDetail } from 'oskcore';
import MapPlaceholder from 'oskcore/src/assets/images/map-placeholder.png';
import React from 'react';
import { connect } from 'react-redux';
import { filterAlertsByAsset, filterDetectionsByAsset } from '~/redux/modules/monitor/app';
import { RootState } from '~/redux/store';
import { OSKMapTooltip } from '../OSKMapTooltip';

type AssetMarkerProps = {
    /** The currently active assetId */
    selectedAssetId?: number;
    /** The asset to display info for */
    data: PublicAssetDetail;
    /** Optional: The number to display for Alerts */
    numAlerts?: number;
    /** Optional: The number to display for Reports */
    numReports?: number;
    /** Optional: The number to display for Detections */
    numDetections?: number;
};
const AssetMarker = ({ data, numAlerts, numDetections }: AssetMarkerProps) => {
    return (
        <OSKMapTooltip position={OSKGeoJson.fromAPIGeometry(data.aoi)}>
            <Box grow col w={220}>
                <Box
                    style={{
                        fontSize: '1.25em',
                        fontWeight: 'bold',
                    }}
                    col
                >
                    <Box
                        grow
                        center="vertical"
                        style={{
                            placeContent: 'space-between',
                            paddingBottom: '8px',
                        }}
                    >
                        {data.name}
                    </Box>
                    <Box>
                        <img src={MapPlaceholder} width={100} height={65} />
                        <Box
                            col
                            style={{
                                paddingLeft: '12px',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Box row center="vertical">
                                <Text variant="small" strong style={{ paddingRight: '8px' }}>
                                    Alert:
                                </Text>
                                <Text variant="small">{numAlerts ?? 0}</Text>
                            </Box>
                            <Box row center="vertical">
                                <Text variant="small" strong style={{ paddingRight: '8px' }}>
                                    Detections:
                                </Text>
                                <Text variant="small">{numDetections ?? 0}</Text>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </OSKMapTooltip>
    );
};

export { AssetMarker };
export type { AssetMarkerProps };

const mapStateToProps = (state: RootState, props: Partial<AssetMarkerProps>) => {
    const { selectedAssetId } = props;
    const reports = state.monitor.app.reports ?? {};

    return {
        data: state.monitor.app.assets[selectedAssetId ?? -1] as PublicAssetDetail,
        numAlerts: filterAlertsByAsset(state, selectedAssetId ?? -1).length,
        numDetections: filterDetectionsByAsset(state, selectedAssetId ?? -1).length,
        numReports: (reports[selectedAssetId ?? -1] ?? []).length,
    };
};

export default connect(mapStateToProps, noop)(AssetMarker);
