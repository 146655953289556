import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components';
import { Box, BoxProps } from '../Box';
import { OSKThemeType } from '../DefaultThemeProvider';
import CSS from 'csstype';
import { Step } from '../Step';

export type StepperProps = {
    /** If true, the Stepper component will be entirely centered */
    centered?: boolean;
    /** Children components which will be separated by a divider bar */
    children?: React.ReactNode;
    /** A custom style to apply to the divider bar */
    dividerStyle?: CSS.Properties;
    /** A custom style to apply to the stepper as a whole */
    style?: CSS.Properties;
} & Omit<BoxProps, 'ref'>;

/**
 * Stepper will take children components and arrange them vertically with a
 * vertical bar separating each element. Use it to create vertical wizard-type
 * interfaces and components. Pairs well with <Step /> element.
 */
export const Stepper = ({ dividerStyle, centered, children, style, ...props }: StepperProps) => {
    const theme = useTheme() as OSKThemeType;
    const containerStyle: CSS.Properties = Object.assign(
        {},
        centered
            ? {
                  textAlign: 'center',
                  alignItems: 'center',
              }
            : {},
        style,
    );

    const barStyle: CSS.Properties = Object.assign(
        {},
        centered
            ? {}
            : {
                  paddingLeft: '6px',
                  marginBottom: '2px',
                  borderRight: `2px solid ${theme.colors.gray80}`,
                  width: '1px',
                  height: '10px',
              },
        dividerStyle,
    );

    return (
        <Box style={containerStyle} col {...props}>
            {React.Children.map(children, (child, idx) => {
                const moreProps =
                    (child as ReactElement).type === Step
                        ? {
                              renderLine: idx !== React.Children.count(children) - 1,
                          }
                        : {};

                return (
                    <React.Fragment>
                        {idx !== 0 ? <Box style={barStyle} grow></Box> : null}{' '}
                        {React.cloneElement(child as ReactElement, {
                            ...moreProps,
                        })}
                    </React.Fragment>
                );
            })}
        </Box>
    );
};
