import React, { useContext } from 'react';
import { ColorVariants } from 'oskcomponents';
import { IconButton } from '../IconButton';
import { ViewContainerContext } from '../ViewContainer';

export type ViewToggleProps = {
    /** The view which should be considered actively rendered */
    viewKey: string;
    /** The color variant to use for this button */
    variant: ColorVariants;
    /** An icon to render for this button */
    icon: React.FunctionComponent;
};

/**
 * ViewToggle is a component which contains a toggle button to switch
 * between a ListView and a GridView. Often composed with tabular-like
 * data representations.
 **/
export const ViewToggle = ({ icon, viewKey, variant }: ViewToggleProps) => {
    const context = useContext(ViewContainerContext);
    const handleToggle = () => {
        context.changeView(viewKey);
    };

    return (
        <IconButton
            icon={icon}
            variant={context.activeView === viewKey ? 'selected' : variant}
            selected={context.activeView === viewKey}
            onToggle={handleToggle}
        />
    );
};
