import { Box } from 'oskcomponents';
import { noop } from 'oskcore';
import React from 'react';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ModeButton } from '~/atoms';
import { GlobalZIndex } from '~/constants';
import { useCurrentApp } from '~/hooks';
import { getProgramId } from '~/utils';

type ActiveMode = 'search' | 'tasking' | 'tasking-dashboard' | 'monitor' | 'reports' | 'alerts';

type ActiveModeSwitcherProps = {
    /** CSS Class for styling the container */
    className?: string;
};

const ActiveModeSwitcher = styled(({ className }: ActiveModeSwitcherProps) => {
    const { pathname } = useLocation();
    const activeApp = useCurrentApp();
    const programId = useParams()?.programId ?? getProgramId();

    let activeMode: ActiveMode = 'search';

    // Use this more advanced form of rule-based matching so we can
    // prioritize more easily. For example:
    //
    // startsWith('/monitor/') would match /monitor/reports
    // But those are two separate paths and the latter should
    // overrule the former.
    if (pathname === '/tasking') {
        activeMode = 'tasking';
    } else if (pathname === '/tasking/dashboard') {
        activeMode = 'tasking-dashboard';
    } else if (pathname.includes('/monitor/reports')) {
        activeMode = 'reports';
    } else if (pathname.includes('/monitor/alerts')) {
        activeMode = 'alerts';
    } else if (pathname.includes('/monitor')) {
        activeMode = 'monitor';
    } else if (pathname === '/' || pathname.includes('/map')) {
        activeMode = 'search';
    }

    return (
        <Box col style={{ zIndex: GlobalZIndex.MapSection + 1 }} className={className} data-testid="ActiveModeSwitcher">
            {activeApp === 'data' ? (
                <React.Fragment>
                    <ModeButton data-mode="search" icon={'search'} location={`/`} active={activeMode === 'search'} />
                </React.Fragment>
            ) : activeApp === 'monitor' ? (
                <React.Fragment>
                    <ModeButton
                        data-mode="monitor"
                        icon={'quilt'}
                        location={`/program/${programId}/monitor/1/grid`}
                        active={activeMode === 'monitor'}
                    />
                    <ModeButton
                        data-mode="alerts"
                        icon={'alert'}
                        location={`/program/${programId}/monitor/alerts`}
                        active={activeMode === 'alerts'}
                    />
                    <ModeButton
                        data-mode="reports"
                        icon={'paperclip'}
                        location={`/program/${programId}/monitor/reports`}
                        active={activeMode === 'reports'}
                    />
                </React.Fragment>
            ) : activeApp === 'tasking' ? (
                <React.Fragment>
                    <ModeButton
                        data-mode="tasking"
                        icon={'satellite'}
                        location="/tasking"
                        active={activeMode === 'tasking'}
                    />
                    <ModeButton
                        data-mode="tasking-dash"
                        icon={'clipboard-check'}
                        location="/tasking/dashboard"
                        active={activeMode === 'tasking-dashboard'}
                    />
                </React.Fragment>
            ) : (
                <React.Fragment></React.Fragment>
            )}
        </Box>
    );
})`
    & {
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        align-items: center;
        min-width: 67px;
        width: 67px;
        background-color: ${(props: any) => props.theme.colors.primary.bg};
    }
`;
export { ActiveModeSwitcher };
export default connect(noop, noop)(ActiveModeSwitcher);
