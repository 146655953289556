import React from 'react';
import { Box, BoxProps, OSKThemeType, Text } from 'oskcomponents';
import styled, { useTheme } from 'styled-components';
import { AppDispatch, RootState } from '~/redux/store';
import { isItemSelected } from '~/redux/modules/data/cart';
import { connect } from 'react-redux';
import { toogleVisibleFootprint } from '~/redux/modules/data/map';

export type FootprintRadioProps = {
    /** Title of the footprint */
    name: string;
    /** Is the radio a header in group  */
    header?: boolean;
    /** Collection date */
    date?: string;
    /** Footprint ID  */
    fileId: string;
    /** Is highlighted state in redux store */
    highlighted: boolean;
    /** Toggle hightlighted in redux store */
    toggleVisibleFootprint: (fileId: string) => void;
    /** Is the footprint visible in the map */
    visible: boolean;
    /** Is the footprint selected in the map */
    selected: boolean;
} & Omit<BoxProps, 'ref'>;

const FootprintRadio = styled(
    ({
        name,
        date,
        header,
        className,
        highlighted,
        toggleVisibleFootprint,
        visible,
        fileId,
        selected,
        ...props
    }: FootprintRadioProps) => {
        const theme = useTheme() as OSKThemeType;

        return (
            <Box
                col
                className={`${className} wrapper`}
                data-highlighted={highlighted && visible}
                data-selected={selected}
                onClick={() => {
                    toggleVisibleFootprint(fileId);
                }}
                {...props}
            >
                <Box
                    onClick={(e) => {
                        if (header) {
                            e.stopPropagation();
                        }
                    }}
                >
                    {!header && (
                        <Box
                            mr={7}
                            h={11.5}
                            w={11.5}
                            bg={visible ? theme.colors.primary.accent : 'none'}
                            style={{
                                fontWeight: 'bold',
                                border: `1px solid ${theme.colors.primary.fg}`,
                                borderRadius: '50%',
                                alignSelf: 'center',
                            }}
                        />
                    )}
                    <Text style={{ fontWeight: 'bold' }}>{name}</Text>
                </Box>
                {date && (
                    <Text variant="tiny" style={{ marginLeft: '20px' }}>
                        {date}
                    </Text>
                )}
            </Box>
        );
    },
)`
    & {
        cursor: pointer;
        user-select: none;
        margin-bottom: 10px;
        padding: 5px;
        border: 1px solid transparent;
    }

    &[data-selected='true'] {
        color: ${(props: any) => props.theme.colors.accent.bg};
`;

const mapStateToProps = (state: RootState, ownProps: Partial<FootprintRadioProps>) => {
    const { fileId } = ownProps;
    const { highlightedFootprints, visibleFootprints } = state.data.map;
    return {
        selected: isItemSelected(state, fileId),
        highlighted: highlightedFootprints[fileId ?? '-1'],
        visible: visibleFootprints[fileId ?? '-1'],
        fileId,
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        toggleVisibleFootprint: (fileId: string) => {
            dispatch(toogleVisibleFootprint(fileId));
        },
    };
};

export { FootprintRadio };
export default connect(mapStateToProps, mapDispatchToProps)(FootprintRadio);
