import React, { useEffect, useState, useRef } from 'react';
import { Stepper, Step, StepperProps } from 'oskcomponents';
import { map } from 'lodash';
import { shallowEquals } from 'oskcore';

export type SliderItem = {
    /** The textual label to render */
    label?: string;
    /** The value of the slider which this item is selected */
    value?: any;
    /** An optional ReactNode to render instead of the <Step/> component */
    element?: React.ReactNode;
};

export type SliderProps = {
    /** An array of SliderItem options which will be rendered inside the Slider */
    options?: Array<SliderItem>;
    /** A method which will be invoked when a particular item is selected */
    onSelect?: (item: SliderItem | undefined, idx: number) => boolean;
    /** The index of which item should be actively selected */
    selectedIdx?: number;
} & Omit<StepperProps, 'onSelect'>;

/**
 * The Slider component composes a core <Stepper /> element. It presents as a vertical
 * bar with selectable items interleaved along the Y axis. Uses include: the Timeline component
 * which uses this Slider to showcase time periods for the map.
 */
export const Slider = ({ options, onSelect, selectedIdx, ...props }: SliderProps) => {
    const [selected, setSelected] = useState(selectedIdx || 0);
    const optionRef = useRef(options);

    const handleSelect = (idx: number) => {
        onSelect && onSelect(options && options[idx], idx) && setSelected(idx);
    };

    // Reset selection when options are updated.
    useEffect(() => {
        if (!shallowEquals(optionRef.current ?? [], options ?? [])) {
            setSelected(0);
            optionRef.current = options;
        }
    }, [options]);

    return (
        <Stepper {...props}>
            {map(
                options,
                (option, idx) =>
                    (option && option.element) ?? (
                        <Step
                            key={`step_${idx}`}
                            onToggle={handleSelect.bind(this, idx)}
                            variant={'contrast'}
                            active={idx === selected}
                        >
                            {option.label}
                        </Step>
                    ),
            )}
        </Stepper>
    );
};
