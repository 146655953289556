import React, { useContext, useEffect, useState } from 'react';
import { Box, OSKThemeType } from 'oskcomponents';
import { MapToolsPanel } from '~/molecules/MapToolsPanel';
import { useTheme } from 'styled-components';
import { MapCentroid, MapMode, MapModes } from '~/atoms';
import { OSKGeoJson, SearchArea, SigmaAPI } from 'oskcore';
import { WizardContext } from '~/templates';
import { connect } from 'react-redux';
import { AppDispatch, RootState } from '~/redux/store';
import { StepScaffold } from '../StepScaffold';
import {
    deleteCustomAreaAsync,
    doFetchCustomAreasAsync,
    getCustomAreas,
    getLibraryLoading,
} from '~/redux/modules/data/library';
import EditableMap from '~/organisms/map/EditableMap';
import { Polygon } from 'react-leaflet';

export type AOIStepProps = {
    libraryLoading: boolean;
    libraryItems: Array<SearchArea>;
    deleteLibraryItem: (id: number) => void;
    reloadLibrary: () => void;
    saveAoi: (areaName: string, geoJson: OSKGeoJson) => Promise<any>;
};

const mapStateToProps = (state: RootState) => {
    return {
        libraryLoading: getLibraryLoading(state),
        libraryItems: getCustomAreas(state),
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        reloadLibrary: () => {
            dispatch<any>(
                doFetchCustomAreasAsync({
                    offset: 0,
                    limit: 1000,
                    silent: false,
                }),
            );
        },
        deleteLibraryItem: (id: number) => {
            dispatch<any>(deleteCustomAreaAsync(id, { silent: true }));
        },
        saveAoi: (AreaName: string, geoJson: OSKGeoJson) => {
            return SigmaAPI.createSearchArea({
                searchAreaRequest: {
                    name: AreaName,
                    area: JSON.stringify(geoJson.toAPIGeometry()) as any,
                },
            });
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(({ libraryLoading, libraryItems, deleteLibraryItem, saveAoi, reloadLibrary }: AOIStepProps) => {
    const theme = useTheme() as OSKThemeType;
    const [taskingArea, setTaskingArea] = useState<OSKGeoJson>(new OSKGeoJson());
    const [mode, setMode] = useState<MapModes>('None');
    const ctx = useContext(WizardContext);

    useEffect(() => {
        reloadLibrary();
    }, []);

    useEffect(() => {
        if (taskingArea.isEmpty()) {
            ctx.setValidity(false);
        } else {
            ctx.setValidity(true);
        }
    }, [taskingArea]);

    return (
        <StepScaffold title="AOI Region" subTitle="Create, upload, or use an existing area of interest.">
            <Box
                grow
                style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Box style={{ width: '100%', height: '100%' }} grow>
                    <EditableMap>
                        <MapCentroid area={taskingArea} />
                        <Polygon positions={taskingArea.toLeafletCoordinates()} />
                        <MapMode
                            mode={mode}
                            onBeforeEdit={() => {
                                if (!taskingArea.isEmpty()) {
                                    setTaskingArea(new OSKGeoJson());
                                }
                            }}
                            onSave={(geoJson) => {
                                setMode('None');
                                setTaskingArea(geoJson);
                            }}
                        />
                    </EditableMap>
                </Box>
                <Box
                    style={{
                        marginLeft: '20px',
                        borderRadius: '15px',
                        height: 'calc(65vh - 120px)',
                    }}
                    w={400}
                    bg={theme.colors.primary.bg}
                >
                    <MapToolsPanel
                        currentAoi={taskingArea}
                        mode={mode}
                        onPoint={() => {
                            setMode('Point');
                        }}
                        onDraw={() => {
                            setMode('Polygon');
                        }}
                        onClear={() => {
                            setMode('Clear');
                        }}
                        setMapRoi={(area) => {
                            setMode('Clear');
                            // setMode('clear') will cause taskingArea to be wiped out
                            // so we need a bit of a delay before repopulating it and
                            // becuase of react, there is no real way to guarantee
                            // the operation has been completed or not.
                            // So we use a setTimeout with wreckless abandon.
                            setTimeout(() => {
                                setTaskingArea(area);
                            }, 150);
                        }}
                        libraryLoading={libraryLoading}
                        libraryItems={libraryItems}
                        deleteLibraryItem={deleteLibraryItem}
                        reloadLibrary={reloadLibrary}
                        saveAoi={saveAoi}
                    />
                </Box>

                <input
                    disabled={taskingArea.isEmpty()}
                    type="hidden"
                    name="taskingArea"
                    value={JSON.stringify(taskingArea.toGeoJSON())}
                />
            </Box>
        </StepScaffold>
    );
});
