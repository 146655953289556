import React from 'react';
import { OSKThemeType, PageScaffold } from 'oskcomponents';
import { useTheme } from 'styled-components';
import { PortalContentView } from '~/atoms';
import { AppNames } from '~/constants';

export type BaseAdminDashboardProps = {
    /** Children elements to render inside the dashboard */
    children: React.ReactNode;
    /** The title element to pass to the scaffold */
    title: React.ReactElement<any, any>;
};

/**
 * The BaseAdminDashboard component is used to wrap
 * a dashboard with default styling to position them all
 * consistently in the screen pane.
 */
export const BaseAdminDashboard = ({ children, title }: BaseAdminDashboardProps) => {
    const theme = useTheme() as OSKThemeType;
    return (
        <PortalContentView crumbs={[{ title: AppNames['monitor'], url: '/' }, { title: 'Programs' }]}>
            <PageScaffold title={title}>{children}</PageScaffold>
        </PortalContentView>
    );
};
