import React from 'react';
import { Box, Text, Table } from 'oskcomponents';
import { ProgramReport } from '~/redux/modules/monitor/reports';
import { date_format } from '~/utils';

export type ProgramReportsTableProps = {
    isLoading: boolean;
    selectedIndex?: number;
    data: Array<ProgramReport>;
    onRowClick?: (program: Record<string, any>) => void;
};

const DateCell = ({ row: { values } }: any) => {
    return date_format(values.date);
};

export const ProgramReportsTable = ({ isLoading, selectedIndex, data, onRowClick }: ProgramReportsTableProps) => {
    const columns = React.useMemo(
        () => [
            { Header: 'Name', accessor: 'name' },
            { Header: 'Date', accessor: 'date', Cell: DateCell },
        ],
        [],
    );

    if (isLoading) {
        return (
            <Box>
                <Text style={{ fontSize: '1.85rem' }}>Loading...</Text>
            </Box>
        );
    } else if (data.length > 0) {
        return <Table selectedIndex={selectedIndex} onRowClick={onRowClick} columns={columns} data={data} />;
    } else {
        return (
            <Box>
                <Text style={{ fontSize: '1.5rem' }}>There are no reports in the system.</Text>
            </Box>
        );
    }
};
