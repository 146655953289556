import { Box, IconSwitch, OSKThemeType } from 'oskcomponents';
import { noop, OSKGeoJson, PublicAssetDetail, useRemountOnChange } from 'oskcore';
import React, { Fragment, useEffect, useState } from 'react';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { connect, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { Breadcrumb, Footprint, MapCentroid, MapRefresher } from '~/atoms';
import { ActionBar } from '~/atoms/ActionBar';
import { AppNames, GlobalZIndex } from '~/constants';
import { MarkerClusterIcon } from '~/molecules/map';
import AlertMarkerList from '~/organisms/map/AlertMarkerList';
import DetectionMarkerList from '~/organisms/map/DetectionMarkerList';
import EditableMap from '~/organisms/map/EditableMap';
import { decrementBusyCount, incrementBusyCount } from '~/redux/modules/data/app';
import { Asset } from '~/redux/modules/monitor/app';
import { RootState } from '~/redux/store';
import { getProgramId } from '~/utils';
import AssetDetailsSection from '../../AssetDetailsSection';
import AlertDetailsSection from '../../AlertDetailsSection';
import { Bell } from '../../Notifications';
import AssetDetailsSidePanel from './AssetDetailsSidePanel';
import { DataProvider } from '~/redux';
import DateRangePicker from '~/molecules/DateRangePicker';

const STAGE = {
    SHOW_SHROUD: 0,
    SHOW_MAP: 1,
    SHOW_MAP_PINS: 2,
    RENDER_FOOTPRINTS: 3,
};

type ValidAssetDetailMapViewProps = {
    /** The assetId that is selected */
    selectedAssetId?: number;
    /** The optional detection (including alert) that is selected */
    selectedAlertId?: string;
    /** The geometry to render */
    geometry?: OSKGeoJson;
    /** The asset object from redux */
    asset: Asset;
    /** The loading state of the current asset */
    fetchingAsset: boolean;
};

const ValidAssetDetailMapView = ({
    geometry,
    selectedAssetId,
    selectedAlertId,
    asset,
    fetchingAsset,
}: ValidAssetDetailMapViewProps) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { programId } = useParams();
    const [stage, setStage] = useState(0);
    const [center, setCenter] = useState(false);
    const [showOverlay, setShowOverlay] = useState(true);
    const [visible] = useRemountOnChange([selectedAssetId, geometry]);
    const [selectedDetailTabIndex, setSelectedDetailTabIndex] = useState(0);
    const theme = useTheme() as OSKThemeType;

    useEffect(() => {
        if (showOverlay) {
            dispatch(incrementBusyCount());
        } else {
            dispatch(decrementBusyCount());
        }
    }, [showOverlay, dispatch]);

    useEffect(() => {
        if (stage == STAGE.SHOW_SHROUD) {
            const timerId = setTimeout(() => setStage(STAGE.SHOW_MAP), 100);
            return () => {
                clearTimeout(timerId);
            };
        }
    });

    if (fetchingAsset) return <Box>...</Box>;

    return (
        <DataProvider programId={getProgramId()} assetId={`${selectedAssetId}`} features={['asset']}>
            <Box style={{ position: 'relative', width: '100%', zIndex: 0 }}>
                {
                    <Box
                        style={{
                            position: 'absolute',
                            opacity: showOverlay ? 1.0 : 0.0,
                            visibility: showOverlay ? 'visible' : 'hidden',
                            top: '0px',
                            left: '0px',
                            width: '100vw',
                            height: '100vh',
                            filter: 'blur(.25rem)',
                            transition: 'opacity 1.5s, visibility 1.5s',
                            backgroundImage: 'url(/images/spaaace.jpg)',
                            backgroundSize: 'cover',
                            zIndex: GlobalZIndex.MapSection + 1000,
                        }}
                    />
                }
                {stage > STAGE.SHOW_SHROUD && geometry && geometry.features.length > 0 && (
                    <EditableMap fadeAnimation={false} center={geometry?.getCentroid()} zoomPosition="bottomright">
                        {geometry && stage > STAGE.SHOW_MAP_PINS ? (
                            <Fragment>
                                {stage === STAGE.RENDER_FOOTPRINTS && (
                                    <MapCentroid animated={false} area={geometry} padding={0.001} />
                                )}
                                <Footprint shape={geometry} color="#00d1ff" />
                                <MapRefresher
                                    onLoad={(map) => {
                                        // If we are on stage 2, increment stage and then wait 100ms before unveiling
                                        // the page and forcing a final re-render of the map.
                                        if (stage === STAGE.SHOW_MAP_PINS) {
                                            map.invalidate();
                                        } else if (stage === STAGE.RENDER_FOOTPRINTS) {
                                            setStage(stage + 1);
                                            setTimeout(() => {
                                                setShowOverlay(false);
                                            }, 1000);
                                        }
                                    }}
                                />
                            </Fragment>
                        ) : (
                            <Fragment></Fragment>
                        )}

                        <ActionBar
                            left={<Breadcrumb nodes={[{ title: AppNames['monitor'], url: '/' }, { title: 'Map' }]} />}
                            right={
                                <React.Fragment>
                                    <DateRangePicker />
                                    <IconSwitch
                                        options={[
                                            {
                                                value: 'grid',
                                                icon: 'list',
                                            },
                                            {
                                                value: 'map',
                                                icon: 'map',
                                            },
                                        ]}
                                        selectedValue="map"
                                        onSelect={(value: any) => {
                                            if (value === 'grid') {
                                                navigate(`/program/${programId}/monitor/${selectedAssetId}/grid`);
                                            }
                                        }}
                                    />
                                    <Bell />
                                </React.Fragment>
                            }
                        />

                        {center && (
                            <AssetDetailsSidePanel title={asset.name}>
                                {selectedAlertId ? (
                                    <AlertDetailsSection selectedAssetId={asset.id} selectedAlertId={selectedAlertId} />
                                ) : (
                                    <AssetDetailsSection
                                        selectedAssetId={asset.id}
                                        showMap={false}
                                        showVisibilityFilters={true}
                                        onTabChange={(idx: number) => setSelectedDetailTabIndex(idx)}
                                    />
                                )}
                            </AssetDetailsSidePanel>
                        )}
                        {center && stage > STAGE.SHOW_MAP && (
                            <>
                                {selectedDetailTabIndex === 0 && (
                                    <MarkerClusterGroup
                                        key={`marker-cluster-2-${selectedAssetId}`}
                                        iconCreateFunction={MarkerClusterIcon('red')}
                                    >
                                        <AlertMarkerList selectedAssetId={selectedAssetId} filter={selectedAlertId} />
                                    </MarkerClusterGroup>
                                )}
                                {selectedDetailTabIndex === 2 && (
                                    <MarkerClusterGroup
                                        key={`marker-cluster-1-${selectedAssetId}`}
                                        iconCreateFunction={MarkerClusterIcon('#F96621')}
                                    >
                                        <DetectionMarkerList selectedAssetId={selectedAssetId} />
                                    </MarkerClusterGroup>
                                )}
                                {stage == STAGE.SHOW_MAP_PINS && (
                                    <MapRefresher
                                        onLoad={() => {
                                            setStage(STAGE.RENDER_FOOTPRINTS);
                                        }}
                                    />
                                )}
                            </>
                        )}
                        {geometry && stage === STAGE.SHOW_MAP && (
                            <MapRefresher
                                onLoad={(map) => {
                                    if (
                                        geometry &&
                                        geometry.features &&
                                        geometry.features.length > 0 &&
                                        !(geometry.getCentroid().lat === 0 && geometry.getCentroid().lng === 0)
                                    ) {
                                        setStage(STAGE.SHOW_MAP_PINS);
                                        setCenter(true);
                                        map.invalidate();
                                    }
                                }}
                            />
                        )}
                    </EditableMap>
                )}
            </Box>
        </DataProvider>
    );
};

const mapStateToProps = (state: RootState, props: Partial<ValidAssetDetailMapViewProps>) => {
    const { selectedAssetId } = props;
    const asset = state.monitor.app.assets[selectedAssetId ?? -1] as PublicAssetDetail;

    return {
        asset,
        geometry: asset && asset.aoi ? OSKGeoJson.fromAPIGeometry(asset.aoi) : undefined,
        fetchingAsset: state.monitor.app.fetchingAsset,
    };
};

const ConnectedValidAssetDetailMapView = connect(mapStateToProps, noop)(ValidAssetDetailMapView);
export default ConnectedValidAssetDetailMapView;
export { ConnectedValidAssetDetailMapView as ValidAssetDetailMapView };
