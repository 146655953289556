import React from 'react';
import { BoxProps, SidePanelBody, SidePanelFooter, Button } from 'oskcomponents';
import { AppDispatch, RootState } from '~/redux/store';
import { connect } from 'react-redux';
import { OSKGeoJson } from 'oskcore';
import { canSearch, doSearchAsync, setRoi } from '~/redux/modules/data/search';
import { useMap } from '~/hooks';
import DataSource from './DataSource';
import DateRange from './DateRange';
import AOISection from './AOISection';

export type SearchProps = {
    /** Aggregate all search options from store and query API */
    doSearch: () => void;
    /** Checks Editable map for an AOI and sets to redux store */
    setMapRoi: (geoJson: OSKGeoJson) => void;
    /** Checks that there is the minimun data required for a footprint search */
    canSearch: boolean;
} & Omit<BoxProps, 'ref'>;

const Search = ({ setMapRoi, doSearch, canSearch }: SearchProps) => {
    const map = useMap();

    const handleSubmit = () => {
        setMapRoi && setMapRoi(map.toGeoJSON());
        doSearch && doSearch();
    };

    return (
        <React.Fragment>
            <SidePanelBody col>
                <DataSource />
                <DateRange />
                <AOISection />
            </SidePanelBody>
            <SidePanelFooter col grow style={{ justifyContent: 'center', margin: '0 auto' }}>
                <Button
                    onClick={() => handleSubmit()}
                    variant="action"
                    disabled={!canSearch}
                    style={{ height: '48px', width: '175px' }}
                >
                    Search
                </Button>
            </SidePanelFooter>
        </React.Fragment>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        canSearch: canSearch(state),
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        setMapRoi: (geoJson: OSKGeoJson) => {
            dispatch(setRoi(geoJson));
        },
        doSearch: () => {
            // @ts-ignore TODO: figure out how to dispatch thunks in a way that makes typescript happy
            dispatch(doSearchAsync());
        },
    };
};

export { Search };
export default connect(mapStateToProps, mapDispatchToProps)(Search);
