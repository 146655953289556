import React, { useState } from 'react';
import { Box, OSKThemeType, Text } from 'oskcomponents';
import { OSKMapTooltip } from '../../../molecules/map/OSKMapTooltip';
import { OSKIcon, OSKIconType } from 'oskcomponents/src/OSKIcon/OSKIcon';
import { connect } from 'react-redux';
import { hasParentWithId, Geometry, noop, OSKGeoJson } from 'oskcore';
import { RootState } from '~/redux/store';
import { AssetDetection } from '~/redux/modules/monitor/app';
import { useTheme } from 'styled-components';
import { coord_format } from '~/utils';

type DetectionMarkerProps = {
    /* Whether to render this marker or not */
    visible?: boolean;
    /** Coordinates for placing this on the map */
    position: OSKGeoJson;
    /** The id of the asset */
    assetId: string;
    /** The id of the detection */
    detectionId: string;
    /** The detection object */
    detection: AssetDetection;
};

const DetectionMarker = ({ visible, position, detection }: DetectionMarkerProps) => {
    const theme = useTheme() as OSKThemeType;

    // TODO: Replace data.message with new detection type prop
    const iconCodeMap: Record<string, OSKIconType> = {
        Construction: 'construction',
        Digging: 'digging',
        Plume: 'methane',
        'Soil Contamination': 'hydrocarbon',
        Vehicle: 'vehicle',
        'Exposed Piping': 'exposed-piping',
        'Hydrocarbon Leak': 'hydrocarbon',
    };
    const coords = (detection.coordinates as Geometry).coordinates;
    const coordText = `${coords[1]}, ${coords[0]}`;
    const visualCoordText = coord_format(coords);
    const [coordMessage, setCoordMessage] = useState(visualCoordText);

    const copyCoords = (evt: any) => {
        const isCopyButton = hasParentWithId(evt.originalEvent.target, 'copy-button');

        if (isCopyButton) {
            navigator.clipboard.writeText(coordText);
            setCoordMessage('Copied');
            setTimeout(() => {
                setCoordMessage(visualCoordText);
            }, 3000);
        }
    };

    return visible ? (
        <OSKMapTooltip position={position} onClick={(evt) => copyCoords(evt)}>
            <Box grow row center="all">
                <OSKIcon
                    code={iconCodeMap[detection.detection_type] ?? 'alert-circle'}
                    fill="#F96621"
                    width={50}
                    height={50}
                    style={{ paddingRight: '12px' }}
                />
                <Box col h={60} style={{ justifyContent: 'space-between' }}>
                    <Text strong>{detection.detection_type}</Text>
                    <Text variant="small">{detection.call_type}</Text>
                    <Box row center="vertical" id="copy-button">
                        <Text variant="small" w={100} style={{ textDecoration: 'underline' }}>
                            {coordMessage}
                        </Text>
                        <OSKIcon code="copy" style={{ paddingLeft: '8px' }} />
                    </Box>
                </Box>
            </Box>
        </OSKMapTooltip>
    ) : (
        <React.Fragment />
    );
};

export { DetectionMarker };
export type { DetectionMarkerProps };

const mapStateToProps = (state: RootState, ownProps: Partial<DetectionMarkerProps>) => {
    const visibleDetections = state.monitor.app.visibleDetections;
    const assetId = ownProps.assetId + '';
    const detectionId = ownProps.detectionId + '';

    let visible = false;

    if (visibleDetections[detectionId]) {
        visible = true;
    }

    return {
        visible: visible,
    };
};
export default connect(mapStateToProps, noop)(DetectionMarker);
