import React, { useEffect, useState } from 'react';
import { Box, getFormValues, Heading, OSKThemeType, useForm } from 'oskcomponents';
import { StepScaffold } from '../StepScaffold';
import EditableMap from '~/organisms/map/EditableMap';
import OpportunityWindowTable from '~/organisms/tables/OpportunityWindowTable';
import { Footprint, MapCentroid, MapSatelliteTrack } from '~/atoms';
import { fetchOpportunityAsync, OpportunityWindow, SensorTLEMap } from '~/redux/modules/tasking/app';
import { getAverageOrbitTimeMS } from 'tle.js';
import { OpportunityOverlay } from './OpportunityOverlay';
import { noop, Opportunity, OSKGeoJson, Sensor } from 'oskcore';
import { connect } from 'react-redux';
import { RootState } from '~/redux/store';
import { convertToOpportunities } from './utils';
import { Polygon } from 'react-leaflet';
import { useTheme } from 'styled-components';

export type ReviewStepProps = {
    sensors: Array<Sensor>;
};

export const ReviewStep = ({ sensors }: ReviewStepProps) => {
    const form = useForm();
    const [ticks, setTicks] = useState<Opportunity[]>();
    const [grouped, setGrouped] = useState<Array<OpportunityWindow>>([]);
    const [selectedGroup, setSelectedGroup] = useState<OpportunityWindow>();
    const [assetAoi, setAssetAoi] = useState<OSKGeoJson>();

    const theme = useTheme() as OSKThemeType;

    // Group the ticks by orbital period
    useEffect(() => {
        // Group the ticks
        if (ticks && ticks.length > 0) {
            // Calculate orbital period for all sensors
            const orbitalPeriods: Record<string, number> = {};
            for (const sensor_id in SensorTLEMap) {
                orbitalPeriods[sensor_id] = getAverageOrbitTimeMS(SensorTLEMap[sensor_id]);
            }

            // Group all ticks by sensor_id first
            const ticksBySensor: Record<number, Array<Opportunity>> = {};
            for (const tick of ticks) {
                ticksBySensor[tick.sensor] = ticksBySensor[tick.sensor] ?? [];
                ticksBySensor[tick.sensor].push(tick);
            }

            // Iterate over each group of ticks and create a list of opportunities
            const opportunities: Array<OpportunityWindow> = [];
            for (const sensor in ticksBySensor) {
                const tickList = ticksBySensor[sensor];
                convertToOpportunities(tickList, SensorTLEMap[sensor], orbitalPeriods[sensor]).forEach(
                    (opportunity) => {
                        opportunities.push(opportunity);
                    },
                );
            }

            // Finally, save the windows
            setGrouped(opportunities);

            // And auto-select the first window if applicable
            if (selectedGroup === undefined) {
                setSelectedGroup(opportunities[0]);
            }
        }
    }, [ticks]);

    useEffect(() => {
        if (selectedGroup === undefined) {
            setSelectedGroup(grouped[0]);
        }
    }, [grouped]);

    useEffect(() => {
        if (form) {
            const formData = getFormValues(form.current);
            const { taskingArea } = formData;
            setAssetAoi(OSKGeoJson.fromGeoJSON(JSON.parse(taskingArea)));

            // Load the opportunities
            fetchOpportunityAsync(OSKGeoJson.fromGeoJSON(JSON.parse(taskingArea)))
                .then((ticks) => setTicks(ticks))
                .catch((ex) => {
                    console.error(ex);
                });
        }
    }, [form]);

    return (
        <StepScaffold
            title="Coverage Preview"
            subTitle="View the upcoming overpass opportunities & final order request below."
        >
            <Box style={{ width: '100%', paddingRight: '10px' }}>
                <EditableMap>
                    {selectedGroup && (
                        <MapCentroid padding={0.75} area={OSKGeoJson.fromAPIGeometry(selectedGroup.ticks[0].max_fov)} />
                    )}
                    {selectedGroup && (
                        <MapSatelliteTrack
                            startTime={new Date(selectedGroup.start_time).getTime()}
                            orbitalPeriods={1}
                            tle={selectedGroup.tle}
                        />
                    )}
                    {selectedGroup &&
                        selectedGroup.ticks.map((tick, idx) => (
                            <Footprint
                                color="#00D1FF"
                                key={`${tick.start_time}_${idx}`}
                                shape={OSKGeoJson.fromAPIGeometry(tick.max_fov)}
                            />
                        ))}
                    {assetAoi && <Polygon color="#00D1FF" positions={assetAoi.toLeafletCoordinates()} />}
                </EditableMap>
            </Box>

            {selectedGroup && (
                <OpportunityOverlay
                    sensor_name={
                        (sensors ?? []).filter((sensor) => sensor.osk_id === selectedGroup.sensor)[0]
                            ?.osk_sensor_name ?? 'Unknown'
                    }
                    start_time={selectedGroup.start_time}
                    end_time={selectedGroup.end_time}
                    ticks={selectedGroup.ticks}
                />
            )}
            <Box
                style={{
                    borderRadius: '7px',
                    backgroundColor: theme.colors.primary.bg,
                    padding: '10px',
                    width: '380px',
                }}
                col
            >
                <Heading variant="tiny" style={{ paddingBottom: '10px', textAlign: 'center' }}>
                    Overpass Opportunities
                </Heading>
                <Box
                    style={{
                        display: 'block',
                        overflowY: 'scroll',
                        overflowX: 'hidden',
                    }}
                >
                    <OpportunityWindowTable
                        onRowClick={(row) => {
                            setSelectedGroup(row);
                        }}
                        selectedRow={selectedGroup ? grouped.indexOf(selectedGroup) : 0}
                        data={grouped ?? []}
                    />
                </Box>
            </Box>
        </StepScaffold>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        sensors: state.osk.sensors,
    };
};
export default connect(mapStateToProps, noop)(ReviewStep);
