import React from 'react';
import { Box, OSKThemeType } from 'oskcomponents';
import { useCurrentApp } from '~/hooks';
import { useTheme } from 'styled-components';
import { AppNames } from '~/constants';

export const AppName = () => {
    const theme = useTheme() as OSKThemeType;
    const app = useCurrentApp();

    return (
        <Box
            style={{
                position: 'absolute',
                width: '100%',
                left: 0,
                top: '15px',
                justifyContent: 'center',
                height: '35px',
            }}
        >
            <Box
                style={{
                    opacity: 0.4,
                    color: theme.colors.primary.white,
                    fontSize: '1.5rem',
                    borderRadius: '30px',
                    padding: '0px 100px',
                }}
            >
                {AppNames[app]}
            </Box>
        </Box>
    );
};
