import { Box, Button, Divider, OSKThemeType } from 'oskcomponents';
import { OSKIcon } from 'oskcomponents/src/OSKIcon';
import React, { useCallback, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import { GlobalZIndex } from '~/constants';
import { useDisableFeatureOnMouseOver } from '~/hooks';
import { IconHeader } from '~/molecules/Monitor';

type AssetDetailsSidePanelProps = {
    /** The title to display at the top */
    title?: string;
    /** The element(s) to display underneath the header. */
    children?: React.ReactNode | Array<React.ReactNode>;
};

const AssetDetailsSidePanel = ({ title, children }: AssetDetailsSidePanelProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const [minimized, setMinimized] = useState<boolean>(false);
    const theme = useTheme() as OSKThemeType;

    // This prevents the scrollbar from showing arbitrarily when interacting with the minimize functionality.
    const toggleMinimized = useCallback(() => {
        setMinimized(!minimized);
        if (ref.current) {
            ref.current.style.overflow = 'hidden';
        }
    }, [minimized, ref]);

    useDisableFeatureOnMouseOver(ref, 'Drag', true);
    useDisableFeatureOnMouseOver(ref, 'Zoom', true);

    return (
        <Box
            bg={theme.colors.primary.transBg}
            m={24}
            style={{
                position: 'relative',
                zIndex: GlobalZIndex.MapSection,
                width: '450px',
                minHeight: '72px',
                height: `${minimized ? '0%' : '80%'}`,
                transition: 'height 0.3s ease',
                borderRadius: '7px',
            }}
            ref={ref}
            id="asset-detail-side-panel"
        >
            <Box col grow p={8}>
                <Box
                    style={{
                        placeContent: 'space-between',
                        padding: '10px',
                    }}
                >
                    <IconHeader title={title ?? '--'} iconCode="pipeline" />
                    <Button
                        style={{
                            backgroundColor: `${theme.colors.primary.bg}`,
                            border: `2px solid ${theme.colors.primary.border}`,
                        }}
                        onClick={toggleMinimized}
                    >
                        <OSKIcon code={minimized ? 'arrow-right' : 'arrow-down'} />
                    </Button>
                </Box>
                <Divider m={8} />
                {children}
                {!minimized && <React.Fragment />}
            </Box>
        </Box>
    );
};

export default AssetDetailsSidePanel;
export type { AssetDetailsSidePanelProps };
