import React from 'react';
import { Box, Heading, Text } from 'oskcomponents';
import { date_format, time_format } from '~/utils';
import { Geometry, noop } from 'oskcore';
import { connect } from 'react-redux';
import { RootState } from '~/redux/store';
import { AssetDetection } from '~/redux/modules/monitor/app';
import { Coordinate } from '~/atoms';

type AlertDetailsSectionProps = {
    /** If selectedAlertId provided, the associated object */
    alert?: AssetDetection;
    /** The assetId that is selected */
    selectedAssetId?: number;
    /** The optional detection (including alert) that is selected */
    selectedAlertId?: string;
};

const AlertDetailsSection = ({ alert }: AlertDetailsSectionProps) => {
    const [longitude, latitude] = alert
        ? ((alert.coordinates as Geometry).coordinates as number[])
        : [undefined, undefined];

    return (
        <Box p={12} style={{ overflow: 'scroll', paddingTop: '0px' }} col>
            <Heading style={{ paddingBottom: '4px' }}>Alert {alert?.id}</Heading>
            <Box style={{ paddingBottom: '4px' }}>
                <Text strong style={{ paddingRight: '8px' }}>
                    Call Type:
                </Text>
                <Text>{alert?.call_type}</Text>
            </Box>
            <Box style={{ paddingBottom: '4px' }}>
                <Text strong style={{ paddingRight: '8px' }}>
                    Sub Call Type:
                </Text>
                <Text>{alert?.detection_type}</Text>
            </Box>
            <Box style={{ paddingBottom: '4px' }}>
                <Text strong style={{ paddingRight: '8px' }}>
                    Location:
                </Text>
                {longitude && latitude && <Coordinate longitude={longitude} latitude={latitude} />}
            </Box>
            <Box style={{ paddingBottom: '4px' }}>
                <Text strong style={{ paddingRight: '8px' }}>
                    Created At:
                </Text>
                <Text>
                    {date_format(alert?.created_at)} {time_format(alert?.created_at)}
                </Text>
            </Box>
            <Box col>
                <Text strong style={{ paddingRight: '8px', paddingBottom: '4px' }}>
                    Artifacts:
                </Text>
                <Box col>
                    {alert?.artifacts &&
                        Object.values(alert?.artifacts).map((artifact, idx) => (
                            <img
                                key={`artifact-${idx}`}
                                src={artifact}
                                style={{ height: 'auto', width: '100%', cursor: 'pointer' }}
                                onClick={() => window.open(artifact)}
                            />
                        ))}
                </Box>
            </Box>
        </Box>
    );
};

const mapStateToProps = (state: RootState, props: Partial<AlertDetailsSectionProps>) => {
    const { selectedAssetId, selectedAlertId } = props;
    const detections = state.monitor.app.detections[selectedAssetId ?? -1];
    const alert = detections ? detections.find((alert) => alert.id === selectedAlertId) : undefined;

    return {
        fetchingAsset: state.monitor.app.fetchingAsset,
        fetchingDetections: state.monitor.app.fetchingDetections,
        alert: alert ?? undefined,
    };
};

export { AlertDetailsSection };
export default connect(mapStateToProps, noop)(AlertDetailsSection);
