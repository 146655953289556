import React from 'react';
import { Box, Text, Button } from 'oskcomponents';
import { useNavigate } from 'react-router-dom';
import { OSKIcon } from 'oskcomponents/src/OSKIcon';
import { TaskListTable } from '~/organisms/tables/TaskListTable';
import { loadTaskingInstanceWithAssociatedData, TaskingInstance } from '~/redux/modules/tasking/app';
import { connect } from 'react-redux';
import { AppDispatch, RootState } from '~/redux/store';

export type TaskListViewProps = {
    taskingList: Array<TaskingInstance>;
    selectedTaskingInstance: TaskingInstance | null;
    setTaskingInstance: (instance: TaskingInstance) => void;
};
export const TaskListView = ({ taskingList, setTaskingInstance, selectedTaskingInstance }: TaskListViewProps) => {
    const navigate = useNavigate();
    const selectedRow = selectedTaskingInstance ? taskingList.indexOf(selectedTaskingInstance) : -1;

    return (
        <Box p={24} style={{ zIndex: 0, height: '100%', overflowX: 'scroll' }} col>
            <Box row style={{ placeContent: 'flex-end' }}>
                <Button
                    variant="clear"
                    onClick={() => {
                        navigate('/tasking');
                    }}
                >
                    <Box row style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <OSKIcon code="plus" style={{ paddingRight: '8px' }} />
                        <Text>New Task</Text>
                    </Box>
                </Button>
            </Box>
            <TaskListTable
                data={taskingList}
                selectedIndex={selectedRow}
                onRowClick={(instance: TaskingInstance) => setTaskingInstance(instance)}
            />
        </Box>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        taskingList: state.tasking.app.taskingList,
        selectedTaskingInstance: state.tasking.app.activeTaskingInstance,
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        setTaskingInstance: (instance: TaskingInstance) => {
            dispatch<any>(loadTaskingInstanceWithAssociatedData(instance));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskListView);
