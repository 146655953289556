import { TimelineMode } from '~/redux/modules/data/search';
import { eachDayOfInterval, eachMonthOfInterval, eachWeekOfInterval, eachYearOfInterval, sub } from 'date-fns';

const MAXIMUM_WEEKS = 51;
const MAXIMUM_MONTHS = 11;
const MAXIMUM_YEARS = 3;

/***
 * This method will take two dates and a mode, and interpolate all the datapoints between them
 * based on the granularity of the mode in question.
 *
 * @param earliestDate The earliest date to consider in the interpolation process
 * @param latestDate The latest date to consider in the interpolation process
 * @param mode The TimelineMode to consider. Values include daily, monthly, weekly, yearly.
 * @returns An array of date objects containing the interpolated values of each day based on the mode.
 */
export function interpolate(earliestDate: Date | undefined | null, latestDate: Date, mode: TimelineMode) {
    switch (mode) {
        case 'daily': {
            if (!earliestDate) {
                earliestDate = sub(latestDate, { months: 1 });
            }
            return eachDayOfInterval({ start: earliestDate, end: latestDate });
        }

        case 'weekly': {
            if (!earliestDate) {
                earliestDate = sub(latestDate, { weeks: MAXIMUM_WEEKS });
            }
            return eachWeekOfInterval({ start: earliestDate, end: latestDate });
        }

        case 'monthly': {
            if (!earliestDate) {
                earliestDate = sub(latestDate, { months: MAXIMUM_MONTHS });
            }
            return eachMonthOfInterval({ start: earliestDate, end: latestDate });
        }

        case 'yearly': {
            if (!earliestDate) {
                earliestDate = sub(latestDate, { years: MAXIMUM_YEARS });
            }
            return eachYearOfInterval({ start: earliestDate, end: latestDate });
        }
    }
}
