import React from 'react';
import InfiniteCalendar, { Calendar, ReactInfiniteCalendarProps, withRange } from 'react-infinite-calendar';
import styled, { useTheme } from 'styled-components';
import { OSKThemeType } from '../DefaultThemeProvider';

import '../css/react-infinite-calendar.theme.css';

type ThemedInfiniteCalendarProps = ReactInfiniteCalendarProps & {
    single?: boolean;
    margin?: number;
};

type SingleInfiniteCalendarProps = ThemedInfiniteCalendarProps & {
    selectedDate: Date;
};

type RangeInfiniteCalendarProps = ThemedInfiniteCalendarProps & {
    startDate: Date;
    endDate: Date;
};

const CalendarWithRange = withRange(Calendar);

const defaultMargin = 5;

const CoreCalendar = ({ single, margin = defaultMargin, ...props }: ThemedInfiniteCalendarProps) => {
    const theme = useTheme() as OSKThemeType;

    const calWidth = (props.width as number) ?? 1;
    const cellSize = (calWidth - margin * 14) / 7;
    const rowHeight = cellSize + margin * 2;

    const CalendarTheme = {
        accentColor: theme.colors.orange50,
        floatingNav: {
            background: theme.colors.orange50,
            chevron: theme.colors.white,
            color: theme.colors.primary.bg,
        },
        headerColor: theme.colors.primary.bg,
        selectionColor: theme.colors.orange50,
        textColor: {
            active: theme.colors.primary.fg,
            default: theme.colors.primary.fg,
        },
        todayColor: theme.colors.orange50,
        weekdayColor: theme.colors.orange50,
    };

    return (
        <InfiniteCalendar
            {...props}
            {...(!single && { Component: CalendarWithRange })}
            theme={CalendarTheme}
            rowHeight={rowHeight}
        />
    );
};

const OSKInfiniteCalendar = styled(CoreCalendar)`
    // Override a bunch of CSS properties that react-infinite-calendar
    // doesn't expose through their 'theme' object.

    ${(props: any) => {
        const calWidth = (props.width as number) ?? 1;
        const margin = props.margin ?? defaultMargin;
        const cellSize = (calWidth - margin * 14) / 7;

        return `--cell-size: ${cellSize}px;`;
    }}

    --cell-radius: 5px;

    .Cal__Header__date,
    Cal__Header__dateWrapper Cal__Header__year {
        color: black;
    }

    // Hide calendar background
    .Cal__MonthList__root,
    .Cal__Container__listWrapper {
        background-color: transparent;
    }

    // Hide start / end selection
    .Cal__Day__range.Cal__Day__selected.Cal__Day__start:after,
    .Cal__Day__range.Cal__Day__selected.Cal__Day__end:after {
        color: transparent;
    }

    // Remove formatting for selected range
    .Cal__Day__root.Cal__Day__selected .Cal__Day__selection {
        border-radius: var(--cell-radius);

        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        margin: 0px;
    }

    .Cal__Day__root.Cal__Day__selected .Cal__Day__selection {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .Cal__Day__range.Cal__Day__selected.Cal__Day__betweenRange .Cal__Day__day {
        font-size: var(--large-font);
    }

    .Cal__Weekdays__day {
        font-weight: 600;
    }

    .Cal__Day__start .Cal__Day__day,
    .Cal__Day__end .Cal__Day__day {
        top: 7% !important;
    }

    .Cal__Day__root.Cal__Day__selected .Cal__Day__selection .Cal__Day__day {
        top: 0px;
    }

    .Cal__Day__month {
        top: calc(var(--cell-size) / 10) !important;
    }

    // Reset border around "today" cell
    .Cal__Day__root.Cal__Day__enabled.Cal__Day__highlighted:before,
    .Cal__Day__root.Cal__Day__enabled:active:before,
    .Cal__Day__root.Cal__Day__enabled:hover:before,
    .Cal__Day__root.Cal__Day__today:before {
        border-radius: var(--cell-radius);

        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        margin-top: 0px;
        margin-left: 0px;
    }

    .Cal__Month__row.Cal__Month__partial:first-child {
        box-shadow: none;
    }

    .Cal__Month__rows {
        background-color: transparent;
        background-image: none;
        box-shadow: none;
    }

    .Cal__Month__row:first-child li {
        background-color: transparent;
        box-shadow: none !important;
    }
    .Cal__Month__row:nth-child(2) {
        box-shadow: none;
    }

    // Set day cell color
    .Cal__Month__row:first-child,
    .Cal__Month__partial:first-child {
        .Cal__Day__root {
            color: ${(props: any) => props.theme.colors.primary.fg};
            background-color: ${(props: any) => props.theme.colors.primary.neutralBg};
        }
    }

    // Adjust day cell size based on our calculated variables
    .Cal__Day__root {
        font-size: var(--large-font);
        display: inline-flex;
        align-items: center;
        justify-content: center;

        width: var(--cell-size);
        height: var(--cell-size);
        --row-width: calc(var(--cell-size) * 7);
        --day-margin: calc((100% - var(--row-width)) / 14);
        margin: 0 var(--day-margin);

        border-radius: var(--cell-radius);
        color: ${(props: any) => props.theme.colors.primary.fg};
        background-color: ${(props: any) => props.theme.colors.primary.neutralBg};
    }
`;

const OSKSingleCalendar = ({ selectedDate, ...props }: SingleInfiniteCalendarProps) => (
    <OSKInfiniteCalendar {...props} selected={selectedDate} single />
);

const OSKRangedCalendar = ({ startDate, endDate, ...props }: RangeInfiniteCalendarProps) => (
    <OSKInfiniteCalendar {...props} selected={{ start: startDate, end: endDate }} />
);

export { OSKSingleCalendar, OSKRangedCalendar };
export type { SingleInfiniteCalendarProps, RangeInfiniteCalendarProps, ThemedInfiniteCalendarProps };
