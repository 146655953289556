import { Box, Checkbox, Table, TableColumn, Text } from 'oskcomponents';
import { MapOf, noop } from 'oskcore';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { RootState } from '~/redux/store';
import {
    AssetDetection,
    filterAlertsByAsset,
    setVisibleDetections,
    toggleVisibleDetection,
} from '~/redux/modules/monitor/app';
import { date_format } from '~/utils';

type AssetDetectionCheckboxProps = {
    visible?: boolean;
    detectionId?: string;
};

const BaseAssetDetectionCheckbox = ({ detectionId, visible }: AssetDetectionCheckboxProps) => {
    const dispatch = useDispatch();
    return (
        <Checkbox
            defaultChecked={visible}
            onChange={(value: boolean) => {
                dispatch(toggleVisibleDetection(detectionId ?? '-1', value));
            }}
        />
    );
};

const AssetDetectionCheckbox = connect((state: RootState, ownProps: Partial<AssetDetectionCheckboxProps>) => {
    const { detectionId } = ownProps;
    return {
        visible: state.monitor.app.visibleDetections[detectionId ?? '-1'],
    };
}, noop)(BaseAssetDetectionCheckbox);

type AssetAlertsTableProps = {
    /** The currently selected assetId */
    selectedAssetId?: number;
    /** The alert to populate the table with */
    data: Array<AssetDetection>;
    /** Method that's called when the download button is clicked */
    onDownload: (file: string) => void;
    /** Whether or not to display the visibility checkbox column */
    showVisibilityFilters: boolean;
    /** The visibility map of assets from redux */
    visibleDetections: MapOf<boolean>;
};

const AssetAlertsTable = ({
    data,
    onDownload,
    showVisibilityFilters,
    visibleDetections,
    ...props
}: AssetAlertsTableProps) => {
    const dispatch = useDispatch();
    const columns = React.useMemo<Array<TableColumn>>(
        () => [
            ...(showVisibilityFilters
                ? [
                      {
                          Header: (e: any) => {
                              const { rows } = e;
                              const allSelected = (rows as Array<any>).every(
                                  (row) => visibleDetections[row.original.id],
                              );

                              return (
                                  <Checkbox
                                      defaultChecked={allSelected}
                                      onChange={(value: boolean) => {
                                          const newVisibleDetections: MapOf<boolean> = {};
                                          for (const row of rows) {
                                              newVisibleDetections[row.original.id] = value;
                                          }

                                          dispatch(setVisibleDetections(newVisibleDetections));
                                      }}
                                  />
                              );
                          },
                          disableSortBy: true,
                          accessor: 'visible', // Accessor value required.
                          Cell: ({ row }: any) => {
                              return <AssetDetectionCheckbox detectionId={row.original.id} />;
                          },
                      },
                  ]
                : []),
            {
                Header: 'Type',
                accessor: 'call_type',
                disableSortBy: true,
            },
            {
                Header: 'Detection Type',
                accessor: 'detection_type',
                disableSortBy: true,
            },
            {
                Header: 'Date',
                accessor: 'created_at',
                disableSortBy: true,
                Cell: ({ value }: any) => {
                    return date_format(value);
                },
            },
        ],
        [],
    );

    if (data.length < 1) {
        return (
            <Box grow center="all" style={{ alignSelf: 'center' }}>
                <Text>No alerts available</Text>
            </Box>
        );
    }

    return <Table {...props} columns={columns} data={data} />;
};

const mapStateToProps = (state: RootState, ownProps: Partial<AssetAlertsTableProps>) => {
    const { selectedAssetId } = ownProps;

    return {
        data: filterAlertsByAsset(state, selectedAssetId ?? -1),
        visibleDetections: state.monitor.app.visibleDetections,
    };
};

const ConnectedAssetAlertsTable = connect(mapStateToProps, noop)(AssetAlertsTable);
export default ConnectedAssetAlertsTable;
export { ConnectedAssetAlertsTable as AssetAlertsTable };
export type { AssetAlertsTableProps };
