import { noop, OSKGeoJson } from 'oskcore';
import React from 'react';
import { connect } from 'react-redux';
import { AssetDetection, filterAlertsByAsset } from '~/redux/modules/monitor/app';
import { RootState } from '~/redux/store';
import AlertMarker from '../AlertMarker';

export type AlertMarkerListProps = {
    /** The currently active assetId */
    selectedAssetId?: number;
    /** The alerts to render */
    alerts?: AssetDetection[];
    /** Optional filter to only show certain alerts. */
    filter?: string;
};

export const AlertMarkerList = ({ alerts, filter }: AlertMarkerListProps) => {
    return (
        <>
            {(alerts ?? []).map((alert, idx) => {
                if (filter && !alert.id.includes(filter)) {
                    return;
                }

                return (
                    <AlertMarker
                        key={`alert-${alert.id}-${idx}`}
                        position={OSKGeoJson.fromAPIGeometry(alert.coordinates)}
                        alert={alert}
                        assetId={alert.asset + ''}
                        alertId={alert.id + ''}
                    />
                );
            })}
        </>
    );
};

const mapStateToProps = (state: RootState, ownProps: Partial<AlertMarkerListProps>) => {
    const { selectedAssetId } = ownProps;

    return {
        alerts: filterAlertsByAsset(state, selectedAssetId ?? -1),
    };
};
export default connect(mapStateToProps, noop)(AlertMarkerList);
