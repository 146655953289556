import React, { useState, useRef } from 'react';
import { Box, Button, ColorVariants } from 'oskcomponents';
import { TimeSlider } from '~/molecules';
import { useDisableFeatureOnMouseOver, useDragScroll, useVerticalScroll } from '~/hooks';
import { connect } from 'react-redux';
import { AppDispatch, RootState } from '~/redux/store';
import { doSearchAsync, setTimelineDate, setTimelineMode, TimelineMode } from '~/redux/modules/data/search';
import { SliderItem } from '~/atoms';
import { isNavOpened } from '~/redux/modules/data/nav';
import { GlobalZIndex } from '~/constants';

const buttonStyle = {
    width: '105px',
    padding: '6px',
    margin: '2px',
};

function computeVariant(currentMode: TimelineMode, targetMode: TimelineMode): ColorVariants {
    if (currentMode === targetMode) {
        return 'selected';
    } else {
        return 'primary';
    }
}

export type TimelineProps = {
    /** The earliest date which the timeline component should consider. If null,
     * it will be a recommended value based on the mode. */
    earliestDate?: Date;
    /** The latest date which the timeline component should consider. If null,
     * it will be today. */
    latestDate?: Date;
    /** The currently selected index */
    selectedDate?: Date;
    /** The method to invoke when the TimelineMode should be changing */
    onChangeMode: (mode: TimelineMode) => void;
    /** The method to invoke when a particular date node is selected */
    onChangeDate: (date: Date | null) => void;
    /** Whether the component should be rendered at all. */
    visible?: boolean;
};

/** The Timeline component is used to display a vertical bar of time periods which
 * represent footprints on the map. As you click into the Timeline, you can adjust the range
 * of the currently visible footprints. It allows different modes including Yearly, Monthly, Daily.
 * Based on the mode, the respective time period will adjust to fit accordingly. For example:
 *
 * If you are in Monthly mode and you select January, you will see all footprints within your AOI
 * for January of the particular year in question.
 */
export const Timeline = ({
    earliestDate,
    latestDate,
    onChangeMode,
    onChangeDate,
    selectedDate,
    visible,
}: TimelineProps) => {
    const ref = useRef(null);
    const [mode, setMode] = useState<TimelineMode>('daily');

    useDisableFeatureOnMouseOver(ref, 'Drag', visible ?? true);
    useDisableFeatureOnMouseOver(ref, 'Zoom', visible ?? true);
    useVerticalScroll(ref, []);

    const [dragging] = useDragScroll('Vertitcal', ref, []);

    const handleModeChange = (mode: TimelineMode) => {
        setMode(mode);
        onChangeMode(mode);
    };

    const handleTimeSelect = (item: SliderItem | undefined, idx: number) => {
        if (item && !dragging) {
            onChangeDate(item.value ? new Date(item.value) : null);
            return true;
        } else {
            return false;
        }
    };

    return !visible ? (
        <React.Fragment />
    ) : (
        <Box
            style={{
                zIndex: GlobalZIndex.Timeline,
                top: '100px',
                right: '25px',
                alignItems: 'center',
                position: 'absolute',
            }}
            col
        >
            <Box style={{ paddingBottom: '10px' }} col>
                <Button
                    nohover={mode == 'yearly'}
                    variant={computeVariant(mode, 'yearly')}
                    onClick={handleModeChange.bind(this, 'yearly')}
                    style={buttonStyle}
                >
                    Yearly
                </Button>
                <Button
                    nohover={mode == 'monthly'}
                    variant={computeVariant(mode, 'monthly')}
                    onClick={handleModeChange.bind(this, 'monthly')}
                    style={buttonStyle}
                >
                    Monthly
                </Button>
                <Button
                    nohover={mode == 'daily'}
                    variant={computeVariant(mode, 'daily')}
                    onClick={handleModeChange.bind(this, 'daily')}
                    style={buttonStyle}
                >
                    Daily
                </Button>
            </Box>

            <Box
                ref={ref}
                id="time-slider-container"
                style={{
                    background: 'rgba(0,0,0,0.45)',
                    cursor: 'grab',
                    overflow: 'hidden',
                    height: 'calc(100vh - 500px)',
                    maxHeight: '400px',
                    width: '80px',
                    padding: '12px',
                    alignItems: 'center',
                    userSelect: 'none',
                }}
                col
            >
                <TimeSlider
                    selectedDate={selectedDate}
                    mode={mode}
                    earliestDate={earliestDate}
                    latestDate={latestDate || new Date()}
                    onSelect={handleTimeSelect}
                />
            </Box>
        </Box>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        earliestDate: state.data.search.startDate,
        latestDate: state.data.search.endDate,
        selectedDate: state.data.search.timelineDate,
        visible: !isNavOpened(state),
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        onChangeMode: (mode: TimelineMode) => {
            dispatch(setTimelineMode(mode));
        },
        onChangeDate: (date: Date | null) => {
            dispatch(setTimelineDate(date));
            // @ts-ignore
            dispatch(doSearchAsync());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Timeline);
