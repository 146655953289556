import { Box, OSKThemeType, Spinner, TabView, Text } from 'oskcomponents';
import {
    capitalize,
    DEFAULT_OSK_POSITION,
    encodeOSKPosition,
    noop,
    OSKGeoJson,
    OSKPosition,
    PublicAssetDetail,
    useRemountOnChange,
} from 'oskcore';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTheme } from 'styled-components';
import { Footprint, MapCentroid } from '~/atoms';
import EditableMap from '~/organisms/map/EditableMap';
import AssetAlertsTable from '~/organisms/tables/AssetAlertsTable';
import AssetDetectionsTable from '~/organisms/tables/AssetDetectionsTable';
import AssetReportsTable from '~/organisms/tables/AssetReportsTable';
import { Asset, AssetDetection, getDetectionZip } from '~/redux/modules/monitor/app';
import { RootState } from '~/redux/store';
import { date_format } from '~/utils';

type AssetDetailsSectionProps = {
    /** The asset currently being rendered */
    selectedAssetId: number;
    /** The asset to display details for */
    asset?: Asset;
    /** Optionally show the map */
    showMap?: boolean;
    /** Optionally show visibility filters for tables*/
    showVisibilityFilters?: boolean;
    /** Filtered detections to render */
    detections?: Array<AssetDetection>;
    /** Fetching single asset */
    fetchingAsset: boolean;
    /** Fetching asset detections*/
    fetchingDetections: boolean;
    /** A method that's called whenever the tab index in AssetDetailsSection changes. */
    onTabChange?: (tabIndex: number) => void;
};

const AssetDetailsSection = ({
    selectedAssetId,
    asset,
    showMap = true,
    showVisibilityFilters = false,
    fetchingAsset,
    fetchingDetections,
    onTabChange,
}: AssetDetailsSectionProps) => {
    const [visible] = useRemountOnChange([selectedAssetId]);
    const theme = useTheme() as OSKThemeType;
    const [geometry, setGeometry] = useState<OSKGeoJson>();
    const [loadingGeometry, setLoadingGeometry] = useState(true);

    useEffect(() => {
        setLoadingGeometry(true);

        const mappedAsset = asset as PublicAssetDetail;
        if (mappedAsset && mappedAsset.aoi) {
            setGeometry(OSKGeoJson.fromAPIGeometry(mappedAsset.aoi));
        }
        setLoadingGeometry(false);
    }, [asset]);

    let assetPosition: OSKPosition = DEFAULT_OSK_POSITION;

    if (geometry && !geometry.isEmpty()) {
        const bounds = geometry.getCentroid();
        assetPosition = { zoom: 19, coords: [bounds.lat, bounds.lng] };
    }

    return (
        <React.Fragment>
            {showMap && (
                <React.Fragment>
                    {!fetchingAsset && !loadingGeometry && geometry ? (
                        <Box
                            p={14}
                            style={{
                                marginBottom: '18px',
                                minHeight: '200px',
                            }}
                            col
                            data-testid="Details-Map"
                        >
                            <EditableMap position={encodeOSKPosition(assetPosition)}>
                                {visible && geometry ? (
                                    <Fragment>
                                        <MapCentroid animated={false} area={geometry} />
                                        <Footprint color="#00d1ff" shape={geometry} />
                                    </Fragment>
                                ) : (
                                    <Fragment></Fragment>
                                )}
                            </EditableMap>
                        </Box>
                    ) : (
                        <Box
                            center="all"
                            style={{
                                marginBottom: '18px',
                            }}
                            h={200}
                        >
                            <Spinner />
                        </Box>
                    )}
                </React.Fragment>
            )}
            <Box style={{ padding: '0px 14px' }} col>
                <Box row style={{ paddingBottom: '8px' }}>
                    <Text strong style={{ paddingRight: '8px' }}>
                        Monitor Period:
                    </Text>
                    <Text>
                        {asset
                            ? `${date_format(asset.monitoring_start_date)} - ${date_format(asset.monitoring_end_date)}`
                            : '--'}
                    </Text>
                </Box>
                <Box row>
                    <Text strong style={{ paddingRight: '8px' }}>
                        Monitor Frequency:
                    </Text>
                    <Text>
                        {asset && asset.monitoring_frequency ? `${capitalize(asset.monitoring_frequency)}` : '--'}
                    </Text>
                </Box>
            </Box>
            <Box grow style={{ height: '100%', paddingTop: '20px', paddingBottom: '10px' }}>
                <TabView
                    contentStyle={{
                        display: 'block',
                        height: 0,
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        border: 0,
                        margin: '10px',
                        paddingTop: '10px',
                    }}
                    tabContainerStyle={{
                        backgroundColor: 'transparent',
                        borderTop: `1px solid ${theme.colors.primary.altTransBg}`,
                        borderBottom: `1px solid ${theme.colors.primary.altTransBg}`,
                        justifyContent: 'space-around',
                    }}
                    tabStyle={{ marginLeft: '2px', margin: '0px 18px' }}
                    defaultTab={0}
                    onTabChange={onTabChange}
                    tabItems={[
                        {
                            label: 'Alerts',
                            component: (
                                <Box style={{ height: '100%' }} col>
                                    {!fetchingAsset && !fetchingDetections ? (
                                        <AssetAlertsTable
                                            selectedAssetId={selectedAssetId}
                                            onDownload={() => {
                                                getDetectionZip().then((detectionZip) => {
                                                    /**
                                                     * download file
                                                     */
                                                    console.log(detectionZip);
                                                });
                                            }}
                                            showVisibilityFilters={showVisibilityFilters}
                                        />
                                    ) : (
                                        <Box grow center="all">
                                            <Spinner />
                                        </Box>
                                    )}
                                </Box>
                            ),
                        },
                        {
                            label: 'Reports',
                            component: (
                                <Box style={{ height: '100%' }} col>
                                    {!fetchingAsset && !fetchingDetections ? (
                                        <AssetReportsTable
                                            selectedAssetId={selectedAssetId}
                                            onDownload={() => {
                                                getDetectionZip().then((detectionZip) => {
                                                    /**
                                                     * download file
                                                     */
                                                    console.log(detectionZip);
                                                });
                                            }}
                                        />
                                    ) : (
                                        <Box grow center="all">
                                            <Spinner />
                                        </Box>
                                    )}
                                </Box>
                            ),
                        },
                        {
                            label: 'Detections',
                            component: (
                                <Box style={{ height: '100%' }} col>
                                    {!fetchingAsset && !fetchingDetections ? (
                                        <AssetDetectionsTable
                                            selectedAssetId={selectedAssetId}
                                            onDownload={() => {
                                                getDetectionZip().then((detectionZip) => {
                                                    /**
                                                     * download file
                                                     */
                                                    console.log(detectionZip);
                                                });
                                            }}
                                            showVisibilityFilters={showVisibilityFilters}
                                        />
                                    ) : (
                                        <Box grow center="all">
                                            <Spinner />
                                        </Box>
                                    )}
                                </Box>
                            ),
                        },
                    ]}
                />
            </Box>
        </React.Fragment>
    );
};

const mapStateToProps = (state: RootState, props: Partial<AssetDetailsSectionProps>) => {
    const { selectedAssetId } = props;
    return {
        asset: state.monitor.app.assets[selectedAssetId ?? -1],
        fetchingAsset: state.monitor.app.fetchingAsset,
        fetchingDetections: state.monitor.app.fetchingDetections,
    };
};
export { AssetDetailsSection };
export default connect(mapStateToProps, noop)(AssetDetailsSection);
