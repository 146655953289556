import React, { useCallback, useRef } from 'react';
import { Box } from '../Box';

export type FileUploadProps = {
    /** The children of this component should be the button which will be clicked to open a file picker. */
    children?: React.ReactNode;
    /** If true, the click event will not open a file picker dialog. */
    disabled?: boolean;
    /** The comma-delimited list of file types which should be passed to the input box. */
    fileTypes?: string;
    /** The callback which is invoked when one or more files are picked. */
    onFilesChosen?: (files: FileList | null) => void;
};

/**
 * This component allows you to convert any arbitrary button into a FileUpload dialog.
 * Simply pass, as children, the button component. And when it is pressed, a FilePicker
 * will be presented. The Files object will be passed to the onFilesChosen callback when
 * a selection is made.
 */
export const FileUpload = ({ children, disabled, fileTypes, onFilesChosen }: FileUploadProps) => {
    const fileUploadRef = useRef<HTMLInputElement>(null);

    const handleClick = useCallback(() => {
        if (fileUploadRef.current && !disabled) {
            fileUploadRef.current.click();
        }
    }, [disabled, fileUploadRef.current]);

    const handleSubmit = useCallback(() => {
        if (fileUploadRef.current) {
            onFilesChosen && onFilesChosen(fileUploadRef.current.files);
        }
    }, [fileUploadRef.current]);

    return (
        <Box onClick={handleClick}>
            <input
                ref={fileUploadRef}
                accept={fileTypes}
                onChange={handleSubmit}
                type="file"
                style={{ display: 'none' }}
            />
            {children}
        </Box>
    );
};
