import React, { useRef, useState } from 'react';
import { Box, DefaultThemeProvider } from 'oskcomponents';
import { OSKCoordinate, OSKGeoJson } from 'oskcore';
import { divIcon, LatLng } from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import styled from 'styled-components';

import { Marker } from 'react-leaflet';
type OSKMapTooltipProps = {
    /** Class name for styled-components support */
    className?: string;
    /** Children */
    children: React.ReactNode | Array<React.ReactNode>;
    /** The location on the map to render it */
    position: OSKCoordinate | OSKGeoJson;
    /** Whether or not the tooltip can expand */
    expandable?: boolean;
    /** Content to show in expanded mode */
    expandedContent?: React.ReactNode | Array<React.ReactNode>;
    /** Method to invoke when a click event is detected inside the popup */
    onClick?: (evt: any) => void;
    /** Method to invoke when a dblclick event is detected inside the popup */
    onDoubleClick?: () => void;
    /** Method to invoke when the mouse enters the popup */
    onHoverStart?: () => void;
    /** Method to invoke when the mouse exits the popup */
    onHoverEnd?: () => void;
};

const OSKMapTooltipBase = ({
    expandable,
    expandedContent,
    className,
    children,
    position,
    onClick,
    onDoubleClick,
    onHoverStart,
    onHoverEnd,
    ...props
}: OSKMapTooltipProps) => {
    const [expand, setExpand] = useState<boolean>(false);
    const isHovered = useRef(false);

    const iconMarkup = renderToStaticMarkup(
        <DefaultThemeProvider>
            <Box
                className={className}
                {...props}
                onClick={() => {
                    expandable && setExpand(!expand);
                }}
            >
                <Box className="info-box-container" center="all">
                    {children}
                    <Box center="vertical">
                        <Box className={`info-box-expansion ${expand ? 'info-expanded' : 'info-collapsed'}`}>
                            {expand && expandedContent}
                        </Box>
                    </Box>
                </Box>
                <Box className="info-box-pointer" />
            </Box>
        </DefaultThemeProvider>,
    );

    // Compute the eventHandler
    const eventHandlers = {
        click: onClick,
        dblclick: onDoubleClick,
        mouseover: () => {
            if (!isHovered.current && onHoverStart) {
                onHoverStart();
            }
            isHovered.current = true;
        },
        mouseout: () => {
            if (isHovered.current && onHoverEnd) {
                onHoverEnd();
            }
            isHovered.current = false;
        },
    };

    const pos = 'getCentroid' in position ? (position as OSKGeoJson).getCentroid() : (position as LatLng);

    // Setting an empty class name to override the 'leaflet-div-icon' styling
    const customMarkerIcon: any = divIcon({ className: '', html: iconMarkup });
    return <Marker eventHandlers={eventHandlers} icon={customMarkerIcon} position={pos} />;
};

const OSKMapTooltip = styled(OSKMapTooltipBase)`
    position: absolute;
    width: 0;
    height: 0;
    display: flex;
    justify-content: center;
    white-space: nowrap;
    transition: width 2s;

    /* Align 100% with the default leaflet marker. If this is found
     to be inaccurate or unnecessary in the future, we can remove it. */
    left: 6px;

    .info-box-pointer {
        width: 0;
        height: 0;
        position: absolute;
        top: -8px;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;

        border-top: 15px solid ${(props: any) => props.theme.colors.primary.transBg};
    }

    .info-box-container {
        position: absolute;
        margin-left: -12px;
        max-height: 125px;
        min-width: 75px;
        max-width: 260px;
        bottom: 8px;
        background-color: ${(props: any) => props.theme.colors.primary.transBg};
        color: ${(props: any) => props.theme.colors.primary.fg};
        border-radius: 10px 0px 0px 10px;
        padding: 10px 0px 10px 12px;
    }

    .info-box-expansion {
        height: 100%;

        background-color: ${(props: any) => props.theme.colors.primary.transBg};
        color: ${(props: any) => props.theme.colors.primary.fg};
        border-radius: 0px 10px 10px 0px;
    }

    .info-collapsed {
        position: absolute;
        right: -12px;
        width: 12px;
    }

    .info-expanded {
        position: absolute;
        padding: 10px;
    }
`;

export { OSKMapTooltip };
export type { OSKMapTooltipProps };
