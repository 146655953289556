import React from 'react';
import styled, { useTheme } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { OSKIcon, OSKIconType, OSKThemeType } from 'oskcomponents';

export type ModeButtonProps = {
    /** Whether this button can be clicked or not */
    disabled?: boolean;
    /** The icon to display inside the button */
    icon: OSKIconType;
    /** An additional theme to apply to the button container */
    className?: string;
    /** href for where to navigate to */
    location: string;
    /** Which mode the user is on */
    active: boolean;
    /** Pass onClick from parent */
    onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
};

/**
 * ActiveModeButton is a component which renders an icon in a circular button
 * with optional text beneath. It is styled using the variant system
 * and can optionally support mouse-hover effects.
 *
 * To use, simply pass an Icon component and implement onToggle.
 */
const ModeButton = styled(({ className, location, icon, active, onClick }: ModeButtonProps) => {
    const theme = useTheme() as OSKThemeType;
    return (
        <NavLink data-isactive={active} to={location} className={`${className}`} onClick={onClick}>
            <OSKIcon code={icon} fill={active ? theme.colors.primary.invertedFg : theme.colors.primary.fg} />
        </NavLink>
    );
})`
    margin-top: 20px;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    display: flex;
    height: 40px;
    width: 40px;

    &[data-isactive='true'] {
        background: ${(props: any) => props.theme.colors.primary.accent};
    }

    &[data-isactive='false']:hover {
        background: ${(props: any) => props.theme.colors.primary.accent};
        svg {
            fill: ${(props: any) => props.theme.colors.primary.invertedFg};
        }
    }
`;

export { ModeButton };
