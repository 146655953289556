import React from 'react';
import { Box, BoxProps, OSKThemeType, Text } from 'oskcomponents';
import styled, { useTheme } from 'styled-components';
import { Opportunity, useRemountOnChange } from 'oskcore';
import { GlobalZIndex } from '~/constants';
import { date_format } from '~/utils';

type OpportunityAttributeProps = {
    attribute: string;
    value: string;
    color: string;
};

const OpportunityAttribute = ({ attribute, value, color }: OpportunityAttributeProps) => {
    const theme = useTheme() as OSKThemeType;
    return (
        <Box>
            <Text variant="small" font={theme.monospaceFont} style={{ flexGrow: 1, color: theme.colors.primary.fg }}>
                {attribute}:
            </Text>
            <Text variant="small" font={theme.monospaceFont} style={{ color }}>
                {value}
            </Text>
        </Box>
    );
};

export type OpportunityOverlayProps = {
    className?: string;
    sensor_name: string;
    start_time: Date;
    end_time: Date;
    ticks: Array<Opportunity>;
} & Omit<BoxProps, 'ref'>;

export const OpportunityOverlay = styled(
    ({ className, style, sensor_name, start_time, end_time, ticks, ...props }: OpportunityOverlayProps) => {
        const theme = useTheme() as OSKThemeType;
        const [visible] = useRemountOnChange([sensor_name, start_time, end_time, ticks]);

        return visible ? (
            <Box
                className={className}
                style={{
                    position: 'absolute',
                    marginLeft: '16px',
                    marginTop: '16px',
                    width: '304px',
                    borderRadius: '30px',
                    padding: '20px',
                    zIndex: GlobalZIndex.MapSection,
                    ...style,
                }}
                col
                {...props}
            >
                <OpportunityAttribute color={theme.colors.accent} attribute="Sensor" value={sensor_name} />
                <OpportunityAttribute color={theme.colors.accent} attribute="Segments" value={`${ticks.length}`} />
                <OpportunityAttribute
                    color={theme.colors.accent}
                    attribute="Intercept begins"
                    value={date_format(start_time)}
                />
            </Box>
        ) : (
            <></>
        );
    },
)`
    & {
        background-color: ${(props: any) => props.theme.colors.primary.transBg}; //rgba(24, 24, 24, 0.82);
        animation-duration: 1s;
        animation-name: blinkin;
    }

    @keyframes blinkin {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
`;
