import React from 'react';
import { useTheme } from 'styled-components';
import { Box, BoxProps } from '../Box';
import { Text } from '../Typography';
import { ColorVariants, OSKThemeType } from '../DefaultThemeProvider';
import CSS from 'csstype';

export type SidePanelHeaderProps = {
    /** Children elements to render in the header */
    children?: React.ReactNode;
    /** Which color theme to use for the header */
    variant: ColorVariants;
    /** Styles to override */
    style?: CSS.Properties;
} & Omit<BoxProps, 'ref'>;

/**
 * The SidePanelHeader component is styled according to the color variant prop
 * and represents the top section of a SidePanel dialog box. Use it to enclose
 * header title text.
 */
const SidePanelHeader = ({ children, variant, style, ...props }: SidePanelHeaderProps) => {
    const theme = useTheme() as OSKThemeType;
    return (
        <Box
            style={{
                borderBottom: '1px solid #626264',
                ...style,
            }}
            {...props}
        >
            <Text variant="large" color={theme.colors[variant].fg}>
                {children}
            </Text>
        </Box>
    );
};

export { SidePanelHeader };
