/* tslint:disable */
/* eslint-disable */
/**
 * Orbital Sidekick Sigma API
 * The Orbital Sidekick Sigma API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * Represents a user\'s association with a program. Includes relevant details about the user and the list of roles they have on a given program.
 * @export
 * @interface AddUserToProgramRequest
 */
export interface AddUserToProgramRequest {
    /**
     * The id of the user
     * @type {number}
     * @memberof AddUserToProgramRequest
     */
    id?: number;
    /**
     * The email address of the user
     * @type {string}
     * @memberof AddUserToProgramRequest
     */
    email?: string;
    /**
     * A list of role names associated with the user
     * @type {Array<string>}
     * @memberof AddUserToProgramRequest
     */
    roles: Array<string>;
}
/**
 * For security purposes, we will not return client_secret in any requests except for create.
 * @export
 * @interface Application
 */
export interface Application {
    /**
     *
     * @type {number}
     * @memberof Application
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof Application
     */
    client_id?: string;
    /**
     *
     * @type {number}
     * @memberof Application
     */
    user?: number | null;
    /**
     * Allowed URIs list, space separated
     * @type {string}
     * @memberof Application
     */
    redirect_uris?: string;
    /**
     *
     * @type {string}
     * @memberof Application
     */
    client_type: ApplicationClientTypeEnum;
    /**
     *
     * @type {string}
     * @memberof Application
     */
    authorization_grant_type: ApplicationAuthorizationGrantTypeEnum;
    /**
     *
     * @type {string}
     * @memberof Application
     */
    name?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum ApplicationClientTypeEnum {
    Confidential = 'confidential',
    Public = 'public',
}
/**
 * @export
 * @enum {string}
 */
export enum ApplicationAuthorizationGrantTypeEnum {
    AuthorizationCode = 'authorization-code',
    Implicit = 'implicit',
    Password = 'password',
    ClientCredentials = 'client-credentials',
    OpenidHybrid = 'openid-hybrid',
}

/**
 *
 * @export
 * @interface ApplicationCreate
 */
export interface ApplicationCreate {
    /**
     *
     * @type {number}
     * @memberof ApplicationCreate
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof ApplicationCreate
     */
    client_id?: string;
    /**
     *
     * @type {number}
     * @memberof ApplicationCreate
     */
    user?: number | null;
    /**
     * Allowed URIs list, space separated
     * @type {string}
     * @memberof ApplicationCreate
     */
    redirect_uris?: string;
    /**
     *
     * @type {string}
     * @memberof ApplicationCreate
     */
    client_type: ApplicationCreateClientTypeEnum;
    /**
     *
     * @type {string}
     * @memberof ApplicationCreate
     */
    authorization_grant_type: ApplicationCreateAuthorizationGrantTypeEnum;
    /**
     *
     * @type {string}
     * @memberof ApplicationCreate
     */
    client_secret?: string;
    /**
     *
     * @type {string}
     * @memberof ApplicationCreate
     */
    name?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum ApplicationCreateClientTypeEnum {
    Confidential = 'confidential',
    Public = 'public',
}
/**
 * @export
 * @enum {string}
 */
export enum ApplicationCreateAuthorizationGrantTypeEnum {
    AuthorizationCode = 'authorization-code',
    Implicit = 'implicit',
    Password = 'password',
    ClientCredentials = 'client-credentials',
    OpenidHybrid = 'openid-hybrid',
}

/**
 *
 * @export
 * @interface ApplicationCreateRequest
 */
export interface ApplicationCreateRequest {
    /**
     *
     * @type {string}
     * @memberof ApplicationCreateRequest
     */
    client_id?: string;
    /**
     *
     * @type {number}
     * @memberof ApplicationCreateRequest
     */
    user?: number | null;
    /**
     * Allowed URIs list, space separated
     * @type {string}
     * @memberof ApplicationCreateRequest
     */
    redirect_uris?: string;
    /**
     *
     * @type {string}
     * @memberof ApplicationCreateRequest
     */
    client_type: ApplicationCreateRequestClientTypeEnum;
    /**
     *
     * @type {string}
     * @memberof ApplicationCreateRequest
     */
    authorization_grant_type: ApplicationCreateRequestAuthorizationGrantTypeEnum;
    /**
     *
     * @type {string}
     * @memberof ApplicationCreateRequest
     */
    client_secret?: string;
    /**
     *
     * @type {string}
     * @memberof ApplicationCreateRequest
     */
    name?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum ApplicationCreateRequestClientTypeEnum {
    Confidential = 'confidential',
    Public = 'public',
}
/**
 * @export
 * @enum {string}
 */
export enum ApplicationCreateRequestAuthorizationGrantTypeEnum {
    AuthorizationCode = 'authorization-code',
    Implicit = 'implicit',
    Password = 'password',
    ClientCredentials = 'client-credentials',
    OpenidHybrid = 'openid-hybrid',
}

/**
 * For security purposes, we will not return client_secret in any requests except for create.
 * @export
 * @interface ApplicationRequest
 */
export interface ApplicationRequest {
    /**
     *
     * @type {string}
     * @memberof ApplicationRequest
     */
    client_id?: string;
    /**
     *
     * @type {number}
     * @memberof ApplicationRequest
     */
    user?: number | null;
    /**
     * Allowed URIs list, space separated
     * @type {string}
     * @memberof ApplicationRequest
     */
    redirect_uris?: string;
    /**
     *
     * @type {string}
     * @memberof ApplicationRequest
     */
    client_type: ApplicationRequestClientTypeEnum;
    /**
     *
     * @type {string}
     * @memberof ApplicationRequest
     */
    authorization_grant_type: ApplicationRequestAuthorizationGrantTypeEnum;
    /**
     *
     * @type {string}
     * @memberof ApplicationRequest
     */
    name?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum ApplicationRequestClientTypeEnum {
    Confidential = 'confidential',
    Public = 'public',
}
/**
 * @export
 * @enum {string}
 */
export enum ApplicationRequestAuthorizationGrantTypeEnum {
    AuthorizationCode = 'authorization-code',
    Implicit = 'implicit',
    Password = 'password',
    ClientCredentials = 'client-credentials',
    OpenidHybrid = 'openid-hybrid',
}

/**
 *
 * @export
 * @interface Capture
 */
export interface Capture {
    /**
     *
     * @type {string}
     * @memberof Capture
     */
    id: string;
    /**
     *
     * @type {number}
     * @memberof Capture
     */
    sensor_id: number;
    /**
     *
     * @type {string}
     * @memberof Capture
     */
    collect_id: string;
    /**
     *
     * @type {Geometry | GeometryCollection}
     * @memberof Capture
     */
    footprint?: Geometry | GeometryCollection | null;
    /**
     *
     * @type {string}
     * @memberof Capture
     */
    collect_start_time: string;
    /**
     *
     * @type {string}
     * @memberof Capture
     */
    capture_start_time: string;
}
/**
 *
 * @export
 * @interface DataPipelineCalibration
 */
export interface DataPipelineCalibration {
    /**
     *
     * @type {number}
     * @memberof DataPipelineCalibration
     */
    id: number;
    /**
     *
     * @type {number}
     * @memberof DataPipelineCalibration
     */
    calibration_type: number;
    /**
     *
     * @type {string}
     * @memberof DataPipelineCalibration
     */
    calibration_time: string;
    /**
     *
     * @type {string}
     * @memberof DataPipelineCalibration
     */
    raw_arn: string;
    /**
     *
     * @type {string}
     * @memberof DataPipelineCalibration
     */
    working_arn?: string | null;
    /**
     *
     * @type {number}
     * @memberof DataPipelineCalibration
     */
    validation_status: number;
    /**
     *
     * @type {number}
     * @memberof DataPipelineCalibration
     */
    frame_rate?: number | null;
    /**
     *
     * @type {number}
     * @memberof DataPipelineCalibration
     */
    samples?: number | null;
    /**
     *
     * @type {number}
     * @memberof DataPipelineCalibration
     */
    bands?: number | null;
    /**
     *
     * @type {number}
     * @memberof DataPipelineCalibration
     */
    bin?: number | null;
    /**
     *
     * @type {number}
     * @memberof DataPipelineCalibration
     */
    sensor: number;
}
/**
 *
 * @export
 * @interface DataPipelineCalibrationRequest
 */
export interface DataPipelineCalibrationRequest {
    /**
     *
     * @type {number}
     * @memberof DataPipelineCalibrationRequest
     */
    calibration_type: number;
    /**
     *
     * @type {string}
     * @memberof DataPipelineCalibrationRequest
     */
    calibration_time: string;
    /**
     *
     * @type {string}
     * @memberof DataPipelineCalibrationRequest
     */
    raw_arn: string;
    /**
     *
     * @type {string}
     * @memberof DataPipelineCalibrationRequest
     */
    working_arn?: string | null;
    /**
     *
     * @type {number}
     * @memberof DataPipelineCalibrationRequest
     */
    validation_status: number;
    /**
     *
     * @type {number}
     * @memberof DataPipelineCalibrationRequest
     */
    frame_rate?: number | null;
    /**
     *
     * @type {number}
     * @memberof DataPipelineCalibrationRequest
     */
    samples?: number | null;
    /**
     *
     * @type {number}
     * @memberof DataPipelineCalibrationRequest
     */
    bands?: number | null;
    /**
     *
     * @type {number}
     * @memberof DataPipelineCalibrationRequest
     */
    bin?: number | null;
    /**
     *
     * @type {number}
     * @memberof DataPipelineCalibrationRequest
     */
    sensor: number;
}
/**
 *
 * @export
 * @interface Detection
 */
export interface Detection {
    /**
     *
     * @type {string}
     * @memberof Detection
     */
    id: string;
    /**
     *
     * @type {Geometry | GeometryCollection}
     * @memberof Detection
     */
    coordinates: Geometry | GeometryCollection;
    /**
     *
     * @type {string}
     * @memberof Detection
     */
    call_type: string;
    /**
     *
     * @type {string}
     * @memberof Detection
     */
    detection_type: string;
    /**
     *
     * @type {number}
     * @memberof Detection
     */
    capture?: number | null;
    /**
     *
     * @type {string}
     * @memberof Detection
     */
    created_at: string;
    /**
     *
     * @type {number}
     * @memberof Detection
     */
    asset: number;
    /**
     *
     * @type {string}
     * @memberof Detection
     */
    detection_class?: DetectionDetectionClassEnum;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof Detection
     */
    artifacts: { [key: string]: string };
}

/**
 * @export
 * @enum {string}
 */
export enum DetectionDetectionClassEnum {
    Default = 'default',
    Alert = 'alert',
}

/**
 *
 * @export
 * @interface DetectionArtifact
 */
export interface DetectionArtifact {
    /**
     *
     * @type {number}
     * @memberof DetectionArtifact
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof DetectionArtifact
     */
    artifact_type: string;
    /**
     *
     * @type {string}
     * @memberof DetectionArtifact
     */
    uri: string;
    /**
     *
     * @type {number}
     * @memberof DetectionArtifact
     */
    detection: number;
}
/**
 *
 * @export
 * @interface DetectionArtifactRequest
 */
export interface DetectionArtifactRequest {
    /**
     *
     * @type {string}
     * @memberof DetectionArtifactRequest
     */
    artifact_type: string;
    /**
     *
     * @type {string}
     * @memberof DetectionArtifactRequest
     */
    uri: string;
    /**
     *
     * @type {number}
     * @memberof DetectionArtifactRequest
     */
    detection: number;
}
/**
 * API Serializers for Download Request, creates serializer classes with all fields and built-in create()/update() methods.
 * @export
 * @interface DownloadRequest
 */
export interface DownloadRequest {
    /**
     *
     * @type {number}
     * @memberof DownloadRequest
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof DownloadRequest
     */
    time_requested: string;
    /**
     *
     * @type {number}
     * @memberof DownloadRequest
     */
    requested_by: number;
    /**
     *
     * @type {number}
     * @memberof DownloadRequest
     */
    requested_for: number;
    /**
     *
     * @type {string}
     * @memberof DownloadRequest
     */
    zip_file_loc?: string | null;
    /**
     *
     * @type {string}
     * @memberof DownloadRequest
     */
    time_ready?: string | null;
    /**
     *
     * @type {string}
     * @memberof DownloadRequest
     */
    time_expired?: string | null;
    /**
     *
     * @type {Array<number>}
     * @memberof DownloadRequest
     */
    requested_captures: Array<number>;
}
/**
 * API Serializers for Download Request, creates serializer classes with all fields and built-in create()/update() methods.
 * @export
 * @interface DownloadRequestRequest
 */
export interface DownloadRequestRequest {
    /**
     *
     * @type {string}
     * @memberof DownloadRequestRequest
     */
    zip_file_loc?: string | null;
    /**
     *
     * @type {string}
     * @memberof DownloadRequestRequest
     */
    time_ready?: string | null;
    /**
     *
     * @type {string}
     * @memberof DownloadRequestRequest
     */
    time_expired?: string | null;
    /**
     *
     * @type {Array<number>}
     * @memberof DownloadRequestRequest
     */
    requested_captures: Array<number>;
}
/**
 *
 * @export
 * @interface Geometry
 */
export interface Geometry {
    /**
     * The type of the geos instance
     * @type {string}
     * @memberof Geometry
     */
    type: string;
    /**
     *
     * @type {Array<number> | Array<Array>}
     * @memberof Geometry
     */
    coordinates: Array<number> | Array<any>;
}
/**
 *
 * @export
 * @interface GeometryCollection
 */
export interface GeometryCollection {
    /**
     * The type of the geos instance
     * @type {string}
     * @memberof GeometryCollection
     */
    type: GeometryCollectionTypeEnum;
    /**
     *
     * @type {Array<Geometry>}
     * @memberof GeometryCollection
     */
    geometries: Array<Geometry>;
}

/**
 * @export
 * @enum {string}
 */
export enum GeometryCollectionTypeEnum {
    GeometryCollection = 'GeometryCollection',
}

/**
 *
 * @export
 * @interface InternalCapture
 */
export interface InternalCapture {
    /**
     *
     * @type {number}
     * @memberof InternalCapture
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof InternalCapture
     */
    collect_id: string;
    /**
     *
     * @type {string}
     * @memberof InternalCapture
     */
    file_id: string;
    /**
     *
     * @type {string}
     * @memberof InternalCapture
     */
    collect_start_time: string;
    /**
     *
     * @type {string}
     * @memberof InternalCapture
     */
    capture_start_time: string;
    /**
     *
     * @type {Geometry | GeometryCollection}
     * @memberof InternalCapture
     */
    footprint?: Geometry | GeometryCollection | null;
    /**
     *
     * @type {number}
     * @memberof InternalCapture
     */
    sensor: number;
}
/**
 *
 * @export
 * @interface InternalCaptureRequest
 */
export interface InternalCaptureRequest {
    /**
     *
     * @type {string}
     * @memberof InternalCaptureRequest
     */
    collect_id: string;
    /**
     *
     * @type {string}
     * @memberof InternalCaptureRequest
     */
    file_id: string;
    /**
     *
     * @type {string}
     * @memberof InternalCaptureRequest
     */
    collect_start_time: string;
    /**
     *
     * @type {string}
     * @memberof InternalCaptureRequest
     */
    capture_start_time: string;
    /**
     *
     * @type {Geometry | GeometryCollection}
     * @memberof InternalCaptureRequest
     */
    footprint?: Geometry | GeometryCollection | null;
    /**
     *
     * @type {number}
     * @memberof InternalCaptureRequest
     */
    sensor: number;
}
/**
 *
 * @export
 * @interface InternalDetection
 */
export interface InternalDetection {
    /**
     *
     * @type {number}
     * @memberof InternalDetection
     */
    pk_id: number;
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof InternalDetection
     */
    artifacts: { [key: string]: string };
    /**
     *
     * @type {string}
     * @memberof InternalDetection
     */
    external_id?: string | null;
    /**
     *
     * @type {Geometry | GeometryCollection}
     * @memberof InternalDetection
     */
    coordinates: Geometry | GeometryCollection;
    /**
     *
     * @type {string}
     * @memberof InternalDetection
     */
    call_type: string;
    /**
     *
     * @type {string}
     * @memberof InternalDetection
     */
    detection_type: string;
    /**
     *
     * @type {string}
     * @memberof InternalDetection
     */
    detection_class?: InternalDetectionDetectionClassEnum;
    /**
     *
     * @type {string}
     * @memberof InternalDetection
     */
    created_at: string;
    /**
     *
     * @type {number}
     * @memberof InternalDetection
     */
    intensity_value?: number | null;
    /**
     *
     * @type {string}
     * @memberof InternalDetection
     */
    intensity_unit?: string | null;
    /**
     *
     * @type {number}
     * @memberof InternalDetection
     */
    review_status?: InternalDetectionReviewStatusEnum;
    /**
     *
     * @type {string}
     * @memberof InternalDetection
     */
    reviewed_at?: string | null;
    /**
     *
     * @type {number}
     * @memberof InternalDetection
     */
    capture?: number | null;
    /**
     *
     * @type {number}
     * @memberof InternalDetection
     */
    asset: number;
    /**
     *
     * @type {number}
     * @memberof InternalDetection
     */
    reviewed_by?: number | null;
}

/**
 * @export
 * @enum {string}
 */
export enum InternalDetectionDetectionClassEnum {
    Default = 'default',
    Alert = 'alert',
}
/**
 * @export
 * @enum {string}
 */
export enum InternalDetectionReviewStatusEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
}

/**
 *
 * @export
 * @interface InternalDetectionRequest
 */
export interface InternalDetectionRequest {
    /**
     *
     * @type {string}
     * @memberof InternalDetectionRequest
     */
    external_id?: string | null;
    /**
     *
     * @type {Geometry | GeometryCollection}
     * @memberof InternalDetectionRequest
     */
    coordinates: Geometry | GeometryCollection;
    /**
     *
     * @type {string}
     * @memberof InternalDetectionRequest
     */
    call_type: string;
    /**
     *
     * @type {string}
     * @memberof InternalDetectionRequest
     */
    detection_type: string;
    /**
     *
     * @type {string}
     * @memberof InternalDetectionRequest
     */
    detection_class?: InternalDetectionRequestDetectionClassEnum;
    /**
     *
     * @type {number}
     * @memberof InternalDetectionRequest
     */
    intensity_value?: number | null;
    /**
     *
     * @type {string}
     * @memberof InternalDetectionRequest
     */
    intensity_unit?: string | null;
    /**
     *
     * @type {number}
     * @memberof InternalDetectionRequest
     */
    review_status?: InternalDetectionRequestReviewStatusEnum;
    /**
     *
     * @type {string}
     * @memberof InternalDetectionRequest
     */
    reviewed_at?: string | null;
    /**
     *
     * @type {number}
     * @memberof InternalDetectionRequest
     */
    capture?: number | null;
    /**
     *
     * @type {number}
     * @memberof InternalDetectionRequest
     */
    asset: number;
    /**
     *
     * @type {number}
     * @memberof InternalDetectionRequest
     */
    reviewed_by?: number | null;
}

/**
 * @export
 * @enum {string}
 */
export enum InternalDetectionRequestDetectionClassEnum {
    Default = 'default',
    Alert = 'alert',
}
/**
 * @export
 * @enum {string}
 */
export enum InternalDetectionRequestReviewStatusEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
}

/**
 * API Serializers for Download Request, creates serializer classes with all fields and built-in create()/update() methods.
 * @export
 * @interface InternalDownloadRequest
 */
export interface InternalDownloadRequest {
    /**
     *
     * @type {number}
     * @memberof InternalDownloadRequest
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof InternalDownloadRequest
     */
    time_requested: string;
    /**
     *
     * @type {number}
     * @memberof InternalDownloadRequest
     */
    requested_by: number;
    /**
     *
     * @type {number}
     * @memberof InternalDownloadRequest
     */
    requested_for: number;
    /**
     *
     * @type {string}
     * @memberof InternalDownloadRequest
     */
    zip_file_loc?: string | null;
    /**
     *
     * @type {string}
     * @memberof InternalDownloadRequest
     */
    time_ready?: string | null;
    /**
     *
     * @type {string}
     * @memberof InternalDownloadRequest
     */
    time_expired?: string | null;
    /**
     *
     * @type {Array<number>}
     * @memberof InternalDownloadRequest
     */
    requested_captures: Array<number>;
}
/**
 * API Serializers for Download Request, creates serializer classes with all fields and built-in create()/update() methods.
 * @export
 * @interface InternalDownloadRequestRequest
 */
export interface InternalDownloadRequestRequest {
    /**
     *
     * @type {string}
     * @memberof InternalDownloadRequestRequest
     */
    zip_file_loc?: string | null;
    /**
     *
     * @type {string}
     * @memberof InternalDownloadRequestRequest
     */
    time_ready?: string | null;
    /**
     *
     * @type {string}
     * @memberof InternalDownloadRequestRequest
     */
    time_expired?: string | null;
    /**
     *
     * @type {Array<number>}
     * @memberof InternalDownloadRequestRequest
     */
    requested_captures: Array<number>;
}
/**
 *
 * @export
 * @interface InternalReport
 */
export interface InternalReport {
    /**
     *
     * @type {number}
     * @memberof InternalReport
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof InternalReport
     */
    external_id?: string;
    /**
     *
     * @type {number}
     * @memberof InternalReport
     */
    report_type?: InternalReportReportTypeEnum;
    /**
     *
     * @type {string}
     * @memberof InternalReport
     */
    s3_uri?: string | null;
    /**
     *
     * @type {number}
     * @memberof InternalReport
     */
    status?: InternalReportStatusEnum;
    /**
     *
     * @type {number}
     * @memberof InternalReport
     */
    requested_by?: number | null;
    /**
     *
     * @type {number}
     * @memberof InternalReport
     */
    program: number;
    /**
     *
     * @type {Array<number>}
     * @memberof InternalReport
     */
    assets: Array<number>;
    /**
     *
     * @type {string}
     * @memberof InternalReport
     */
    start_date: string;
    /**
     *
     * @type {string}
     * @memberof InternalReport
     */
    end_date: string;
    /**
     *
     * @type {string}
     * @memberof InternalReport
     */
    created_at: string;
    /**
     *
     * @type {string}
     * @memberof InternalReport
     */
    updated_at: string;
}

/**
 * @export
 * @enum {string}
 */
export enum InternalReportReportTypeEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
}
/**
 * @export
 * @enum {string}
 */
export enum InternalReportStatusEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
}

/**
 *
 * @export
 * @interface InternalReportRequest
 */
export interface InternalReportRequest {
    /**
     *
     * @type {string}
     * @memberof InternalReportRequest
     */
    external_id?: string;
    /**
     *
     * @type {number}
     * @memberof InternalReportRequest
     */
    report_type?: InternalReportRequestReportTypeEnum;
    /**
     *
     * @type {string}
     * @memberof InternalReportRequest
     */
    s3_uri?: string | null;
    /**
     *
     * @type {number}
     * @memberof InternalReportRequest
     */
    status?: InternalReportRequestStatusEnum;
    /**
     *
     * @type {number}
     * @memberof InternalReportRequest
     */
    requested_by?: number | null;
    /**
     *
     * @type {number}
     * @memberof InternalReportRequest
     */
    program: number;
    /**
     *
     * @type {Array<number>}
     * @memberof InternalReportRequest
     */
    assets: Array<number>;
    /**
     *
     * @type {string}
     * @memberof InternalReportRequest
     */
    start_date: string;
    /**
     *
     * @type {string}
     * @memberof InternalReportRequest
     */
    end_date: string;
}

/**
 * @export
 * @enum {string}
 */
export enum InternalReportRequestReportTypeEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
}
/**
 * @export
 * @enum {string}
 */
export enum InternalReportRequestStatusEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
}

/**
 *
 * @export
 * @interface InternalRole
 */
export interface InternalRole {
    /**
     *
     * @type {number}
     * @memberof InternalRole
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof InternalRole
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof InternalRole
     */
    slug: string;
    /**
     *
     * @type {number}
     * @memberof InternalRole
     */
    permission_set: number;
    /**
     *
     * @type {number}
     * @memberof InternalRole
     */
    resource: number;
}
/**
 *
 * @export
 * @interface InternalRoleRequest
 */
export interface InternalRoleRequest {
    /**
     *
     * @type {string}
     * @memberof InternalRoleRequest
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof InternalRoleRequest
     */
    slug: string;
}
/**
 * An Opportunity represents and an intersection between
 * @export
 * @interface Opportunity
 */
export interface Opportunity {
    /**
     * Nadir point directly underneath the sensor
     * @type {Geometry | GeometryCollection}
     * @memberof Opportunity
     */
    nadir_point: Geometry | GeometryCollection;
    /**
     * Polygon covering Field of View while pointed at nadir
     * @type {Geometry | GeometryCollection}
     * @memberof Opportunity
     */
    nadir_fov: Geometry | GeometryCollection;
    /**
     * Polygon covering all areas potentially within the Field of             View allowing for slewing. Sensors will not be able to image the entire max field of view in one pass.
     * @type {Geometry | GeometryCollection}
     * @memberof Opportunity
     */
    max_fov: Geometry | GeometryCollection;
    /**
     * Start of the time interval covered by this opportunity
     * @type {string}
     * @memberof Opportunity
     */
    start_time: string;
    /**
     * End of the time interval covered by this opportunity, by default             10 seconds after start_time
     * @type {string}
     * @memberof Opportunity
     */
    end_time: string;
    /**
     * The sensor id
     * @type {number}
     * @memberof Opportunity
     */
    sensor: number;
}
/**
 * An Opportunity represents and an intersection between
 * @export
 * @interface OpportunityRequest
 */
export interface OpportunityRequest {
    /**
     * Nadir point directly underneath the sensor
     * @type {Geometry | GeometryCollection}
     * @memberof OpportunityRequest
     */
    nadir_point: Geometry | GeometryCollection;
    /**
     * Polygon covering Field of View while pointed at nadir
     * @type {Geometry | GeometryCollection}
     * @memberof OpportunityRequest
     */
    nadir_fov: Geometry | GeometryCollection;
    /**
     * Polygon covering all areas potentially within the Field of             View allowing for slewing. Sensors will not be able to image the entire max field of view in one pass.
     * @type {Geometry | GeometryCollection}
     * @memberof OpportunityRequest
     */
    max_fov: Geometry | GeometryCollection;
    /**
     * Start of the time interval covered by this opportunity
     * @type {string}
     * @memberof OpportunityRequest
     */
    start_time: string;
    /**
     * End of the time interval covered by this opportunity, by default             10 seconds after start_time
     * @type {string}
     * @memberof OpportunityRequest
     */
    end_time: string;
    /**
     * The sensor id
     * @type {number}
     * @memberof OpportunityRequest
     */
    sensor: number;
}
/**
 *
 * @export
 * @interface PaginatedApplicationList
 */
export interface PaginatedApplicationList {
    /**
     *
     * @type {number}
     * @memberof PaginatedApplicationList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedApplicationList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedApplicationList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<Application>}
     * @memberof PaginatedApplicationList
     */
    results?: Array<Application>;
}
/**
 *
 * @export
 * @interface PaginatedCaptureList
 */
export interface PaginatedCaptureList {
    /**
     *
     * @type {number}
     * @memberof PaginatedCaptureList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedCaptureList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedCaptureList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<Capture>}
     * @memberof PaginatedCaptureList
     */
    results?: Array<Capture>;
}
/**
 *
 * @export
 * @interface PaginatedDataPipelineCalibrationList
 */
export interface PaginatedDataPipelineCalibrationList {
    /**
     *
     * @type {number}
     * @memberof PaginatedDataPipelineCalibrationList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedDataPipelineCalibrationList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedDataPipelineCalibrationList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<DataPipelineCalibration>}
     * @memberof PaginatedDataPipelineCalibrationList
     */
    results?: Array<DataPipelineCalibration>;
}
/**
 *
 * @export
 * @interface PaginatedDetectionList
 */
export interface PaginatedDetectionList {
    /**
     *
     * @type {number}
     * @memberof PaginatedDetectionList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedDetectionList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedDetectionList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<Detection>}
     * @memberof PaginatedDetectionList
     */
    results?: Array<Detection>;
}
/**
 *
 * @export
 * @interface PaginatedDownloadRequestList
 */
export interface PaginatedDownloadRequestList {
    /**
     *
     * @type {number}
     * @memberof PaginatedDownloadRequestList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedDownloadRequestList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedDownloadRequestList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<DownloadRequest>}
     * @memberof PaginatedDownloadRequestList
     */
    results?: Array<DownloadRequest>;
}
/**
 *
 * @export
 * @interface PaginatedInternalCaptureList
 */
export interface PaginatedInternalCaptureList {
    /**
     *
     * @type {number}
     * @memberof PaginatedInternalCaptureList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedInternalCaptureList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedInternalCaptureList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<InternalCapture>}
     * @memberof PaginatedInternalCaptureList
     */
    results?: Array<InternalCapture>;
}
/**
 *
 * @export
 * @interface PaginatedInternalDetectionList
 */
export interface PaginatedInternalDetectionList {
    /**
     *
     * @type {number}
     * @memberof PaginatedInternalDetectionList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedInternalDetectionList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedInternalDetectionList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<InternalDetection>}
     * @memberof PaginatedInternalDetectionList
     */
    results?: Array<InternalDetection>;
}
/**
 *
 * @export
 * @interface PaginatedInternalReportList
 */
export interface PaginatedInternalReportList {
    /**
     *
     * @type {number}
     * @memberof PaginatedInternalReportList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedInternalReportList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedInternalReportList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<InternalReport>}
     * @memberof PaginatedInternalReportList
     */
    results?: Array<InternalReport>;
}
/**
 *
 * @export
 * @interface PaginatedInternalRoleList
 */
export interface PaginatedInternalRoleList {
    /**
     *
     * @type {number}
     * @memberof PaginatedInternalRoleList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedInternalRoleList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedInternalRoleList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<InternalRole>}
     * @memberof PaginatedInternalRoleList
     */
    results?: Array<InternalRole>;
}
/**
 *
 * @export
 * @interface PaginatedOpportunityList
 */
export interface PaginatedOpportunityList {
    /**
     *
     * @type {number}
     * @memberof PaginatedOpportunityList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedOpportunityList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedOpportunityList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<Opportunity>}
     * @memberof PaginatedOpportunityList
     */
    results?: Array<Opportunity>;
}
/**
 *
 * @export
 * @interface PaginatedPermissionSetList
 */
export interface PaginatedPermissionSetList {
    /**
     *
     * @type {number}
     * @memberof PaginatedPermissionSetList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedPermissionSetList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedPermissionSetList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<PermissionSet>}
     * @memberof PaginatedPermissionSetList
     */
    results?: Array<PermissionSet>;
}
/**
 *
 * @export
 * @interface PaginatedPlanTaskList
 */
export interface PaginatedPlanTaskList {
    /**
     *
     * @type {number}
     * @memberof PaginatedPlanTaskList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedPlanTaskList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedPlanTaskList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<PlanTask>}
     * @memberof PaginatedPlanTaskList
     */
    results?: Array<PlanTask>;
}
/**
 *
 * @export
 * @interface PaginatedPrivateAssetList
 */
export interface PaginatedPrivateAssetList {
    /**
     *
     * @type {number}
     * @memberof PaginatedPrivateAssetList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedPrivateAssetList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedPrivateAssetList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<PrivateAsset>}
     * @memberof PaginatedPrivateAssetList
     */
    results?: Array<PrivateAsset>;
}
/**
 *
 * @export
 * @interface PaginatedPrivatePlanList
 */
export interface PaginatedPrivatePlanList {
    /**
     *
     * @type {number}
     * @memberof PaginatedPrivatePlanList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedPrivatePlanList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedPrivatePlanList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<PrivatePlan>}
     * @memberof PaginatedPrivatePlanList
     */
    results?: Array<PrivatePlan>;
}
/**
 *
 * @export
 * @interface PaginatedProgramAOIList
 */
export interface PaginatedProgramAOIList {
    /**
     *
     * @type {number}
     * @memberof PaginatedProgramAOIList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedProgramAOIList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedProgramAOIList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<ProgramAOI>}
     * @memberof PaginatedProgramAOIList
     */
    results?: Array<ProgramAOI>;
}
/**
 *
 * @export
 * @interface PaginatedProgramList
 */
export interface PaginatedProgramList {
    /**
     *
     * @type {number}
     * @memberof PaginatedProgramList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedProgramList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedProgramList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<Program>}
     * @memberof PaginatedProgramList
     */
    results?: Array<Program>;
}
/**
 *
 * @export
 * @interface PaginatedProgramUserList
 */
export interface PaginatedProgramUserList {
    /**
     *
     * @type {number}
     * @memberof PaginatedProgramUserList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedProgramUserList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedProgramUserList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<ProgramUser>}
     * @memberof PaginatedProgramUserList
     */
    results?: Array<ProgramUser>;
}
/**
 *
 * @export
 * @interface PaginatedPublicAssetList
 */
export interface PaginatedPublicAssetList {
    /**
     *
     * @type {number}
     * @memberof PaginatedPublicAssetList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedPublicAssetList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedPublicAssetList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<PublicAsset>}
     * @memberof PaginatedPublicAssetList
     */
    results?: Array<PublicAsset>;
}
/**
 *
 * @export
 * @interface PaginatedPublicPlanList
 */
export interface PaginatedPublicPlanList {
    /**
     *
     * @type {number}
     * @memberof PaginatedPublicPlanList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedPublicPlanList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedPublicPlanList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<PublicPlan>}
     * @memberof PaginatedPublicPlanList
     */
    results?: Array<PublicPlan>;
}
/**
 *
 * @export
 * @interface PaginatedRasterArtifactList
 */
export interface PaginatedRasterArtifactList {
    /**
     *
     * @type {number}
     * @memberof PaginatedRasterArtifactList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedRasterArtifactList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedRasterArtifactList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<RasterArtifact>}
     * @memberof PaginatedRasterArtifactList
     */
    results?: Array<RasterArtifact>;
}
/**
 *
 * @export
 * @interface PaginatedReportList
 */
export interface PaginatedReportList {
    /**
     *
     * @type {number}
     * @memberof PaginatedReportList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedReportList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedReportList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<Report>}
     * @memberof PaginatedReportList
     */
    results?: Array<Report>;
}
/**
 *
 * @export
 * @interface PaginatedRoleList
 */
export interface PaginatedRoleList {
    /**
     *
     * @type {number}
     * @memberof PaginatedRoleList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedRoleList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedRoleList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<Role>}
     * @memberof PaginatedRoleList
     */
    results?: Array<Role>;
}
/**
 *
 * @export
 * @interface PaginatedSearchAreaList
 */
export interface PaginatedSearchAreaList {
    /**
     *
     * @type {number}
     * @memberof PaginatedSearchAreaList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedSearchAreaList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedSearchAreaList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<SearchArea>}
     * @memberof PaginatedSearchAreaList
     */
    results?: Array<SearchArea>;
}
/**
 *
 * @export
 * @interface PaginatedSensorList
 */
export interface PaginatedSensorList {
    /**
     *
     * @type {number}
     * @memberof PaginatedSensorList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedSensorList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedSensorList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<Sensor>}
     * @memberof PaginatedSensorList
     */
    results?: Array<Sensor>;
}
/**
 *
 * @export
 * @interface PaginatedStateVectorList
 */
export interface PaginatedStateVectorList {
    /**
     *
     * @type {number}
     * @memberof PaginatedStateVectorList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedStateVectorList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedStateVectorList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<StateVector>}
     * @memberof PaginatedStateVectorList
     */
    results?: Array<StateVector>;
}
/**
 *
 * @export
 * @interface PaginatedTLEList
 */
export interface PaginatedTLEList {
    /**
     *
     * @type {number}
     * @memberof PaginatedTLEList
     */
    count?: number;
    /**
     *
     * @type {string}
     * @memberof PaginatedTLEList
     */
    next?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaginatedTLEList
     */
    previous?: string | null;
    /**
     *
     * @type {Array<TLE>}
     * @memberof PaginatedTLEList
     */
    results?: Array<TLE>;
}
/**
 * For security purposes, we will not return client_secret in any requests except for create.
 * @export
 * @interface PatchedApplicationRequest
 */
export interface PatchedApplicationRequest {
    /**
     *
     * @type {string}
     * @memberof PatchedApplicationRequest
     */
    client_id?: string;
    /**
     *
     * @type {number}
     * @memberof PatchedApplicationRequest
     */
    user?: number | null;
    /**
     * Allowed URIs list, space separated
     * @type {string}
     * @memberof PatchedApplicationRequest
     */
    redirect_uris?: string;
    /**
     *
     * @type {string}
     * @memberof PatchedApplicationRequest
     */
    client_type?: PatchedApplicationRequestClientTypeEnum;
    /**
     *
     * @type {string}
     * @memberof PatchedApplicationRequest
     */
    authorization_grant_type?: PatchedApplicationRequestAuthorizationGrantTypeEnum;
    /**
     *
     * @type {string}
     * @memberof PatchedApplicationRequest
     */
    name?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PatchedApplicationRequestClientTypeEnum {
    Confidential = 'confidential',
    Public = 'public',
}
/**
 * @export
 * @enum {string}
 */
export enum PatchedApplicationRequestAuthorizationGrantTypeEnum {
    AuthorizationCode = 'authorization-code',
    Implicit = 'implicit',
    Password = 'password',
    ClientCredentials = 'client-credentials',
    OpenidHybrid = 'openid-hybrid',
}

/**
 *
 * @export
 * @interface PatchedDataPipelineCalibrationRequest
 */
export interface PatchedDataPipelineCalibrationRequest {
    /**
     *
     * @type {number}
     * @memberof PatchedDataPipelineCalibrationRequest
     */
    calibration_type?: number;
    /**
     *
     * @type {string}
     * @memberof PatchedDataPipelineCalibrationRequest
     */
    calibration_time?: string;
    /**
     *
     * @type {string}
     * @memberof PatchedDataPipelineCalibrationRequest
     */
    raw_arn?: string;
    /**
     *
     * @type {string}
     * @memberof PatchedDataPipelineCalibrationRequest
     */
    working_arn?: string | null;
    /**
     *
     * @type {number}
     * @memberof PatchedDataPipelineCalibrationRequest
     */
    validation_status?: number;
    /**
     *
     * @type {number}
     * @memberof PatchedDataPipelineCalibrationRequest
     */
    frame_rate?: number | null;
    /**
     *
     * @type {number}
     * @memberof PatchedDataPipelineCalibrationRequest
     */
    samples?: number | null;
    /**
     *
     * @type {number}
     * @memberof PatchedDataPipelineCalibrationRequest
     */
    bands?: number | null;
    /**
     *
     * @type {number}
     * @memberof PatchedDataPipelineCalibrationRequest
     */
    bin?: number | null;
    /**
     *
     * @type {number}
     * @memberof PatchedDataPipelineCalibrationRequest
     */
    sensor?: number;
}
/**
 *
 * @export
 * @interface PatchedDetectionArtifactRequest
 */
export interface PatchedDetectionArtifactRequest {
    /**
     *
     * @type {string}
     * @memberof PatchedDetectionArtifactRequest
     */
    artifact_type?: string;
    /**
     *
     * @type {string}
     * @memberof PatchedDetectionArtifactRequest
     */
    uri?: string;
    /**
     *
     * @type {number}
     * @memberof PatchedDetectionArtifactRequest
     */
    detection?: number;
}
/**
 *
 * @export
 * @interface PatchedInternalCaptureRequest
 */
export interface PatchedInternalCaptureRequest {
    /**
     *
     * @type {string}
     * @memberof PatchedInternalCaptureRequest
     */
    collect_id?: string;
    /**
     *
     * @type {string}
     * @memberof PatchedInternalCaptureRequest
     */
    file_id?: string;
    /**
     *
     * @type {string}
     * @memberof PatchedInternalCaptureRequest
     */
    collect_start_time?: string;
    /**
     *
     * @type {string}
     * @memberof PatchedInternalCaptureRequest
     */
    capture_start_time?: string;
    /**
     *
     * @type {Geometry | GeometryCollection}
     * @memberof PatchedInternalCaptureRequest
     */
    footprint?: Geometry | GeometryCollection | null;
    /**
     *
     * @type {number}
     * @memberof PatchedInternalCaptureRequest
     */
    sensor?: number;
}
/**
 *
 * @export
 * @interface PatchedInternalDetectionRequest
 */
export interface PatchedInternalDetectionRequest {
    /**
     *
     * @type {string}
     * @memberof PatchedInternalDetectionRequest
     */
    external_id?: string | null;
    /**
     *
     * @type {Geometry | GeometryCollection}
     * @memberof PatchedInternalDetectionRequest
     */
    coordinates?: Geometry | GeometryCollection;
    /**
     *
     * @type {string}
     * @memberof PatchedInternalDetectionRequest
     */
    call_type?: string;
    /**
     *
     * @type {string}
     * @memberof PatchedInternalDetectionRequest
     */
    detection_type?: string;
    /**
     *
     * @type {string}
     * @memberof PatchedInternalDetectionRequest
     */
    detection_class?: PatchedInternalDetectionRequestDetectionClassEnum;
    /**
     *
     * @type {number}
     * @memberof PatchedInternalDetectionRequest
     */
    intensity_value?: number | null;
    /**
     *
     * @type {string}
     * @memberof PatchedInternalDetectionRequest
     */
    intensity_unit?: string | null;
    /**
     *
     * @type {number}
     * @memberof PatchedInternalDetectionRequest
     */
    review_status?: PatchedInternalDetectionRequestReviewStatusEnum;
    /**
     *
     * @type {string}
     * @memberof PatchedInternalDetectionRequest
     */
    reviewed_at?: string | null;
    /**
     *
     * @type {number}
     * @memberof PatchedInternalDetectionRequest
     */
    capture?: number | null;
    /**
     *
     * @type {number}
     * @memberof PatchedInternalDetectionRequest
     */
    asset?: number;
    /**
     *
     * @type {number}
     * @memberof PatchedInternalDetectionRequest
     */
    reviewed_by?: number | null;
}

/**
 * @export
 * @enum {string}
 */
export enum PatchedInternalDetectionRequestDetectionClassEnum {
    Default = 'default',
    Alert = 'alert',
}
/**
 * @export
 * @enum {string}
 */
export enum PatchedInternalDetectionRequestReviewStatusEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
}

/**
 * API Serializers for Download Request, creates serializer classes with all fields and built-in create()/update() methods.
 * @export
 * @interface PatchedInternalDownloadRequestRequest
 */
export interface PatchedInternalDownloadRequestRequest {
    /**
     *
     * @type {string}
     * @memberof PatchedInternalDownloadRequestRequest
     */
    zip_file_loc?: string | null;
    /**
     *
     * @type {string}
     * @memberof PatchedInternalDownloadRequestRequest
     */
    time_ready?: string | null;
    /**
     *
     * @type {string}
     * @memberof PatchedInternalDownloadRequestRequest
     */
    time_expired?: string | null;
    /**
     *
     * @type {Array<number>}
     * @memberof PatchedInternalDownloadRequestRequest
     */
    requested_captures?: Array<number>;
}
/**
 *
 * @export
 * @interface PatchedInternalReportRequest
 */
export interface PatchedInternalReportRequest {
    /**
     *
     * @type {string}
     * @memberof PatchedInternalReportRequest
     */
    external_id?: string;
    /**
     *
     * @type {number}
     * @memberof PatchedInternalReportRequest
     */
    report_type?: PatchedInternalReportRequestReportTypeEnum;
    /**
     *
     * @type {string}
     * @memberof PatchedInternalReportRequest
     */
    s3_uri?: string | null;
    /**
     *
     * @type {number}
     * @memberof PatchedInternalReportRequest
     */
    status?: PatchedInternalReportRequestStatusEnum;
    /**
     *
     * @type {number}
     * @memberof PatchedInternalReportRequest
     */
    requested_by?: number | null;
    /**
     *
     * @type {number}
     * @memberof PatchedInternalReportRequest
     */
    program?: number;
    /**
     *
     * @type {Array<number>}
     * @memberof PatchedInternalReportRequest
     */
    assets?: Array<number>;
    /**
     *
     * @type {string}
     * @memberof PatchedInternalReportRequest
     */
    start_date?: string;
    /**
     *
     * @type {string}
     * @memberof PatchedInternalReportRequest
     */
    end_date?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PatchedInternalReportRequestReportTypeEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
}
/**
 * @export
 * @enum {string}
 */
export enum PatchedInternalReportRequestStatusEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
}

/**
 *
 * @export
 * @interface PatchedInternalRoleRequest
 */
export interface PatchedInternalRoleRequest {
    /**
     *
     * @type {string}
     * @memberof PatchedInternalRoleRequest
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof PatchedInternalRoleRequest
     */
    slug?: string;
}
/**
 * An Opportunity represents and an intersection between
 * @export
 * @interface PatchedOpportunityRequest
 */
export interface PatchedOpportunityRequest {
    /**
     * Nadir point directly underneath the sensor
     * @type {Geometry | GeometryCollection}
     * @memberof PatchedOpportunityRequest
     */
    nadir_point?: Geometry | GeometryCollection;
    /**
     * Polygon covering Field of View while pointed at nadir
     * @type {Geometry | GeometryCollection}
     * @memberof PatchedOpportunityRequest
     */
    nadir_fov?: Geometry | GeometryCollection;
    /**
     * Polygon covering all areas potentially within the Field of             View allowing for slewing. Sensors will not be able to image the entire max field of view in one pass.
     * @type {Geometry | GeometryCollection}
     * @memberof PatchedOpportunityRequest
     */
    max_fov?: Geometry | GeometryCollection;
    /**
     * Start of the time interval covered by this opportunity
     * @type {string}
     * @memberof PatchedOpportunityRequest
     */
    start_time?: string;
    /**
     * End of the time interval covered by this opportunity, by default             10 seconds after start_time
     * @type {string}
     * @memberof PatchedOpportunityRequest
     */
    end_time?: string;
    /**
     * The sensor id
     * @type {number}
     * @memberof PatchedOpportunityRequest
     */
    sensor?: number;
}
/**
 *
 * @export
 * @interface PatchedPermissionSetRequest
 */
export interface PatchedPermissionSetRequest {
    /**
     *
     * @type {string}
     * @memberof PatchedPermissionSetRequest
     */
    name?: string;
    /**
     *
     * @type {Array<number>}
     * @memberof PatchedPermissionSetRequest
     */
    permissions?: Array<number>;
}
/**
 *
 * @export
 * @interface PatchedPlanTaskRequest
 */
export interface PatchedPlanTaskRequest {
    /**
     *
     * @type {Geometry | GeometryCollection}
     * @memberof PatchedPlanTaskRequest
     */
    geometry?: Geometry | GeometryCollection;
    /**
     *
     * @type {number}
     * @memberof PatchedPlanTaskRequest
     */
    time_start?: number;
    /**
     *
     * @type {number}
     * @memberof PatchedPlanTaskRequest
     */
    time_end?: number;
    /**
     *
     * @type {number}
     * @memberof PatchedPlanTaskRequest
     */
    commit_status?: PatchedPlanTaskRequestCommitStatusEnum;
    /**
     *
     * @type {number}
     * @memberof PatchedPlanTaskRequest
     */
    plan?: number;
    /**
     *
     * @type {number}
     * @memberof PatchedPlanTaskRequest
     */
    sensor?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum PatchedPlanTaskRequestCommitStatusEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
}

/**
 *
 * @export
 * @interface PatchedPrivateAssetRequest
 */
export interface PatchedPrivateAssetRequest {
    /**
     *
     * @type {string}
     * @memberof PatchedPrivateAssetRequest
     */
    name?: string;
    /**
     *
     * @type {number}
     * @memberof PatchedPrivateAssetRequest
     */
    aoi?: number;
    /**
     *
     * @type {number}
     * @memberof PatchedPrivateAssetRequest
     */
    program?: number;
    /**
     *
     * @type {Array<string>}
     * @memberof PatchedPrivateAssetRequest
     */
    alert_emails?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof PatchedPrivateAssetRequest
     */
    report_emails?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof PatchedPrivateAssetRequest
     */
    monitoring_start_date?: string | null;
    /**
     *
     * @type {string}
     * @memberof PatchedPrivateAssetRequest
     */
    monitoring_end_date?: string | null;
    /**
     *
     * @type {string}
     * @memberof PatchedPrivateAssetRequest
     */
    monitoring_frequency?: PatchedPrivateAssetRequestMonitoringFrequencyEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum PatchedPrivateAssetRequestMonitoringFrequencyEnum {
    Daily = 'daily',
    Weekly = 'weekly',
    Monthly = 'monthly',
    Quarterly = 'quarterly',
    Yearly = 'yearly',
}

/**
 *
 * @export
 * @interface PatchedPrivatePlanRequest
 */
export interface PatchedPrivatePlanRequest {
    /**
     *
     * @type {Geometry | GeometryCollection}
     * @memberof PatchedPrivatePlanRequest
     */
    geometry?: Geometry | GeometryCollection;
    /**
     *
     * @type {number}
     * @memberof PatchedPrivatePlanRequest
     */
    priority?: PatchedPrivatePlanRequestPriorityEnum;
    /**
     *
     * @type {number}
     * @memberof PatchedPrivatePlanRequest
     */
    progress_status?: PatchedPrivatePlanRequestProgressStatusEnum;
    /**
     *
     * @type {number}
     * @memberof PatchedPrivatePlanRequest
     */
    commit_status?: PatchedPrivatePlanRequestCommitStatusEnum;
    /**
     *
     * @type {number}
     * @memberof PatchedPrivatePlanRequest
     */
    generation_mode?: PatchedPrivatePlanRequestGenerationModeEnum;
    /**
     *
     * @type {number}
     * @memberof PatchedPrivatePlanRequest
     */
    num_tasks_planned?: number;
    /**
     *
     * @type {number}
     * @memberof PatchedPrivatePlanRequest
     */
    num_tasks_committed?: number;
    /**
     *
     * @type {string}
     * @memberof PatchedPrivatePlanRequest
     */
    start_date?: string;
    /**
     *
     * @type {string}
     * @memberof PatchedPrivatePlanRequest
     */
    expiration_date?: string;
    /**
     *
     * @type {Array<number>}
     * @memberof PatchedPrivatePlanRequest
     */
    sensors?: Array<number>;
}

/**
 * @export
 * @enum {string}
 */
export enum PatchedPrivatePlanRequestPriorityEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
}
/**
 * @export
 * @enum {string}
 */
export enum PatchedPrivatePlanRequestProgressStatusEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
}
/**
 * @export
 * @enum {string}
 */
export enum PatchedPrivatePlanRequestCommitStatusEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
}
/**
 * @export
 * @enum {string}
 */
export enum PatchedPrivatePlanRequestGenerationModeEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
}

/**
 *
 * @export
 * @interface PatchedProgramAOIRequest
 */
export interface PatchedProgramAOIRequest {
    /**
     *
     * @type {string}
     * @memberof PatchedProgramAOIRequest
     */
    name?: string;
    /**
     *
     * @type {number}
     * @memberof PatchedProgramAOIRequest
     */
    program?: number;
    /**
     *
     * @type {Geometry | GeometryCollection}
     * @memberof PatchedProgramAOIRequest
     */
    geometry?: Geometry | GeometryCollection;
}
/**
 *
 * @export
 * @interface PatchedProgramRequest
 */
export interface PatchedProgramRequest {
    /**
     *
     * @type {string}
     * @memberof PatchedProgramRequest
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof PatchedProgramRequest
     */
    company_name?: string | null;
    /**
     *
     * @type {string}
     * @memberof PatchedProgramRequest
     */
    start_date?: string | null;
    /**
     *
     * @type {string}
     * @memberof PatchedProgramRequest
     */
    end_date?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof PatchedProgramRequest
     */
    active?: boolean;
    /**
     *
     * @type {Array<number>}
     * @memberof PatchedProgramRequest
     */
    data_level_access?: Array<number> | null;
    /**
     *
     * @type {Geometry | GeometryCollection}
     * @memberof PatchedProgramRequest
     */
    region_access?: Geometry | GeometryCollection | null;
    /**
     *
     * @type {number}
     * @memberof PatchedProgramRequest
     */
    max_users?: number | null;
    /**
     *
     * @type {number}
     * @memberof PatchedProgramRequest
     */
    max_taskings?: number | null;
    /**
     *
     * @type {Array<number>}
     * @memberof PatchedProgramRequest
     */
    sensor_data_access?: Array<number>;
}
/**
 *
 * @export
 * @interface PatchedPublicAssetRequest
 */
export interface PatchedPublicAssetRequest {
    /**
     *
     * @type {Array<string>}
     * @memberof PatchedPublicAssetRequest
     */
    alert_emails?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof PatchedPublicAssetRequest
     */
    report_emails?: Array<string>;
}
/**
 *
 * @export
 * @interface PatchedRasterArtifactRequest
 */
export interface PatchedRasterArtifactRequest {
    /**
     *
     * @type {Array<string>}
     * @memberof PatchedRasterArtifactRequest
     */
    files?: Array<string>;
    /**
     *
     * @type {number}
     * @memberof PatchedRasterArtifactRequest
     */
    processing_level?: number;
    /**
     *
     * @type {string}
     * @memberof PatchedRasterArtifactRequest
     */
    artifact_type?: string;
    /**
     *
     * @type {number}
     * @memberof PatchedRasterArtifactRequest
     */
    capture?: number | null;
}
/**
 *
 * @export
 * @interface PatchedReportRequest
 */
export interface PatchedReportRequest {
    /**
     *
     * @type {string}
     * @memberof PatchedReportRequest
     */
    external_id?: string;
    /**
     *
     * @type {number}
     * @memberof PatchedReportRequest
     */
    report_type?: PatchedReportRequestReportTypeEnum;
    /**
     *
     * @type {string}
     * @memberof PatchedReportRequest
     */
    s3_uri?: string | null;
    /**
     *
     * @type {number}
     * @memberof PatchedReportRequest
     */
    requested_by?: number | null;
    /**
     *
     * @type {number}
     * @memberof PatchedReportRequest
     */
    program?: number;
    /**
     *
     * @type {Array<number>}
     * @memberof PatchedReportRequest
     */
    assets?: Array<number>;
    /**
     *
     * @type {string}
     * @memberof PatchedReportRequest
     */
    start_date?: string;
    /**
     *
     * @type {string}
     * @memberof PatchedReportRequest
     */
    end_date?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PatchedReportRequestReportTypeEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
}

/**
 *
 * @export
 * @interface PatchedSearchAreaRequest
 */
export interface PatchedSearchAreaRequest {
    /**
     *
     * @type {string}
     * @memberof PatchedSearchAreaRequest
     */
    name?: string;
    /**
     *
     * @type {Geometry | GeometryCollection}
     * @memberof PatchedSearchAreaRequest
     */
    area?: Geometry | GeometryCollection;
}
/**
 *
 * @export
 * @interface PatchedSensorRequest
 */
export interface PatchedSensorRequest {
    /**
     *
     * @type {number}
     * @memberof PatchedSensorRequest
     */
    osk_id?: number;
    /**
     *
     * @type {string}
     * @memberof PatchedSensorRequest
     */
    osk_sensor_name?: string;
    /**
     *
     * @type {string}
     * @memberof PatchedSensorRequest
     */
    osk_serial_number?: string;
    /**
     *
     * @type {number}
     * @memberof PatchedSensorRequest
     */
    default_frame_rate?: number | null;
    /**
     *
     * @type {number}
     * @memberof PatchedSensorRequest
     */
    ifov?: number | null;
    /**
     *
     * @type {string}
     * @memberof PatchedSensorRequest
     */
    catalog_number?: string | null;
}
/**
 *
 * @export
 * @interface PatchedStateVectorRequest
 */
export interface PatchedStateVectorRequest {
    /**
     *
     * @type {number}
     * @memberof PatchedStateVectorRequest
     */
    epoch?: number;
    /**
     *
     * @type {number}
     * @memberof PatchedStateVectorRequest
     */
    position_x?: number;
    /**
     *
     * @type {number}
     * @memberof PatchedStateVectorRequest
     */
    position_y?: number;
    /**
     *
     * @type {number}
     * @memberof PatchedStateVectorRequest
     */
    position_z?: number;
    /**
     *
     * @type {number}
     * @memberof PatchedStateVectorRequest
     */
    velocity_x?: number;
    /**
     *
     * @type {number}
     * @memberof PatchedStateVectorRequest
     */
    velocity_y?: number;
    /**
     *
     * @type {number}
     * @memberof PatchedStateVectorRequest
     */
    velocity_z?: number;
}
/**
 *
 * @export
 * @interface PermissionSet
 */
export interface PermissionSet {
    /**
     *
     * @type {number}
     * @memberof PermissionSet
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof PermissionSet
     */
    name: string;
    /**
     *
     * @type {Array<number>}
     * @memberof PermissionSet
     */
    permissions?: Array<number>;
}
/**
 *
 * @export
 * @interface PermissionSetRequest
 */
export interface PermissionSetRequest {
    /**
     *
     * @type {string}
     * @memberof PermissionSetRequest
     */
    name: string;
    /**
     *
     * @type {Array<number>}
     * @memberof PermissionSetRequest
     */
    permissions?: Array<number>;
}
/**
 *
 * @export
 * @interface PlanTask
 */
export interface PlanTask {
    /**
     *
     * @type {number}
     * @memberof PlanTask
     */
    id: number;
    /**
     *
     * @type {Geometry | GeometryCollection}
     * @memberof PlanTask
     */
    geometry: Geometry | GeometryCollection;
    /**
     *
     * @type {number}
     * @memberof PlanTask
     */
    time_start: number;
    /**
     *
     * @type {number}
     * @memberof PlanTask
     */
    time_end: number;
    /**
     *
     * @type {number}
     * @memberof PlanTask
     */
    commit_status?: PlanTaskCommitStatusEnum;
    /**
     *
     * @type {string}
     * @memberof PlanTask
     */
    create_date: string;
    /**
     *
     * @type {number}
     * @memberof PlanTask
     */
    plan: number;
    /**
     *
     * @type {number}
     * @memberof PlanTask
     */
    sensor: number;
}

/**
 * @export
 * @enum {string}
 */
export enum PlanTaskCommitStatusEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
}

/**
 *
 * @export
 * @interface PlanTaskRequest
 */
export interface PlanTaskRequest {
    /**
     *
     * @type {Geometry | GeometryCollection}
     * @memberof PlanTaskRequest
     */
    geometry: Geometry | GeometryCollection;
    /**
     *
     * @type {number}
     * @memberof PlanTaskRequest
     */
    time_start: number;
    /**
     *
     * @type {number}
     * @memberof PlanTaskRequest
     */
    time_end: number;
    /**
     *
     * @type {number}
     * @memberof PlanTaskRequest
     */
    commit_status?: PlanTaskRequestCommitStatusEnum;
    /**
     *
     * @type {number}
     * @memberof PlanTaskRequest
     */
    plan: number;
    /**
     *
     * @type {number}
     * @memberof PlanTaskRequest
     */
    sensor: number;
}

/**
 * @export
 * @enum {string}
 */
export enum PlanTaskRequestCommitStatusEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
}

/**
 *
 * @export
 * @interface PrivateAsset
 */
export interface PrivateAsset {
    /**
     *
     * @type {number}
     * @memberof PrivateAsset
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof PrivateAsset
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof PrivateAsset
     */
    aoi: number;
    /**
     *
     * @type {number}
     * @memberof PrivateAsset
     */
    program: number;
    /**
     *
     * @type {Array<string>}
     * @memberof PrivateAsset
     */
    alert_emails?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof PrivateAsset
     */
    report_emails?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof PrivateAsset
     */
    monitoring_start_date?: string | null;
    /**
     *
     * @type {string}
     * @memberof PrivateAsset
     */
    monitoring_end_date?: string | null;
    /**
     *
     * @type {string}
     * @memberof PrivateAsset
     */
    monitoring_frequency?: PrivateAssetMonitoringFrequencyEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum PrivateAssetMonitoringFrequencyEnum {
    Daily = 'daily',
    Weekly = 'weekly',
    Monthly = 'monthly',
    Quarterly = 'quarterly',
    Yearly = 'yearly',
}

/**
 *
 * @export
 * @interface PrivateAssetRequest
 */
export interface PrivateAssetRequest {
    /**
     *
     * @type {string}
     * @memberof PrivateAssetRequest
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof PrivateAssetRequest
     */
    aoi: number;
    /**
     *
     * @type {number}
     * @memberof PrivateAssetRequest
     */
    program: number;
    /**
     *
     * @type {Array<string>}
     * @memberof PrivateAssetRequest
     */
    alert_emails?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof PrivateAssetRequest
     */
    report_emails?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof PrivateAssetRequest
     */
    monitoring_start_date?: string | null;
    /**
     *
     * @type {string}
     * @memberof PrivateAssetRequest
     */
    monitoring_end_date?: string | null;
    /**
     *
     * @type {string}
     * @memberof PrivateAssetRequest
     */
    monitoring_frequency?: PrivateAssetRequestMonitoringFrequencyEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum PrivateAssetRequestMonitoringFrequencyEnum {
    Daily = 'daily',
    Weekly = 'weekly',
    Monthly = 'monthly',
    Quarterly = 'quarterly',
    Yearly = 'yearly',
}

/**
 *
 * @export
 * @interface PrivatePlan
 */
export interface PrivatePlan {
    /**
     *
     * @type {number}
     * @memberof PrivatePlan
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof PrivatePlan
     */
    job_id: string;
    /**
     *
     * @type {Geometry | GeometryCollection}
     * @memberof PrivatePlan
     */
    geometry: Geometry | GeometryCollection;
    /**
     *
     * @type {number}
     * @memberof PrivatePlan
     */
    priority?: PrivatePlanPriorityEnum;
    /**
     *
     * @type {number}
     * @memberof PrivatePlan
     */
    progress_status?: PrivatePlanProgressStatusEnum;
    /**
     *
     * @type {number}
     * @memberof PrivatePlan
     */
    commit_status?: PrivatePlanCommitStatusEnum;
    /**
     *
     * @type {number}
     * @memberof PrivatePlan
     */
    generation_mode: PrivatePlanGenerationModeEnum;
    /**
     *
     * @type {number}
     * @memberof PrivatePlan
     */
    num_tasks_planned?: number;
    /**
     *
     * @type {number}
     * @memberof PrivatePlan
     */
    num_tasks_committed?: number;
    /**
     *
     * @type {string}
     * @memberof PrivatePlan
     */
    start_date: string;
    /**
     *
     * @type {string}
     * @memberof PrivatePlan
     */
    expiration_date: string;
    /**
     *
     * @type {string}
     * @memberof PrivatePlan
     */
    create_date: string;
    /**
     *
     * @type {string}
     * @memberof PrivatePlan
     */
    update_date: string;
    /**
     *
     * @type {Array<number>}
     * @memberof PrivatePlan
     */
    sensors: Array<number>;
}

/**
 * @export
 * @enum {string}
 */
export enum PrivatePlanPriorityEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
}
/**
 * @export
 * @enum {string}
 */
export enum PrivatePlanProgressStatusEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
}
/**
 * @export
 * @enum {string}
 */
export enum PrivatePlanCommitStatusEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
}
/**
 * @export
 * @enum {string}
 */
export enum PrivatePlanGenerationModeEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
}

/**
 *
 * @export
 * @interface PrivatePlanRequest
 */
export interface PrivatePlanRequest {
    /**
     *
     * @type {Geometry | GeometryCollection}
     * @memberof PrivatePlanRequest
     */
    geometry: Geometry | GeometryCollection;
    /**
     *
     * @type {number}
     * @memberof PrivatePlanRequest
     */
    priority?: PrivatePlanRequestPriorityEnum;
    /**
     *
     * @type {number}
     * @memberof PrivatePlanRequest
     */
    progress_status?: PrivatePlanRequestProgressStatusEnum;
    /**
     *
     * @type {number}
     * @memberof PrivatePlanRequest
     */
    commit_status?: PrivatePlanRequestCommitStatusEnum;
    /**
     *
     * @type {number}
     * @memberof PrivatePlanRequest
     */
    generation_mode: PrivatePlanRequestGenerationModeEnum;
    /**
     *
     * @type {number}
     * @memberof PrivatePlanRequest
     */
    num_tasks_planned?: number;
    /**
     *
     * @type {number}
     * @memberof PrivatePlanRequest
     */
    num_tasks_committed?: number;
    /**
     *
     * @type {string}
     * @memberof PrivatePlanRequest
     */
    start_date: string;
    /**
     *
     * @type {string}
     * @memberof PrivatePlanRequest
     */
    expiration_date: string;
    /**
     *
     * @type {Array<number>}
     * @memberof PrivatePlanRequest
     */
    sensors: Array<number>;
}

/**
 * @export
 * @enum {string}
 */
export enum PrivatePlanRequestPriorityEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
}
/**
 * @export
 * @enum {string}
 */
export enum PrivatePlanRequestProgressStatusEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
}
/**
 * @export
 * @enum {string}
 */
export enum PrivatePlanRequestCommitStatusEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
}
/**
 * @export
 * @enum {string}
 */
export enum PrivatePlanRequestGenerationModeEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
}

/**
 *
 * @export
 * @interface Program
 */
export interface Program {
    /**
     *
     * @type {number}
     * @memberof Program
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof Program
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof Program
     */
    company_name?: string | null;
    /**
     *
     * @type {string}
     * @memberof Program
     */
    start_date?: string | null;
    /**
     *
     * @type {string}
     * @memberof Program
     */
    end_date?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof Program
     */
    active?: boolean;
    /**
     *
     * @type {Array<number>}
     * @memberof Program
     */
    data_level_access?: Array<number> | null;
    /**
     *
     * @type {Geometry | GeometryCollection}
     * @memberof Program
     */
    region_access?: Geometry | GeometryCollection | null;
    /**
     *
     * @type {number}
     * @memberof Program
     */
    max_users?: number | null;
    /**
     *
     * @type {number}
     * @memberof Program
     */
    max_taskings?: number | null;
    /**
     *
     * @type {Array<number>}
     * @memberof Program
     */
    sensor_data_access: Array<number>;
}
/**
 *
 * @export
 * @interface ProgramAOI
 */
export interface ProgramAOI {
    /**
     *
     * @type {number}
     * @memberof ProgramAOI
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof ProgramAOI
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof ProgramAOI
     */
    program: number;
    /**
     *
     * @type {Geometry | GeometryCollection}
     * @memberof ProgramAOI
     */
    geometry: Geometry | GeometryCollection;
}
/**
 *
 * @export
 * @interface ProgramAOIRequest
 */
export interface ProgramAOIRequest {
    /**
     *
     * @type {string}
     * @memberof ProgramAOIRequest
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof ProgramAOIRequest
     */
    program: number;
    /**
     *
     * @type {Geometry | GeometryCollection}
     * @memberof ProgramAOIRequest
     */
    geometry: Geometry | GeometryCollection;
}
/**
 *
 * @export
 * @interface ProgramRequest
 */
export interface ProgramRequest {
    /**
     *
     * @type {string}
     * @memberof ProgramRequest
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof ProgramRequest
     */
    company_name?: string | null;
    /**
     *
     * @type {string}
     * @memberof ProgramRequest
     */
    start_date?: string | null;
    /**
     *
     * @type {string}
     * @memberof ProgramRequest
     */
    end_date?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProgramRequest
     */
    active?: boolean;
    /**
     *
     * @type {Array<number>}
     * @memberof ProgramRequest
     */
    data_level_access?: Array<number> | null;
    /**
     *
     * @type {Geometry | GeometryCollection}
     * @memberof ProgramRequest
     */
    region_access?: Geometry | GeometryCollection | null;
    /**
     *
     * @type {number}
     * @memberof ProgramRequest
     */
    max_users?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProgramRequest
     */
    max_taskings?: number | null;
    /**
     *
     * @type {Array<number>}
     * @memberof ProgramRequest
     */
    sensor_data_access: Array<number>;
}
/**
 * Represents a user\'s association with a program. Includes relevant details about the user and the list of roles they have on a given program.
 * @export
 * @interface ProgramUser
 */
export interface ProgramUser {
    /**
     * The id of the user
     * @type {number}
     * @memberof ProgramUser
     */
    id: number;
    /**
     * The email address of the user
     * @type {string}
     * @memberof ProgramUser
     */
    email: string;
    /**
     * A list of role names associated with the user
     * @type {Array<string>}
     * @memberof ProgramUser
     */
    roles: Array<string>;
}
/**
 * Represents a user\'s association with a program. Includes relevant details about the user and the list of roles they have on a given program.
 * @export
 * @interface ProgramUserRequest
 */
export interface ProgramUserRequest {
    /**
     * A list of role names associated with the user
     * @type {Array<string>}
     * @memberof ProgramUserRequest
     */
    roles: Array<string>;
}
/**
 *
 * @export
 * @interface PublicAsset
 */
export interface PublicAsset {
    /**
     *
     * @type {number}
     * @memberof PublicAsset
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof PublicAsset
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof PublicAsset
     */
    program: number;
    /**
     *
     * @type {Array<string>}
     * @memberof PublicAsset
     */
    alert_emails?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof PublicAsset
     */
    report_emails?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof PublicAsset
     */
    monitoring_start_date: string | null;
    /**
     *
     * @type {string}
     * @memberof PublicAsset
     */
    monitoring_end_date: string | null;
    /**
     *
     * @type {string}
     * @memberof PublicAsset
     */
    monitoring_frequency: PublicAssetMonitoringFrequencyEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum PublicAssetMonitoringFrequencyEnum {
    Daily = 'daily',
    Weekly = 'weekly',
    Monthly = 'monthly',
    Quarterly = 'quarterly',
    Yearly = 'yearly',
}

/**
 *
 * @export
 * @interface PublicAssetDetail
 */
export interface PublicAssetDetail {
    /**
     *
     * @type {number}
     * @memberof PublicAssetDetail
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof PublicAssetDetail
     */
    name: string;
    /**
     *
     * @type {Geometry | GeometryCollection}
     * @memberof PublicAssetDetail
     */
    aoi: Geometry | GeometryCollection;
    /**
     *
     * @type {number}
     * @memberof PublicAssetDetail
     */
    program: number;
    /**
     *
     * @type {Array<string>}
     * @memberof PublicAssetDetail
     */
    alert_emails?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof PublicAssetDetail
     */
    report_emails?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof PublicAssetDetail
     */
    monitoring_start_date?: string | null;
    /**
     *
     * @type {string}
     * @memberof PublicAssetDetail
     */
    monitoring_end_date?: string | null;
    /**
     *
     * @type {string}
     * @memberof PublicAssetDetail
     */
    monitoring_frequency?: PublicAssetDetailMonitoringFrequencyEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum PublicAssetDetailMonitoringFrequencyEnum {
    Daily = 'daily',
    Weekly = 'weekly',
    Monthly = 'monthly',
    Quarterly = 'quarterly',
    Yearly = 'yearly',
}

/**
 *
 * @export
 * @interface PublicAssetRequest
 */
export interface PublicAssetRequest {
    /**
     *
     * @type {Array<string>}
     * @memberof PublicAssetRequest
     */
    alert_emails?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof PublicAssetRequest
     */
    report_emails?: Array<string>;
}
/**
 *
 * @export
 * @interface PublicPlan
 */
export interface PublicPlan {
    /**
     *
     * @type {number}
     * @memberof PublicPlan
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof PublicPlan
     */
    job_id: string;
    /**
     *
     * @type {Geometry | GeometryCollection}
     * @memberof PublicPlan
     */
    geometry: Geometry | GeometryCollection;
    /**
     *
     * @type {number}
     * @memberof PublicPlan
     */
    priority?: PublicPlanPriorityEnum;
    /**
     *
     * @type {number}
     * @memberof PublicPlan
     */
    progress_status: PublicPlanProgressStatusEnum;
    /**
     *
     * @type {number}
     * @memberof PublicPlan
     */
    commit_status: PublicPlanCommitStatusEnum;
    /**
     *
     * @type {number}
     * @memberof PublicPlan
     */
    generation_mode: PublicPlanGenerationModeEnum;
    /**
     *
     * @type {number}
     * @memberof PublicPlan
     */
    num_tasks_planned: number;
    /**
     *
     * @type {number}
     * @memberof PublicPlan
     */
    num_tasks_committed: number;
    /**
     *
     * @type {string}
     * @memberof PublicPlan
     */
    start_date: string;
    /**
     *
     * @type {string}
     * @memberof PublicPlan
     */
    expiration_date: string;
    /**
     *
     * @type {string}
     * @memberof PublicPlan
     */
    create_date: string;
    /**
     *
     * @type {string}
     * @memberof PublicPlan
     */
    update_date: string;
    /**
     *
     * @type {Array<number>}
     * @memberof PublicPlan
     */
    sensors: Array<number>;
}

/**
 * @export
 * @enum {string}
 */
export enum PublicPlanPriorityEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
}
/**
 * @export
 * @enum {string}
 */
export enum PublicPlanProgressStatusEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
}
/**
 * @export
 * @enum {string}
 */
export enum PublicPlanCommitStatusEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
}
/**
 * @export
 * @enum {string}
 */
export enum PublicPlanGenerationModeEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
}

/**
 *
 * @export
 * @interface PublicPlanRequest
 */
export interface PublicPlanRequest {
    /**
     *
     * @type {Geometry | GeometryCollection}
     * @memberof PublicPlanRequest
     */
    geometry: Geometry | GeometryCollection;
    /**
     *
     * @type {number}
     * @memberof PublicPlanRequest
     */
    priority?: PublicPlanRequestPriorityEnum;
    /**
     *
     * @type {number}
     * @memberof PublicPlanRequest
     */
    generation_mode: PublicPlanRequestGenerationModeEnum;
    /**
     *
     * @type {string}
     * @memberof PublicPlanRequest
     */
    start_date: string;
    /**
     *
     * @type {string}
     * @memberof PublicPlanRequest
     */
    expiration_date: string;
    /**
     *
     * @type {Array<number>}
     * @memberof PublicPlanRequest
     */
    sensors: Array<number>;
}

/**
 * @export
 * @enum {string}
 */
export enum PublicPlanRequestPriorityEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
}
/**
 * @export
 * @enum {string}
 */
export enum PublicPlanRequestGenerationModeEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
}

/**
 *
 * @export
 * @interface RasterArtifact
 */
export interface RasterArtifact {
    /**
     *
     * @type {number}
     * @memberof RasterArtifact
     */
    id: number;
    /**
     *
     * @type {Array<string>}
     * @memberof RasterArtifact
     */
    files: Array<string>;
    /**
     *
     * @type {number}
     * @memberof RasterArtifact
     */
    processing_level: number;
    /**
     *
     * @type {string}
     * @memberof RasterArtifact
     */
    artifact_type: string;
    /**
     *
     * @type {number}
     * @memberof RasterArtifact
     */
    capture?: number | null;
}
/**
 *
 * @export
 * @interface RasterArtifactRequest
 */
export interface RasterArtifactRequest {
    /**
     *
     * @type {Array<string>}
     * @memberof RasterArtifactRequest
     */
    files: Array<string>;
    /**
     *
     * @type {number}
     * @memberof RasterArtifactRequest
     */
    processing_level: number;
    /**
     *
     * @type {string}
     * @memberof RasterArtifactRequest
     */
    artifact_type: string;
    /**
     *
     * @type {number}
     * @memberof RasterArtifactRequest
     */
    capture?: number | null;
}
/**
 *
 * @export
 * @interface Report
 */
export interface Report {
    /**
     *
     * @type {string}
     * @memberof Report
     */
    external_id?: string;
    /**
     *
     * @type {number}
     * @memberof Report
     */
    report_type?: ReportReportTypeEnum;
    /**
     *
     * @type {string}
     * @memberof Report
     */
    s3_uri?: string | null;
    /**
     *
     * @type {number}
     * @memberof Report
     */
    requested_by?: number | null;
    /**
     *
     * @type {number}
     * @memberof Report
     */
    program: number;
    /**
     *
     * @type {Array<number>}
     * @memberof Report
     */
    assets: Array<number>;
    /**
     *
     * @type {string}
     * @memberof Report
     */
    start_date: string;
    /**
     *
     * @type {string}
     * @memberof Report
     */
    end_date: string;
    /**
     *
     * @type {string}
     * @memberof Report
     */
    created_at: string;
    /**
     *
     * @type {string}
     * @memberof Report
     */
    updated_at: string;
}

/**
 * @export
 * @enum {string}
 */
export enum ReportReportTypeEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
}

/**
 *
 * @export
 * @interface ReportRequest
 */
export interface ReportRequest {
    /**
     *
     * @type {string}
     * @memberof ReportRequest
     */
    external_id?: string;
    /**
     *
     * @type {number}
     * @memberof ReportRequest
     */
    report_type?: ReportRequestReportTypeEnum;
    /**
     *
     * @type {string}
     * @memberof ReportRequest
     */
    s3_uri?: string | null;
    /**
     *
     * @type {number}
     * @memberof ReportRequest
     */
    requested_by?: number | null;
    /**
     *
     * @type {number}
     * @memberof ReportRequest
     */
    program: number;
    /**
     *
     * @type {Array<number>}
     * @memberof ReportRequest
     */
    assets: Array<number>;
    /**
     *
     * @type {string}
     * @memberof ReportRequest
     */
    start_date: string;
    /**
     *
     * @type {string}
     * @memberof ReportRequest
     */
    end_date: string;
}

/**
 * @export
 * @enum {string}
 */
export enum ReportRequestReportTypeEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
}

/**
 *
 * @export
 * @interface Role
 */
export interface Role {
    /**
     * A unique identifier for the role. This value is unique across all programs.
     * @type {number}
     * @memberof Role
     */
    id: number;
    /**
     * The name of the role. This value is unique within a program.
     * @type {string}
     * @memberof Role
     */
    name: string;
}
/**
 *
 * @export
 * @interface SearchArea
 */
export interface SearchArea {
    /**
     *
     * @type {number}
     * @memberof SearchArea
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof SearchArea
     */
    name: string;
    /**
     *
     * @type {Geometry | GeometryCollection}
     * @memberof SearchArea
     */
    area: Geometry | GeometryCollection;
}
/**
 *
 * @export
 * @interface SearchAreaRequest
 */
export interface SearchAreaRequest {
    /**
     *
     * @type {string}
     * @memberof SearchAreaRequest
     */
    name: string;
    /**
     *
     * @type {Geometry | GeometryCollection}
     * @memberof SearchAreaRequest
     */
    area: Geometry | GeometryCollection;
}
/**
 *
 * @export
 * @interface Sensor
 */
export interface Sensor {
    /**
     *
     * @type {number}
     * @memberof Sensor
     */
    osk_id: number;
    /**
     *
     * @type {string}
     * @memberof Sensor
     */
    osk_sensor_name: string;
    /**
     *
     * @type {string}
     * @memberof Sensor
     */
    osk_serial_number: string;
    /**
     *
     * @type {number}
     * @memberof Sensor
     */
    default_frame_rate?: number | null;
    /**
     *
     * @type {number}
     * @memberof Sensor
     */
    ifov?: number | null;
    /**
     *
     * @type {string}
     * @memberof Sensor
     */
    catalog_number?: string | null;
}
/**
 *
 * @export
 * @interface SensorRequest
 */
export interface SensorRequest {
    /**
     *
     * @type {number}
     * @memberof SensorRequest
     */
    osk_id: number;
    /**
     *
     * @type {string}
     * @memberof SensorRequest
     */
    osk_sensor_name: string;
    /**
     *
     * @type {string}
     * @memberof SensorRequest
     */
    osk_serial_number: string;
    /**
     *
     * @type {number}
     * @memberof SensorRequest
     */
    default_frame_rate?: number | null;
    /**
     *
     * @type {number}
     * @memberof SensorRequest
     */
    ifov?: number | null;
    /**
     *
     * @type {string}
     * @memberof SensorRequest
     */
    catalog_number?: string | null;
}
/**
 *
 * @export
 * @interface StateVector
 */
export interface StateVector {
    /**
     *
     * @type {string}
     * @memberof StateVector
     */
    epoch_date: string;
    /**
     *
     * @type {string}
     * @memberof StateVector
     */
    create_date: string;
    /**
     *
     * @type {number}
     * @memberof StateVector
     */
    sensor: number;
    /**
     *
     * @type {number}
     * @memberof StateVector
     */
    id: number;
    /**
     *
     * @type {number}
     * @memberof StateVector
     */
    epoch: number;
    /**
     *
     * @type {number}
     * @memberof StateVector
     */
    position_x: number;
    /**
     *
     * @type {number}
     * @memberof StateVector
     */
    position_y: number;
    /**
     *
     * @type {number}
     * @memberof StateVector
     */
    position_z: number;
    /**
     *
     * @type {number}
     * @memberof StateVector
     */
    velocity_x: number;
    /**
     *
     * @type {number}
     * @memberof StateVector
     */
    velocity_y: number;
    /**
     *
     * @type {number}
     * @memberof StateVector
     */
    velocity_z: number;
}
/**
 *
 * @export
 * @interface StateVectorRequest
 */
export interface StateVectorRequest {
    /**
     *
     * @type {number}
     * @memberof StateVectorRequest
     */
    epoch: number;
    /**
     *
     * @type {number}
     * @memberof StateVectorRequest
     */
    position_x: number;
    /**
     *
     * @type {number}
     * @memberof StateVectorRequest
     */
    position_y: number;
    /**
     *
     * @type {number}
     * @memberof StateVectorRequest
     */
    position_z: number;
    /**
     *
     * @type {number}
     * @memberof StateVectorRequest
     */
    velocity_x: number;
    /**
     *
     * @type {number}
     * @memberof StateVectorRequest
     */
    velocity_y: number;
    /**
     *
     * @type {number}
     * @memberof StateVectorRequest
     */
    velocity_z: number;
}
/**
 *
 * @export
 * @interface TLE
 */
export interface TLE {
    /**
     *
     * @type {number}
     * @memberof TLE
     */
    id: number;
    /**
     *
     * @type {number}
     * @memberof TLE
     */
    sensor: number;
    /**
     *
     * @type {string}
     * @memberof TLE
     */
    satellite_name: string;
    /**
     *
     * @type {string}
     * @memberof TLE
     */
    satellite_catalog_number: string;
    /**
     *
     * @type {string}
     * @memberof TLE
     */
    classification: TLEClassificationEnum;
    /**
     *
     * @type {string}
     * @memberof TLE
     */
    id_launch_year: string;
    /**
     *
     * @type {string}
     * @memberof TLE
     */
    id_launch_number: string;
    /**
     *
     * @type {string}
     * @memberof TLE
     */
    id_launch_piece: string;
    /**
     *
     * @type {string}
     * @memberof TLE
     */
    epoch: string;
    /**
     *
     * @type {string}
     * @memberof TLE
     */
    epoch_year: string;
    /**
     *
     * @type {number}
     * @memberof TLE
     */
    epoch_day: number;
    /**
     *
     * @type {number}
     * @memberof TLE
     */
    mean_motion_first_derivative: number;
    /**
     *
     * @type {number}
     * @memberof TLE
     */
    mean_motion_second_derivative: number;
    /**
     *
     * @type {number}
     * @memberof TLE
     */
    b_star: number;
    /**
     *
     * @type {number}
     * @memberof TLE
     */
    element_set_number: number;
    /**
     *
     * @type {number}
     * @memberof TLE
     */
    inclination_degrees: number;
    /**
     *
     * @type {number}
     * @memberof TLE
     */
    right_ascension_degrees: number;
    /**
     *
     * @type {number}
     * @memberof TLE
     */
    eccentricity: number;
    /**
     *
     * @type {number}
     * @memberof TLE
     */
    argument_of_perigee_degrees: number;
    /**
     *
     * @type {number}
     * @memberof TLE
     */
    mean_anomaly_degrees: number;
    /**
     *
     * @type {number}
     * @memberof TLE
     */
    mean_motion_in_revolutions_per_day: number;
    /**
     *
     * @type {number}
     * @memberof TLE
     */
    revolution_number_at_epoch: number;
    /**
     *
     * @type {number}
     * @memberof TLE
     */
    ephemeris_type: number;
    /**
     *
     * @type {string}
     * @memberof TLE
     */
    create_date: string;
    /**
     *
     * @type {string}
     * @memberof TLE
     */
    raw_tle: string;
}

/**
 * @export
 * @enum {string}
 */
export enum TLEClassificationEnum {
    U = 'U',
    C = 'C',
    S = 'S',
}

/**
 *
 * @export
 * @interface TLERequest
 */
export interface TLERequest {
    /**
     *
     * @type {string}
     * @memberof TLERequest
     */
    raw_tle: string;
}
/**
 *
 * @export
 * @interface User
 */
export interface User {
    /**
     *
     * @type {number}
     * @memberof User
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof User
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof User
     */
    first_name?: string;
    /**
     *
     * @type {string}
     * @memberof User
     */
    last_name?: string;
    /**
     *
     * @type {string}
     * @memberof User
     */
    organization?: string | null;
    /**
     *
     * @type {string}
     * @memberof User
     */
    job_title?: string | null;
}
/**
 *
 * @export
 * @interface UserRequest
 */
export interface UserRequest {
    /**
     *
     * @type {string}
     * @memberof UserRequest
     */
    first_name?: string;
    /**
     *
     * @type {string}
     * @memberof UserRequest
     */
    last_name?: string;
    /**
     *
     * @type {string}
     * @memberof UserRequest
     */
    organization?: string | null;
    /**
     *
     * @type {string}
     * @memberof UserRequest
     */
    job_title?: string | null;
}

/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Adding a user to a program is done by associating them with one or more program roles. This endpoint will assign the given program roles to the specified user.
         * @summary Add a user to a program
         * @param {number} program
         * @param {AddUserToProgramRequest} addUserToProgramRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserToProgram: async (
            program: number,
            addUserToProgramRequest: AddUserToProgramRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'program' is not null or undefined
            assertParamExists('addUserToProgram', 'program', program);
            // verify required parameter 'addUserToProgramRequest' is not null or undefined
            assertParamExists('addUserToProgram', 'addUserToProgramRequest', addUserToProgramRequest);
            const localVarPath = `/public/v1/admin/programs/{program}/users/`.replace(
                `{${'program'}}`,
                encodeURIComponent(String(program)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                addUserToProgramRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/v1/admin/users/profile/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to Programs, which are sets of data or tasks used by the same group of users.
         * @param {number} id A unique integer value identifying this program.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgram: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProgram', 'id', id);
            const localVarPath = `/public/v1/admin/programs/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations for a Role, which dertermines what access and capabilities a User has
         * @summary Inspect details about a program role
         * @param {number} id A unique integer value identifying this role.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramRole: async (id: number, program: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProgramRole', 'id', id);
            // verify required parameter 'program' is not null or undefined
            assertParamExists('getProgramRole', 'program', program);
            const localVarPath = `/public/v1/admin/programs/{program}/roles/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'program'}}`, encodeURIComponent(String(program)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns relevant information about a user as well as a list of roles they currently have on the program.
         * @summary Inspect details about a program user
         * @param {number} id A unique integer value identifying this user.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramUser: async (id: number, program: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProgramUser', 'id', id);
            // verify required parameter 'program' is not null or undefined
            assertParamExists('getProgramUser', 'program', program);
            const localVarPath = `/public/v1/admin/programs/{program}/users/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'program'}}`, encodeURIComponent(String(program)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Adding a user to a program is done by associating them with one or more program roles. This endpoint will assign the given program roles to the specified user.
         * @summary Add a user to a program
         * @param {number} program
         * @param {AddUserToProgramRequest} addUserToProgramRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalAddUserToProgram: async (
            program: number,
            addUserToProgramRequest: AddUserToProgramRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'program' is not null or undefined
            assertParamExists('internalAddUserToProgram', 'program', program);
            // verify required parameter 'addUserToProgramRequest' is not null or undefined
            assertParamExists('internalAddUserToProgram', 'addUserToProgramRequest', addUserToProgramRequest);
            const localVarPath = `/private/v1/admin/programs/{program}/users/`.replace(
                `{${'program'}}`,
                encodeURIComponent(String(program)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                addUserToProgramRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations for Applications used for Machine to Machine connections
         * @param {ApplicationCreateRequest} applicationCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateApplication: async (
            applicationCreateRequest: ApplicationCreateRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'applicationCreateRequest' is not null or undefined
            assertParamExists('internalCreateApplication', 'applicationCreateRequest', applicationCreateRequest);
            const localVarPath = `/private/v1/admin/application/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                applicationCreateRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations for desribing a set of permissions that may be assigned to a Role, and by extension, a User
         * @param {PermissionSetRequest} permissionSetRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreatePermissionSet: async (
            permissionSetRequest: PermissionSetRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'permissionSetRequest' is not null or undefined
            assertParamExists('internalCreatePermissionSet', 'permissionSetRequest', permissionSetRequest);
            const localVarPath = `/private/v1/admin/permission_set/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                permissionSetRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to Programs, which are sets of data or tasks used by the same group of users.
         * @param {ProgramRequest} programRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateProgram: async (
            programRequest: ProgramRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'programRequest' is not null or undefined
            assertParamExists('internalCreateProgram', 'programRequest', programRequest);
            const localVarPath = `/private/v1/admin/programs/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(programRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations for a Role, which dertermines what access and capabilities a User has
         * @param {InternalRoleRequest} internalRoleRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateRole: async (
            internalRoleRequest: InternalRoleRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'internalRoleRequest' is not null or undefined
            assertParamExists('internalCreateRole', 'internalRoleRequest', internalRoleRequest);
            const localVarPath = `/private/v1/admin/roles/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                internalRoleRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations for Applications used for Machine to Machine connections
         * @param {number} id A unique integer value identifying this application.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyApplication: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalDestroyApplication', 'id', id);
            const localVarPath = `/private/v1/admin/application/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations for desribing a set of permissions that may be assigned to a Role, and by extension, a User
         * @param {number} id A unique integer value identifying this permission set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyPermissionSet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalDestroyPermissionSet', 'id', id);
            const localVarPath = `/private/v1/admin/permission_set/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to Programs, which are sets of data or tasks used by the same group of users.
         * @param {number} id A unique integer value identifying this program.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyProgram: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalDestroyProgram', 'id', id);
            const localVarPath = `/private/v1/admin/programs/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations for a Role, which dertermines what access and capabilities a User has
         * @param {number} id A unique integer value identifying this role.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyRole: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalDestroyRole', 'id', id);
            const localVarPath = `/private/v1/admin/roles/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations for Applications used for Machine to Machine connections
         * @param {number} id A unique integer value identifying this application.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetApplication: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalGetApplication', 'id', id);
            const localVarPath = `/private/v1/admin/application/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations for desribing a set of permissions that may be assigned to a Role, and by extension, a User
         * @param {number} id A unique integer value identifying this permission set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetPermissionSet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalGetPermissionSet', 'id', id);
            const localVarPath = `/private/v1/admin/permission_set/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns relevant information about a user as well as a list of roles they currently have on the program.
         * @summary Inspect details about a program user
         * @param {number} id A unique integer value identifying this user.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetProgramUser: async (
            id: number,
            program: number,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalGetProgramUser', 'id', id);
            // verify required parameter 'program' is not null or undefined
            assertParamExists('internalGetProgramUser', 'program', program);
            const localVarPath = `/private/v1/admin/programs/{program}/users/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'program'}}`, encodeURIComponent(String(program)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations for a Role, which dertermines what access and capabilities a User has
         * @param {number} id A unique integer value identifying this role.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetRole: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalGetRole', 'id', id);
            const localVarPath = `/private/v1/admin/roles/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations for Applications used for Machine to Machine connections
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListApplication: async (
            limit?: number,
            offset?: number,
            ordering?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/private/v1/admin/application/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations for desribing a set of permissions that may be assigned to a Role, and by extension, a User
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListPermissionSet: async (
            limit?: number,
            offset?: number,
            ordering?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/private/v1/admin/permission_set/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of opportunities for OSK\'s sensors, filtered for sensor, time, and area of interest  (AOI). Each opportunity is a 10-second piece of the orbit.
         * @summary List the users associated with this program
         * @param {number} program
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListProgramUsers: async (
            program: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'program' is not null or undefined
            assertParamExists('internalListProgramUsers', 'program', program);
            const localVarPath = `/private/v1/admin/programs/{program}/users/`.replace(
                `{${'program'}}`,
                encodeURIComponent(String(program)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to Programs, which are sets of data or tasks used by the same group of users.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListPrograms: async (
            limit?: number,
            offset?: number,
            ordering?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/private/v1/admin/programs/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations for a Role, which dertermines what access and capabilities a User has
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListRoles: async (
            limit?: number,
            offset?: number,
            ordering?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/private/v1/admin/roles/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations for Applications used for Machine to Machine connections
         * @param {number} id A unique integer value identifying this application.
         * @param {PatchedApplicationRequest} [patchedApplicationRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateApplication: async (
            id: number,
            patchedApplicationRequest?: PatchedApplicationRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalPartialUpdateApplication', 'id', id);
            const localVarPath = `/private/v1/admin/application/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                patchedApplicationRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations for desribing a set of permissions that may be assigned to a Role, and by extension, a User
         * @param {number} id A unique integer value identifying this permission set.
         * @param {PatchedPermissionSetRequest} [patchedPermissionSetRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdatePermissionSet: async (
            id: number,
            patchedPermissionSetRequest?: PatchedPermissionSetRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalPartialUpdatePermissionSet', 'id', id);
            const localVarPath = `/private/v1/admin/permission_set/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                patchedPermissionSetRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to Programs, which are sets of data or tasks used by the same group of users.
         * @param {number} id A unique integer value identifying this program.
         * @param {PatchedProgramRequest} [patchedProgramRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateProgram: async (
            id: number,
            patchedProgramRequest?: PatchedProgramRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalPartialUpdateProgram', 'id', id);
            const localVarPath = `/private/v1/admin/programs/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                patchedProgramRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations for a Role, which dertermines what access and capabilities a User has
         * @param {number} id A unique integer value identifying this role.
         * @param {PatchedInternalRoleRequest} [patchedInternalRoleRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateRole: async (
            id: number,
            patchedInternalRoleRequest?: PatchedInternalRoleRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalPartialUpdateRole', 'id', id);
            const localVarPath = `/private/v1/admin/roles/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                patchedInternalRoleRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the user from the program by disassociating them from all program roles. This is equivalent to calling update_program_user with an empty set of roles.
         * @summary Remove a user from the program
         * @param {number} id A unique integer value identifying this user.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalRemoveUserFromProgram: async (
            id: number,
            program: number,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalRemoveUserFromProgram', 'id', id);
            // verify required parameter 'program' is not null or undefined
            assertParamExists('internalRemoveUserFromProgram', 'program', program);
            const localVarPath = `/private/v1/admin/programs/{program}/users/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'program'}}`, encodeURIComponent(String(program)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations for Applications used for Machine to Machine connections
         * @param {number} id A unique integer value identifying this application.
         * @param {ApplicationRequest} applicationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateApplication: async (
            id: number,
            applicationRequest: ApplicationRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalUpdateApplication', 'id', id);
            // verify required parameter 'applicationRequest' is not null or undefined
            assertParamExists('internalUpdateApplication', 'applicationRequest', applicationRequest);
            const localVarPath = `/private/v1/admin/application/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                applicationRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations for desribing a set of permissions that may be assigned to a Role, and by extension, a User
         * @param {number} id A unique integer value identifying this permission set.
         * @param {PermissionSetRequest} permissionSetRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdatePermissionSet: async (
            id: number,
            permissionSetRequest: PermissionSetRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalUpdatePermissionSet', 'id', id);
            // verify required parameter 'permissionSetRequest' is not null or undefined
            assertParamExists('internalUpdatePermissionSet', 'permissionSetRequest', permissionSetRequest);
            const localVarPath = `/private/v1/admin/permission_set/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                permissionSetRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to Programs, which are sets of data or tasks used by the same group of users.
         * @param {number} id A unique integer value identifying this program.
         * @param {ProgramRequest} programRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateProgram: async (
            id: number,
            programRequest: ProgramRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalUpdateProgram', 'id', id);
            // verify required parameter 'programRequest' is not null or undefined
            assertParamExists('internalUpdateProgram', 'programRequest', programRequest);
            const localVarPath = `/private/v1/admin/programs/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(programRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Atomically set the program roles for a specific user. This operation will set the user\'s roles to the list of roles passed in, if the user currently has a role which is not included in the update, it will be removed.
         * @summary Update a user\'s program roles
         * @param {number} id A unique integer value identifying this user.
         * @param {number} program
         * @param {ProgramUserRequest} programUserRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateProgramUser: async (
            id: number,
            program: number,
            programUserRequest: ProgramUserRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalUpdateProgramUser', 'id', id);
            // verify required parameter 'program' is not null or undefined
            assertParamExists('internalUpdateProgramUser', 'program', program);
            // verify required parameter 'programUserRequest' is not null or undefined
            assertParamExists('internalUpdateProgramUser', 'programUserRequest', programUserRequest);
            const localVarPath = `/private/v1/admin/programs/{program}/users/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'program'}}`, encodeURIComponent(String(program)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                programUserRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations for a Role, which dertermines what access and capabilities a User has
         * @param {number} id A unique integer value identifying this role.
         * @param {InternalRoleRequest} internalRoleRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateRole: async (
            id: number,
            internalRoleRequest: InternalRoleRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalUpdateRole', 'id', id);
            // verify required parameter 'internalRoleRequest' is not null or undefined
            assertParamExists('internalUpdateRole', 'internalRoleRequest', internalRoleRequest);
            const localVarPath = `/private/v1/admin/roles/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                internalRoleRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations for a Role, which dertermines what access and capabilities a User has
         * @summary List the roles associated with this program
         * @param {number} program
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProgramRoles: async (
            program: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'program' is not null or undefined
            assertParamExists('listProgramRoles', 'program', program);
            const localVarPath = `/public/v1/admin/programs/{program}/roles/`.replace(
                `{${'program'}}`,
                encodeURIComponent(String(program)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of opportunities for OSK\'s sensors, filtered for sensor, time, and area of interest  (AOI). Each opportunity is a 10-second piece of the orbit.
         * @summary List the users associated with this program
         * @param {number} program
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProgramUsers: async (
            program: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'program' is not null or undefined
            assertParamExists('listProgramUsers', 'program', program);
            const localVarPath = `/public/v1/admin/programs/{program}/users/`.replace(
                `{${'program'}}`,
                encodeURIComponent(String(program)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to Programs, which are sets of data or tasks used by the same group of users.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPrograms: async (
            limit?: number,
            offset?: number,
            ordering?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/public/v1/admin/programs/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the user from the program by disassociating them from all program roles. This is equivalent to calling update_program_user with an empty set of roles.
         * @summary Remove a user from the program
         * @param {number} id A unique integer value identifying this user.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUserFromProgram: async (
            id: number,
            program: number,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeUserFromProgram', 'id', id);
            // verify required parameter 'program' is not null or undefined
            assertParamExists('removeUserFromProgram', 'program', program);
            const localVarPath = `/public/v1/admin/programs/{program}/users/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'program'}}`, encodeURIComponent(String(program)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {UserRequest} [userRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfile: async (userRequest?: UserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/v1/admin/users/profile/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(userRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Atomically set the program roles for a specific user. This operation will set the user\'s roles to the list of roles passed in, if the user currently has a role which is not included in the update, it will be removed.
         * @summary Update a user\'s program roles
         * @param {number} id A unique integer value identifying this user.
         * @param {number} program
         * @param {ProgramUserRequest} programUserRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProgramUser: async (
            id: number,
            program: number,
            programUserRequest: ProgramUserRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateProgramUser', 'id', id);
            // verify required parameter 'program' is not null or undefined
            assertParamExists('updateProgramUser', 'program', program);
            // verify required parameter 'programUserRequest' is not null or undefined
            assertParamExists('updateProgramUser', 'programUserRequest', programUserRequest);
            const localVarPath = `/public/v1/admin/programs/{program}/users/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'program'}}`, encodeURIComponent(String(program)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                programUserRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration);
    return {
        /**
         * Adding a user to a program is done by associating them with one or more program roles. This endpoint will assign the given program roles to the specified user.
         * @summary Add a user to a program
         * @param {number} program
         * @param {AddUserToProgramRequest} addUserToProgramRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addUserToProgram(
            program: number,
            addUserToProgramRequest: AddUserToProgramRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProgramUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addUserToProgram(
                program,
                addUserToProgramRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfile(
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to Programs, which are sets of data or tasks used by the same group of users.
         * @param {number} id A unique integer value identifying this program.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProgram(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Program>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProgram(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations for a Role, which dertermines what access and capabilities a User has
         * @summary Inspect details about a program role
         * @param {number} id A unique integer value identifying this role.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProgramRole(
            id: number,
            program: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Role>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProgramRole(id, program, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns relevant information about a user as well as a list of roles they currently have on the program.
         * @summary Inspect details about a program user
         * @param {number} id A unique integer value identifying this user.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProgramUser(
            id: number,
            program: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProgramUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProgramUser(id, program, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Adding a user to a program is done by associating them with one or more program roles. This endpoint will assign the given program roles to the specified user.
         * @summary Add a user to a program
         * @param {number} program
         * @param {AddUserToProgramRequest} addUserToProgramRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalAddUserToProgram(
            program: number,
            addUserToProgramRequest: AddUserToProgramRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProgramUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalAddUserToProgram(
                program,
                addUserToProgramRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations for Applications used for Machine to Machine connections
         * @param {ApplicationCreateRequest} applicationCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCreateApplication(
            applicationCreateRequest: ApplicationCreateRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationCreate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCreateApplication(
                applicationCreateRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations for desribing a set of permissions that may be assigned to a Role, and by extension, a User
         * @param {PermissionSetRequest} permissionSetRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCreatePermissionSet(
            permissionSetRequest: PermissionSetRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PermissionSet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCreatePermissionSet(
                permissionSetRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to Programs, which are sets of data or tasks used by the same group of users.
         * @param {ProgramRequest} programRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCreateProgram(
            programRequest: ProgramRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Program>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCreateProgram(programRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations for a Role, which dertermines what access and capabilities a User has
         * @param {InternalRoleRequest} internalRoleRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCreateRole(
            internalRoleRequest: InternalRoleRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalRole>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCreateRole(internalRoleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations for Applications used for Machine to Machine connections
         * @param {number} id A unique integer value identifying this application.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalDestroyApplication(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalDestroyApplication(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations for desribing a set of permissions that may be assigned to a Role, and by extension, a User
         * @param {number} id A unique integer value identifying this permission set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalDestroyPermissionSet(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalDestroyPermissionSet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to Programs, which are sets of data or tasks used by the same group of users.
         * @param {number} id A unique integer value identifying this program.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalDestroyProgram(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalDestroyProgram(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations for a Role, which dertermines what access and capabilities a User has
         * @param {number} id A unique integer value identifying this role.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalDestroyRole(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalDestroyRole(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations for Applications used for Machine to Machine connections
         * @param {number} id A unique integer value identifying this application.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalGetApplication(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Application>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalGetApplication(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations for desribing a set of permissions that may be assigned to a Role, and by extension, a User
         * @param {number} id A unique integer value identifying this permission set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalGetPermissionSet(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PermissionSet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalGetPermissionSet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns relevant information about a user as well as a list of roles they currently have on the program.
         * @summary Inspect details about a program user
         * @param {number} id A unique integer value identifying this user.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalGetProgramUser(
            id: number,
            program: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProgramUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalGetProgramUser(id, program, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations for a Role, which dertermines what access and capabilities a User has
         * @param {number} id A unique integer value identifying this role.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalGetRole(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalRole>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalGetRole(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations for Applications used for Machine to Machine connections
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalListApplication(
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedApplicationList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalListApplication(
                limit,
                offset,
                ordering,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations for desribing a set of permissions that may be assigned to a Role, and by extension, a User
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalListPermissionSet(
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedPermissionSetList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalListPermissionSet(
                limit,
                offset,
                ordering,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of opportunities for OSK\'s sensors, filtered for sensor, time, and area of interest  (AOI). Each opportunity is a 10-second piece of the orbit.
         * @summary List the users associated with this program
         * @param {number} program
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalListProgramUsers(
            program: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedProgramUserList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalListProgramUsers(
                program,
                limit,
                offset,
                ordering,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to Programs, which are sets of data or tasks used by the same group of users.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalListPrograms(
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedProgramList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalListPrograms(
                limit,
                offset,
                ordering,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations for a Role, which dertermines what access and capabilities a User has
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalListRoles(
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedInternalRoleList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalListRoles(
                limit,
                offset,
                ordering,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations for Applications used for Machine to Machine connections
         * @param {number} id A unique integer value identifying this application.
         * @param {PatchedApplicationRequest} [patchedApplicationRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalPartialUpdateApplication(
            id: number,
            patchedApplicationRequest?: PatchedApplicationRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Application>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalPartialUpdateApplication(
                id,
                patchedApplicationRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations for desribing a set of permissions that may be assigned to a Role, and by extension, a User
         * @param {number} id A unique integer value identifying this permission set.
         * @param {PatchedPermissionSetRequest} [patchedPermissionSetRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalPartialUpdatePermissionSet(
            id: number,
            patchedPermissionSetRequest?: PatchedPermissionSetRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PermissionSet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalPartialUpdatePermissionSet(
                id,
                patchedPermissionSetRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to Programs, which are sets of data or tasks used by the same group of users.
         * @param {number} id A unique integer value identifying this program.
         * @param {PatchedProgramRequest} [patchedProgramRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalPartialUpdateProgram(
            id: number,
            patchedProgramRequest?: PatchedProgramRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Program>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalPartialUpdateProgram(
                id,
                patchedProgramRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations for a Role, which dertermines what access and capabilities a User has
         * @param {number} id A unique integer value identifying this role.
         * @param {PatchedInternalRoleRequest} [patchedInternalRoleRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalPartialUpdateRole(
            id: number,
            patchedInternalRoleRequest?: PatchedInternalRoleRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalRole>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalPartialUpdateRole(
                id,
                patchedInternalRoleRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Removes the user from the program by disassociating them from all program roles. This is equivalent to calling update_program_user with an empty set of roles.
         * @summary Remove a user from the program
         * @param {number} id A unique integer value identifying this user.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalRemoveUserFromProgram(
            id: number,
            program: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalRemoveUserFromProgram(
                id,
                program,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations for Applications used for Machine to Machine connections
         * @param {number} id A unique integer value identifying this application.
         * @param {ApplicationRequest} applicationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdateApplication(
            id: number,
            applicationRequest: ApplicationRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Application>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdateApplication(
                id,
                applicationRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations for desribing a set of permissions that may be assigned to a Role, and by extension, a User
         * @param {number} id A unique integer value identifying this permission set.
         * @param {PermissionSetRequest} permissionSetRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdatePermissionSet(
            id: number,
            permissionSetRequest: PermissionSetRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PermissionSet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdatePermissionSet(
                id,
                permissionSetRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to Programs, which are sets of data or tasks used by the same group of users.
         * @param {number} id A unique integer value identifying this program.
         * @param {ProgramRequest} programRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdateProgram(
            id: number,
            programRequest: ProgramRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Program>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdateProgram(
                id,
                programRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Atomically set the program roles for a specific user. This operation will set the user\'s roles to the list of roles passed in, if the user currently has a role which is not included in the update, it will be removed.
         * @summary Update a user\'s program roles
         * @param {number} id A unique integer value identifying this user.
         * @param {number} program
         * @param {ProgramUserRequest} programUserRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdateProgramUser(
            id: number,
            program: number,
            programUserRequest: ProgramUserRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProgramUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdateProgramUser(
                id,
                program,
                programUserRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations for a Role, which dertermines what access and capabilities a User has
         * @param {number} id A unique integer value identifying this role.
         * @param {InternalRoleRequest} internalRoleRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdateRole(
            id: number,
            internalRoleRequest: InternalRoleRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalRole>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdateRole(
                id,
                internalRoleRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations for a Role, which dertermines what access and capabilities a User has
         * @summary List the roles associated with this program
         * @param {number} program
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listProgramRoles(
            program: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedRoleList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listProgramRoles(
                program,
                limit,
                offset,
                ordering,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of opportunities for OSK\'s sensors, filtered for sensor, time, and area of interest  (AOI). Each opportunity is a 10-second piece of the orbit.
         * @summary List the users associated with this program
         * @param {number} program
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listProgramUsers(
            program: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedProgramUserList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listProgramUsers(
                program,
                limit,
                offset,
                ordering,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to Programs, which are sets of data or tasks used by the same group of users.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPrograms(
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedProgramList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPrograms(limit, offset, ordering, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Removes the user from the program by disassociating them from all program roles. This is equivalent to calling update_program_user with an empty set of roles.
         * @summary Remove a user from the program
         * @param {number} id A unique integer value identifying this user.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeUserFromProgram(
            id: number,
            program: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeUserFromProgram(id, program, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {UserRequest} [userRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProfile(
            userRequest?: UserRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProfile(userRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Atomically set the program roles for a specific user. This operation will set the user\'s roles to the list of roles passed in, if the user currently has a role which is not included in the update, it will be removed.
         * @summary Update a user\'s program roles
         * @param {number} id A unique integer value identifying this user.
         * @param {number} program
         * @param {ProgramUserRequest} programUserRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProgramUser(
            id: number,
            program: number,
            programUserRequest: ProgramUserRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProgramUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProgramUser(
                id,
                program,
                programUserRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminApiFp(configuration);
    return {
        /**
         * Adding a user to a program is done by associating them with one or more program roles. This endpoint will assign the given program roles to the specified user.
         * @summary Add a user to a program
         * @param {number} program
         * @param {AddUserToProgramRequest} addUserToProgramRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserToProgram(
            program: number,
            addUserToProgramRequest: AddUserToProgramRequest,
            options?: any,
        ): AxiosPromise<ProgramUser> {
            return localVarFp
                .addUserToProgram(program, addUserToProgramRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile(options?: any): AxiosPromise<User> {
            return localVarFp.getProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * API operations related to Programs, which are sets of data or tasks used by the same group of users.
         * @param {number} id A unique integer value identifying this program.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgram(id: number, options?: any): AxiosPromise<Program> {
            return localVarFp.getProgram(id, options).then((request) => request(axios, basePath));
        },
        /**
         * API operations for a Role, which dertermines what access and capabilities a User has
         * @summary Inspect details about a program role
         * @param {number} id A unique integer value identifying this role.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramRole(id: number, program: number, options?: any): AxiosPromise<Role> {
            return localVarFp.getProgramRole(id, program, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns relevant information about a user as well as a list of roles they currently have on the program.
         * @summary Inspect details about a program user
         * @param {number} id A unique integer value identifying this user.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramUser(id: number, program: number, options?: any): AxiosPromise<ProgramUser> {
            return localVarFp.getProgramUser(id, program, options).then((request) => request(axios, basePath));
        },
        /**
         * Adding a user to a program is done by associating them with one or more program roles. This endpoint will assign the given program roles to the specified user.
         * @summary Add a user to a program
         * @param {number} program
         * @param {AddUserToProgramRequest} addUserToProgramRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalAddUserToProgram(
            program: number,
            addUserToProgramRequest: AddUserToProgramRequest,
            options?: any,
        ): AxiosPromise<ProgramUser> {
            return localVarFp
                .internalAddUserToProgram(program, addUserToProgramRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations for Applications used for Machine to Machine connections
         * @param {ApplicationCreateRequest} applicationCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateApplication(
            applicationCreateRequest: ApplicationCreateRequest,
            options?: any,
        ): AxiosPromise<ApplicationCreate> {
            return localVarFp
                .internalCreateApplication(applicationCreateRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations for desribing a set of permissions that may be assigned to a Role, and by extension, a User
         * @param {PermissionSetRequest} permissionSetRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreatePermissionSet(
            permissionSetRequest: PermissionSetRequest,
            options?: any,
        ): AxiosPromise<PermissionSet> {
            return localVarFp
                .internalCreatePermissionSet(permissionSetRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations related to Programs, which are sets of data or tasks used by the same group of users.
         * @param {ProgramRequest} programRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateProgram(programRequest: ProgramRequest, options?: any): AxiosPromise<Program> {
            return localVarFp
                .internalCreateProgram(programRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations for a Role, which dertermines what access and capabilities a User has
         * @param {InternalRoleRequest} internalRoleRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateRole(internalRoleRequest: InternalRoleRequest, options?: any): AxiosPromise<InternalRole> {
            return localVarFp
                .internalCreateRole(internalRoleRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations for Applications used for Machine to Machine connections
         * @param {number} id A unique integer value identifying this application.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyApplication(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.internalDestroyApplication(id, options).then((request) => request(axios, basePath));
        },
        /**
         * API operations for desribing a set of permissions that may be assigned to a Role, and by extension, a User
         * @param {number} id A unique integer value identifying this permission set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyPermissionSet(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.internalDestroyPermissionSet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * API operations related to Programs, which are sets of data or tasks used by the same group of users.
         * @param {number} id A unique integer value identifying this program.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyProgram(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.internalDestroyProgram(id, options).then((request) => request(axios, basePath));
        },
        /**
         * API operations for a Role, which dertermines what access and capabilities a User has
         * @param {number} id A unique integer value identifying this role.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyRole(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.internalDestroyRole(id, options).then((request) => request(axios, basePath));
        },
        /**
         * API operations for Applications used for Machine to Machine connections
         * @param {number} id A unique integer value identifying this application.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetApplication(id: number, options?: any): AxiosPromise<Application> {
            return localVarFp.internalGetApplication(id, options).then((request) => request(axios, basePath));
        },
        /**
         * API operations for desribing a set of permissions that may be assigned to a Role, and by extension, a User
         * @param {number} id A unique integer value identifying this permission set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetPermissionSet(id: number, options?: any): AxiosPromise<PermissionSet> {
            return localVarFp.internalGetPermissionSet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns relevant information about a user as well as a list of roles they currently have on the program.
         * @summary Inspect details about a program user
         * @param {number} id A unique integer value identifying this user.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetProgramUser(id: number, program: number, options?: any): AxiosPromise<ProgramUser> {
            return localVarFp.internalGetProgramUser(id, program, options).then((request) => request(axios, basePath));
        },
        /**
         * API operations for a Role, which dertermines what access and capabilities a User has
         * @param {number} id A unique integer value identifying this role.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetRole(id: number, options?: any): AxiosPromise<InternalRole> {
            return localVarFp.internalGetRole(id, options).then((request) => request(axios, basePath));
        },
        /**
         * API operations for Applications used for Machine to Machine connections
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListApplication(
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: any,
        ): AxiosPromise<PaginatedApplicationList> {
            return localVarFp
                .internalListApplication(limit, offset, ordering, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations for desribing a set of permissions that may be assigned to a Role, and by extension, a User
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListPermissionSet(
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: any,
        ): AxiosPromise<PaginatedPermissionSetList> {
            return localVarFp
                .internalListPermissionSet(limit, offset, ordering, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of opportunities for OSK\'s sensors, filtered for sensor, time, and area of interest  (AOI). Each opportunity is a 10-second piece of the orbit.
         * @summary List the users associated with this program
         * @param {number} program
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListProgramUsers(
            program: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: any,
        ): AxiosPromise<PaginatedProgramUserList> {
            return localVarFp
                .internalListProgramUsers(program, limit, offset, ordering, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations related to Programs, which are sets of data or tasks used by the same group of users.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListPrograms(
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: any,
        ): AxiosPromise<PaginatedProgramList> {
            return localVarFp
                .internalListPrograms(limit, offset, ordering, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations for a Role, which dertermines what access and capabilities a User has
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListRoles(
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: any,
        ): AxiosPromise<PaginatedInternalRoleList> {
            return localVarFp
                .internalListRoles(limit, offset, ordering, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations for Applications used for Machine to Machine connections
         * @param {number} id A unique integer value identifying this application.
         * @param {PatchedApplicationRequest} [patchedApplicationRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateApplication(
            id: number,
            patchedApplicationRequest?: PatchedApplicationRequest,
            options?: any,
        ): AxiosPromise<Application> {
            return localVarFp
                .internalPartialUpdateApplication(id, patchedApplicationRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations for desribing a set of permissions that may be assigned to a Role, and by extension, a User
         * @param {number} id A unique integer value identifying this permission set.
         * @param {PatchedPermissionSetRequest} [patchedPermissionSetRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdatePermissionSet(
            id: number,
            patchedPermissionSetRequest?: PatchedPermissionSetRequest,
            options?: any,
        ): AxiosPromise<PermissionSet> {
            return localVarFp
                .internalPartialUpdatePermissionSet(id, patchedPermissionSetRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations related to Programs, which are sets of data or tasks used by the same group of users.
         * @param {number} id A unique integer value identifying this program.
         * @param {PatchedProgramRequest} [patchedProgramRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateProgram(
            id: number,
            patchedProgramRequest?: PatchedProgramRequest,
            options?: any,
        ): AxiosPromise<Program> {
            return localVarFp
                .internalPartialUpdateProgram(id, patchedProgramRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations for a Role, which dertermines what access and capabilities a User has
         * @param {number} id A unique integer value identifying this role.
         * @param {PatchedInternalRoleRequest} [patchedInternalRoleRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateRole(
            id: number,
            patchedInternalRoleRequest?: PatchedInternalRoleRequest,
            options?: any,
        ): AxiosPromise<InternalRole> {
            return localVarFp
                .internalPartialUpdateRole(id, patchedInternalRoleRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Removes the user from the program by disassociating them from all program roles. This is equivalent to calling update_program_user with an empty set of roles.
         * @summary Remove a user from the program
         * @param {number} id A unique integer value identifying this user.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalRemoveUserFromProgram(id: number, program: number, options?: any): AxiosPromise<void> {
            return localVarFp
                .internalRemoveUserFromProgram(id, program, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations for Applications used for Machine to Machine connections
         * @param {number} id A unique integer value identifying this application.
         * @param {ApplicationRequest} applicationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateApplication(
            id: number,
            applicationRequest: ApplicationRequest,
            options?: any,
        ): AxiosPromise<Application> {
            return localVarFp
                .internalUpdateApplication(id, applicationRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations for desribing a set of permissions that may be assigned to a Role, and by extension, a User
         * @param {number} id A unique integer value identifying this permission set.
         * @param {PermissionSetRequest} permissionSetRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdatePermissionSet(
            id: number,
            permissionSetRequest: PermissionSetRequest,
            options?: any,
        ): AxiosPromise<PermissionSet> {
            return localVarFp
                .internalUpdatePermissionSet(id, permissionSetRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations related to Programs, which are sets of data or tasks used by the same group of users.
         * @param {number} id A unique integer value identifying this program.
         * @param {ProgramRequest} programRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateProgram(id: number, programRequest: ProgramRequest, options?: any): AxiosPromise<Program> {
            return localVarFp
                .internalUpdateProgram(id, programRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Atomically set the program roles for a specific user. This operation will set the user\'s roles to the list of roles passed in, if the user currently has a role which is not included in the update, it will be removed.
         * @summary Update a user\'s program roles
         * @param {number} id A unique integer value identifying this user.
         * @param {number} program
         * @param {ProgramUserRequest} programUserRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateProgramUser(
            id: number,
            program: number,
            programUserRequest: ProgramUserRequest,
            options?: any,
        ): AxiosPromise<ProgramUser> {
            return localVarFp
                .internalUpdateProgramUser(id, program, programUserRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations for a Role, which dertermines what access and capabilities a User has
         * @param {number} id A unique integer value identifying this role.
         * @param {InternalRoleRequest} internalRoleRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateRole(
            id: number,
            internalRoleRequest: InternalRoleRequest,
            options?: any,
        ): AxiosPromise<InternalRole> {
            return localVarFp
                .internalUpdateRole(id, internalRoleRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations for a Role, which dertermines what access and capabilities a User has
         * @summary List the roles associated with this program
         * @param {number} program
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProgramRoles(
            program: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: any,
        ): AxiosPromise<PaginatedRoleList> {
            return localVarFp
                .listProgramRoles(program, limit, offset, ordering, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of opportunities for OSK\'s sensors, filtered for sensor, time, and area of interest  (AOI). Each opportunity is a 10-second piece of the orbit.
         * @summary List the users associated with this program
         * @param {number} program
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProgramUsers(
            program: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: any,
        ): AxiosPromise<PaginatedProgramUserList> {
            return localVarFp
                .listProgramUsers(program, limit, offset, ordering, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations related to Programs, which are sets of data or tasks used by the same group of users.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPrograms(
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: any,
        ): AxiosPromise<PaginatedProgramList> {
            return localVarFp
                .listPrograms(limit, offset, ordering, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Removes the user from the program by disassociating them from all program roles. This is equivalent to calling update_program_user with an empty set of roles.
         * @summary Remove a user from the program
         * @param {number} id A unique integer value identifying this user.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUserFromProgram(id: number, program: number, options?: any): AxiosPromise<void> {
            return localVarFp.removeUserFromProgram(id, program, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {UserRequest} [userRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfile(userRequest?: UserRequest, options?: any): AxiosPromise<User> {
            return localVarFp.updateProfile(userRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Atomically set the program roles for a specific user. This operation will set the user\'s roles to the list of roles passed in, if the user currently has a role which is not included in the update, it will be removed.
         * @summary Update a user\'s program roles
         * @param {number} id A unique integer value identifying this user.
         * @param {number} program
         * @param {ProgramUserRequest} programUserRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProgramUser(
            id: number,
            program: number,
            programUserRequest: ProgramUserRequest,
            options?: any,
        ): AxiosPromise<ProgramUser> {
            return localVarFp
                .updateProgramUser(id, program, programUserRequest, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addUserToProgram operation in AdminApi.
 * @export
 * @interface AdminApiAddUserToProgramRequest
 */
export interface AdminApiAddUserToProgramRequest {
    /**
     *
     * @type {number}
     * @memberof AdminApiAddUserToProgram
     */
    readonly program: number;

    /**
     *
     * @type {AddUserToProgramRequest}
     * @memberof AdminApiAddUserToProgram
     */
    readonly addUserToProgramRequest: AddUserToProgramRequest;
}

/**
 * Request parameters for getProgram operation in AdminApi.
 * @export
 * @interface AdminApiGetProgramRequest
 */
export interface AdminApiGetProgramRequest {
    /**
     * A unique integer value identifying this program.
     * @type {number}
     * @memberof AdminApiGetProgram
     */
    readonly id: number;
}

/**
 * Request parameters for getProgramRole operation in AdminApi.
 * @export
 * @interface AdminApiGetProgramRoleRequest
 */
export interface AdminApiGetProgramRoleRequest {
    /**
     * A unique integer value identifying this role.
     * @type {number}
     * @memberof AdminApiGetProgramRole
     */
    readonly id: number;

    /**
     *
     * @type {number}
     * @memberof AdminApiGetProgramRole
     */
    readonly program: number;
}

/**
 * Request parameters for getProgramUser operation in AdminApi.
 * @export
 * @interface AdminApiGetProgramUserRequest
 */
export interface AdminApiGetProgramUserRequest {
    /**
     * A unique integer value identifying this user.
     * @type {number}
     * @memberof AdminApiGetProgramUser
     */
    readonly id: number;

    /**
     *
     * @type {number}
     * @memberof AdminApiGetProgramUser
     */
    readonly program: number;
}

/**
 * Request parameters for internalAddUserToProgram operation in AdminApi.
 * @export
 * @interface AdminApiInternalAddUserToProgramRequest
 */
export interface AdminApiInternalAddUserToProgramRequest {
    /**
     *
     * @type {number}
     * @memberof AdminApiInternalAddUserToProgram
     */
    readonly program: number;

    /**
     *
     * @type {AddUserToProgramRequest}
     * @memberof AdminApiInternalAddUserToProgram
     */
    readonly addUserToProgramRequest: AddUserToProgramRequest;
}

/**
 * Request parameters for internalCreateApplication operation in AdminApi.
 * @export
 * @interface AdminApiInternalCreateApplicationRequest
 */
export interface AdminApiInternalCreateApplicationRequest {
    /**
     *
     * @type {ApplicationCreateRequest}
     * @memberof AdminApiInternalCreateApplication
     */
    readonly applicationCreateRequest: ApplicationCreateRequest;
}

/**
 * Request parameters for internalCreatePermissionSet operation in AdminApi.
 * @export
 * @interface AdminApiInternalCreatePermissionSetRequest
 */
export interface AdminApiInternalCreatePermissionSetRequest {
    /**
     *
     * @type {PermissionSetRequest}
     * @memberof AdminApiInternalCreatePermissionSet
     */
    readonly permissionSetRequest: PermissionSetRequest;
}

/**
 * Request parameters for internalCreateProgram operation in AdminApi.
 * @export
 * @interface AdminApiInternalCreateProgramRequest
 */
export interface AdminApiInternalCreateProgramRequest {
    /**
     *
     * @type {ProgramRequest}
     * @memberof AdminApiInternalCreateProgram
     */
    readonly programRequest: ProgramRequest;
}

/**
 * Request parameters for internalCreateRole operation in AdminApi.
 * @export
 * @interface AdminApiInternalCreateRoleRequest
 */
export interface AdminApiInternalCreateRoleRequest {
    /**
     *
     * @type {InternalRoleRequest}
     * @memberof AdminApiInternalCreateRole
     */
    readonly internalRoleRequest: InternalRoleRequest;
}

/**
 * Request parameters for internalDestroyApplication operation in AdminApi.
 * @export
 * @interface AdminApiInternalDestroyApplicationRequest
 */
export interface AdminApiInternalDestroyApplicationRequest {
    /**
     * A unique integer value identifying this application.
     * @type {number}
     * @memberof AdminApiInternalDestroyApplication
     */
    readonly id: number;
}

/**
 * Request parameters for internalDestroyPermissionSet operation in AdminApi.
 * @export
 * @interface AdminApiInternalDestroyPermissionSetRequest
 */
export interface AdminApiInternalDestroyPermissionSetRequest {
    /**
     * A unique integer value identifying this permission set.
     * @type {number}
     * @memberof AdminApiInternalDestroyPermissionSet
     */
    readonly id: number;
}

/**
 * Request parameters for internalDestroyProgram operation in AdminApi.
 * @export
 * @interface AdminApiInternalDestroyProgramRequest
 */
export interface AdminApiInternalDestroyProgramRequest {
    /**
     * A unique integer value identifying this program.
     * @type {number}
     * @memberof AdminApiInternalDestroyProgram
     */
    readonly id: number;
}

/**
 * Request parameters for internalDestroyRole operation in AdminApi.
 * @export
 * @interface AdminApiInternalDestroyRoleRequest
 */
export interface AdminApiInternalDestroyRoleRequest {
    /**
     * A unique integer value identifying this role.
     * @type {number}
     * @memberof AdminApiInternalDestroyRole
     */
    readonly id: number;
}

/**
 * Request parameters for internalGetApplication operation in AdminApi.
 * @export
 * @interface AdminApiInternalGetApplicationRequest
 */
export interface AdminApiInternalGetApplicationRequest {
    /**
     * A unique integer value identifying this application.
     * @type {number}
     * @memberof AdminApiInternalGetApplication
     */
    readonly id: number;
}

/**
 * Request parameters for internalGetPermissionSet operation in AdminApi.
 * @export
 * @interface AdminApiInternalGetPermissionSetRequest
 */
export interface AdminApiInternalGetPermissionSetRequest {
    /**
     * A unique integer value identifying this permission set.
     * @type {number}
     * @memberof AdminApiInternalGetPermissionSet
     */
    readonly id: number;
}

/**
 * Request parameters for internalGetProgramUser operation in AdminApi.
 * @export
 * @interface AdminApiInternalGetProgramUserRequest
 */
export interface AdminApiInternalGetProgramUserRequest {
    /**
     * A unique integer value identifying this user.
     * @type {number}
     * @memberof AdminApiInternalGetProgramUser
     */
    readonly id: number;

    /**
     *
     * @type {number}
     * @memberof AdminApiInternalGetProgramUser
     */
    readonly program: number;
}

/**
 * Request parameters for internalGetRole operation in AdminApi.
 * @export
 * @interface AdminApiInternalGetRoleRequest
 */
export interface AdminApiInternalGetRoleRequest {
    /**
     * A unique integer value identifying this role.
     * @type {number}
     * @memberof AdminApiInternalGetRole
     */
    readonly id: number;
}

/**
 * Request parameters for internalListApplication operation in AdminApi.
 * @export
 * @interface AdminApiInternalListApplicationRequest
 */
export interface AdminApiInternalListApplicationRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof AdminApiInternalListApplication
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof AdminApiInternalListApplication
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof AdminApiInternalListApplication
     */
    readonly ordering?: string;
}

/**
 * Request parameters for internalListPermissionSet operation in AdminApi.
 * @export
 * @interface AdminApiInternalListPermissionSetRequest
 */
export interface AdminApiInternalListPermissionSetRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof AdminApiInternalListPermissionSet
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof AdminApiInternalListPermissionSet
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof AdminApiInternalListPermissionSet
     */
    readonly ordering?: string;
}

/**
 * Request parameters for internalListProgramUsers operation in AdminApi.
 * @export
 * @interface AdminApiInternalListProgramUsersRequest
 */
export interface AdminApiInternalListProgramUsersRequest {
    /**
     *
     * @type {number}
     * @memberof AdminApiInternalListProgramUsers
     */
    readonly program: number;

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof AdminApiInternalListProgramUsers
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof AdminApiInternalListProgramUsers
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof AdminApiInternalListProgramUsers
     */
    readonly ordering?: string;
}

/**
 * Request parameters for internalListPrograms operation in AdminApi.
 * @export
 * @interface AdminApiInternalListProgramsRequest
 */
export interface AdminApiInternalListProgramsRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof AdminApiInternalListPrograms
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof AdminApiInternalListPrograms
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof AdminApiInternalListPrograms
     */
    readonly ordering?: string;
}

/**
 * Request parameters for internalListRoles operation in AdminApi.
 * @export
 * @interface AdminApiInternalListRolesRequest
 */
export interface AdminApiInternalListRolesRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof AdminApiInternalListRoles
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof AdminApiInternalListRoles
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof AdminApiInternalListRoles
     */
    readonly ordering?: string;
}

/**
 * Request parameters for internalPartialUpdateApplication operation in AdminApi.
 * @export
 * @interface AdminApiInternalPartialUpdateApplicationRequest
 */
export interface AdminApiInternalPartialUpdateApplicationRequest {
    /**
     * A unique integer value identifying this application.
     * @type {number}
     * @memberof AdminApiInternalPartialUpdateApplication
     */
    readonly id: number;

    /**
     *
     * @type {PatchedApplicationRequest}
     * @memberof AdminApiInternalPartialUpdateApplication
     */
    readonly patchedApplicationRequest?: PatchedApplicationRequest;
}

/**
 * Request parameters for internalPartialUpdatePermissionSet operation in AdminApi.
 * @export
 * @interface AdminApiInternalPartialUpdatePermissionSetRequest
 */
export interface AdminApiInternalPartialUpdatePermissionSetRequest {
    /**
     * A unique integer value identifying this permission set.
     * @type {number}
     * @memberof AdminApiInternalPartialUpdatePermissionSet
     */
    readonly id: number;

    /**
     *
     * @type {PatchedPermissionSetRequest}
     * @memberof AdminApiInternalPartialUpdatePermissionSet
     */
    readonly patchedPermissionSetRequest?: PatchedPermissionSetRequest;
}

/**
 * Request parameters for internalPartialUpdateProgram operation in AdminApi.
 * @export
 * @interface AdminApiInternalPartialUpdateProgramRequest
 */
export interface AdminApiInternalPartialUpdateProgramRequest {
    /**
     * A unique integer value identifying this program.
     * @type {number}
     * @memberof AdminApiInternalPartialUpdateProgram
     */
    readonly id: number;

    /**
     *
     * @type {PatchedProgramRequest}
     * @memberof AdminApiInternalPartialUpdateProgram
     */
    readonly patchedProgramRequest?: PatchedProgramRequest;
}

/**
 * Request parameters for internalPartialUpdateRole operation in AdminApi.
 * @export
 * @interface AdminApiInternalPartialUpdateRoleRequest
 */
export interface AdminApiInternalPartialUpdateRoleRequest {
    /**
     * A unique integer value identifying this role.
     * @type {number}
     * @memberof AdminApiInternalPartialUpdateRole
     */
    readonly id: number;

    /**
     *
     * @type {PatchedInternalRoleRequest}
     * @memberof AdminApiInternalPartialUpdateRole
     */
    readonly patchedInternalRoleRequest?: PatchedInternalRoleRequest;
}

/**
 * Request parameters for internalRemoveUserFromProgram operation in AdminApi.
 * @export
 * @interface AdminApiInternalRemoveUserFromProgramRequest
 */
export interface AdminApiInternalRemoveUserFromProgramRequest {
    /**
     * A unique integer value identifying this user.
     * @type {number}
     * @memberof AdminApiInternalRemoveUserFromProgram
     */
    readonly id: number;

    /**
     *
     * @type {number}
     * @memberof AdminApiInternalRemoveUserFromProgram
     */
    readonly program: number;
}

/**
 * Request parameters for internalUpdateApplication operation in AdminApi.
 * @export
 * @interface AdminApiInternalUpdateApplicationRequest
 */
export interface AdminApiInternalUpdateApplicationRequest {
    /**
     * A unique integer value identifying this application.
     * @type {number}
     * @memberof AdminApiInternalUpdateApplication
     */
    readonly id: number;

    /**
     *
     * @type {ApplicationRequest}
     * @memberof AdminApiInternalUpdateApplication
     */
    readonly applicationRequest: ApplicationRequest;
}

/**
 * Request parameters for internalUpdatePermissionSet operation in AdminApi.
 * @export
 * @interface AdminApiInternalUpdatePermissionSetRequest
 */
export interface AdminApiInternalUpdatePermissionSetRequest {
    /**
     * A unique integer value identifying this permission set.
     * @type {number}
     * @memberof AdminApiInternalUpdatePermissionSet
     */
    readonly id: number;

    /**
     *
     * @type {PermissionSetRequest}
     * @memberof AdminApiInternalUpdatePermissionSet
     */
    readonly permissionSetRequest: PermissionSetRequest;
}

/**
 * Request parameters for internalUpdateProgram operation in AdminApi.
 * @export
 * @interface AdminApiInternalUpdateProgramRequest
 */
export interface AdminApiInternalUpdateProgramRequest {
    /**
     * A unique integer value identifying this program.
     * @type {number}
     * @memberof AdminApiInternalUpdateProgram
     */
    readonly id: number;

    /**
     *
     * @type {ProgramRequest}
     * @memberof AdminApiInternalUpdateProgram
     */
    readonly programRequest: ProgramRequest;
}

/**
 * Request parameters for internalUpdateProgramUser operation in AdminApi.
 * @export
 * @interface AdminApiInternalUpdateProgramUserRequest
 */
export interface AdminApiInternalUpdateProgramUserRequest {
    /**
     * A unique integer value identifying this user.
     * @type {number}
     * @memberof AdminApiInternalUpdateProgramUser
     */
    readonly id: number;

    /**
     *
     * @type {number}
     * @memberof AdminApiInternalUpdateProgramUser
     */
    readonly program: number;

    /**
     *
     * @type {ProgramUserRequest}
     * @memberof AdminApiInternalUpdateProgramUser
     */
    readonly programUserRequest: ProgramUserRequest;
}

/**
 * Request parameters for internalUpdateRole operation in AdminApi.
 * @export
 * @interface AdminApiInternalUpdateRoleRequest
 */
export interface AdminApiInternalUpdateRoleRequest {
    /**
     * A unique integer value identifying this role.
     * @type {number}
     * @memberof AdminApiInternalUpdateRole
     */
    readonly id: number;

    /**
     *
     * @type {InternalRoleRequest}
     * @memberof AdminApiInternalUpdateRole
     */
    readonly internalRoleRequest: InternalRoleRequest;
}

/**
 * Request parameters for listProgramRoles operation in AdminApi.
 * @export
 * @interface AdminApiListProgramRolesRequest
 */
export interface AdminApiListProgramRolesRequest {
    /**
     *
     * @type {number}
     * @memberof AdminApiListProgramRoles
     */
    readonly program: number;

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof AdminApiListProgramRoles
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof AdminApiListProgramRoles
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof AdminApiListProgramRoles
     */
    readonly ordering?: string;
}

/**
 * Request parameters for listProgramUsers operation in AdminApi.
 * @export
 * @interface AdminApiListProgramUsersRequest
 */
export interface AdminApiListProgramUsersRequest {
    /**
     *
     * @type {number}
     * @memberof AdminApiListProgramUsers
     */
    readonly program: number;

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof AdminApiListProgramUsers
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof AdminApiListProgramUsers
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof AdminApiListProgramUsers
     */
    readonly ordering?: string;
}

/**
 * Request parameters for listPrograms operation in AdminApi.
 * @export
 * @interface AdminApiListProgramsRequest
 */
export interface AdminApiListProgramsRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof AdminApiListPrograms
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof AdminApiListPrograms
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof AdminApiListPrograms
     */
    readonly ordering?: string;
}

/**
 * Request parameters for removeUserFromProgram operation in AdminApi.
 * @export
 * @interface AdminApiRemoveUserFromProgramRequest
 */
export interface AdminApiRemoveUserFromProgramRequest {
    /**
     * A unique integer value identifying this user.
     * @type {number}
     * @memberof AdminApiRemoveUserFromProgram
     */
    readonly id: number;

    /**
     *
     * @type {number}
     * @memberof AdminApiRemoveUserFromProgram
     */
    readonly program: number;
}

/**
 * Request parameters for updateProfile operation in AdminApi.
 * @export
 * @interface AdminApiUpdateProfileRequest
 */
export interface AdminApiUpdateProfileRequest {
    /**
     *
     * @type {UserRequest}
     * @memberof AdminApiUpdateProfile
     */
    readonly userRequest?: UserRequest;
}

/**
 * Request parameters for updateProgramUser operation in AdminApi.
 * @export
 * @interface AdminApiUpdateProgramUserRequest
 */
export interface AdminApiUpdateProgramUserRequest {
    /**
     * A unique integer value identifying this user.
     * @type {number}
     * @memberof AdminApiUpdateProgramUser
     */
    readonly id: number;

    /**
     *
     * @type {number}
     * @memberof AdminApiUpdateProgramUser
     */
    readonly program: number;

    /**
     *
     * @type {ProgramUserRequest}
     * @memberof AdminApiUpdateProgramUser
     */
    readonly programUserRequest: ProgramUserRequest;
}

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * Adding a user to a program is done by associating them with one or more program roles. This endpoint will assign the given program roles to the specified user.
     * @summary Add a user to a program
     * @param {AdminApiAddUserToProgramRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public addUserToProgram(requestParameters: AdminApiAddUserToProgramRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration)
            .addUserToProgram(requestParameters.program, requestParameters.addUserToProgramRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getProfile(options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration)
            .getProfile(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to Programs, which are sets of data or tasks used by the same group of users.
     * @param {AdminApiGetProgramRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getProgram(requestParameters: AdminApiGetProgramRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration)
            .getProgram(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations for a Role, which dertermines what access and capabilities a User has
     * @summary Inspect details about a program role
     * @param {AdminApiGetProgramRoleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getProgramRole(requestParameters: AdminApiGetProgramRoleRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration)
            .getProgramRole(requestParameters.id, requestParameters.program, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns relevant information about a user as well as a list of roles they currently have on the program.
     * @summary Inspect details about a program user
     * @param {AdminApiGetProgramUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getProgramUser(requestParameters: AdminApiGetProgramUserRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration)
            .getProgramUser(requestParameters.id, requestParameters.program, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Adding a user to a program is done by associating them with one or more program roles. This endpoint will assign the given program roles to the specified user.
     * @summary Add a user to a program
     * @param {AdminApiInternalAddUserToProgramRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalAddUserToProgram(
        requestParameters: AdminApiInternalAddUserToProgramRequest,
        options?: AxiosRequestConfig,
    ) {
        return AdminApiFp(this.configuration)
            .internalAddUserToProgram(requestParameters.program, requestParameters.addUserToProgramRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations for Applications used for Machine to Machine connections
     * @param {AdminApiInternalCreateApplicationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalCreateApplication(
        requestParameters: AdminApiInternalCreateApplicationRequest,
        options?: AxiosRequestConfig,
    ) {
        return AdminApiFp(this.configuration)
            .internalCreateApplication(requestParameters.applicationCreateRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations for desribing a set of permissions that may be assigned to a Role, and by extension, a User
     * @param {AdminApiInternalCreatePermissionSetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalCreatePermissionSet(
        requestParameters: AdminApiInternalCreatePermissionSetRequest,
        options?: AxiosRequestConfig,
    ) {
        return AdminApiFp(this.configuration)
            .internalCreatePermissionSet(requestParameters.permissionSetRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to Programs, which are sets of data or tasks used by the same group of users.
     * @param {AdminApiInternalCreateProgramRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalCreateProgram(
        requestParameters: AdminApiInternalCreateProgramRequest,
        options?: AxiosRequestConfig,
    ) {
        return AdminApiFp(this.configuration)
            .internalCreateProgram(requestParameters.programRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations for a Role, which dertermines what access and capabilities a User has
     * @param {AdminApiInternalCreateRoleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalCreateRole(requestParameters: AdminApiInternalCreateRoleRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration)
            .internalCreateRole(requestParameters.internalRoleRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations for Applications used for Machine to Machine connections
     * @param {AdminApiInternalDestroyApplicationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalDestroyApplication(
        requestParameters: AdminApiInternalDestroyApplicationRequest,
        options?: AxiosRequestConfig,
    ) {
        return AdminApiFp(this.configuration)
            .internalDestroyApplication(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations for desribing a set of permissions that may be assigned to a Role, and by extension, a User
     * @param {AdminApiInternalDestroyPermissionSetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalDestroyPermissionSet(
        requestParameters: AdminApiInternalDestroyPermissionSetRequest,
        options?: AxiosRequestConfig,
    ) {
        return AdminApiFp(this.configuration)
            .internalDestroyPermissionSet(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to Programs, which are sets of data or tasks used by the same group of users.
     * @param {AdminApiInternalDestroyProgramRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalDestroyProgram(
        requestParameters: AdminApiInternalDestroyProgramRequest,
        options?: AxiosRequestConfig,
    ) {
        return AdminApiFp(this.configuration)
            .internalDestroyProgram(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations for a Role, which dertermines what access and capabilities a User has
     * @param {AdminApiInternalDestroyRoleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalDestroyRole(requestParameters: AdminApiInternalDestroyRoleRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration)
            .internalDestroyRole(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations for Applications used for Machine to Machine connections
     * @param {AdminApiInternalGetApplicationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalGetApplication(
        requestParameters: AdminApiInternalGetApplicationRequest,
        options?: AxiosRequestConfig,
    ) {
        return AdminApiFp(this.configuration)
            .internalGetApplication(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations for desribing a set of permissions that may be assigned to a Role, and by extension, a User
     * @param {AdminApiInternalGetPermissionSetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalGetPermissionSet(
        requestParameters: AdminApiInternalGetPermissionSetRequest,
        options?: AxiosRequestConfig,
    ) {
        return AdminApiFp(this.configuration)
            .internalGetPermissionSet(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns relevant information about a user as well as a list of roles they currently have on the program.
     * @summary Inspect details about a program user
     * @param {AdminApiInternalGetProgramUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalGetProgramUser(
        requestParameters: AdminApiInternalGetProgramUserRequest,
        options?: AxiosRequestConfig,
    ) {
        return AdminApiFp(this.configuration)
            .internalGetProgramUser(requestParameters.id, requestParameters.program, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations for a Role, which dertermines what access and capabilities a User has
     * @param {AdminApiInternalGetRoleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalGetRole(requestParameters: AdminApiInternalGetRoleRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration)
            .internalGetRole(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations for Applications used for Machine to Machine connections
     * @param {AdminApiInternalListApplicationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalListApplication(
        requestParameters: AdminApiInternalListApplicationRequest = {},
        options?: AxiosRequestConfig,
    ) {
        return AdminApiFp(this.configuration)
            .internalListApplication(
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.ordering,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations for desribing a set of permissions that may be assigned to a Role, and by extension, a User
     * @param {AdminApiInternalListPermissionSetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalListPermissionSet(
        requestParameters: AdminApiInternalListPermissionSetRequest = {},
        options?: AxiosRequestConfig,
    ) {
        return AdminApiFp(this.configuration)
            .internalListPermissionSet(
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.ordering,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of opportunities for OSK\'s sensors, filtered for sensor, time, and area of interest  (AOI). Each opportunity is a 10-second piece of the orbit.
     * @summary List the users associated with this program
     * @param {AdminApiInternalListProgramUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalListProgramUsers(
        requestParameters: AdminApiInternalListProgramUsersRequest,
        options?: AxiosRequestConfig,
    ) {
        return AdminApiFp(this.configuration)
            .internalListProgramUsers(
                requestParameters.program,
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.ordering,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to Programs, which are sets of data or tasks used by the same group of users.
     * @param {AdminApiInternalListProgramsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalListPrograms(
        requestParameters: AdminApiInternalListProgramsRequest = {},
        options?: AxiosRequestConfig,
    ) {
        return AdminApiFp(this.configuration)
            .internalListPrograms(
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.ordering,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations for a Role, which dertermines what access and capabilities a User has
     * @param {AdminApiInternalListRolesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalListRoles(requestParameters: AdminApiInternalListRolesRequest = {}, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration)
            .internalListRoles(requestParameters.limit, requestParameters.offset, requestParameters.ordering, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations for Applications used for Machine to Machine connections
     * @param {AdminApiInternalPartialUpdateApplicationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalPartialUpdateApplication(
        requestParameters: AdminApiInternalPartialUpdateApplicationRequest,
        options?: AxiosRequestConfig,
    ) {
        return AdminApiFp(this.configuration)
            .internalPartialUpdateApplication(
                requestParameters.id,
                requestParameters.patchedApplicationRequest,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations for desribing a set of permissions that may be assigned to a Role, and by extension, a User
     * @param {AdminApiInternalPartialUpdatePermissionSetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalPartialUpdatePermissionSet(
        requestParameters: AdminApiInternalPartialUpdatePermissionSetRequest,
        options?: AxiosRequestConfig,
    ) {
        return AdminApiFp(this.configuration)
            .internalPartialUpdatePermissionSet(
                requestParameters.id,
                requestParameters.patchedPermissionSetRequest,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to Programs, which are sets of data or tasks used by the same group of users.
     * @param {AdminApiInternalPartialUpdateProgramRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalPartialUpdateProgram(
        requestParameters: AdminApiInternalPartialUpdateProgramRequest,
        options?: AxiosRequestConfig,
    ) {
        return AdminApiFp(this.configuration)
            .internalPartialUpdateProgram(requestParameters.id, requestParameters.patchedProgramRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations for a Role, which dertermines what access and capabilities a User has
     * @param {AdminApiInternalPartialUpdateRoleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalPartialUpdateRole(
        requestParameters: AdminApiInternalPartialUpdateRoleRequest,
        options?: AxiosRequestConfig,
    ) {
        return AdminApiFp(this.configuration)
            .internalPartialUpdateRole(requestParameters.id, requestParameters.patchedInternalRoleRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the user from the program by disassociating them from all program roles. This is equivalent to calling update_program_user with an empty set of roles.
     * @summary Remove a user from the program
     * @param {AdminApiInternalRemoveUserFromProgramRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalRemoveUserFromProgram(
        requestParameters: AdminApiInternalRemoveUserFromProgramRequest,
        options?: AxiosRequestConfig,
    ) {
        return AdminApiFp(this.configuration)
            .internalRemoveUserFromProgram(requestParameters.id, requestParameters.program, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations for Applications used for Machine to Machine connections
     * @param {AdminApiInternalUpdateApplicationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalUpdateApplication(
        requestParameters: AdminApiInternalUpdateApplicationRequest,
        options?: AxiosRequestConfig,
    ) {
        return AdminApiFp(this.configuration)
            .internalUpdateApplication(requestParameters.id, requestParameters.applicationRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations for desribing a set of permissions that may be assigned to a Role, and by extension, a User
     * @param {AdminApiInternalUpdatePermissionSetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalUpdatePermissionSet(
        requestParameters: AdminApiInternalUpdatePermissionSetRequest,
        options?: AxiosRequestConfig,
    ) {
        return AdminApiFp(this.configuration)
            .internalUpdatePermissionSet(requestParameters.id, requestParameters.permissionSetRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to Programs, which are sets of data or tasks used by the same group of users.
     * @param {AdminApiInternalUpdateProgramRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalUpdateProgram(
        requestParameters: AdminApiInternalUpdateProgramRequest,
        options?: AxiosRequestConfig,
    ) {
        return AdminApiFp(this.configuration)
            .internalUpdateProgram(requestParameters.id, requestParameters.programRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Atomically set the program roles for a specific user. This operation will set the user\'s roles to the list of roles passed in, if the user currently has a role which is not included in the update, it will be removed.
     * @summary Update a user\'s program roles
     * @param {AdminApiInternalUpdateProgramUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalUpdateProgramUser(
        requestParameters: AdminApiInternalUpdateProgramUserRequest,
        options?: AxiosRequestConfig,
    ) {
        return AdminApiFp(this.configuration)
            .internalUpdateProgramUser(
                requestParameters.id,
                requestParameters.program,
                requestParameters.programUserRequest,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations for a Role, which dertermines what access and capabilities a User has
     * @param {AdminApiInternalUpdateRoleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public internalUpdateRole(requestParameters: AdminApiInternalUpdateRoleRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration)
            .internalUpdateRole(requestParameters.id, requestParameters.internalRoleRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations for a Role, which dertermines what access and capabilities a User has
     * @summary List the roles associated with this program
     * @param {AdminApiListProgramRolesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public listProgramRoles(requestParameters: AdminApiListProgramRolesRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration)
            .listProgramRoles(
                requestParameters.program,
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.ordering,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of opportunities for OSK\'s sensors, filtered for sensor, time, and area of interest  (AOI). Each opportunity is a 10-second piece of the orbit.
     * @summary List the users associated with this program
     * @param {AdminApiListProgramUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public listProgramUsers(requestParameters: AdminApiListProgramUsersRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration)
            .listProgramUsers(
                requestParameters.program,
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.ordering,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to Programs, which are sets of data or tasks used by the same group of users.
     * @param {AdminApiListProgramsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public listPrograms(requestParameters: AdminApiListProgramsRequest = {}, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration)
            .listPrograms(requestParameters.limit, requestParameters.offset, requestParameters.ordering, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the user from the program by disassociating them from all program roles. This is equivalent to calling update_program_user with an empty set of roles.
     * @summary Remove a user from the program
     * @param {AdminApiRemoveUserFromProgramRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public removeUserFromProgram(
        requestParameters: AdminApiRemoveUserFromProgramRequest,
        options?: AxiosRequestConfig,
    ) {
        return AdminApiFp(this.configuration)
            .removeUserFromProgram(requestParameters.id, requestParameters.program, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {AdminApiUpdateProfileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public updateProfile(requestParameters: AdminApiUpdateProfileRequest = {}, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration)
            .updateProfile(requestParameters.userRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Atomically set the program roles for a specific user. This operation will set the user\'s roles to the list of roles passed in, if the user currently has a role which is not included in the update, it will be removed.
     * @summary Update a user\'s program roles
     * @param {AdminApiUpdateProgramUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public updateProgramUser(requestParameters: AdminApiUpdateProgramUserRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration)
            .updateProgramUser(
                requestParameters.id,
                requestParameters.program,
                requestParameters.programUserRequest,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * PipelineApi - axios parameter creator
 * @export
 */
export const PipelineApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * API operations related to data pipeline calibration data.
         * @param {DataPipelineCalibrationRequest} dataPipelineCalibrationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateCalibration: async (
            dataPipelineCalibrationRequest: DataPipelineCalibrationRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'dataPipelineCalibrationRequest' is not null or undefined
            assertParamExists(
                'internalCreateCalibration',
                'dataPipelineCalibrationRequest',
                dataPipelineCalibrationRequest,
            );
            const localVarPath = `/private/v1/pipeline/calibrations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                dataPipelineCalibrationRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {InternalCaptureRequest} internalCaptureRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateCapture: async (
            internalCaptureRequest: InternalCaptureRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'internalCaptureRequest' is not null or undefined
            assertParamExists('internalCreateCapture', 'internalCaptureRequest', internalCaptureRequest);
            const localVarPath = `/private/v1/pipeline/captures/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                internalCaptureRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to Raster Artifacts, an output from a step in the data pipeline
         * @param {RasterArtifactRequest} rasterArtifactRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateRaster: async (
            rasterArtifactRequest: RasterArtifactRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'rasterArtifactRequest' is not null or undefined
            assertParamExists('internalCreateRaster', 'rasterArtifactRequest', rasterArtifactRequest);
            const localVarPath = `/private/v1/pipeline/rasters/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                rasterArtifactRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to data pipeline calibration data.
         * @param {number} id A unique integer value identifying this data pipeline calibration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyCalibration: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalDestroyCalibration', 'id', id);
            const localVarPath = `/private/v1/pipeline/calibrations/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this capture.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyCapture: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalDestroyCapture', 'id', id);
            const localVarPath = `/private/v1/pipeline/captures/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to Raster Artifacts, an output from a step in the data pipeline
         * @param {number} id A unique integer value identifying this raster artifact.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyRaster: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalDestroyRaster', 'id', id);
            const localVarPath = `/private/v1/pipeline/rasters/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to data pipeline calibration data.
         * @param {number} id A unique integer value identifying this data pipeline calibration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetCalibration: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalGetCalibration', 'id', id);
            const localVarPath = `/private/v1/pipeline/calibrations/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this capture.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetCapture: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalGetCapture', 'id', id);
            const localVarPath = `/private/v1/pipeline/captures/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to Raster Artifacts, an output from a step in the data pipeline
         * @param {number} id A unique integer value identifying this raster artifact.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetRaster: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalGetRaster', 'id', id);
            const localVarPath = `/private/v1/pipeline/rasters/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to data pipeline calibration data.
         * @param {number} [bands]
         * @param {number} [bin]
         * @param {string} [calibrationTimeAfter]
         * @param {string} [calibrationTimeBefore]
         * @param {number} [calibrationType]
         * @param {number} [frameRate]
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [rawArn]
         * @param {number} [samples]
         * @param {number} [sensor]
         * @param {number} [validationStatus]
         * @param {string} [workingArn]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListCalibrations: async (
            bands?: number,
            bin?: number,
            calibrationTimeAfter?: string,
            calibrationTimeBefore?: string,
            calibrationType?: number,
            frameRate?: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            rawArn?: string,
            samples?: number,
            sensor?: number,
            validationStatus?: number,
            workingArn?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/private/v1/pipeline/calibrations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (bands !== undefined) {
                localVarQueryParameter['bands'] = bands;
            }

            if (bin !== undefined) {
                localVarQueryParameter['bin'] = bin;
            }

            if (calibrationTimeAfter !== undefined) {
                localVarQueryParameter['calibration_time_after'] =
                    (calibrationTimeAfter as any) instanceof Date
                        ? (calibrationTimeAfter as any).toISOString().substr(0, 10)
                        : calibrationTimeAfter;
            }

            if (calibrationTimeBefore !== undefined) {
                localVarQueryParameter['calibration_time_before'] =
                    (calibrationTimeBefore as any) instanceof Date
                        ? (calibrationTimeBefore as any).toISOString().substr(0, 10)
                        : calibrationTimeBefore;
            }

            if (calibrationType !== undefined) {
                localVarQueryParameter['calibration_type'] = calibrationType;
            }

            if (frameRate !== undefined) {
                localVarQueryParameter['frame_rate'] = frameRate;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (rawArn !== undefined) {
                localVarQueryParameter['raw_arn'] = rawArn;
            }

            if (samples !== undefined) {
                localVarQueryParameter['samples'] = samples;
            }

            if (sensor !== undefined) {
                localVarQueryParameter['sensor'] = sensor;
            }

            if (validationStatus !== undefined) {
                localVarQueryParameter['validation_status'] = validationStatus;
            }

            if (workingArn !== undefined) {
                localVarQueryParameter['working_arn'] = workingArn;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListCaptures: async (
            limit?: number,
            offset?: number,
            ordering?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/private/v1/pipeline/captures/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to Raster Artifacts, an output from a step in the data pipeline
         * @param {string} [artifactType]
         * @param {number} [capture]
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [processingLevel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListRasters: async (
            artifactType?: string,
            capture?: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            processingLevel?: number,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/private/v1/pipeline/rasters/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (artifactType !== undefined) {
                localVarQueryParameter['artifact_type'] = artifactType;
            }

            if (capture !== undefined) {
                localVarQueryParameter['capture'] = capture;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (processingLevel !== undefined) {
                localVarQueryParameter['processing_level'] = processingLevel;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to data pipeline calibration data.
         * @param {number} id A unique integer value identifying this data pipeline calibration.
         * @param {PatchedDataPipelineCalibrationRequest} [patchedDataPipelineCalibrationRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateCalibration: async (
            id: number,
            patchedDataPipelineCalibrationRequest?: PatchedDataPipelineCalibrationRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalPartialUpdateCalibration', 'id', id);
            const localVarPath = `/private/v1/pipeline/calibrations/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                patchedDataPipelineCalibrationRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this capture.
         * @param {PatchedInternalCaptureRequest} [patchedInternalCaptureRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateCapture: async (
            id: number,
            patchedInternalCaptureRequest?: PatchedInternalCaptureRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalPartialUpdateCapture', 'id', id);
            const localVarPath = `/private/v1/pipeline/captures/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                patchedInternalCaptureRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to Raster Artifacts, an output from a step in the data pipeline
         * @param {number} id A unique integer value identifying this raster artifact.
         * @param {PatchedRasterArtifactRequest} [patchedRasterArtifactRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateRaster: async (
            id: number,
            patchedRasterArtifactRequest?: PatchedRasterArtifactRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalPartialUpdateRaster', 'id', id);
            const localVarPath = `/private/v1/pipeline/rasters/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                patchedRasterArtifactRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to data pipeline calibration data.
         * @param {number} id A unique integer value identifying this data pipeline calibration.
         * @param {DataPipelineCalibrationRequest} dataPipelineCalibrationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateCalibration: async (
            id: number,
            dataPipelineCalibrationRequest: DataPipelineCalibrationRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalUpdateCalibration', 'id', id);
            // verify required parameter 'dataPipelineCalibrationRequest' is not null or undefined
            assertParamExists(
                'internalUpdateCalibration',
                'dataPipelineCalibrationRequest',
                dataPipelineCalibrationRequest,
            );
            const localVarPath = `/private/v1/pipeline/calibrations/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                dataPipelineCalibrationRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this capture.
         * @param {InternalCaptureRequest} internalCaptureRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateCapture: async (
            id: number,
            internalCaptureRequest: InternalCaptureRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalUpdateCapture', 'id', id);
            // verify required parameter 'internalCaptureRequest' is not null or undefined
            assertParamExists('internalUpdateCapture', 'internalCaptureRequest', internalCaptureRequest);
            const localVarPath = `/private/v1/pipeline/captures/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                internalCaptureRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to Raster Artifacts, an output from a step in the data pipeline
         * @param {number} id A unique integer value identifying this raster artifact.
         * @param {RasterArtifactRequest} rasterArtifactRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateRaster: async (
            id: number,
            rasterArtifactRequest: RasterArtifactRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalUpdateRaster', 'id', id);
            // verify required parameter 'rasterArtifactRequest' is not null or undefined
            assertParamExists('internalUpdateRaster', 'rasterArtifactRequest', rasterArtifactRequest);
            const localVarPath = `/private/v1/pipeline/rasters/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                rasterArtifactRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * PipelineApi - functional programming interface
 * @export
 */
export const PipelineApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = PipelineApiAxiosParamCreator(configuration);
    return {
        /**
         * API operations related to data pipeline calibration data.
         * @param {DataPipelineCalibrationRequest} dataPipelineCalibrationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCreateCalibration(
            dataPipelineCalibrationRequest: DataPipelineCalibrationRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataPipelineCalibration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCreateCalibration(
                dataPipelineCalibrationRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {InternalCaptureRequest} internalCaptureRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCreateCapture(
            internalCaptureRequest: InternalCaptureRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalCapture>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCreateCapture(
                internalCaptureRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to Raster Artifacts, an output from a step in the data pipeline
         * @param {RasterArtifactRequest} rasterArtifactRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCreateRaster(
            rasterArtifactRequest: RasterArtifactRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RasterArtifact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCreateRaster(
                rasterArtifactRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to data pipeline calibration data.
         * @param {number} id A unique integer value identifying this data pipeline calibration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalDestroyCalibration(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalDestroyCalibration(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this capture.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalDestroyCapture(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalDestroyCapture(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to Raster Artifacts, an output from a step in the data pipeline
         * @param {number} id A unique integer value identifying this raster artifact.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalDestroyRaster(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalDestroyRaster(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to data pipeline calibration data.
         * @param {number} id A unique integer value identifying this data pipeline calibration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalGetCalibration(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataPipelineCalibration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalGetCalibration(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this capture.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalGetCapture(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalCapture>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalGetCapture(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to Raster Artifacts, an output from a step in the data pipeline
         * @param {number} id A unique integer value identifying this raster artifact.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalGetRaster(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RasterArtifact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalGetRaster(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to data pipeline calibration data.
         * @param {number} [bands]
         * @param {number} [bin]
         * @param {string} [calibrationTimeAfter]
         * @param {string} [calibrationTimeBefore]
         * @param {number} [calibrationType]
         * @param {number} [frameRate]
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [rawArn]
         * @param {number} [samples]
         * @param {number} [sensor]
         * @param {number} [validationStatus]
         * @param {string} [workingArn]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalListCalibrations(
            bands?: number,
            bin?: number,
            calibrationTimeAfter?: string,
            calibrationTimeBefore?: string,
            calibrationType?: number,
            frameRate?: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            rawArn?: string,
            samples?: number,
            sensor?: number,
            validationStatus?: number,
            workingArn?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedDataPipelineCalibrationList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalListCalibrations(
                bands,
                bin,
                calibrationTimeAfter,
                calibrationTimeBefore,
                calibrationType,
                frameRate,
                limit,
                offset,
                ordering,
                rawArn,
                samples,
                sensor,
                validationStatus,
                workingArn,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalListCaptures(
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedInternalCaptureList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalListCaptures(
                limit,
                offset,
                ordering,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to Raster Artifacts, an output from a step in the data pipeline
         * @param {string} [artifactType]
         * @param {number} [capture]
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [processingLevel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalListRasters(
            artifactType?: string,
            capture?: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            processingLevel?: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedRasterArtifactList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalListRasters(
                artifactType,
                capture,
                limit,
                offset,
                ordering,
                processingLevel,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to data pipeline calibration data.
         * @param {number} id A unique integer value identifying this data pipeline calibration.
         * @param {PatchedDataPipelineCalibrationRequest} [patchedDataPipelineCalibrationRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalPartialUpdateCalibration(
            id: number,
            patchedDataPipelineCalibrationRequest?: PatchedDataPipelineCalibrationRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataPipelineCalibration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalPartialUpdateCalibration(
                id,
                patchedDataPipelineCalibrationRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this capture.
         * @param {PatchedInternalCaptureRequest} [patchedInternalCaptureRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalPartialUpdateCapture(
            id: number,
            patchedInternalCaptureRequest?: PatchedInternalCaptureRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalCapture>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalPartialUpdateCapture(
                id,
                patchedInternalCaptureRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to Raster Artifacts, an output from a step in the data pipeline
         * @param {number} id A unique integer value identifying this raster artifact.
         * @param {PatchedRasterArtifactRequest} [patchedRasterArtifactRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalPartialUpdateRaster(
            id: number,
            patchedRasterArtifactRequest?: PatchedRasterArtifactRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RasterArtifact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalPartialUpdateRaster(
                id,
                patchedRasterArtifactRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to data pipeline calibration data.
         * @param {number} id A unique integer value identifying this data pipeline calibration.
         * @param {DataPipelineCalibrationRequest} dataPipelineCalibrationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdateCalibration(
            id: number,
            dataPipelineCalibrationRequest: DataPipelineCalibrationRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataPipelineCalibration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdateCalibration(
                id,
                dataPipelineCalibrationRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this capture.
         * @param {InternalCaptureRequest} internalCaptureRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdateCapture(
            id: number,
            internalCaptureRequest: InternalCaptureRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalCapture>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdateCapture(
                id,
                internalCaptureRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to Raster Artifacts, an output from a step in the data pipeline
         * @param {number} id A unique integer value identifying this raster artifact.
         * @param {RasterArtifactRequest} rasterArtifactRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdateRaster(
            id: number,
            rasterArtifactRequest: RasterArtifactRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RasterArtifact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdateRaster(
                id,
                rasterArtifactRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * PipelineApi - factory interface
 * @export
 */
export const PipelineApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PipelineApiFp(configuration);
    return {
        /**
         * API operations related to data pipeline calibration data.
         * @param {DataPipelineCalibrationRequest} dataPipelineCalibrationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateCalibration(
            dataPipelineCalibrationRequest: DataPipelineCalibrationRequest,
            options?: any,
        ): AxiosPromise<DataPipelineCalibration> {
            return localVarFp
                .internalCreateCalibration(dataPipelineCalibrationRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {InternalCaptureRequest} internalCaptureRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateCapture(
            internalCaptureRequest: InternalCaptureRequest,
            options?: any,
        ): AxiosPromise<InternalCapture> {
            return localVarFp
                .internalCreateCapture(internalCaptureRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations related to Raster Artifacts, an output from a step in the data pipeline
         * @param {RasterArtifactRequest} rasterArtifactRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateRaster(
            rasterArtifactRequest: RasterArtifactRequest,
            options?: any,
        ): AxiosPromise<RasterArtifact> {
            return localVarFp
                .internalCreateRaster(rasterArtifactRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations related to data pipeline calibration data.
         * @param {number} id A unique integer value identifying this data pipeline calibration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyCalibration(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.internalDestroyCalibration(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this capture.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyCapture(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.internalDestroyCapture(id, options).then((request) => request(axios, basePath));
        },
        /**
         * API operations related to Raster Artifacts, an output from a step in the data pipeline
         * @param {number} id A unique integer value identifying this raster artifact.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyRaster(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.internalDestroyRaster(id, options).then((request) => request(axios, basePath));
        },
        /**
         * API operations related to data pipeline calibration data.
         * @param {number} id A unique integer value identifying this data pipeline calibration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetCalibration(id: number, options?: any): AxiosPromise<DataPipelineCalibration> {
            return localVarFp.internalGetCalibration(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this capture.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetCapture(id: number, options?: any): AxiosPromise<InternalCapture> {
            return localVarFp.internalGetCapture(id, options).then((request) => request(axios, basePath));
        },
        /**
         * API operations related to Raster Artifacts, an output from a step in the data pipeline
         * @param {number} id A unique integer value identifying this raster artifact.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetRaster(id: number, options?: any): AxiosPromise<RasterArtifact> {
            return localVarFp.internalGetRaster(id, options).then((request) => request(axios, basePath));
        },
        /**
         * API operations related to data pipeline calibration data.
         * @param {number} [bands]
         * @param {number} [bin]
         * @param {string} [calibrationTimeAfter]
         * @param {string} [calibrationTimeBefore]
         * @param {number} [calibrationType]
         * @param {number} [frameRate]
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [rawArn]
         * @param {number} [samples]
         * @param {number} [sensor]
         * @param {number} [validationStatus]
         * @param {string} [workingArn]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListCalibrations(
            bands?: number,
            bin?: number,
            calibrationTimeAfter?: string,
            calibrationTimeBefore?: string,
            calibrationType?: number,
            frameRate?: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            rawArn?: string,
            samples?: number,
            sensor?: number,
            validationStatus?: number,
            workingArn?: string,
            options?: any,
        ): AxiosPromise<PaginatedDataPipelineCalibrationList> {
            return localVarFp
                .internalListCalibrations(
                    bands,
                    bin,
                    calibrationTimeAfter,
                    calibrationTimeBefore,
                    calibrationType,
                    frameRate,
                    limit,
                    offset,
                    ordering,
                    rawArn,
                    samples,
                    sensor,
                    validationStatus,
                    workingArn,
                    options,
                )
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListCaptures(
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: any,
        ): AxiosPromise<PaginatedInternalCaptureList> {
            return localVarFp
                .internalListCaptures(limit, offset, ordering, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations related to Raster Artifacts, an output from a step in the data pipeline
         * @param {string} [artifactType]
         * @param {number} [capture]
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [processingLevel]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListRasters(
            artifactType?: string,
            capture?: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            processingLevel?: number,
            options?: any,
        ): AxiosPromise<PaginatedRasterArtifactList> {
            return localVarFp
                .internalListRasters(artifactType, capture, limit, offset, ordering, processingLevel, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations related to data pipeline calibration data.
         * @param {number} id A unique integer value identifying this data pipeline calibration.
         * @param {PatchedDataPipelineCalibrationRequest} [patchedDataPipelineCalibrationRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateCalibration(
            id: number,
            patchedDataPipelineCalibrationRequest?: PatchedDataPipelineCalibrationRequest,
            options?: any,
        ): AxiosPromise<DataPipelineCalibration> {
            return localVarFp
                .internalPartialUpdateCalibration(id, patchedDataPipelineCalibrationRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this capture.
         * @param {PatchedInternalCaptureRequest} [patchedInternalCaptureRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateCapture(
            id: number,
            patchedInternalCaptureRequest?: PatchedInternalCaptureRequest,
            options?: any,
        ): AxiosPromise<InternalCapture> {
            return localVarFp
                .internalPartialUpdateCapture(id, patchedInternalCaptureRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations related to Raster Artifacts, an output from a step in the data pipeline
         * @param {number} id A unique integer value identifying this raster artifact.
         * @param {PatchedRasterArtifactRequest} [patchedRasterArtifactRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateRaster(
            id: number,
            patchedRasterArtifactRequest?: PatchedRasterArtifactRequest,
            options?: any,
        ): AxiosPromise<RasterArtifact> {
            return localVarFp
                .internalPartialUpdateRaster(id, patchedRasterArtifactRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations related to data pipeline calibration data.
         * @param {number} id A unique integer value identifying this data pipeline calibration.
         * @param {DataPipelineCalibrationRequest} dataPipelineCalibrationRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateCalibration(
            id: number,
            dataPipelineCalibrationRequest: DataPipelineCalibrationRequest,
            options?: any,
        ): AxiosPromise<DataPipelineCalibration> {
            return localVarFp
                .internalUpdateCalibration(id, dataPipelineCalibrationRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this capture.
         * @param {InternalCaptureRequest} internalCaptureRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateCapture(
            id: number,
            internalCaptureRequest: InternalCaptureRequest,
            options?: any,
        ): AxiosPromise<InternalCapture> {
            return localVarFp
                .internalUpdateCapture(id, internalCaptureRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations related to Raster Artifacts, an output from a step in the data pipeline
         * @param {number} id A unique integer value identifying this raster artifact.
         * @param {RasterArtifactRequest} rasterArtifactRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateRaster(
            id: number,
            rasterArtifactRequest: RasterArtifactRequest,
            options?: any,
        ): AxiosPromise<RasterArtifact> {
            return localVarFp
                .internalUpdateRaster(id, rasterArtifactRequest, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for internalCreateCalibration operation in PipelineApi.
 * @export
 * @interface PipelineApiInternalCreateCalibrationRequest
 */
export interface PipelineApiInternalCreateCalibrationRequest {
    /**
     *
     * @type {DataPipelineCalibrationRequest}
     * @memberof PipelineApiInternalCreateCalibration
     */
    readonly dataPipelineCalibrationRequest: DataPipelineCalibrationRequest;
}

/**
 * Request parameters for internalCreateCapture operation in PipelineApi.
 * @export
 * @interface PipelineApiInternalCreateCaptureRequest
 */
export interface PipelineApiInternalCreateCaptureRequest {
    /**
     *
     * @type {InternalCaptureRequest}
     * @memberof PipelineApiInternalCreateCapture
     */
    readonly internalCaptureRequest: InternalCaptureRequest;
}

/**
 * Request parameters for internalCreateRaster operation in PipelineApi.
 * @export
 * @interface PipelineApiInternalCreateRasterRequest
 */
export interface PipelineApiInternalCreateRasterRequest {
    /**
     *
     * @type {RasterArtifactRequest}
     * @memberof PipelineApiInternalCreateRaster
     */
    readonly rasterArtifactRequest: RasterArtifactRequest;
}

/**
 * Request parameters for internalDestroyCalibration operation in PipelineApi.
 * @export
 * @interface PipelineApiInternalDestroyCalibrationRequest
 */
export interface PipelineApiInternalDestroyCalibrationRequest {
    /**
     * A unique integer value identifying this data pipeline calibration.
     * @type {number}
     * @memberof PipelineApiInternalDestroyCalibration
     */
    readonly id: number;
}

/**
 * Request parameters for internalDestroyCapture operation in PipelineApi.
 * @export
 * @interface PipelineApiInternalDestroyCaptureRequest
 */
export interface PipelineApiInternalDestroyCaptureRequest {
    /**
     * A unique integer value identifying this capture.
     * @type {number}
     * @memberof PipelineApiInternalDestroyCapture
     */
    readonly id: number;
}

/**
 * Request parameters for internalDestroyRaster operation in PipelineApi.
 * @export
 * @interface PipelineApiInternalDestroyRasterRequest
 */
export interface PipelineApiInternalDestroyRasterRequest {
    /**
     * A unique integer value identifying this raster artifact.
     * @type {number}
     * @memberof PipelineApiInternalDestroyRaster
     */
    readonly id: number;
}

/**
 * Request parameters for internalGetCalibration operation in PipelineApi.
 * @export
 * @interface PipelineApiInternalGetCalibrationRequest
 */
export interface PipelineApiInternalGetCalibrationRequest {
    /**
     * A unique integer value identifying this data pipeline calibration.
     * @type {number}
     * @memberof PipelineApiInternalGetCalibration
     */
    readonly id: number;
}

/**
 * Request parameters for internalGetCapture operation in PipelineApi.
 * @export
 * @interface PipelineApiInternalGetCaptureRequest
 */
export interface PipelineApiInternalGetCaptureRequest {
    /**
     * A unique integer value identifying this capture.
     * @type {number}
     * @memberof PipelineApiInternalGetCapture
     */
    readonly id: number;
}

/**
 * Request parameters for internalGetRaster operation in PipelineApi.
 * @export
 * @interface PipelineApiInternalGetRasterRequest
 */
export interface PipelineApiInternalGetRasterRequest {
    /**
     * A unique integer value identifying this raster artifact.
     * @type {number}
     * @memberof PipelineApiInternalGetRaster
     */
    readonly id: number;
}

/**
 * Request parameters for internalListCalibrations operation in PipelineApi.
 * @export
 * @interface PipelineApiInternalListCalibrationsRequest
 */
export interface PipelineApiInternalListCalibrationsRequest {
    /**
     *
     * @type {number}
     * @memberof PipelineApiInternalListCalibrations
     */
    readonly bands?: number;

    /**
     *
     * @type {number}
     * @memberof PipelineApiInternalListCalibrations
     */
    readonly bin?: number;

    /**
     *
     * @type {string}
     * @memberof PipelineApiInternalListCalibrations
     */
    readonly calibrationTimeAfter?: string;

    /**
     *
     * @type {string}
     * @memberof PipelineApiInternalListCalibrations
     */
    readonly calibrationTimeBefore?: string;

    /**
     *
     * @type {number}
     * @memberof PipelineApiInternalListCalibrations
     */
    readonly calibrationType?: number;

    /**
     *
     * @type {number}
     * @memberof PipelineApiInternalListCalibrations
     */
    readonly frameRate?: number;

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof PipelineApiInternalListCalibrations
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof PipelineApiInternalListCalibrations
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof PipelineApiInternalListCalibrations
     */
    readonly ordering?: string;

    /**
     *
     * @type {string}
     * @memberof PipelineApiInternalListCalibrations
     */
    readonly rawArn?: string;

    /**
     *
     * @type {number}
     * @memberof PipelineApiInternalListCalibrations
     */
    readonly samples?: number;

    /**
     *
     * @type {number}
     * @memberof PipelineApiInternalListCalibrations
     */
    readonly sensor?: number;

    /**
     *
     * @type {number}
     * @memberof PipelineApiInternalListCalibrations
     */
    readonly validationStatus?: number;

    /**
     *
     * @type {string}
     * @memberof PipelineApiInternalListCalibrations
     */
    readonly workingArn?: string;
}

/**
 * Request parameters for internalListCaptures operation in PipelineApi.
 * @export
 * @interface PipelineApiInternalListCapturesRequest
 */
export interface PipelineApiInternalListCapturesRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof PipelineApiInternalListCaptures
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof PipelineApiInternalListCaptures
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof PipelineApiInternalListCaptures
     */
    readonly ordering?: string;
}

/**
 * Request parameters for internalListRasters operation in PipelineApi.
 * @export
 * @interface PipelineApiInternalListRastersRequest
 */
export interface PipelineApiInternalListRastersRequest {
    /**
     *
     * @type {string}
     * @memberof PipelineApiInternalListRasters
     */
    readonly artifactType?: string;

    /**
     *
     * @type {number}
     * @memberof PipelineApiInternalListRasters
     */
    readonly capture?: number;

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof PipelineApiInternalListRasters
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof PipelineApiInternalListRasters
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof PipelineApiInternalListRasters
     */
    readonly ordering?: string;

    /**
     *
     * @type {number}
     * @memberof PipelineApiInternalListRasters
     */
    readonly processingLevel?: number;
}

/**
 * Request parameters for internalPartialUpdateCalibration operation in PipelineApi.
 * @export
 * @interface PipelineApiInternalPartialUpdateCalibrationRequest
 */
export interface PipelineApiInternalPartialUpdateCalibrationRequest {
    /**
     * A unique integer value identifying this data pipeline calibration.
     * @type {number}
     * @memberof PipelineApiInternalPartialUpdateCalibration
     */
    readonly id: number;

    /**
     *
     * @type {PatchedDataPipelineCalibrationRequest}
     * @memberof PipelineApiInternalPartialUpdateCalibration
     */
    readonly patchedDataPipelineCalibrationRequest?: PatchedDataPipelineCalibrationRequest;
}

/**
 * Request parameters for internalPartialUpdateCapture operation in PipelineApi.
 * @export
 * @interface PipelineApiInternalPartialUpdateCaptureRequest
 */
export interface PipelineApiInternalPartialUpdateCaptureRequest {
    /**
     * A unique integer value identifying this capture.
     * @type {number}
     * @memberof PipelineApiInternalPartialUpdateCapture
     */
    readonly id: number;

    /**
     *
     * @type {PatchedInternalCaptureRequest}
     * @memberof PipelineApiInternalPartialUpdateCapture
     */
    readonly patchedInternalCaptureRequest?: PatchedInternalCaptureRequest;
}

/**
 * Request parameters for internalPartialUpdateRaster operation in PipelineApi.
 * @export
 * @interface PipelineApiInternalPartialUpdateRasterRequest
 */
export interface PipelineApiInternalPartialUpdateRasterRequest {
    /**
     * A unique integer value identifying this raster artifact.
     * @type {number}
     * @memberof PipelineApiInternalPartialUpdateRaster
     */
    readonly id: number;

    /**
     *
     * @type {PatchedRasterArtifactRequest}
     * @memberof PipelineApiInternalPartialUpdateRaster
     */
    readonly patchedRasterArtifactRequest?: PatchedRasterArtifactRequest;
}

/**
 * Request parameters for internalUpdateCalibration operation in PipelineApi.
 * @export
 * @interface PipelineApiInternalUpdateCalibrationRequest
 */
export interface PipelineApiInternalUpdateCalibrationRequest {
    /**
     * A unique integer value identifying this data pipeline calibration.
     * @type {number}
     * @memberof PipelineApiInternalUpdateCalibration
     */
    readonly id: number;

    /**
     *
     * @type {DataPipelineCalibrationRequest}
     * @memberof PipelineApiInternalUpdateCalibration
     */
    readonly dataPipelineCalibrationRequest: DataPipelineCalibrationRequest;
}

/**
 * Request parameters for internalUpdateCapture operation in PipelineApi.
 * @export
 * @interface PipelineApiInternalUpdateCaptureRequest
 */
export interface PipelineApiInternalUpdateCaptureRequest {
    /**
     * A unique integer value identifying this capture.
     * @type {number}
     * @memberof PipelineApiInternalUpdateCapture
     */
    readonly id: number;

    /**
     *
     * @type {InternalCaptureRequest}
     * @memberof PipelineApiInternalUpdateCapture
     */
    readonly internalCaptureRequest: InternalCaptureRequest;
}

/**
 * Request parameters for internalUpdateRaster operation in PipelineApi.
 * @export
 * @interface PipelineApiInternalUpdateRasterRequest
 */
export interface PipelineApiInternalUpdateRasterRequest {
    /**
     * A unique integer value identifying this raster artifact.
     * @type {number}
     * @memberof PipelineApiInternalUpdateRaster
     */
    readonly id: number;

    /**
     *
     * @type {RasterArtifactRequest}
     * @memberof PipelineApiInternalUpdateRaster
     */
    readonly rasterArtifactRequest: RasterArtifactRequest;
}

/**
 * PipelineApi - object-oriented interface
 * @export
 * @class PipelineApi
 * @extends {BaseAPI}
 */
export class PipelineApi extends BaseAPI {
    /**
     * API operations related to data pipeline calibration data.
     * @param {PipelineApiInternalCreateCalibrationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelineApi
     */
    public internalCreateCalibration(
        requestParameters: PipelineApiInternalCreateCalibrationRequest,
        options?: AxiosRequestConfig,
    ) {
        return PipelineApiFp(this.configuration)
            .internalCreateCalibration(requestParameters.dataPipelineCalibrationRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {PipelineApiInternalCreateCaptureRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelineApi
     */
    public internalCreateCapture(
        requestParameters: PipelineApiInternalCreateCaptureRequest,
        options?: AxiosRequestConfig,
    ) {
        return PipelineApiFp(this.configuration)
            .internalCreateCapture(requestParameters.internalCaptureRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to Raster Artifacts, an output from a step in the data pipeline
     * @param {PipelineApiInternalCreateRasterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelineApi
     */
    public internalCreateRaster(
        requestParameters: PipelineApiInternalCreateRasterRequest,
        options?: AxiosRequestConfig,
    ) {
        return PipelineApiFp(this.configuration)
            .internalCreateRaster(requestParameters.rasterArtifactRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to data pipeline calibration data.
     * @param {PipelineApiInternalDestroyCalibrationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelineApi
     */
    public internalDestroyCalibration(
        requestParameters: PipelineApiInternalDestroyCalibrationRequest,
        options?: AxiosRequestConfig,
    ) {
        return PipelineApiFp(this.configuration)
            .internalDestroyCalibration(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {PipelineApiInternalDestroyCaptureRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelineApi
     */
    public internalDestroyCapture(
        requestParameters: PipelineApiInternalDestroyCaptureRequest,
        options?: AxiosRequestConfig,
    ) {
        return PipelineApiFp(this.configuration)
            .internalDestroyCapture(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to Raster Artifacts, an output from a step in the data pipeline
     * @param {PipelineApiInternalDestroyRasterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelineApi
     */
    public internalDestroyRaster(
        requestParameters: PipelineApiInternalDestroyRasterRequest,
        options?: AxiosRequestConfig,
    ) {
        return PipelineApiFp(this.configuration)
            .internalDestroyRaster(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to data pipeline calibration data.
     * @param {PipelineApiInternalGetCalibrationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelineApi
     */
    public internalGetCalibration(
        requestParameters: PipelineApiInternalGetCalibrationRequest,
        options?: AxiosRequestConfig,
    ) {
        return PipelineApiFp(this.configuration)
            .internalGetCalibration(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {PipelineApiInternalGetCaptureRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelineApi
     */
    public internalGetCapture(requestParameters: PipelineApiInternalGetCaptureRequest, options?: AxiosRequestConfig) {
        return PipelineApiFp(this.configuration)
            .internalGetCapture(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to Raster Artifacts, an output from a step in the data pipeline
     * @param {PipelineApiInternalGetRasterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelineApi
     */
    public internalGetRaster(requestParameters: PipelineApiInternalGetRasterRequest, options?: AxiosRequestConfig) {
        return PipelineApiFp(this.configuration)
            .internalGetRaster(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to data pipeline calibration data.
     * @param {PipelineApiInternalListCalibrationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelineApi
     */
    public internalListCalibrations(
        requestParameters: PipelineApiInternalListCalibrationsRequest = {},
        options?: AxiosRequestConfig,
    ) {
        return PipelineApiFp(this.configuration)
            .internalListCalibrations(
                requestParameters.bands,
                requestParameters.bin,
                requestParameters.calibrationTimeAfter,
                requestParameters.calibrationTimeBefore,
                requestParameters.calibrationType,
                requestParameters.frameRate,
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.ordering,
                requestParameters.rawArn,
                requestParameters.samples,
                requestParameters.sensor,
                requestParameters.validationStatus,
                requestParameters.workingArn,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {PipelineApiInternalListCapturesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelineApi
     */
    public internalListCaptures(
        requestParameters: PipelineApiInternalListCapturesRequest = {},
        options?: AxiosRequestConfig,
    ) {
        return PipelineApiFp(this.configuration)
            .internalListCaptures(
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.ordering,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to Raster Artifacts, an output from a step in the data pipeline
     * @param {PipelineApiInternalListRastersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelineApi
     */
    public internalListRasters(
        requestParameters: PipelineApiInternalListRastersRequest = {},
        options?: AxiosRequestConfig,
    ) {
        return PipelineApiFp(this.configuration)
            .internalListRasters(
                requestParameters.artifactType,
                requestParameters.capture,
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.ordering,
                requestParameters.processingLevel,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to data pipeline calibration data.
     * @param {PipelineApiInternalPartialUpdateCalibrationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelineApi
     */
    public internalPartialUpdateCalibration(
        requestParameters: PipelineApiInternalPartialUpdateCalibrationRequest,
        options?: AxiosRequestConfig,
    ) {
        return PipelineApiFp(this.configuration)
            .internalPartialUpdateCalibration(
                requestParameters.id,
                requestParameters.patchedDataPipelineCalibrationRequest,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {PipelineApiInternalPartialUpdateCaptureRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelineApi
     */
    public internalPartialUpdateCapture(
        requestParameters: PipelineApiInternalPartialUpdateCaptureRequest,
        options?: AxiosRequestConfig,
    ) {
        return PipelineApiFp(this.configuration)
            .internalPartialUpdateCapture(
                requestParameters.id,
                requestParameters.patchedInternalCaptureRequest,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to Raster Artifacts, an output from a step in the data pipeline
     * @param {PipelineApiInternalPartialUpdateRasterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelineApi
     */
    public internalPartialUpdateRaster(
        requestParameters: PipelineApiInternalPartialUpdateRasterRequest,
        options?: AxiosRequestConfig,
    ) {
        return PipelineApiFp(this.configuration)
            .internalPartialUpdateRaster(requestParameters.id, requestParameters.patchedRasterArtifactRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to data pipeline calibration data.
     * @param {PipelineApiInternalUpdateCalibrationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelineApi
     */
    public internalUpdateCalibration(
        requestParameters: PipelineApiInternalUpdateCalibrationRequest,
        options?: AxiosRequestConfig,
    ) {
        return PipelineApiFp(this.configuration)
            .internalUpdateCalibration(requestParameters.id, requestParameters.dataPipelineCalibrationRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {PipelineApiInternalUpdateCaptureRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelineApi
     */
    public internalUpdateCapture(
        requestParameters: PipelineApiInternalUpdateCaptureRequest,
        options?: AxiosRequestConfig,
    ) {
        return PipelineApiFp(this.configuration)
            .internalUpdateCapture(requestParameters.id, requestParameters.internalCaptureRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to Raster Artifacts, an output from a step in the data pipeline
     * @param {PipelineApiInternalUpdateRasterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelineApi
     */
    public internalUpdateRaster(
        requestParameters: PipelineApiInternalUpdateRasterRequest,
        options?: AxiosRequestConfig,
    ) {
        return PipelineApiFp(this.configuration)
            .internalUpdateRaster(requestParameters.id, requestParameters.rasterArtifactRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * SigmaApi - axios parameter creator
 * @export
 */
export const SigmaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * API operations related to Download Requests, a set of raster images that has been requested by a user
         * @param {number} program
         * @param {DownloadRequestRequest} downloadRequestRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDownload: async (
            program: number,
            downloadRequestRequest: DownloadRequestRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'program' is not null or undefined
            assertParamExists('createDownload', 'program', program);
            // verify required parameter 'downloadRequestRequest' is not null or undefined
            assertParamExists('createDownload', 'downloadRequestRequest', downloadRequestRequest);
            const localVarPath = `/public/v1/sigma/programs/{program}/downloads/`.replace(
                `{${'program'}}`,
                encodeURIComponent(String(program)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                downloadRequestRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} program
         * @param {PublicPlanRequest} publicPlanRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPlan: async (
            program: number,
            publicPlanRequest: PublicPlanRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'program' is not null or undefined
            assertParamExists('createPlan', 'program', program);
            // verify required parameter 'publicPlanRequest' is not null or undefined
            assertParamExists('createPlan', 'publicPlanRequest', publicPlanRequest);
            const localVarPath = `/public/v1/sigma/programs/{program}/plans/`.replace(
                `{${'program'}}`,
                encodeURIComponent(String(program)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                publicPlanRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} program
         * @param {ReportRequest} reportRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReport: async (
            program: number,
            reportRequest: ReportRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'program' is not null or undefined
            assertParamExists('createReport', 'program', program);
            // verify required parameter 'reportRequest' is not null or undefined
            assertParamExists('createReport', 'reportRequest', reportRequest);
            const localVarPath = `/public/v1/sigma/programs/{program}/reports/`.replace(
                `{${'program'}}`,
                encodeURIComponent(String(program)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(reportRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to user-created areas of interest.
         * @param {SearchAreaRequest} searchAreaRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSearchArea: async (
            searchAreaRequest: SearchAreaRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'searchAreaRequest' is not null or undefined
            assertParamExists('createSearchArea', 'searchAreaRequest', searchAreaRequest);
            const localVarPath = `/public/v1/sigma/search_areas/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                searchAreaRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this report.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyReport: async (id: number, program: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('destroyReport', 'id', id);
            // verify required parameter 'program' is not null or undefined
            assertParamExists('destroyReport', 'program', program);
            const localVarPath = `/public/v1/sigma/programs/{program}/reports/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'program'}}`, encodeURIComponent(String(program)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to user-created areas of interest.
         * @param {number} id A unique integer value identifying this search area.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroySearchArea: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('destroySearchArea', 'id', id);
            const localVarPath = `/public/v1/sigma/search_areas/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this asset.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAsset: async (id: number, program: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAsset', 'id', id);
            // verify required parameter 'program' is not null or undefined
            assertParamExists('getAsset', 'program', program);
            const localVarPath = `/public/v1/sigma/programs/{program}/assets/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'program'}}`, encodeURIComponent(String(program)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to data pipeline results, the final output of the data pipeline of a single file
         * @param {number} id A unique integer value identifying this capture.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCapture: async (id: number, program: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCapture', 'id', id);
            // verify required parameter 'program' is not null or undefined
            assertParamExists('getCapture', 'program', program);
            const localVarPath = `/public/v1/sigma/programs/{program}/captures/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'program'}}`, encodeURIComponent(String(program)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetection: async (id: string, program: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDetection', 'id', id);
            // verify required parameter 'program' is not null or undefined
            assertParamExists('getDetection', 'program', program);
            const localVarPath = `/public/v1/sigma/programs/{program}/detections/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'program'}}`, encodeURIComponent(String(program)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to Download Requests, a set of raster images that has been requested by a user
         * @param {number} id A unique integer value identifying this download request.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownload: async (id: number, program: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDownload', 'id', id);
            // verify required parameter 'program' is not null or undefined
            assertParamExists('getDownload', 'program', program);
            const localVarPath = `/public/v1/sigma/programs/{program}/downloads/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'program'}}`, encodeURIComponent(String(program)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this plan.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlan: async (id: number, program: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPlan', 'id', id);
            // verify required parameter 'program' is not null or undefined
            assertParamExists('getPlan', 'program', program);
            const localVarPath = `/public/v1/sigma/programs/{program}/plans/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'program'}}`, encodeURIComponent(String(program)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this report.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReport: async (id: number, program: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReport', 'id', id);
            // verify required parameter 'program' is not null or undefined
            assertParamExists('getReport', 'program', program);
            const localVarPath = `/public/v1/sigma/programs/{program}/reports/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'program'}}`, encodeURIComponent(String(program)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to user-created areas of interest.
         * @param {number} id A unique integer value identifying this search area.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchArea: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSearchArea', 'id', id);
            const localVarPath = `/public/v1/sigma/search_areas/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to sensors. Supports create, read, update, and delete
         * @param {number} oskId A unique value identifying this sensor.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSensor: async (oskId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'oskId' is not null or undefined
            assertParamExists('getSensor', 'oskId', oskId);
            const localVarPath = `/public/v1/sigma/sensors/{osk_id}/`.replace(
                `{${'osk_id'}}`,
                encodeURIComponent(String(oskId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method will return the most recently saved StateVector for sensor_id=pk
         * @param {number} oskId A unique value identifying this sensor.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStateVector: async (oskId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'oskId' is not null or undefined
            assertParamExists('getStateVector', 'oskId', oskId);
            const localVarPath = `/public/v1/sigma/sensors/{osk_id}/state_vector/`.replace(
                `{${'osk_id'}}`,
                encodeURIComponent(String(oskId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method will return the most recently saved TLE for sensor_id=pk
         * @param {number} oskId A unique value identifying this sensor.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTle: async (oskId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'oskId' is not null or undefined
            assertParamExists('getTle', 'oskId', oskId);
            const localVarPath = `/public/v1/sigma/sensors/{osk_id}/tle/`.replace(
                `{${'osk_id'}}`,
                encodeURIComponent(String(oskId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {ProgramAOIRequest} programAOIRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateAoi: async (
            programAOIRequest: ProgramAOIRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'programAOIRequest' is not null or undefined
            assertParamExists('internalCreateAoi', 'programAOIRequest', programAOIRequest);
            const localVarPath = `/private/v1/sigma/aois/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                programAOIRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {PrivateAssetRequest} privateAssetRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateAsset: async (
            privateAssetRequest: PrivateAssetRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'privateAssetRequest' is not null or undefined
            assertParamExists('internalCreateAsset', 'privateAssetRequest', privateAssetRequest);
            const localVarPath = `/private/v1/sigma/assets/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                privateAssetRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {InternalDetectionRequest} internalDetectionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateDetection: async (
            internalDetectionRequest: InternalDetectionRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'internalDetectionRequest' is not null or undefined
            assertParamExists('internalCreateDetection', 'internalDetectionRequest', internalDetectionRequest);
            const localVarPath = `/private/v1/sigma/detections/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                internalDetectionRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {DetectionArtifactRequest} detectionArtifactRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateDetectionArtifact: async (
            detectionArtifactRequest: DetectionArtifactRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'detectionArtifactRequest' is not null or undefined
            assertParamExists('internalCreateDetectionArtifact', 'detectionArtifactRequest', detectionArtifactRequest);
            const localVarPath = `/private/v1/sigma/detection_artifacts/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                detectionArtifactRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to user-created areas of interest.
         * @param {OpportunityRequest} opportunityRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateOrbit: async (
            opportunityRequest: OpportunityRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'opportunityRequest' is not null or undefined
            assertParamExists('internalCreateOrbit', 'opportunityRequest', opportunityRequest);
            const localVarPath = `/private/v1/sigma/orbit/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                opportunityRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {PrivatePlanRequest} privatePlanRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreatePlan: async (
            privatePlanRequest: PrivatePlanRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'privatePlanRequest' is not null or undefined
            assertParamExists('internalCreatePlan', 'privatePlanRequest', privatePlanRequest);
            const localVarPath = `/private/v1/sigma/plans/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                privatePlanRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {InternalReportRequest} internalReportRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateReport: async (
            internalReportRequest: InternalReportRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'internalReportRequest' is not null or undefined
            assertParamExists('internalCreateReport', 'internalReportRequest', internalReportRequest);
            const localVarPath = `/private/v1/sigma/reports/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                internalReportRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to sensors. Supports create, read, update, and delete
         * @param {SensorRequest} sensorRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateSensor: async (
            sensorRequest: SensorRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'sensorRequest' is not null or undefined
            assertParamExists('internalCreateSensor', 'sensorRequest', sensorRequest);
            const localVarPath = `/private/v1/sigma/sensors/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(sensorRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} sensorPk
         * @param {StateVectorRequest} stateVectorRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateStateVector: async (
            sensorPk: number,
            stateVectorRequest: StateVectorRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'sensorPk' is not null or undefined
            assertParamExists('internalCreateStateVector', 'sensorPk', sensorPk);
            // verify required parameter 'stateVectorRequest' is not null or undefined
            assertParamExists('internalCreateStateVector', 'stateVectorRequest', stateVectorRequest);
            const localVarPath = `/private/v1/sigma/sensors/{sensor_pk}/state_vectors/`.replace(
                `{${'sensor_pk'}}`,
                encodeURIComponent(String(sensorPk)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                stateVectorRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} planPk
         * @param {PlanTaskRequest} planTaskRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateTask: async (
            planPk: number,
            planTaskRequest: PlanTaskRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'planPk' is not null or undefined
            assertParamExists('internalCreateTask', 'planPk', planPk);
            // verify required parameter 'planTaskRequest' is not null or undefined
            assertParamExists('internalCreateTask', 'planTaskRequest', planTaskRequest);
            const localVarPath = `/private/v1/sigma/plans/{plan_pk}/tasks/`.replace(
                `{${'plan_pk'}}`,
                encodeURIComponent(String(planPk)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(planTaskRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} sensorPk
         * @param {TLERequest} tLERequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateTle: async (
            sensorPk: number,
            tLERequest: TLERequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'sensorPk' is not null or undefined
            assertParamExists('internalCreateTle', 'sensorPk', sensorPk);
            // verify required parameter 'tLERequest' is not null or undefined
            assertParamExists('internalCreateTle', 'tLERequest', tLERequest);
            const localVarPath = `/private/v1/sigma/sensors/{sensor_pk}/tles/`.replace(
                `{${'sensor_pk'}}`,
                encodeURIComponent(String(sensorPk)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(tLERequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this program aoi.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyAoi: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalDestroyAoi', 'id', id);
            const localVarPath = `/private/v1/sigma/aois/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyAsset: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalDestroyAsset', 'id', id);
            const localVarPath = `/private/v1/sigma/assets/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} pkId A unique integer value identifying this detection.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyDetection: async (pkId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pkId' is not null or undefined
            assertParamExists('internalDestroyDetection', 'pkId', pkId);
            const localVarPath = `/private/v1/sigma/detections/{pk_id}/`.replace(
                `{${'pk_id'}}`,
                encodeURIComponent(String(pkId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this detection artifact.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyDetectionArtifact: async (
            id: number,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalDestroyDetectionArtifact', 'id', id);
            const localVarPath = `/private/v1/sigma/detection_artifacts/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to Download Requests, a set of raster images that has been requested by a user
         * @param {number} id A unique integer value identifying this download request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyDownload: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalDestroyDownload', 'id', id);
            const localVarPath = `/private/v1/sigma/downloads/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to user-created areas of interest.
         * @param {number} id A unique integer value identifying this tick.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyOrbit: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalDestroyOrbit', 'id', id);
            const localVarPath = `/private/v1/sigma/orbit/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this plan.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyPlan: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalDestroyPlan', 'id', id);
            const localVarPath = `/private/v1/sigma/plans/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this report.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyReport: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalDestroyReport', 'id', id);
            const localVarPath = `/private/v1/sigma/reports/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to sensors. Supports create, read, update, and delete
         * @param {number} oskId A unique value identifying this sensor.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroySensor: async (oskId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'oskId' is not null or undefined
            assertParamExists('internalDestroySensor', 'oskId', oskId);
            const localVarPath = `/private/v1/sigma/sensors/{osk_id}/`.replace(
                `{${'osk_id'}}`,
                encodeURIComponent(String(oskId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this state vector.
         * @param {number} sensorPk
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyStateVector: async (
            id: number,
            sensorPk: number,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalDestroyStateVector', 'id', id);
            // verify required parameter 'sensorPk' is not null or undefined
            assertParamExists('internalDestroyStateVector', 'sensorPk', sensorPk);
            const localVarPath = `/private/v1/sigma/sensors/{sensor_pk}/state_vectors/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'sensor_pk'}}`, encodeURIComponent(String(sensorPk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this plan task.
         * @param {number} planPk
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyTask: async (
            id: number,
            planPk: number,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalDestroyTask', 'id', id);
            // verify required parameter 'planPk' is not null or undefined
            assertParamExists('internalDestroyTask', 'planPk', planPk);
            const localVarPath = `/private/v1/sigma/plans/{plan_pk}/tasks/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'plan_pk'}}`, encodeURIComponent(String(planPk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this tle.
         * @param {number} sensorPk
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyTle: async (
            id: number,
            sensorPk: number,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalDestroyTle', 'id', id);
            // verify required parameter 'sensorPk' is not null or undefined
            assertParamExists('internalDestroyTle', 'sensorPk', sensorPk);
            const localVarPath = `/private/v1/sigma/sensors/{sensor_pk}/tles/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'sensor_pk'}}`, encodeURIComponent(String(sensorPk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this program aoi.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetAoi: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalGetAoi', 'id', id);
            const localVarPath = `/private/v1/sigma/aois/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetAsset: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalGetAsset', 'id', id);
            const localVarPath = `/private/v1/sigma/assets/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} pkId A unique integer value identifying this detection.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetDetection: async (pkId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pkId' is not null or undefined
            assertParamExists('internalGetDetection', 'pkId', pkId);
            const localVarPath = `/private/v1/sigma/detections/{pk_id}/`.replace(
                `{${'pk_id'}}`,
                encodeURIComponent(String(pkId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to user-created areas of interest.
         * @param {number} id A unique integer value identifying this tick.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetOrbit: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalGetOrbit', 'id', id);
            const localVarPath = `/private/v1/sigma/orbit/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this plan.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetPlan: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalGetPlan', 'id', id);
            const localVarPath = `/private/v1/sigma/plans/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this report.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetReport: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalGetReport', 'id', id);
            const localVarPath = `/private/v1/sigma/reports/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this state vector.
         * @param {number} sensorPk
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetStateVector: async (
            id: number,
            sensorPk: number,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalGetStateVector', 'id', id);
            // verify required parameter 'sensorPk' is not null or undefined
            assertParamExists('internalGetStateVector', 'sensorPk', sensorPk);
            const localVarPath = `/private/v1/sigma/sensors/{sensor_pk}/state_vectors/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'sensor_pk'}}`, encodeURIComponent(String(sensorPk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this plan task.
         * @param {number} planPk
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetTask: async (id: number, planPk: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalGetTask', 'id', id);
            // verify required parameter 'planPk' is not null or undefined
            assertParamExists('internalGetTask', 'planPk', planPk);
            const localVarPath = `/private/v1/sigma/plans/{plan_pk}/tasks/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'plan_pk'}}`, encodeURIComponent(String(planPk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this tle.
         * @param {number} sensorPk
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetTle: async (
            id: number,
            sensorPk: number,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalGetTle', 'id', id);
            // verify required parameter 'sensorPk' is not null or undefined
            assertParamExists('internalGetTle', 'sensorPk', sensorPk);
            const localVarPath = `/private/v1/sigma/sensors/{sensor_pk}/tles/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'sensor_pk'}}`, encodeURIComponent(String(sensorPk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListAois: async (
            limit?: number,
            offset?: number,
            ordering?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/private/v1/sigma/aois/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} [dist] Represents **Distance** in **Distance to point** filter. Default value is used only if ***point*** is passed.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {Array<number>} [point] Point represented in **x,y** format. Represents **point** in **Distance to point filter**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListAssets: async (
            dist?: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            point?: Array<number>,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/private/v1/sigma/assets/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (dist !== undefined) {
                localVarQueryParameter['dist'] = dist;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (point) {
                localVarQueryParameter['point'] = point.join(COLLECTION_FORMATS.csv);
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {Geometry | GeometryCollection} [aoi] The area of interest (AOI) to search (accepts GeoGJSON and WKT).Limits detections to those that within the AOI
         * @param {number} [asset] Filter detections to a single asset
         * @param {string} [callType] Call Type of this Detection
         * @param {string} [detectedAfter] Limits detections by the time it\&#39;s corresponding image was taken
         * @param {string} [detectedBefore] Limits detections by the time it\&#39;s corresponding image was taken
         * @param {Array<'alert' | 'default'>} [detectionClass] One or more detection classifications to return
         * @param {string} [detectionType] Type of this Detection
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {Array<0 | 1 | 2 | 3>} [reviewStatus] The current state for if detection needs/has been manually reviewed, or the results of that review
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListDetections: async (
            aoi?: Geometry | GeometryCollection,
            asset?: number,
            callType?: string,
            detectedAfter?: string,
            detectedBefore?: string,
            detectionClass?: Array<'alert' | 'default'>,
            detectionType?: string,
            limit?: number,
            offset?: number,
            ordering?: string,
            reviewStatus?: Array<0 | 1 | 2 | 3>,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/private/v1/sigma/detections/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (aoi !== undefined) {
                localVarQueryParameter['aoi'] = aoi;
            }

            if (asset !== undefined) {
                localVarQueryParameter['asset'] = asset;
            }

            if (callType !== undefined) {
                localVarQueryParameter['call_type'] = callType;
            }

            if (detectedAfter !== undefined) {
                localVarQueryParameter['detected_after'] =
                    (detectedAfter as any) instanceof Date ? (detectedAfter as any).toISOString() : detectedAfter;
            }

            if (detectedBefore !== undefined) {
                localVarQueryParameter['detected_before'] =
                    (detectedBefore as any) instanceof Date ? (detectedBefore as any).toISOString() : detectedBefore;
            }

            if (detectionClass) {
                localVarQueryParameter['detection_class'] = detectionClass;
            }

            if (detectionType !== undefined) {
                localVarQueryParameter['detection_type'] = detectionType;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (reviewStatus) {
                localVarQueryParameter['review_status'] = reviewStatus;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListPlans: async (
            limit?: number,
            offset?: number,
            ordering?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/private/v1/sigma/plans/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [externalId] The unique id for this report
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [searchTimeEnd] The time for the end of the search. Limits reports to those covering a period starting before the end time.
         * @param {string} [searchTimeStart] The time for the start of the search. Limits reports to those covering a period ending after the start time.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListReports: async (
            externalId?: string,
            limit?: number,
            offset?: number,
            ordering?: string,
            searchTimeEnd?: string,
            searchTimeStart?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/private/v1/sigma/reports/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (externalId !== undefined) {
                localVarQueryParameter['external_id'] = externalId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (searchTimeEnd !== undefined) {
                localVarQueryParameter['search_time_end'] =
                    (searchTimeEnd as any) instanceof Date ? (searchTimeEnd as any).toISOString() : searchTimeEnd;
            }

            if (searchTimeStart !== undefined) {
                localVarQueryParameter['search_time_start'] =
                    (searchTimeStart as any) instanceof Date ? (searchTimeStart as any).toISOString() : searchTimeStart;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} sensorPk
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListStateVectors: async (
            sensorPk: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'sensorPk' is not null or undefined
            assertParamExists('internalListStateVectors', 'sensorPk', sensorPk);
            const localVarPath = `/private/v1/sigma/sensors/{sensor_pk}/state_vectors/`.replace(
                `{${'sensor_pk'}}`,
                encodeURIComponent(String(sensorPk)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} planPk
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListTasks: async (
            planPk: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'planPk' is not null or undefined
            assertParamExists('internalListTasks', 'planPk', planPk);
            const localVarPath = `/private/v1/sigma/plans/{plan_pk}/tasks/`.replace(
                `{${'plan_pk'}}`,
                encodeURIComponent(String(planPk)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} sensorPk
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListTles: async (
            sensorPk: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'sensorPk' is not null or undefined
            assertParamExists('internalListTles', 'sensorPk', sensorPk);
            const localVarPath = `/private/v1/sigma/sensors/{sensor_pk}/tles/`.replace(
                `{${'sensor_pk'}}`,
                encodeURIComponent(String(sensorPk)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this program aoi.
         * @param {PatchedProgramAOIRequest} [patchedProgramAOIRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateAoi: async (
            id: number,
            patchedProgramAOIRequest?: PatchedProgramAOIRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalPartialUpdateAoi', 'id', id);
            const localVarPath = `/private/v1/sigma/aois/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                patchedProgramAOIRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this asset.
         * @param {PatchedPrivateAssetRequest} [patchedPrivateAssetRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateAsset: async (
            id: number,
            patchedPrivateAssetRequest?: PatchedPrivateAssetRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalPartialUpdateAsset', 'id', id);
            const localVarPath = `/private/v1/sigma/assets/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                patchedPrivateAssetRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} pkId A unique integer value identifying this detection.
         * @param {PatchedInternalDetectionRequest} [patchedInternalDetectionRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateDetection: async (
            pkId: number,
            patchedInternalDetectionRequest?: PatchedInternalDetectionRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'pkId' is not null or undefined
            assertParamExists('internalPartialUpdateDetection', 'pkId', pkId);
            const localVarPath = `/private/v1/sigma/detections/{pk_id}/`.replace(
                `{${'pk_id'}}`,
                encodeURIComponent(String(pkId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                patchedInternalDetectionRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this detection artifact.
         * @param {PatchedDetectionArtifactRequest} [patchedDetectionArtifactRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateDetectionArtifact: async (
            id: number,
            patchedDetectionArtifactRequest?: PatchedDetectionArtifactRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalPartialUpdateDetectionArtifact', 'id', id);
            const localVarPath = `/private/v1/sigma/detection_artifacts/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                patchedDetectionArtifactRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to Download Requests, a set of raster images that has been requested by a user
         * @param {number} id A unique integer value identifying this download request.
         * @param {PatchedInternalDownloadRequestRequest} [patchedInternalDownloadRequestRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateDownload: async (
            id: number,
            patchedInternalDownloadRequestRequest?: PatchedInternalDownloadRequestRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalPartialUpdateDownload', 'id', id);
            const localVarPath = `/private/v1/sigma/downloads/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                patchedInternalDownloadRequestRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to user-created areas of interest.
         * @param {number} id A unique integer value identifying this tick.
         * @param {PatchedOpportunityRequest} [patchedOpportunityRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateOrbit: async (
            id: number,
            patchedOpportunityRequest?: PatchedOpportunityRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalPartialUpdateOrbit', 'id', id);
            const localVarPath = `/private/v1/sigma/orbit/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                patchedOpportunityRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this plan.
         * @param {PatchedPrivatePlanRequest} [patchedPrivatePlanRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdatePlan: async (
            id: number,
            patchedPrivatePlanRequest?: PatchedPrivatePlanRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalPartialUpdatePlan', 'id', id);
            const localVarPath = `/private/v1/sigma/plans/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                patchedPrivatePlanRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this report.
         * @param {PatchedInternalReportRequest} [patchedInternalReportRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateReport: async (
            id: number,
            patchedInternalReportRequest?: PatchedInternalReportRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalPartialUpdateReport', 'id', id);
            const localVarPath = `/private/v1/sigma/reports/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                patchedInternalReportRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to sensors. Supports create, read, update, and delete
         * @param {number} oskId A unique value identifying this sensor.
         * @param {PatchedSensorRequest} [patchedSensorRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateSensor: async (
            oskId: number,
            patchedSensorRequest?: PatchedSensorRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'oskId' is not null or undefined
            assertParamExists('internalPartialUpdateSensor', 'oskId', oskId);
            const localVarPath = `/private/v1/sigma/sensors/{osk_id}/`.replace(
                `{${'osk_id'}}`,
                encodeURIComponent(String(oskId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                patchedSensorRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this state vector.
         * @param {number} sensorPk
         * @param {PatchedStateVectorRequest} [patchedStateVectorRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateStateVector: async (
            id: number,
            sensorPk: number,
            patchedStateVectorRequest?: PatchedStateVectorRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalPartialUpdateStateVector', 'id', id);
            // verify required parameter 'sensorPk' is not null or undefined
            assertParamExists('internalPartialUpdateStateVector', 'sensorPk', sensorPk);
            const localVarPath = `/private/v1/sigma/sensors/{sensor_pk}/state_vectors/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'sensor_pk'}}`, encodeURIComponent(String(sensorPk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                patchedStateVectorRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this plan task.
         * @param {number} planPk
         * @param {PatchedPlanTaskRequest} [patchedPlanTaskRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateTask: async (
            id: number,
            planPk: number,
            patchedPlanTaskRequest?: PatchedPlanTaskRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalPartialUpdateTask', 'id', id);
            // verify required parameter 'planPk' is not null or undefined
            assertParamExists('internalPartialUpdateTask', 'planPk', planPk);
            const localVarPath = `/private/v1/sigma/plans/{plan_pk}/tasks/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'plan_pk'}}`, encodeURIComponent(String(planPk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                patchedPlanTaskRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this program aoi.
         * @param {ProgramAOIRequest} programAOIRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateAoi: async (
            id: number,
            programAOIRequest: ProgramAOIRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalUpdateAoi', 'id', id);
            // verify required parameter 'programAOIRequest' is not null or undefined
            assertParamExists('internalUpdateAoi', 'programAOIRequest', programAOIRequest);
            const localVarPath = `/private/v1/sigma/aois/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                programAOIRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this asset.
         * @param {PrivateAssetRequest} privateAssetRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateAsset: async (
            id: number,
            privateAssetRequest: PrivateAssetRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalUpdateAsset', 'id', id);
            // verify required parameter 'privateAssetRequest' is not null or undefined
            assertParamExists('internalUpdateAsset', 'privateAssetRequest', privateAssetRequest);
            const localVarPath = `/private/v1/sigma/assets/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                privateAssetRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} pkId A unique integer value identifying this detection.
         * @param {InternalDetectionRequest} internalDetectionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateDetection: async (
            pkId: number,
            internalDetectionRequest: InternalDetectionRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'pkId' is not null or undefined
            assertParamExists('internalUpdateDetection', 'pkId', pkId);
            // verify required parameter 'internalDetectionRequest' is not null or undefined
            assertParamExists('internalUpdateDetection', 'internalDetectionRequest', internalDetectionRequest);
            const localVarPath = `/private/v1/sigma/detections/{pk_id}/`.replace(
                `{${'pk_id'}}`,
                encodeURIComponent(String(pkId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                internalDetectionRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this detection artifact.
         * @param {DetectionArtifactRequest} detectionArtifactRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateDetectionArtifact: async (
            id: number,
            detectionArtifactRequest: DetectionArtifactRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalUpdateDetectionArtifact', 'id', id);
            // verify required parameter 'detectionArtifactRequest' is not null or undefined
            assertParamExists('internalUpdateDetectionArtifact', 'detectionArtifactRequest', detectionArtifactRequest);
            const localVarPath = `/private/v1/sigma/detection_artifacts/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                detectionArtifactRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to Download Requests, a set of raster images that has been requested by a user
         * @param {number} id A unique integer value identifying this download request.
         * @param {InternalDownloadRequestRequest} internalDownloadRequestRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateDownload: async (
            id: number,
            internalDownloadRequestRequest: InternalDownloadRequestRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalUpdateDownload', 'id', id);
            // verify required parameter 'internalDownloadRequestRequest' is not null or undefined
            assertParamExists(
                'internalUpdateDownload',
                'internalDownloadRequestRequest',
                internalDownloadRequestRequest,
            );
            const localVarPath = `/private/v1/sigma/downloads/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                internalDownloadRequestRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to user-created areas of interest.
         * @param {number} id A unique integer value identifying this tick.
         * @param {OpportunityRequest} opportunityRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateOrbit: async (
            id: number,
            opportunityRequest: OpportunityRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalUpdateOrbit', 'id', id);
            // verify required parameter 'opportunityRequest' is not null or undefined
            assertParamExists('internalUpdateOrbit', 'opportunityRequest', opportunityRequest);
            const localVarPath = `/private/v1/sigma/orbit/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                opportunityRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this plan.
         * @param {PrivatePlanRequest} privatePlanRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdatePlan: async (
            id: number,
            privatePlanRequest: PrivatePlanRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalUpdatePlan', 'id', id);
            // verify required parameter 'privatePlanRequest' is not null or undefined
            assertParamExists('internalUpdatePlan', 'privatePlanRequest', privatePlanRequest);
            const localVarPath = `/private/v1/sigma/plans/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                privatePlanRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this report.
         * @param {InternalReportRequest} internalReportRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateReport: async (
            id: number,
            internalReportRequest: InternalReportRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalUpdateReport', 'id', id);
            // verify required parameter 'internalReportRequest' is not null or undefined
            assertParamExists('internalUpdateReport', 'internalReportRequest', internalReportRequest);
            const localVarPath = `/private/v1/sigma/reports/{id}/`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                internalReportRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to sensors. Supports create, read, update, and delete
         * @param {number} oskId A unique value identifying this sensor.
         * @param {SensorRequest} sensorRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateSensor: async (
            oskId: number,
            sensorRequest: SensorRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'oskId' is not null or undefined
            assertParamExists('internalUpdateSensor', 'oskId', oskId);
            // verify required parameter 'sensorRequest' is not null or undefined
            assertParamExists('internalUpdateSensor', 'sensorRequest', sensorRequest);
            const localVarPath = `/private/v1/sigma/sensors/{osk_id}/`.replace(
                `{${'osk_id'}}`,
                encodeURIComponent(String(oskId)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(sensorRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this state vector.
         * @param {number} sensorPk
         * @param {StateVectorRequest} stateVectorRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateStateVector: async (
            id: number,
            sensorPk: number,
            stateVectorRequest: StateVectorRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalUpdateStateVector', 'id', id);
            // verify required parameter 'sensorPk' is not null or undefined
            assertParamExists('internalUpdateStateVector', 'sensorPk', sensorPk);
            // verify required parameter 'stateVectorRequest' is not null or undefined
            assertParamExists('internalUpdateStateVector', 'stateVectorRequest', stateVectorRequest);
            const localVarPath = `/private/v1/sigma/sensors/{sensor_pk}/state_vectors/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'sensor_pk'}}`, encodeURIComponent(String(sensorPk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                stateVectorRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this plan task.
         * @param {number} planPk
         * @param {PlanTaskRequest} planTaskRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateTask: async (
            id: number,
            planPk: number,
            planTaskRequest: PlanTaskRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalUpdateTask', 'id', id);
            // verify required parameter 'planPk' is not null or undefined
            assertParamExists('internalUpdateTask', 'planPk', planPk);
            // verify required parameter 'planTaskRequest' is not null or undefined
            assertParamExists('internalUpdateTask', 'planTaskRequest', planTaskRequest);
            const localVarPath = `/private/v1/sigma/plans/{plan_pk}/tasks/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'plan_pk'}}`, encodeURIComponent(String(planPk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(planTaskRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this tle.
         * @param {number} sensorPk
         * @param {TLERequest} tLERequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateTle: async (
            id: number,
            sensorPk: number,
            tLERequest: TLERequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('internalUpdateTle', 'id', id);
            // verify required parameter 'sensorPk' is not null or undefined
            assertParamExists('internalUpdateTle', 'sensorPk', sensorPk);
            // verify required parameter 'tLERequest' is not null or undefined
            assertParamExists('internalUpdateTle', 'tLERequest', tLERequest);
            const localVarPath = `/private/v1/sigma/sensors/{sensor_pk}/tles/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'sensor_pk'}}`, encodeURIComponent(String(sensorPk)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(tLERequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} program
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAssets: async (
            program: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'program' is not null or undefined
            assertParamExists('listAssets', 'program', program);
            const localVarPath = `/public/v1/sigma/programs/{program}/assets/`.replace(
                `{${'program'}}`,
                encodeURIComponent(String(program)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to data pipeline results, the final output of the data pipeline of a single file
         * @param {number} program
         * @param {Geometry | GeometryCollection} [aoi]
         * @param {string} [capturedAfter]
         * @param {string} [capturedBefore]
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {Array<number>} [sensor]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCaptures: async (
            program: number,
            aoi?: Geometry | GeometryCollection,
            capturedAfter?: string,
            capturedBefore?: string,
            limit?: number,
            offset?: number,
            ordering?: string,
            sensor?: Array<number>,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'program' is not null or undefined
            assertParamExists('listCaptures', 'program', program);
            const localVarPath = `/public/v1/sigma/programs/{program}/captures/`.replace(
                `{${'program'}}`,
                encodeURIComponent(String(program)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (aoi !== undefined) {
                localVarQueryParameter['aoi'] = aoi;
            }

            if (capturedAfter !== undefined) {
                localVarQueryParameter['captured_after'] =
                    (capturedAfter as any) instanceof Date ? (capturedAfter as any).toISOString() : capturedAfter;
            }

            if (capturedBefore !== undefined) {
                localVarQueryParameter['captured_before'] =
                    (capturedBefore as any) instanceof Date ? (capturedBefore as any).toISOString() : capturedBefore;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (sensor) {
                localVarQueryParameter['sensor'] = sensor;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} program
         * @param {Geometry | GeometryCollection} [aoi] The area of interest (AOI) to search (accepts GeoGJSON and WKT).Limits detections to those that within the AOI
         * @param {number} [asset] Filter detections to a single asset
         * @param {string} [callType] Call Type of this Detection
         * @param {string} [detectedAfter] Limits detections by the time it\&#39;s corresponding image was taken
         * @param {string} [detectedBefore] Limits detections by the time it\&#39;s corresponding image was taken
         * @param {Array<'alert' | 'default'>} [detectionClass] One or more detection classifications to return
         * @param {string} [detectionType] Type of this Detection
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDetections: async (
            program: number,
            aoi?: Geometry | GeometryCollection,
            asset?: number,
            callType?: string,
            detectedAfter?: string,
            detectedBefore?: string,
            detectionClass?: Array<'alert' | 'default'>,
            detectionType?: string,
            limit?: number,
            offset?: number,
            ordering?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'program' is not null or undefined
            assertParamExists('listDetections', 'program', program);
            const localVarPath = `/public/v1/sigma/programs/{program}/detections/`.replace(
                `{${'program'}}`,
                encodeURIComponent(String(program)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (aoi !== undefined) {
                localVarQueryParameter['aoi'] = aoi;
            }

            if (asset !== undefined) {
                localVarQueryParameter['asset'] = asset;
            }

            if (callType !== undefined) {
                localVarQueryParameter['call_type'] = callType;
            }

            if (detectedAfter !== undefined) {
                localVarQueryParameter['detected_after'] =
                    (detectedAfter as any) instanceof Date ? (detectedAfter as any).toISOString() : detectedAfter;
            }

            if (detectedBefore !== undefined) {
                localVarQueryParameter['detected_before'] =
                    (detectedBefore as any) instanceof Date ? (detectedBefore as any).toISOString() : detectedBefore;
            }

            if (detectionClass) {
                localVarQueryParameter['detection_class'] = detectionClass;
            }

            if (detectionType !== undefined) {
                localVarQueryParameter['detection_type'] = detectionType;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to Download Requests, a set of raster images that has been requested by a user
         * @param {number} program
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDownloads: async (
            program: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'program' is not null or undefined
            assertParamExists('listDownloads', 'program', program);
            const localVarPath = `/public/v1/sigma/programs/{program}/downloads/`.replace(
                `{${'program'}}`,
                encodeURIComponent(String(program)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} program
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPlans: async (
            program: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'program' is not null or undefined
            assertParamExists('listPlans', 'program', program);
            const localVarPath = `/public/v1/sigma/programs/{program}/plans/`.replace(
                `{${'program'}}`,
                encodeURIComponent(String(program)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} program
         * @param {string} [externalId] The unique id for this report
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [searchTimeEnd] The time for the end of the search. Limits reports to those covering a period starting before the end time.
         * @param {string} [searchTimeStart] The time for the start of the search. Limits reports to those covering a period ending after the start time.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReports: async (
            program: number,
            externalId?: string,
            limit?: number,
            offset?: number,
            ordering?: string,
            searchTimeEnd?: string,
            searchTimeStart?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'program' is not null or undefined
            assertParamExists('listReports', 'program', program);
            const localVarPath = `/public/v1/sigma/programs/{program}/reports/`.replace(
                `{${'program'}}`,
                encodeURIComponent(String(program)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (externalId !== undefined) {
                localVarQueryParameter['external_id'] = externalId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (searchTimeEnd !== undefined) {
                localVarQueryParameter['search_time_end'] =
                    (searchTimeEnd as any) instanceof Date ? (searchTimeEnd as any).toISOString() : searchTimeEnd;
            }

            if (searchTimeStart !== undefined) {
                localVarQueryParameter['search_time_start'] =
                    (searchTimeStart as any) instanceof Date ? (searchTimeStart as any).toISOString() : searchTimeStart;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to user-created areas of interest.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSearchAreas: async (
            limit?: number,
            offset?: number,
            ordering?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/public/v1/sigma/search_areas/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to sensors. Supports create, read, update, and delete
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSensors: async (
            limit?: number,
            offset?: number,
            ordering?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/public/v1/sigma/sensors/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this asset.
         * @param {number} program
         * @param {PatchedPublicAssetRequest} [patchedPublicAssetRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateAsset: async (
            id: number,
            program: number,
            patchedPublicAssetRequest?: PatchedPublicAssetRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateAsset', 'id', id);
            // verify required parameter 'program' is not null or undefined
            assertParamExists('partialUpdateAsset', 'program', program);
            const localVarPath = `/public/v1/sigma/programs/{program}/assets/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'program'}}`, encodeURIComponent(String(program)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                patchedPublicAssetRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this report.
         * @param {number} program
         * @param {PatchedReportRequest} [patchedReportRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateReport: async (
            id: number,
            program: number,
            patchedReportRequest?: PatchedReportRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateReport', 'id', id);
            // verify required parameter 'program' is not null or undefined
            assertParamExists('partialUpdateReport', 'program', program);
            const localVarPath = `/public/v1/sigma/programs/{program}/reports/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'program'}}`, encodeURIComponent(String(program)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                patchedReportRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to user-created areas of interest.
         * @param {number} id A unique integer value identifying this search area.
         * @param {PatchedSearchAreaRequest} [patchedSearchAreaRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateSearchArea: async (
            id: number,
            patchedSearchAreaRequest?: PatchedSearchAreaRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partialUpdateSearchArea', 'id', id);
            const localVarPath = `/public/v1/sigma/search_areas/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                patchedSearchAreaRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of opportunities for OSK\'s sensors, filtered for sensor, time, and area of interest  (AOI). Each opportunity is a 10-second piece of the orbit.
         * @summary Search orbital paths for opportunities
         * @param {Geometry | GeometryCollection} [aoi] The area of interest (AOI) to search (accepts GeoGJSON and WKT). Limits opportunities to those that intersect the satellite\&#39;s maximum field of view.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [searchTimeEnd] The time for the end of the search. Limits opportunities to those that start before the end time.
         * @param {string} [searchTimeStart] The time for the start of the search. Limits opportunities to those that end after the start time.
         * @param {number} [sensor] The sensor to search for. Limits opportunities to specific sensor(s).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchOrbits: async (
            aoi?: Geometry | GeometryCollection,
            limit?: number,
            offset?: number,
            ordering?: string,
            searchTimeEnd?: string,
            searchTimeStart?: string,
            sensor?: number,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/public/v1/sigma/orbit/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            if (aoi !== undefined) {
                localVarQueryParameter['aoi'] = aoi;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (searchTimeEnd !== undefined) {
                localVarQueryParameter['search_time_end'] =
                    (searchTimeEnd as any) instanceof Date ? (searchTimeEnd as any).toISOString() : searchTimeEnd;
            }

            if (searchTimeStart !== undefined) {
                localVarQueryParameter['search_time_start'] =
                    (searchTimeStart as any) instanceof Date ? (searchTimeStart as any).toISOString() : searchTimeStart;
            }

            if (sensor !== undefined) {
                localVarQueryParameter['sensor'] = sensor;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this asset.
         * @param {number} program
         * @param {PublicAssetRequest} [publicAssetRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAsset: async (
            id: number,
            program: number,
            publicAssetRequest?: PublicAssetRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAsset', 'id', id);
            // verify required parameter 'program' is not null or undefined
            assertParamExists('updateAsset', 'program', program);
            const localVarPath = `/public/v1/sigma/programs/{program}/assets/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'program'}}`, encodeURIComponent(String(program)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                publicAssetRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id A unique integer value identifying this report.
         * @param {number} program
         * @param {ReportRequest} reportRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReport: async (
            id: number,
            program: number,
            reportRequest: ReportRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateReport', 'id', id);
            // verify required parameter 'program' is not null or undefined
            assertParamExists('updateReport', 'program', program);
            // verify required parameter 'reportRequest' is not null or undefined
            assertParamExists('updateReport', 'reportRequest', reportRequest);
            const localVarPath = `/public/v1/sigma/programs/{program}/reports/{id}/`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'program'}}`, encodeURIComponent(String(program)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(reportRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * API operations related to user-created areas of interest.
         * @param {number} id A unique integer value identifying this search area.
         * @param {SearchAreaRequest} searchAreaRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSearchArea: async (
            id: number,
            searchAreaRequest: SearchAreaRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSearchArea', 'id', id);
            // verify required parameter 'searchAreaRequest' is not null or undefined
            assertParamExists('updateSearchArea', 'searchAreaRequest', searchAreaRequest);
            const localVarPath = `/public/v1/sigma/search_areas/{id}/`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(id)),
            );
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                searchAreaRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * SigmaApi - functional programming interface
 * @export
 */
export const SigmaApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = SigmaApiAxiosParamCreator(configuration);
    return {
        /**
         * API operations related to Download Requests, a set of raster images that has been requested by a user
         * @param {number} program
         * @param {DownloadRequestRequest} downloadRequestRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDownload(
            program: number,
            downloadRequestRequest: DownloadRequestRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DownloadRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDownload(
                program,
                downloadRequestRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} program
         * @param {PublicPlanRequest} publicPlanRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPlan(
            program: number,
            publicPlanRequest: PublicPlanRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicPlan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPlan(program, publicPlanRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} program
         * @param {ReportRequest} reportRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createReport(
            program: number,
            reportRequest: ReportRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Report>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createReport(program, reportRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to user-created areas of interest.
         * @param {SearchAreaRequest} searchAreaRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSearchArea(
            searchAreaRequest: SearchAreaRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchArea>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSearchArea(searchAreaRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this report.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroyReport(
            id: number,
            program: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroyReport(id, program, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to user-created areas of interest.
         * @param {number} id A unique integer value identifying this search area.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroySearchArea(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroySearchArea(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this asset.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAsset(
            id: number,
            program: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicAssetDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAsset(id, program, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to data pipeline results, the final output of the data pipeline of a single file
         * @param {number} id A unique integer value identifying this capture.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCapture(
            id: number,
            program: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Capture>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCapture(id, program, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} id
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDetection(
            id: string,
            program: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Detection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDetection(id, program, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to Download Requests, a set of raster images that has been requested by a user
         * @param {number} id A unique integer value identifying this download request.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDownload(
            id: number,
            program: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DownloadRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDownload(id, program, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this plan.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlan(
            id: number,
            program: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicPlan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPlan(id, program, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this report.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReport(
            id: number,
            program: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Report>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReport(id, program, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to user-created areas of interest.
         * @param {number} id A unique integer value identifying this search area.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSearchArea(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchArea>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSearchArea(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to sensors. Supports create, read, update, and delete
         * @param {number} oskId A unique value identifying this sensor.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSensor(
            oskId: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sensor>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSensor(oskId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method will return the most recently saved StateVector for sensor_id=pk
         * @param {number} oskId A unique value identifying this sensor.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStateVector(
            oskId: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StateVector>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStateVector(oskId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method will return the most recently saved TLE for sensor_id=pk
         * @param {number} oskId A unique value identifying this sensor.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTle(
            oskId: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TLE>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTle(oskId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {ProgramAOIRequest} programAOIRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCreateAoi(
            programAOIRequest: ProgramAOIRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProgramAOI>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCreateAoi(programAOIRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {PrivateAssetRequest} privateAssetRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCreateAsset(
            privateAssetRequest: PrivateAssetRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivateAsset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCreateAsset(privateAssetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {InternalDetectionRequest} internalDetectionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCreateDetection(
            internalDetectionRequest: InternalDetectionRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalDetection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCreateDetection(
                internalDetectionRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {DetectionArtifactRequest} detectionArtifactRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCreateDetectionArtifact(
            detectionArtifactRequest: DetectionArtifactRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetectionArtifact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCreateDetectionArtifact(
                detectionArtifactRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to user-created areas of interest.
         * @param {OpportunityRequest} opportunityRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCreateOrbit(
            opportunityRequest: OpportunityRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Opportunity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCreateOrbit(opportunityRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {PrivatePlanRequest} privatePlanRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCreatePlan(
            privatePlanRequest: PrivatePlanRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivatePlan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCreatePlan(privatePlanRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {InternalReportRequest} internalReportRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCreateReport(
            internalReportRequest: InternalReportRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCreateReport(
                internalReportRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to sensors. Supports create, read, update, and delete
         * @param {SensorRequest} sensorRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCreateSensor(
            sensorRequest: SensorRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sensor>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCreateSensor(sensorRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} sensorPk
         * @param {StateVectorRequest} stateVectorRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCreateStateVector(
            sensorPk: number,
            stateVectorRequest: StateVectorRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StateVector>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCreateStateVector(
                sensorPk,
                stateVectorRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} planPk
         * @param {PlanTaskRequest} planTaskRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCreateTask(
            planPk: number,
            planTaskRequest: PlanTaskRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlanTask>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCreateTask(
                planPk,
                planTaskRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} sensorPk
         * @param {TLERequest} tLERequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalCreateTle(
            sensorPk: number,
            tLERequest: TLERequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TLE>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalCreateTle(sensorPk, tLERequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this program aoi.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalDestroyAoi(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalDestroyAoi(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalDestroyAsset(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalDestroyAsset(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} pkId A unique integer value identifying this detection.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalDestroyDetection(
            pkId: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalDestroyDetection(pkId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this detection artifact.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalDestroyDetectionArtifact(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalDestroyDetectionArtifact(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to Download Requests, a set of raster images that has been requested by a user
         * @param {number} id A unique integer value identifying this download request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalDestroyDownload(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalDestroyDownload(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to user-created areas of interest.
         * @param {number} id A unique integer value identifying this tick.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalDestroyOrbit(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalDestroyOrbit(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this plan.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalDestroyPlan(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalDestroyPlan(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this report.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalDestroyReport(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalDestroyReport(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to sensors. Supports create, read, update, and delete
         * @param {number} oskId A unique value identifying this sensor.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalDestroySensor(
            oskId: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalDestroySensor(oskId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this state vector.
         * @param {number} sensorPk
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalDestroyStateVector(
            id: number,
            sensorPk: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalDestroyStateVector(id, sensorPk, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this plan task.
         * @param {number} planPk
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalDestroyTask(
            id: number,
            planPk: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalDestroyTask(id, planPk, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this tle.
         * @param {number} sensorPk
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalDestroyTle(
            id: number,
            sensorPk: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalDestroyTle(id, sensorPk, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this program aoi.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalGetAoi(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProgramAOI>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalGetAoi(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalGetAsset(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivateAsset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalGetAsset(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} pkId A unique integer value identifying this detection.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalGetDetection(
            pkId: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalDetection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalGetDetection(pkId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to user-created areas of interest.
         * @param {number} id A unique integer value identifying this tick.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalGetOrbit(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Opportunity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalGetOrbit(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this plan.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalGetPlan(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivatePlan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalGetPlan(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this report.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalGetReport(
            id: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalGetReport(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this state vector.
         * @param {number} sensorPk
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalGetStateVector(
            id: number,
            sensorPk: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StateVector>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalGetStateVector(id, sensorPk, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this plan task.
         * @param {number} planPk
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalGetTask(
            id: number,
            planPk: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlanTask>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalGetTask(id, planPk, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this tle.
         * @param {number} sensorPk
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalGetTle(
            id: number,
            sensorPk: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TLE>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalGetTle(id, sensorPk, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalListAois(
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedProgramAOIList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalListAois(
                limit,
                offset,
                ordering,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} [dist] Represents **Distance** in **Distance to point** filter. Default value is used only if ***point*** is passed.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {Array<number>} [point] Point represented in **x,y** format. Represents **point** in **Distance to point filter**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalListAssets(
            dist?: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            point?: Array<number>,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedPrivateAssetList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalListAssets(
                dist,
                limit,
                offset,
                ordering,
                point,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {Geometry | GeometryCollection} [aoi] The area of interest (AOI) to search (accepts GeoGJSON and WKT).Limits detections to those that within the AOI
         * @param {number} [asset] Filter detections to a single asset
         * @param {string} [callType] Call Type of this Detection
         * @param {string} [detectedAfter] Limits detections by the time it\&#39;s corresponding image was taken
         * @param {string} [detectedBefore] Limits detections by the time it\&#39;s corresponding image was taken
         * @param {Array<'alert' | 'default'>} [detectionClass] One or more detection classifications to return
         * @param {string} [detectionType] Type of this Detection
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {Array<0 | 1 | 2 | 3>} [reviewStatus] The current state for if detection needs/has been manually reviewed, or the results of that review
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalListDetections(
            aoi?: Geometry | GeometryCollection,
            asset?: number,
            callType?: string,
            detectedAfter?: string,
            detectedBefore?: string,
            detectionClass?: Array<'alert' | 'default'>,
            detectionType?: string,
            limit?: number,
            offset?: number,
            ordering?: string,
            reviewStatus?: Array<0 | 1 | 2 | 3>,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedInternalDetectionList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalListDetections(
                aoi,
                asset,
                callType,
                detectedAfter,
                detectedBefore,
                detectionClass,
                detectionType,
                limit,
                offset,
                ordering,
                reviewStatus,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalListPlans(
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedPrivatePlanList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalListPlans(
                limit,
                offset,
                ordering,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} [externalId] The unique id for this report
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [searchTimeEnd] The time for the end of the search. Limits reports to those covering a period starting before the end time.
         * @param {string} [searchTimeStart] The time for the start of the search. Limits reports to those covering a period ending after the start time.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalListReports(
            externalId?: string,
            limit?: number,
            offset?: number,
            ordering?: string,
            searchTimeEnd?: string,
            searchTimeStart?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedInternalReportList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalListReports(
                externalId,
                limit,
                offset,
                ordering,
                searchTimeEnd,
                searchTimeStart,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} sensorPk
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalListStateVectors(
            sensorPk: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedStateVectorList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalListStateVectors(
                sensorPk,
                limit,
                offset,
                ordering,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} planPk
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalListTasks(
            planPk: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedPlanTaskList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalListTasks(
                planPk,
                limit,
                offset,
                ordering,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} sensorPk
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalListTles(
            sensorPk: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedTLEList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalListTles(
                sensorPk,
                limit,
                offset,
                ordering,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this program aoi.
         * @param {PatchedProgramAOIRequest} [patchedProgramAOIRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalPartialUpdateAoi(
            id: number,
            patchedProgramAOIRequest?: PatchedProgramAOIRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProgramAOI>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalPartialUpdateAoi(
                id,
                patchedProgramAOIRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this asset.
         * @param {PatchedPrivateAssetRequest} [patchedPrivateAssetRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalPartialUpdateAsset(
            id: number,
            patchedPrivateAssetRequest?: PatchedPrivateAssetRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivateAsset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalPartialUpdateAsset(
                id,
                patchedPrivateAssetRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} pkId A unique integer value identifying this detection.
         * @param {PatchedInternalDetectionRequest} [patchedInternalDetectionRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalPartialUpdateDetection(
            pkId: number,
            patchedInternalDetectionRequest?: PatchedInternalDetectionRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalDetection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalPartialUpdateDetection(
                pkId,
                patchedInternalDetectionRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this detection artifact.
         * @param {PatchedDetectionArtifactRequest} [patchedDetectionArtifactRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalPartialUpdateDetectionArtifact(
            id: number,
            patchedDetectionArtifactRequest?: PatchedDetectionArtifactRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetectionArtifact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalPartialUpdateDetectionArtifact(
                id,
                patchedDetectionArtifactRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to Download Requests, a set of raster images that has been requested by a user
         * @param {number} id A unique integer value identifying this download request.
         * @param {PatchedInternalDownloadRequestRequest} [patchedInternalDownloadRequestRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalPartialUpdateDownload(
            id: number,
            patchedInternalDownloadRequestRequest?: PatchedInternalDownloadRequestRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalDownloadRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalPartialUpdateDownload(
                id,
                patchedInternalDownloadRequestRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to user-created areas of interest.
         * @param {number} id A unique integer value identifying this tick.
         * @param {PatchedOpportunityRequest} [patchedOpportunityRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalPartialUpdateOrbit(
            id: number,
            patchedOpportunityRequest?: PatchedOpportunityRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Opportunity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalPartialUpdateOrbit(
                id,
                patchedOpportunityRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this plan.
         * @param {PatchedPrivatePlanRequest} [patchedPrivatePlanRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalPartialUpdatePlan(
            id: number,
            patchedPrivatePlanRequest?: PatchedPrivatePlanRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivatePlan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalPartialUpdatePlan(
                id,
                patchedPrivatePlanRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this report.
         * @param {PatchedInternalReportRequest} [patchedInternalReportRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalPartialUpdateReport(
            id: number,
            patchedInternalReportRequest?: PatchedInternalReportRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalPartialUpdateReport(
                id,
                patchedInternalReportRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to sensors. Supports create, read, update, and delete
         * @param {number} oskId A unique value identifying this sensor.
         * @param {PatchedSensorRequest} [patchedSensorRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalPartialUpdateSensor(
            oskId: number,
            patchedSensorRequest?: PatchedSensorRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sensor>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalPartialUpdateSensor(
                oskId,
                patchedSensorRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this state vector.
         * @param {number} sensorPk
         * @param {PatchedStateVectorRequest} [patchedStateVectorRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalPartialUpdateStateVector(
            id: number,
            sensorPk: number,
            patchedStateVectorRequest?: PatchedStateVectorRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StateVector>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalPartialUpdateStateVector(
                id,
                sensorPk,
                patchedStateVectorRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this plan task.
         * @param {number} planPk
         * @param {PatchedPlanTaskRequest} [patchedPlanTaskRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalPartialUpdateTask(
            id: number,
            planPk: number,
            patchedPlanTaskRequest?: PatchedPlanTaskRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlanTask>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalPartialUpdateTask(
                id,
                planPk,
                patchedPlanTaskRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this program aoi.
         * @param {ProgramAOIRequest} programAOIRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdateAoi(
            id: number,
            programAOIRequest: ProgramAOIRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProgramAOI>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdateAoi(id, programAOIRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this asset.
         * @param {PrivateAssetRequest} privateAssetRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdateAsset(
            id: number,
            privateAssetRequest: PrivateAssetRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivateAsset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdateAsset(
                id,
                privateAssetRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} pkId A unique integer value identifying this detection.
         * @param {InternalDetectionRequest} internalDetectionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdateDetection(
            pkId: number,
            internalDetectionRequest: InternalDetectionRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalDetection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdateDetection(
                pkId,
                internalDetectionRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this detection artifact.
         * @param {DetectionArtifactRequest} detectionArtifactRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdateDetectionArtifact(
            id: number,
            detectionArtifactRequest: DetectionArtifactRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetectionArtifact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdateDetectionArtifact(
                id,
                detectionArtifactRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to Download Requests, a set of raster images that has been requested by a user
         * @param {number} id A unique integer value identifying this download request.
         * @param {InternalDownloadRequestRequest} internalDownloadRequestRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdateDownload(
            id: number,
            internalDownloadRequestRequest: InternalDownloadRequestRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalDownloadRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdateDownload(
                id,
                internalDownloadRequestRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to user-created areas of interest.
         * @param {number} id A unique integer value identifying this tick.
         * @param {OpportunityRequest} opportunityRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdateOrbit(
            id: number,
            opportunityRequest: OpportunityRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Opportunity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdateOrbit(
                id,
                opportunityRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this plan.
         * @param {PrivatePlanRequest} privatePlanRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdatePlan(
            id: number,
            privatePlanRequest: PrivatePlanRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivatePlan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdatePlan(
                id,
                privatePlanRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this report.
         * @param {InternalReportRequest} internalReportRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdateReport(
            id: number,
            internalReportRequest: InternalReportRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdateReport(
                id,
                internalReportRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to sensors. Supports create, read, update, and delete
         * @param {number} oskId A unique value identifying this sensor.
         * @param {SensorRequest} sensorRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdateSensor(
            oskId: number,
            sensorRequest: SensorRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sensor>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdateSensor(
                oskId,
                sensorRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this state vector.
         * @param {number} sensorPk
         * @param {StateVectorRequest} stateVectorRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdateStateVector(
            id: number,
            sensorPk: number,
            stateVectorRequest: StateVectorRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StateVector>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdateStateVector(
                id,
                sensorPk,
                stateVectorRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this plan task.
         * @param {number} planPk
         * @param {PlanTaskRequest} planTaskRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdateTask(
            id: number,
            planPk: number,
            planTaskRequest: PlanTaskRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlanTask>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdateTask(
                id,
                planPk,
                planTaskRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this tle.
         * @param {number} sensorPk
         * @param {TLERequest} tLERequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async internalUpdateTle(
            id: number,
            sensorPk: number,
            tLERequest: TLERequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TLE>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.internalUpdateTle(
                id,
                sensorPk,
                tLERequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} program
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAssets(
            program: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedPublicAssetList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAssets(
                program,
                limit,
                offset,
                ordering,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to data pipeline results, the final output of the data pipeline of a single file
         * @param {number} program
         * @param {Geometry | GeometryCollection} [aoi]
         * @param {string} [capturedAfter]
         * @param {string} [capturedBefore]
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {Array<number>} [sensor]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCaptures(
            program: number,
            aoi?: Geometry | GeometryCollection,
            capturedAfter?: string,
            capturedBefore?: string,
            limit?: number,
            offset?: number,
            ordering?: string,
            sensor?: Array<number>,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedCaptureList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCaptures(
                program,
                aoi,
                capturedAfter,
                capturedBefore,
                limit,
                offset,
                ordering,
                sensor,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} program
         * @param {Geometry | GeometryCollection} [aoi] The area of interest (AOI) to search (accepts GeoGJSON and WKT).Limits detections to those that within the AOI
         * @param {number} [asset] Filter detections to a single asset
         * @param {string} [callType] Call Type of this Detection
         * @param {string} [detectedAfter] Limits detections by the time it\&#39;s corresponding image was taken
         * @param {string} [detectedBefore] Limits detections by the time it\&#39;s corresponding image was taken
         * @param {Array<'alert' | 'default'>} [detectionClass] One or more detection classifications to return
         * @param {string} [detectionType] Type of this Detection
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDetections(
            program: number,
            aoi?: Geometry | GeometryCollection,
            asset?: number,
            callType?: string,
            detectedAfter?: string,
            detectedBefore?: string,
            detectionClass?: Array<'alert' | 'default'>,
            detectionType?: string,
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedDetectionList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDetections(
                program,
                aoi,
                asset,
                callType,
                detectedAfter,
                detectedBefore,
                detectionClass,
                detectionType,
                limit,
                offset,
                ordering,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to Download Requests, a set of raster images that has been requested by a user
         * @param {number} program
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDownloads(
            program: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedDownloadRequestList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDownloads(
                program,
                limit,
                offset,
                ordering,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} program
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPlans(
            program: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedPublicPlanList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPlans(
                program,
                limit,
                offset,
                ordering,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} program
         * @param {string} [externalId] The unique id for this report
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [searchTimeEnd] The time for the end of the search. Limits reports to those covering a period starting before the end time.
         * @param {string} [searchTimeStart] The time for the start of the search. Limits reports to those covering a period ending after the start time.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listReports(
            program: number,
            externalId?: string,
            limit?: number,
            offset?: number,
            ordering?: string,
            searchTimeEnd?: string,
            searchTimeStart?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedReportList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listReports(
                program,
                externalId,
                limit,
                offset,
                ordering,
                searchTimeEnd,
                searchTimeStart,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to user-created areas of interest.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSearchAreas(
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedSearchAreaList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSearchAreas(limit, offset, ordering, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to sensors. Supports create, read, update, and delete
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSensors(
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedSensorList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSensors(limit, offset, ordering, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this asset.
         * @param {number} program
         * @param {PatchedPublicAssetRequest} [patchedPublicAssetRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateAsset(
            id: number,
            program: number,
            patchedPublicAssetRequest?: PatchedPublicAssetRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicAsset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateAsset(
                id,
                program,
                patchedPublicAssetRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this report.
         * @param {number} program
         * @param {PatchedReportRequest} [patchedReportRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateReport(
            id: number,
            program: number,
            patchedReportRequest?: PatchedReportRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Report>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateReport(
                id,
                program,
                patchedReportRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to user-created areas of interest.
         * @param {number} id A unique integer value identifying this search area.
         * @param {PatchedSearchAreaRequest} [patchedSearchAreaRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partialUpdateSearchArea(
            id: number,
            patchedSearchAreaRequest?: PatchedSearchAreaRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchArea>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partialUpdateSearchArea(
                id,
                patchedSearchAreaRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of opportunities for OSK\'s sensors, filtered for sensor, time, and area of interest  (AOI). Each opportunity is a 10-second piece of the orbit.
         * @summary Search orbital paths for opportunities
         * @param {Geometry | GeometryCollection} [aoi] The area of interest (AOI) to search (accepts GeoGJSON and WKT). Limits opportunities to those that intersect the satellite\&#39;s maximum field of view.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [searchTimeEnd] The time for the end of the search. Limits opportunities to those that start before the end time.
         * @param {string} [searchTimeStart] The time for the start of the search. Limits opportunities to those that end after the start time.
         * @param {number} [sensor] The sensor to search for. Limits opportunities to specific sensor(s).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchOrbits(
            aoi?: Geometry | GeometryCollection,
            limit?: number,
            offset?: number,
            ordering?: string,
            searchTimeEnd?: string,
            searchTimeStart?: string,
            sensor?: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedOpportunityList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchOrbits(
                aoi,
                limit,
                offset,
                ordering,
                searchTimeEnd,
                searchTimeStart,
                sensor,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this asset.
         * @param {number} program
         * @param {PublicAssetRequest} [publicAssetRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAsset(
            id: number,
            program: number,
            publicAssetRequest?: PublicAssetRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicAsset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAsset(
                id,
                program,
                publicAssetRequest,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} id A unique integer value identifying this report.
         * @param {number} program
         * @param {ReportRequest} reportRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateReport(
            id: number,
            program: number,
            reportRequest: ReportRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Report>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateReport(id, program, reportRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * API operations related to user-created areas of interest.
         * @param {number} id A unique integer value identifying this search area.
         * @param {SearchAreaRequest} searchAreaRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSearchArea(
            id: number,
            searchAreaRequest: SearchAreaRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchArea>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSearchArea(id, searchAreaRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * SigmaApi - factory interface
 * @export
 */
export const SigmaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SigmaApiFp(configuration);
    return {
        /**
         * API operations related to Download Requests, a set of raster images that has been requested by a user
         * @param {number} program
         * @param {DownloadRequestRequest} downloadRequestRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDownload(
            program: number,
            downloadRequestRequest: DownloadRequestRequest,
            options?: any,
        ): AxiosPromise<DownloadRequest> {
            return localVarFp
                .createDownload(program, downloadRequestRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} program
         * @param {PublicPlanRequest} publicPlanRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPlan(program: number, publicPlanRequest: PublicPlanRequest, options?: any): AxiosPromise<PublicPlan> {
            return localVarFp
                .createPlan(program, publicPlanRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} program
         * @param {ReportRequest} reportRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReport(program: number, reportRequest: ReportRequest, options?: any): AxiosPromise<Report> {
            return localVarFp.createReport(program, reportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * API operations related to user-created areas of interest.
         * @param {SearchAreaRequest} searchAreaRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSearchArea(searchAreaRequest: SearchAreaRequest, options?: any): AxiosPromise<SearchArea> {
            return localVarFp.createSearchArea(searchAreaRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this report.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyReport(id: number, program: number, options?: any): AxiosPromise<void> {
            return localVarFp.destroyReport(id, program, options).then((request) => request(axios, basePath));
        },
        /**
         * API operations related to user-created areas of interest.
         * @param {number} id A unique integer value identifying this search area.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroySearchArea(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.destroySearchArea(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this asset.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAsset(id: number, program: number, options?: any): AxiosPromise<PublicAssetDetail> {
            return localVarFp.getAsset(id, program, options).then((request) => request(axios, basePath));
        },
        /**
         * API operations related to data pipeline results, the final output of the data pipeline of a single file
         * @param {number} id A unique integer value identifying this capture.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCapture(id: number, program: number, options?: any): AxiosPromise<Capture> {
            return localVarFp.getCapture(id, program, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetection(id: string, program: number, options?: any): AxiosPromise<Detection> {
            return localVarFp.getDetection(id, program, options).then((request) => request(axios, basePath));
        },
        /**
         * API operations related to Download Requests, a set of raster images that has been requested by a user
         * @param {number} id A unique integer value identifying this download request.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownload(id: number, program: number, options?: any): AxiosPromise<DownloadRequest> {
            return localVarFp.getDownload(id, program, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this plan.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlan(id: number, program: number, options?: any): AxiosPromise<PublicPlan> {
            return localVarFp.getPlan(id, program, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this report.
         * @param {number} program
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReport(id: number, program: number, options?: any): AxiosPromise<Report> {
            return localVarFp.getReport(id, program, options).then((request) => request(axios, basePath));
        },
        /**
         * API operations related to user-created areas of interest.
         * @param {number} id A unique integer value identifying this search area.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchArea(id: number, options?: any): AxiosPromise<SearchArea> {
            return localVarFp.getSearchArea(id, options).then((request) => request(axios, basePath));
        },
        /**
         * API operations related to sensors. Supports create, read, update, and delete
         * @param {number} oskId A unique value identifying this sensor.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSensor(oskId: number, options?: any): AxiosPromise<Sensor> {
            return localVarFp.getSensor(oskId, options).then((request) => request(axios, basePath));
        },
        /**
         * This method will return the most recently saved StateVector for sensor_id=pk
         * @param {number} oskId A unique value identifying this sensor.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStateVector(oskId: number, options?: any): AxiosPromise<StateVector> {
            return localVarFp.getStateVector(oskId, options).then((request) => request(axios, basePath));
        },
        /**
         * This method will return the most recently saved TLE for sensor_id=pk
         * @param {number} oskId A unique value identifying this sensor.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTle(oskId: number, options?: any): AxiosPromise<TLE> {
            return localVarFp.getTle(oskId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {ProgramAOIRequest} programAOIRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateAoi(programAOIRequest: ProgramAOIRequest, options?: any): AxiosPromise<ProgramAOI> {
            return localVarFp.internalCreateAoi(programAOIRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PrivateAssetRequest} privateAssetRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateAsset(privateAssetRequest: PrivateAssetRequest, options?: any): AxiosPromise<PrivateAsset> {
            return localVarFp
                .internalCreateAsset(privateAssetRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {InternalDetectionRequest} internalDetectionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateDetection(
            internalDetectionRequest: InternalDetectionRequest,
            options?: any,
        ): AxiosPromise<InternalDetection> {
            return localVarFp
                .internalCreateDetection(internalDetectionRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {DetectionArtifactRequest} detectionArtifactRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateDetectionArtifact(
            detectionArtifactRequest: DetectionArtifactRequest,
            options?: any,
        ): AxiosPromise<DetectionArtifact> {
            return localVarFp
                .internalCreateDetectionArtifact(detectionArtifactRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations related to user-created areas of interest.
         * @param {OpportunityRequest} opportunityRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateOrbit(opportunityRequest: OpportunityRequest, options?: any): AxiosPromise<Opportunity> {
            return localVarFp
                .internalCreateOrbit(opportunityRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PrivatePlanRequest} privatePlanRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreatePlan(privatePlanRequest: PrivatePlanRequest, options?: any): AxiosPromise<PrivatePlan> {
            return localVarFp
                .internalCreatePlan(privatePlanRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {InternalReportRequest} internalReportRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateReport(
            internalReportRequest: InternalReportRequest,
            options?: any,
        ): AxiosPromise<InternalReport> {
            return localVarFp
                .internalCreateReport(internalReportRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations related to sensors. Supports create, read, update, and delete
         * @param {SensorRequest} sensorRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateSensor(sensorRequest: SensorRequest, options?: any): AxiosPromise<Sensor> {
            return localVarFp.internalCreateSensor(sensorRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} sensorPk
         * @param {StateVectorRequest} stateVectorRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateStateVector(
            sensorPk: number,
            stateVectorRequest: StateVectorRequest,
            options?: any,
        ): AxiosPromise<StateVector> {
            return localVarFp
                .internalCreateStateVector(sensorPk, stateVectorRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} planPk
         * @param {PlanTaskRequest} planTaskRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateTask(planPk: number, planTaskRequest: PlanTaskRequest, options?: any): AxiosPromise<PlanTask> {
            return localVarFp
                .internalCreateTask(planPk, planTaskRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} sensorPk
         * @param {TLERequest} tLERequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalCreateTle(sensorPk: number, tLERequest: TLERequest, options?: any): AxiosPromise<TLE> {
            return localVarFp
                .internalCreateTle(sensorPk, tLERequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this program aoi.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyAoi(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.internalDestroyAoi(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyAsset(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.internalDestroyAsset(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} pkId A unique integer value identifying this detection.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyDetection(pkId: number, options?: any): AxiosPromise<void> {
            return localVarFp.internalDestroyDetection(pkId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this detection artifact.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyDetectionArtifact(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.internalDestroyDetectionArtifact(id, options).then((request) => request(axios, basePath));
        },
        /**
         * API operations related to Download Requests, a set of raster images that has been requested by a user
         * @param {number} id A unique integer value identifying this download request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyDownload(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.internalDestroyDownload(id, options).then((request) => request(axios, basePath));
        },
        /**
         * API operations related to user-created areas of interest.
         * @param {number} id A unique integer value identifying this tick.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyOrbit(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.internalDestroyOrbit(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this plan.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyPlan(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.internalDestroyPlan(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this report.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyReport(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.internalDestroyReport(id, options).then((request) => request(axios, basePath));
        },
        /**
         * API operations related to sensors. Supports create, read, update, and delete
         * @param {number} oskId A unique value identifying this sensor.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroySensor(oskId: number, options?: any): AxiosPromise<void> {
            return localVarFp.internalDestroySensor(oskId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this state vector.
         * @param {number} sensorPk
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyStateVector(id: number, sensorPk: number, options?: any): AxiosPromise<void> {
            return localVarFp
                .internalDestroyStateVector(id, sensorPk, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this plan task.
         * @param {number} planPk
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyTask(id: number, planPk: number, options?: any): AxiosPromise<void> {
            return localVarFp.internalDestroyTask(id, planPk, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this tle.
         * @param {number} sensorPk
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalDestroyTle(id: number, sensorPk: number, options?: any): AxiosPromise<void> {
            return localVarFp.internalDestroyTle(id, sensorPk, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this program aoi.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetAoi(id: number, options?: any): AxiosPromise<ProgramAOI> {
            return localVarFp.internalGetAoi(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this asset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetAsset(id: number, options?: any): AxiosPromise<PrivateAsset> {
            return localVarFp.internalGetAsset(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} pkId A unique integer value identifying this detection.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetDetection(pkId: number, options?: any): AxiosPromise<InternalDetection> {
            return localVarFp.internalGetDetection(pkId, options).then((request) => request(axios, basePath));
        },
        /**
         * API operations related to user-created areas of interest.
         * @param {number} id A unique integer value identifying this tick.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetOrbit(id: number, options?: any): AxiosPromise<Opportunity> {
            return localVarFp.internalGetOrbit(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this plan.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetPlan(id: number, options?: any): AxiosPromise<PrivatePlan> {
            return localVarFp.internalGetPlan(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this report.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetReport(id: number, options?: any): AxiosPromise<InternalReport> {
            return localVarFp.internalGetReport(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this state vector.
         * @param {number} sensorPk
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetStateVector(id: number, sensorPk: number, options?: any): AxiosPromise<StateVector> {
            return localVarFp.internalGetStateVector(id, sensorPk, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this plan task.
         * @param {number} planPk
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetTask(id: number, planPk: number, options?: any): AxiosPromise<PlanTask> {
            return localVarFp.internalGetTask(id, planPk, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this tle.
         * @param {number} sensorPk
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalGetTle(id: number, sensorPk: number, options?: any): AxiosPromise<TLE> {
            return localVarFp.internalGetTle(id, sensorPk, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListAois(
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: any,
        ): AxiosPromise<PaginatedProgramAOIList> {
            return localVarFp
                .internalListAois(limit, offset, ordering, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} [dist] Represents **Distance** in **Distance to point** filter. Default value is used only if ***point*** is passed.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {Array<number>} [point] Point represented in **x,y** format. Represents **point** in **Distance to point filter**
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListAssets(
            dist?: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            point?: Array<number>,
            options?: any,
        ): AxiosPromise<PaginatedPrivateAssetList> {
            return localVarFp
                .internalListAssets(dist, limit, offset, ordering, point, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {Geometry | GeometryCollection} [aoi] The area of interest (AOI) to search (accepts GeoGJSON and WKT).Limits detections to those that within the AOI
         * @param {number} [asset] Filter detections to a single asset
         * @param {string} [callType] Call Type of this Detection
         * @param {string} [detectedAfter] Limits detections by the time it\&#39;s corresponding image was taken
         * @param {string} [detectedBefore] Limits detections by the time it\&#39;s corresponding image was taken
         * @param {Array<'alert' | 'default'>} [detectionClass] One or more detection classifications to return
         * @param {string} [detectionType] Type of this Detection
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {Array<0 | 1 | 2 | 3>} [reviewStatus] The current state for if detection needs/has been manually reviewed, or the results of that review
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListDetections(
            aoi?: Geometry | GeometryCollection,
            asset?: number,
            callType?: string,
            detectedAfter?: string,
            detectedBefore?: string,
            detectionClass?: Array<'alert' | 'default'>,
            detectionType?: string,
            limit?: number,
            offset?: number,
            ordering?: string,
            reviewStatus?: Array<0 | 1 | 2 | 3>,
            options?: any,
        ): AxiosPromise<PaginatedInternalDetectionList> {
            return localVarFp
                .internalListDetections(
                    aoi,
                    asset,
                    callType,
                    detectedAfter,
                    detectedBefore,
                    detectionClass,
                    detectionType,
                    limit,
                    offset,
                    ordering,
                    reviewStatus,
                    options,
                )
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListPlans(
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: any,
        ): AxiosPromise<PaginatedPrivatePlanList> {
            return localVarFp
                .internalListPlans(limit, offset, ordering, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} [externalId] The unique id for this report
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [searchTimeEnd] The time for the end of the search. Limits reports to those covering a period starting before the end time.
         * @param {string} [searchTimeStart] The time for the start of the search. Limits reports to those covering a period ending after the start time.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListReports(
            externalId?: string,
            limit?: number,
            offset?: number,
            ordering?: string,
            searchTimeEnd?: string,
            searchTimeStart?: string,
            options?: any,
        ): AxiosPromise<PaginatedInternalReportList> {
            return localVarFp
                .internalListReports(externalId, limit, offset, ordering, searchTimeEnd, searchTimeStart, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} sensorPk
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListStateVectors(
            sensorPk: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: any,
        ): AxiosPromise<PaginatedStateVectorList> {
            return localVarFp
                .internalListStateVectors(sensorPk, limit, offset, ordering, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} planPk
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListTasks(
            planPk: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: any,
        ): AxiosPromise<PaginatedPlanTaskList> {
            return localVarFp
                .internalListTasks(planPk, limit, offset, ordering, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} sensorPk
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalListTles(
            sensorPk: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: any,
        ): AxiosPromise<PaginatedTLEList> {
            return localVarFp
                .internalListTles(sensorPk, limit, offset, ordering, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this program aoi.
         * @param {PatchedProgramAOIRequest} [patchedProgramAOIRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateAoi(
            id: number,
            patchedProgramAOIRequest?: PatchedProgramAOIRequest,
            options?: any,
        ): AxiosPromise<ProgramAOI> {
            return localVarFp
                .internalPartialUpdateAoi(id, patchedProgramAOIRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this asset.
         * @param {PatchedPrivateAssetRequest} [patchedPrivateAssetRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateAsset(
            id: number,
            patchedPrivateAssetRequest?: PatchedPrivateAssetRequest,
            options?: any,
        ): AxiosPromise<PrivateAsset> {
            return localVarFp
                .internalPartialUpdateAsset(id, patchedPrivateAssetRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} pkId A unique integer value identifying this detection.
         * @param {PatchedInternalDetectionRequest} [patchedInternalDetectionRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateDetection(
            pkId: number,
            patchedInternalDetectionRequest?: PatchedInternalDetectionRequest,
            options?: any,
        ): AxiosPromise<InternalDetection> {
            return localVarFp
                .internalPartialUpdateDetection(pkId, patchedInternalDetectionRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this detection artifact.
         * @param {PatchedDetectionArtifactRequest} [patchedDetectionArtifactRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateDetectionArtifact(
            id: number,
            patchedDetectionArtifactRequest?: PatchedDetectionArtifactRequest,
            options?: any,
        ): AxiosPromise<DetectionArtifact> {
            return localVarFp
                .internalPartialUpdateDetectionArtifact(id, patchedDetectionArtifactRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations related to Download Requests, a set of raster images that has been requested by a user
         * @param {number} id A unique integer value identifying this download request.
         * @param {PatchedInternalDownloadRequestRequest} [patchedInternalDownloadRequestRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateDownload(
            id: number,
            patchedInternalDownloadRequestRequest?: PatchedInternalDownloadRequestRequest,
            options?: any,
        ): AxiosPromise<InternalDownloadRequest> {
            return localVarFp
                .internalPartialUpdateDownload(id, patchedInternalDownloadRequestRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations related to user-created areas of interest.
         * @param {number} id A unique integer value identifying this tick.
         * @param {PatchedOpportunityRequest} [patchedOpportunityRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateOrbit(
            id: number,
            patchedOpportunityRequest?: PatchedOpportunityRequest,
            options?: any,
        ): AxiosPromise<Opportunity> {
            return localVarFp
                .internalPartialUpdateOrbit(id, patchedOpportunityRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this plan.
         * @param {PatchedPrivatePlanRequest} [patchedPrivatePlanRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdatePlan(
            id: number,
            patchedPrivatePlanRequest?: PatchedPrivatePlanRequest,
            options?: any,
        ): AxiosPromise<PrivatePlan> {
            return localVarFp
                .internalPartialUpdatePlan(id, patchedPrivatePlanRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this report.
         * @param {PatchedInternalReportRequest} [patchedInternalReportRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateReport(
            id: number,
            patchedInternalReportRequest?: PatchedInternalReportRequest,
            options?: any,
        ): AxiosPromise<InternalReport> {
            return localVarFp
                .internalPartialUpdateReport(id, patchedInternalReportRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations related to sensors. Supports create, read, update, and delete
         * @param {number} oskId A unique value identifying this sensor.
         * @param {PatchedSensorRequest} [patchedSensorRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateSensor(
            oskId: number,
            patchedSensorRequest?: PatchedSensorRequest,
            options?: any,
        ): AxiosPromise<Sensor> {
            return localVarFp
                .internalPartialUpdateSensor(oskId, patchedSensorRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this state vector.
         * @param {number} sensorPk
         * @param {PatchedStateVectorRequest} [patchedStateVectorRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateStateVector(
            id: number,
            sensorPk: number,
            patchedStateVectorRequest?: PatchedStateVectorRequest,
            options?: any,
        ): AxiosPromise<StateVector> {
            return localVarFp
                .internalPartialUpdateStateVector(id, sensorPk, patchedStateVectorRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this plan task.
         * @param {number} planPk
         * @param {PatchedPlanTaskRequest} [patchedPlanTaskRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPartialUpdateTask(
            id: number,
            planPk: number,
            patchedPlanTaskRequest?: PatchedPlanTaskRequest,
            options?: any,
        ): AxiosPromise<PlanTask> {
            return localVarFp
                .internalPartialUpdateTask(id, planPk, patchedPlanTaskRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this program aoi.
         * @param {ProgramAOIRequest} programAOIRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateAoi(id: number, programAOIRequest: ProgramAOIRequest, options?: any): AxiosPromise<ProgramAOI> {
            return localVarFp
                .internalUpdateAoi(id, programAOIRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this asset.
         * @param {PrivateAssetRequest} privateAssetRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateAsset(
            id: number,
            privateAssetRequest: PrivateAssetRequest,
            options?: any,
        ): AxiosPromise<PrivateAsset> {
            return localVarFp
                .internalUpdateAsset(id, privateAssetRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} pkId A unique integer value identifying this detection.
         * @param {InternalDetectionRequest} internalDetectionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateDetection(
            pkId: number,
            internalDetectionRequest: InternalDetectionRequest,
            options?: any,
        ): AxiosPromise<InternalDetection> {
            return localVarFp
                .internalUpdateDetection(pkId, internalDetectionRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this detection artifact.
         * @param {DetectionArtifactRequest} detectionArtifactRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateDetectionArtifact(
            id: number,
            detectionArtifactRequest: DetectionArtifactRequest,
            options?: any,
        ): AxiosPromise<DetectionArtifact> {
            return localVarFp
                .internalUpdateDetectionArtifact(id, detectionArtifactRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations related to Download Requests, a set of raster images that has been requested by a user
         * @param {number} id A unique integer value identifying this download request.
         * @param {InternalDownloadRequestRequest} internalDownloadRequestRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateDownload(
            id: number,
            internalDownloadRequestRequest: InternalDownloadRequestRequest,
            options?: any,
        ): AxiosPromise<InternalDownloadRequest> {
            return localVarFp
                .internalUpdateDownload(id, internalDownloadRequestRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations related to user-created areas of interest.
         * @param {number} id A unique integer value identifying this tick.
         * @param {OpportunityRequest} opportunityRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateOrbit(
            id: number,
            opportunityRequest: OpportunityRequest,
            options?: any,
        ): AxiosPromise<Opportunity> {
            return localVarFp
                .internalUpdateOrbit(id, opportunityRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this plan.
         * @param {PrivatePlanRequest} privatePlanRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdatePlan(
            id: number,
            privatePlanRequest: PrivatePlanRequest,
            options?: any,
        ): AxiosPromise<PrivatePlan> {
            return localVarFp
                .internalUpdatePlan(id, privatePlanRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this report.
         * @param {InternalReportRequest} internalReportRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateReport(
            id: number,
            internalReportRequest: InternalReportRequest,
            options?: any,
        ): AxiosPromise<InternalReport> {
            return localVarFp
                .internalUpdateReport(id, internalReportRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations related to sensors. Supports create, read, update, and delete
         * @param {number} oskId A unique value identifying this sensor.
         * @param {SensorRequest} sensorRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateSensor(oskId: number, sensorRequest: SensorRequest, options?: any): AxiosPromise<Sensor> {
            return localVarFp
                .internalUpdateSensor(oskId, sensorRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this state vector.
         * @param {number} sensorPk
         * @param {StateVectorRequest} stateVectorRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateStateVector(
            id: number,
            sensorPk: number,
            stateVectorRequest: StateVectorRequest,
            options?: any,
        ): AxiosPromise<StateVector> {
            return localVarFp
                .internalUpdateStateVector(id, sensorPk, stateVectorRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this plan task.
         * @param {number} planPk
         * @param {PlanTaskRequest} planTaskRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateTask(
            id: number,
            planPk: number,
            planTaskRequest: PlanTaskRequest,
            options?: any,
        ): AxiosPromise<PlanTask> {
            return localVarFp
                .internalUpdateTask(id, planPk, planTaskRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this tle.
         * @param {number} sensorPk
         * @param {TLERequest} tLERequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalUpdateTle(id: number, sensorPk: number, tLERequest: TLERequest, options?: any): AxiosPromise<TLE> {
            return localVarFp
                .internalUpdateTle(id, sensorPk, tLERequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} program
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAssets(
            program: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: any,
        ): AxiosPromise<PaginatedPublicAssetList> {
            return localVarFp
                .listAssets(program, limit, offset, ordering, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations related to data pipeline results, the final output of the data pipeline of a single file
         * @param {number} program
         * @param {Geometry | GeometryCollection} [aoi]
         * @param {string} [capturedAfter]
         * @param {string} [capturedBefore]
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {Array<number>} [sensor]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCaptures(
            program: number,
            aoi?: Geometry | GeometryCollection,
            capturedAfter?: string,
            capturedBefore?: string,
            limit?: number,
            offset?: number,
            ordering?: string,
            sensor?: Array<number>,
            options?: any,
        ): AxiosPromise<PaginatedCaptureList> {
            return localVarFp
                .listCaptures(program, aoi, capturedAfter, capturedBefore, limit, offset, ordering, sensor, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} program
         * @param {Geometry | GeometryCollection} [aoi] The area of interest (AOI) to search (accepts GeoGJSON and WKT).Limits detections to those that within the AOI
         * @param {number} [asset] Filter detections to a single asset
         * @param {string} [callType] Call Type of this Detection
         * @param {string} [detectedAfter] Limits detections by the time it\&#39;s corresponding image was taken
         * @param {string} [detectedBefore] Limits detections by the time it\&#39;s corresponding image was taken
         * @param {Array<'alert' | 'default'>} [detectionClass] One or more detection classifications to return
         * @param {string} [detectionType] Type of this Detection
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDetections(
            program: number,
            aoi?: Geometry | GeometryCollection,
            asset?: number,
            callType?: string,
            detectedAfter?: string,
            detectedBefore?: string,
            detectionClass?: Array<'alert' | 'default'>,
            detectionType?: string,
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: any,
        ): AxiosPromise<PaginatedDetectionList> {
            return localVarFp
                .listDetections(
                    program,
                    aoi,
                    asset,
                    callType,
                    detectedAfter,
                    detectedBefore,
                    detectionClass,
                    detectionType,
                    limit,
                    offset,
                    ordering,
                    options,
                )
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations related to Download Requests, a set of raster images that has been requested by a user
         * @param {number} program
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDownloads(
            program: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: any,
        ): AxiosPromise<PaginatedDownloadRequestList> {
            return localVarFp
                .listDownloads(program, limit, offset, ordering, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} program
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPlans(
            program: number,
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: any,
        ): AxiosPromise<PaginatedPublicPlanList> {
            return localVarFp
                .listPlans(program, limit, offset, ordering, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} program
         * @param {string} [externalId] The unique id for this report
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [searchTimeEnd] The time for the end of the search. Limits reports to those covering a period starting before the end time.
         * @param {string} [searchTimeStart] The time for the start of the search. Limits reports to those covering a period ending after the start time.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReports(
            program: number,
            externalId?: string,
            limit?: number,
            offset?: number,
            ordering?: string,
            searchTimeEnd?: string,
            searchTimeStart?: string,
            options?: any,
        ): AxiosPromise<PaginatedReportList> {
            return localVarFp
                .listReports(program, externalId, limit, offset, ordering, searchTimeEnd, searchTimeStart, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations related to user-created areas of interest.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSearchAreas(
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: any,
        ): AxiosPromise<PaginatedSearchAreaList> {
            return localVarFp
                .listSearchAreas(limit, offset, ordering, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations related to sensors. Supports create, read, update, and delete
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSensors(
            limit?: number,
            offset?: number,
            ordering?: string,
            options?: any,
        ): AxiosPromise<PaginatedSensorList> {
            return localVarFp.listSensors(limit, offset, ordering, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this asset.
         * @param {number} program
         * @param {PatchedPublicAssetRequest} [patchedPublicAssetRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateAsset(
            id: number,
            program: number,
            patchedPublicAssetRequest?: PatchedPublicAssetRequest,
            options?: any,
        ): AxiosPromise<PublicAsset> {
            return localVarFp
                .partialUpdateAsset(id, program, patchedPublicAssetRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this report.
         * @param {number} program
         * @param {PatchedReportRequest} [patchedReportRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateReport(
            id: number,
            program: number,
            patchedReportRequest?: PatchedReportRequest,
            options?: any,
        ): AxiosPromise<Report> {
            return localVarFp
                .partialUpdateReport(id, program, patchedReportRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations related to user-created areas of interest.
         * @param {number} id A unique integer value identifying this search area.
         * @param {PatchedSearchAreaRequest} [patchedSearchAreaRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateSearchArea(
            id: number,
            patchedSearchAreaRequest?: PatchedSearchAreaRequest,
            options?: any,
        ): AxiosPromise<SearchArea> {
            return localVarFp
                .partialUpdateSearchArea(id, patchedSearchAreaRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of opportunities for OSK\'s sensors, filtered for sensor, time, and area of interest  (AOI). Each opportunity is a 10-second piece of the orbit.
         * @summary Search orbital paths for opportunities
         * @param {Geometry | GeometryCollection} [aoi] The area of interest (AOI) to search (accepts GeoGJSON and WKT). Limits opportunities to those that intersect the satellite\&#39;s maximum field of view.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [searchTimeEnd] The time for the end of the search. Limits opportunities to those that start before the end time.
         * @param {string} [searchTimeStart] The time for the start of the search. Limits opportunities to those that end after the start time.
         * @param {number} [sensor] The sensor to search for. Limits opportunities to specific sensor(s).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchOrbits(
            aoi?: Geometry | GeometryCollection,
            limit?: number,
            offset?: number,
            ordering?: string,
            searchTimeEnd?: string,
            searchTimeStart?: string,
            sensor?: number,
            options?: any,
        ): AxiosPromise<PaginatedOpportunityList> {
            return localVarFp
                .searchOrbits(aoi, limit, offset, ordering, searchTimeEnd, searchTimeStart, sensor, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this asset.
         * @param {number} program
         * @param {PublicAssetRequest} [publicAssetRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAsset(
            id: number,
            program: number,
            publicAssetRequest?: PublicAssetRequest,
            options?: any,
        ): AxiosPromise<PublicAsset> {
            return localVarFp
                .updateAsset(id, program, publicAssetRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} id A unique integer value identifying this report.
         * @param {number} program
         * @param {ReportRequest} reportRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReport(id: number, program: number, reportRequest: ReportRequest, options?: any): AxiosPromise<Report> {
            return localVarFp
                .updateReport(id, program, reportRequest, options)
                .then((request) => request(axios, basePath));
        },
        /**
         * API operations related to user-created areas of interest.
         * @param {number} id A unique integer value identifying this search area.
         * @param {SearchAreaRequest} searchAreaRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSearchArea(id: number, searchAreaRequest: SearchAreaRequest, options?: any): AxiosPromise<SearchArea> {
            return localVarFp
                .updateSearchArea(id, searchAreaRequest, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createDownload operation in SigmaApi.
 * @export
 * @interface SigmaApiCreateDownloadRequest
 */
export interface SigmaApiCreateDownloadRequest {
    /**
     *
     * @type {number}
     * @memberof SigmaApiCreateDownload
     */
    readonly program: number;

    /**
     *
     * @type {DownloadRequestRequest}
     * @memberof SigmaApiCreateDownload
     */
    readonly downloadRequestRequest: DownloadRequestRequest;
}

/**
 * Request parameters for createPlan operation in SigmaApi.
 * @export
 * @interface SigmaApiCreatePlanRequest
 */
export interface SigmaApiCreatePlanRequest {
    /**
     *
     * @type {number}
     * @memberof SigmaApiCreatePlan
     */
    readonly program: number;

    /**
     *
     * @type {PublicPlanRequest}
     * @memberof SigmaApiCreatePlan
     */
    readonly publicPlanRequest: PublicPlanRequest;
}

/**
 * Request parameters for createReport operation in SigmaApi.
 * @export
 * @interface SigmaApiCreateReportRequest
 */
export interface SigmaApiCreateReportRequest {
    /**
     *
     * @type {number}
     * @memberof SigmaApiCreateReport
     */
    readonly program: number;

    /**
     *
     * @type {ReportRequest}
     * @memberof SigmaApiCreateReport
     */
    readonly reportRequest: ReportRequest;
}

/**
 * Request parameters for createSearchArea operation in SigmaApi.
 * @export
 * @interface SigmaApiCreateSearchAreaRequest
 */
export interface SigmaApiCreateSearchAreaRequest {
    /**
     *
     * @type {SearchAreaRequest}
     * @memberof SigmaApiCreateSearchArea
     */
    readonly searchAreaRequest: SearchAreaRequest;
}

/**
 * Request parameters for destroyReport operation in SigmaApi.
 * @export
 * @interface SigmaApiDestroyReportRequest
 */
export interface SigmaApiDestroyReportRequest {
    /**
     * A unique integer value identifying this report.
     * @type {number}
     * @memberof SigmaApiDestroyReport
     */
    readonly id: number;

    /**
     *
     * @type {number}
     * @memberof SigmaApiDestroyReport
     */
    readonly program: number;
}

/**
 * Request parameters for destroySearchArea operation in SigmaApi.
 * @export
 * @interface SigmaApiDestroySearchAreaRequest
 */
export interface SigmaApiDestroySearchAreaRequest {
    /**
     * A unique integer value identifying this search area.
     * @type {number}
     * @memberof SigmaApiDestroySearchArea
     */
    readonly id: number;
}

/**
 * Request parameters for getAsset operation in SigmaApi.
 * @export
 * @interface SigmaApiGetAssetRequest
 */
export interface SigmaApiGetAssetRequest {
    /**
     * A unique integer value identifying this asset.
     * @type {number}
     * @memberof SigmaApiGetAsset
     */
    readonly id: number;

    /**
     *
     * @type {number}
     * @memberof SigmaApiGetAsset
     */
    readonly program: number;
}

/**
 * Request parameters for getCapture operation in SigmaApi.
 * @export
 * @interface SigmaApiGetCaptureRequest
 */
export interface SigmaApiGetCaptureRequest {
    /**
     * A unique integer value identifying this capture.
     * @type {number}
     * @memberof SigmaApiGetCapture
     */
    readonly id: number;

    /**
     *
     * @type {number}
     * @memberof SigmaApiGetCapture
     */
    readonly program: number;
}

/**
 * Request parameters for getDetection operation in SigmaApi.
 * @export
 * @interface SigmaApiGetDetectionRequest
 */
export interface SigmaApiGetDetectionRequest {
    /**
     *
     * @type {string}
     * @memberof SigmaApiGetDetection
     */
    readonly id: string;

    /**
     *
     * @type {number}
     * @memberof SigmaApiGetDetection
     */
    readonly program: number;
}

/**
 * Request parameters for getDownload operation in SigmaApi.
 * @export
 * @interface SigmaApiGetDownloadRequest
 */
export interface SigmaApiGetDownloadRequest {
    /**
     * A unique integer value identifying this download request.
     * @type {number}
     * @memberof SigmaApiGetDownload
     */
    readonly id: number;

    /**
     *
     * @type {number}
     * @memberof SigmaApiGetDownload
     */
    readonly program: number;
}

/**
 * Request parameters for getPlan operation in SigmaApi.
 * @export
 * @interface SigmaApiGetPlanRequest
 */
export interface SigmaApiGetPlanRequest {
    /**
     * A unique integer value identifying this plan.
     * @type {number}
     * @memberof SigmaApiGetPlan
     */
    readonly id: number;

    /**
     *
     * @type {number}
     * @memberof SigmaApiGetPlan
     */
    readonly program: number;
}

/**
 * Request parameters for getReport operation in SigmaApi.
 * @export
 * @interface SigmaApiGetReportRequest
 */
export interface SigmaApiGetReportRequest {
    /**
     * A unique integer value identifying this report.
     * @type {number}
     * @memberof SigmaApiGetReport
     */
    readonly id: number;

    /**
     *
     * @type {number}
     * @memberof SigmaApiGetReport
     */
    readonly program: number;
}

/**
 * Request parameters for getSearchArea operation in SigmaApi.
 * @export
 * @interface SigmaApiGetSearchAreaRequest
 */
export interface SigmaApiGetSearchAreaRequest {
    /**
     * A unique integer value identifying this search area.
     * @type {number}
     * @memberof SigmaApiGetSearchArea
     */
    readonly id: number;
}

/**
 * Request parameters for getSensor operation in SigmaApi.
 * @export
 * @interface SigmaApiGetSensorRequest
 */
export interface SigmaApiGetSensorRequest {
    /**
     * A unique value identifying this sensor.
     * @type {number}
     * @memberof SigmaApiGetSensor
     */
    readonly oskId: number;
}

/**
 * Request parameters for getStateVector operation in SigmaApi.
 * @export
 * @interface SigmaApiGetStateVectorRequest
 */
export interface SigmaApiGetStateVectorRequest {
    /**
     * A unique value identifying this sensor.
     * @type {number}
     * @memberof SigmaApiGetStateVector
     */
    readonly oskId: number;
}

/**
 * Request parameters for getTle operation in SigmaApi.
 * @export
 * @interface SigmaApiGetTleRequest
 */
export interface SigmaApiGetTleRequest {
    /**
     * A unique value identifying this sensor.
     * @type {number}
     * @memberof SigmaApiGetTle
     */
    readonly oskId: number;
}

/**
 * Request parameters for internalCreateAoi operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalCreateAoiRequest
 */
export interface SigmaApiInternalCreateAoiRequest {
    /**
     *
     * @type {ProgramAOIRequest}
     * @memberof SigmaApiInternalCreateAoi
     */
    readonly programAOIRequest: ProgramAOIRequest;
}

/**
 * Request parameters for internalCreateAsset operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalCreateAssetRequest
 */
export interface SigmaApiInternalCreateAssetRequest {
    /**
     *
     * @type {PrivateAssetRequest}
     * @memberof SigmaApiInternalCreateAsset
     */
    readonly privateAssetRequest: PrivateAssetRequest;
}

/**
 * Request parameters for internalCreateDetection operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalCreateDetectionRequest
 */
export interface SigmaApiInternalCreateDetectionRequest {
    /**
     *
     * @type {InternalDetectionRequest}
     * @memberof SigmaApiInternalCreateDetection
     */
    readonly internalDetectionRequest: InternalDetectionRequest;
}

/**
 * Request parameters for internalCreateDetectionArtifact operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalCreateDetectionArtifactRequest
 */
export interface SigmaApiInternalCreateDetectionArtifactRequest {
    /**
     *
     * @type {DetectionArtifactRequest}
     * @memberof SigmaApiInternalCreateDetectionArtifact
     */
    readonly detectionArtifactRequest: DetectionArtifactRequest;
}

/**
 * Request parameters for internalCreateOrbit operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalCreateOrbitRequest
 */
export interface SigmaApiInternalCreateOrbitRequest {
    /**
     *
     * @type {OpportunityRequest}
     * @memberof SigmaApiInternalCreateOrbit
     */
    readonly opportunityRequest: OpportunityRequest;
}

/**
 * Request parameters for internalCreatePlan operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalCreatePlanRequest
 */
export interface SigmaApiInternalCreatePlanRequest {
    /**
     *
     * @type {PrivatePlanRequest}
     * @memberof SigmaApiInternalCreatePlan
     */
    readonly privatePlanRequest: PrivatePlanRequest;
}

/**
 * Request parameters for internalCreateReport operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalCreateReportRequest
 */
export interface SigmaApiInternalCreateReportRequest {
    /**
     *
     * @type {InternalReportRequest}
     * @memberof SigmaApiInternalCreateReport
     */
    readonly internalReportRequest: InternalReportRequest;
}

/**
 * Request parameters for internalCreateSensor operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalCreateSensorRequest
 */
export interface SigmaApiInternalCreateSensorRequest {
    /**
     *
     * @type {SensorRequest}
     * @memberof SigmaApiInternalCreateSensor
     */
    readonly sensorRequest: SensorRequest;
}

/**
 * Request parameters for internalCreateStateVector operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalCreateStateVectorRequest
 */
export interface SigmaApiInternalCreateStateVectorRequest {
    /**
     *
     * @type {number}
     * @memberof SigmaApiInternalCreateStateVector
     */
    readonly sensorPk: number;

    /**
     *
     * @type {StateVectorRequest}
     * @memberof SigmaApiInternalCreateStateVector
     */
    readonly stateVectorRequest: StateVectorRequest;
}

/**
 * Request parameters for internalCreateTask operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalCreateTaskRequest
 */
export interface SigmaApiInternalCreateTaskRequest {
    /**
     *
     * @type {number}
     * @memberof SigmaApiInternalCreateTask
     */
    readonly planPk: number;

    /**
     *
     * @type {PlanTaskRequest}
     * @memberof SigmaApiInternalCreateTask
     */
    readonly planTaskRequest: PlanTaskRequest;
}

/**
 * Request parameters for internalCreateTle operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalCreateTleRequest
 */
export interface SigmaApiInternalCreateTleRequest {
    /**
     *
     * @type {number}
     * @memberof SigmaApiInternalCreateTle
     */
    readonly sensorPk: number;

    /**
     *
     * @type {TLERequest}
     * @memberof SigmaApiInternalCreateTle
     */
    readonly tLERequest: TLERequest;
}

/**
 * Request parameters for internalDestroyAoi operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalDestroyAoiRequest
 */
export interface SigmaApiInternalDestroyAoiRequest {
    /**
     * A unique integer value identifying this program aoi.
     * @type {number}
     * @memberof SigmaApiInternalDestroyAoi
     */
    readonly id: number;
}

/**
 * Request parameters for internalDestroyAsset operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalDestroyAssetRequest
 */
export interface SigmaApiInternalDestroyAssetRequest {
    /**
     * A unique integer value identifying this asset.
     * @type {number}
     * @memberof SigmaApiInternalDestroyAsset
     */
    readonly id: number;
}

/**
 * Request parameters for internalDestroyDetection operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalDestroyDetectionRequest
 */
export interface SigmaApiInternalDestroyDetectionRequest {
    /**
     * A unique integer value identifying this detection.
     * @type {number}
     * @memberof SigmaApiInternalDestroyDetection
     */
    readonly pkId: number;
}

/**
 * Request parameters for internalDestroyDetectionArtifact operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalDestroyDetectionArtifactRequest
 */
export interface SigmaApiInternalDestroyDetectionArtifactRequest {
    /**
     * A unique integer value identifying this detection artifact.
     * @type {number}
     * @memberof SigmaApiInternalDestroyDetectionArtifact
     */
    readonly id: number;
}

/**
 * Request parameters for internalDestroyDownload operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalDestroyDownloadRequest
 */
export interface SigmaApiInternalDestroyDownloadRequest {
    /**
     * A unique integer value identifying this download request.
     * @type {number}
     * @memberof SigmaApiInternalDestroyDownload
     */
    readonly id: number;
}

/**
 * Request parameters for internalDestroyOrbit operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalDestroyOrbitRequest
 */
export interface SigmaApiInternalDestroyOrbitRequest {
    /**
     * A unique integer value identifying this tick.
     * @type {number}
     * @memberof SigmaApiInternalDestroyOrbit
     */
    readonly id: number;
}

/**
 * Request parameters for internalDestroyPlan operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalDestroyPlanRequest
 */
export interface SigmaApiInternalDestroyPlanRequest {
    /**
     * A unique integer value identifying this plan.
     * @type {number}
     * @memberof SigmaApiInternalDestroyPlan
     */
    readonly id: number;
}

/**
 * Request parameters for internalDestroyReport operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalDestroyReportRequest
 */
export interface SigmaApiInternalDestroyReportRequest {
    /**
     * A unique integer value identifying this report.
     * @type {number}
     * @memberof SigmaApiInternalDestroyReport
     */
    readonly id: number;
}

/**
 * Request parameters for internalDestroySensor operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalDestroySensorRequest
 */
export interface SigmaApiInternalDestroySensorRequest {
    /**
     * A unique value identifying this sensor.
     * @type {number}
     * @memberof SigmaApiInternalDestroySensor
     */
    readonly oskId: number;
}

/**
 * Request parameters for internalDestroyStateVector operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalDestroyStateVectorRequest
 */
export interface SigmaApiInternalDestroyStateVectorRequest {
    /**
     * A unique integer value identifying this state vector.
     * @type {number}
     * @memberof SigmaApiInternalDestroyStateVector
     */
    readonly id: number;

    /**
     *
     * @type {number}
     * @memberof SigmaApiInternalDestroyStateVector
     */
    readonly sensorPk: number;
}

/**
 * Request parameters for internalDestroyTask operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalDestroyTaskRequest
 */
export interface SigmaApiInternalDestroyTaskRequest {
    /**
     * A unique integer value identifying this plan task.
     * @type {number}
     * @memberof SigmaApiInternalDestroyTask
     */
    readonly id: number;

    /**
     *
     * @type {number}
     * @memberof SigmaApiInternalDestroyTask
     */
    readonly planPk: number;
}

/**
 * Request parameters for internalDestroyTle operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalDestroyTleRequest
 */
export interface SigmaApiInternalDestroyTleRequest {
    /**
     * A unique integer value identifying this tle.
     * @type {number}
     * @memberof SigmaApiInternalDestroyTle
     */
    readonly id: number;

    /**
     *
     * @type {number}
     * @memberof SigmaApiInternalDestroyTle
     */
    readonly sensorPk: number;
}

/**
 * Request parameters for internalGetAoi operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalGetAoiRequest
 */
export interface SigmaApiInternalGetAoiRequest {
    /**
     * A unique integer value identifying this program aoi.
     * @type {number}
     * @memberof SigmaApiInternalGetAoi
     */
    readonly id: number;
}

/**
 * Request parameters for internalGetAsset operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalGetAssetRequest
 */
export interface SigmaApiInternalGetAssetRequest {
    /**
     * A unique integer value identifying this asset.
     * @type {number}
     * @memberof SigmaApiInternalGetAsset
     */
    readonly id: number;
}

/**
 * Request parameters for internalGetDetection operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalGetDetectionRequest
 */
export interface SigmaApiInternalGetDetectionRequest {
    /**
     * A unique integer value identifying this detection.
     * @type {number}
     * @memberof SigmaApiInternalGetDetection
     */
    readonly pkId: number;
}

/**
 * Request parameters for internalGetOrbit operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalGetOrbitRequest
 */
export interface SigmaApiInternalGetOrbitRequest {
    /**
     * A unique integer value identifying this tick.
     * @type {number}
     * @memberof SigmaApiInternalGetOrbit
     */
    readonly id: number;
}

/**
 * Request parameters for internalGetPlan operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalGetPlanRequest
 */
export interface SigmaApiInternalGetPlanRequest {
    /**
     * A unique integer value identifying this plan.
     * @type {number}
     * @memberof SigmaApiInternalGetPlan
     */
    readonly id: number;
}

/**
 * Request parameters for internalGetReport operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalGetReportRequest
 */
export interface SigmaApiInternalGetReportRequest {
    /**
     * A unique integer value identifying this report.
     * @type {number}
     * @memberof SigmaApiInternalGetReport
     */
    readonly id: number;
}

/**
 * Request parameters for internalGetStateVector operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalGetStateVectorRequest
 */
export interface SigmaApiInternalGetStateVectorRequest {
    /**
     * A unique integer value identifying this state vector.
     * @type {number}
     * @memberof SigmaApiInternalGetStateVector
     */
    readonly id: number;

    /**
     *
     * @type {number}
     * @memberof SigmaApiInternalGetStateVector
     */
    readonly sensorPk: number;
}

/**
 * Request parameters for internalGetTask operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalGetTaskRequest
 */
export interface SigmaApiInternalGetTaskRequest {
    /**
     * A unique integer value identifying this plan task.
     * @type {number}
     * @memberof SigmaApiInternalGetTask
     */
    readonly id: number;

    /**
     *
     * @type {number}
     * @memberof SigmaApiInternalGetTask
     */
    readonly planPk: number;
}

/**
 * Request parameters for internalGetTle operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalGetTleRequest
 */
export interface SigmaApiInternalGetTleRequest {
    /**
     * A unique integer value identifying this tle.
     * @type {number}
     * @memberof SigmaApiInternalGetTle
     */
    readonly id: number;

    /**
     *
     * @type {number}
     * @memberof SigmaApiInternalGetTle
     */
    readonly sensorPk: number;
}

/**
 * Request parameters for internalListAois operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalListAoisRequest
 */
export interface SigmaApiInternalListAoisRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SigmaApiInternalListAois
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SigmaApiInternalListAois
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof SigmaApiInternalListAois
     */
    readonly ordering?: string;
}

/**
 * Request parameters for internalListAssets operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalListAssetsRequest
 */
export interface SigmaApiInternalListAssetsRequest {
    /**
     * Represents **Distance** in **Distance to point** filter. Default value is used only if ***point*** is passed.
     * @type {number}
     * @memberof SigmaApiInternalListAssets
     */
    readonly dist?: number;

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SigmaApiInternalListAssets
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SigmaApiInternalListAssets
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof SigmaApiInternalListAssets
     */
    readonly ordering?: string;

    /**
     * Point represented in **x,y** format. Represents **point** in **Distance to point filter**
     * @type {Array<number>}
     * @memberof SigmaApiInternalListAssets
     */
    readonly point?: Array<number>;
}

/**
 * Request parameters for internalListDetections operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalListDetectionsRequest
 */
export interface SigmaApiInternalListDetectionsRequest {
    /**
     * The area of interest (AOI) to search (accepts GeoGJSON and WKT).Limits detections to those that within the AOI
     * @type {Geometry | GeometryCollection}
     * @memberof SigmaApiInternalListDetections
     */
    readonly aoi?: Geometry | GeometryCollection;

    /**
     * Filter detections to a single asset
     * @type {number}
     * @memberof SigmaApiInternalListDetections
     */
    readonly asset?: number;

    /**
     * Call Type of this Detection
     * @type {string}
     * @memberof SigmaApiInternalListDetections
     */
    readonly callType?: string;

    /**
     * Limits detections by the time it\&#39;s corresponding image was taken
     * @type {string}
     * @memberof SigmaApiInternalListDetections
     */
    readonly detectedAfter?: string;

    /**
     * Limits detections by the time it\&#39;s corresponding image was taken
     * @type {string}
     * @memberof SigmaApiInternalListDetections
     */
    readonly detectedBefore?: string;

    /**
     * One or more detection classifications to return
     * @type {Array<'alert' | 'default'>}
     * @memberof SigmaApiInternalListDetections
     */
    readonly detectionClass?: Array<'alert' | 'default'>;

    /**
     * Type of this Detection
     * @type {string}
     * @memberof SigmaApiInternalListDetections
     */
    readonly detectionType?: string;

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SigmaApiInternalListDetections
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SigmaApiInternalListDetections
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof SigmaApiInternalListDetections
     */
    readonly ordering?: string;

    /**
     * The current state for if detection needs/has been manually reviewed, or the results of that review
     * @type {Array<0 | 1 | 2 | 3>}
     * @memberof SigmaApiInternalListDetections
     */
    readonly reviewStatus?: Array<0 | 1 | 2 | 3>;
}

/**
 * Request parameters for internalListPlans operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalListPlansRequest
 */
export interface SigmaApiInternalListPlansRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SigmaApiInternalListPlans
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SigmaApiInternalListPlans
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof SigmaApiInternalListPlans
     */
    readonly ordering?: string;
}

/**
 * Request parameters for internalListReports operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalListReportsRequest
 */
export interface SigmaApiInternalListReportsRequest {
    /**
     * The unique id for this report
     * @type {string}
     * @memberof SigmaApiInternalListReports
     */
    readonly externalId?: string;

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SigmaApiInternalListReports
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SigmaApiInternalListReports
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof SigmaApiInternalListReports
     */
    readonly ordering?: string;

    /**
     * The time for the end of the search. Limits reports to those covering a period starting before the end time.
     * @type {string}
     * @memberof SigmaApiInternalListReports
     */
    readonly searchTimeEnd?: string;

    /**
     * The time for the start of the search. Limits reports to those covering a period ending after the start time.
     * @type {string}
     * @memberof SigmaApiInternalListReports
     */
    readonly searchTimeStart?: string;
}

/**
 * Request parameters for internalListStateVectors operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalListStateVectorsRequest
 */
export interface SigmaApiInternalListStateVectorsRequest {
    /**
     *
     * @type {number}
     * @memberof SigmaApiInternalListStateVectors
     */
    readonly sensorPk: number;

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SigmaApiInternalListStateVectors
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SigmaApiInternalListStateVectors
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof SigmaApiInternalListStateVectors
     */
    readonly ordering?: string;
}

/**
 * Request parameters for internalListTasks operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalListTasksRequest
 */
export interface SigmaApiInternalListTasksRequest {
    /**
     *
     * @type {number}
     * @memberof SigmaApiInternalListTasks
     */
    readonly planPk: number;

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SigmaApiInternalListTasks
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SigmaApiInternalListTasks
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof SigmaApiInternalListTasks
     */
    readonly ordering?: string;
}

/**
 * Request parameters for internalListTles operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalListTlesRequest
 */
export interface SigmaApiInternalListTlesRequest {
    /**
     *
     * @type {number}
     * @memberof SigmaApiInternalListTles
     */
    readonly sensorPk: number;

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SigmaApiInternalListTles
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SigmaApiInternalListTles
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof SigmaApiInternalListTles
     */
    readonly ordering?: string;
}

/**
 * Request parameters for internalPartialUpdateAoi operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalPartialUpdateAoiRequest
 */
export interface SigmaApiInternalPartialUpdateAoiRequest {
    /**
     * A unique integer value identifying this program aoi.
     * @type {number}
     * @memberof SigmaApiInternalPartialUpdateAoi
     */
    readonly id: number;

    /**
     *
     * @type {PatchedProgramAOIRequest}
     * @memberof SigmaApiInternalPartialUpdateAoi
     */
    readonly patchedProgramAOIRequest?: PatchedProgramAOIRequest;
}

/**
 * Request parameters for internalPartialUpdateAsset operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalPartialUpdateAssetRequest
 */
export interface SigmaApiInternalPartialUpdateAssetRequest {
    /**
     * A unique integer value identifying this asset.
     * @type {number}
     * @memberof SigmaApiInternalPartialUpdateAsset
     */
    readonly id: number;

    /**
     *
     * @type {PatchedPrivateAssetRequest}
     * @memberof SigmaApiInternalPartialUpdateAsset
     */
    readonly patchedPrivateAssetRequest?: PatchedPrivateAssetRequest;
}

/**
 * Request parameters for internalPartialUpdateDetection operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalPartialUpdateDetectionRequest
 */
export interface SigmaApiInternalPartialUpdateDetectionRequest {
    /**
     * A unique integer value identifying this detection.
     * @type {number}
     * @memberof SigmaApiInternalPartialUpdateDetection
     */
    readonly pkId: number;

    /**
     *
     * @type {PatchedInternalDetectionRequest}
     * @memberof SigmaApiInternalPartialUpdateDetection
     */
    readonly patchedInternalDetectionRequest?: PatchedInternalDetectionRequest;
}

/**
 * Request parameters for internalPartialUpdateDetectionArtifact operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalPartialUpdateDetectionArtifactRequest
 */
export interface SigmaApiInternalPartialUpdateDetectionArtifactRequest {
    /**
     * A unique integer value identifying this detection artifact.
     * @type {number}
     * @memberof SigmaApiInternalPartialUpdateDetectionArtifact
     */
    readonly id: number;

    /**
     *
     * @type {PatchedDetectionArtifactRequest}
     * @memberof SigmaApiInternalPartialUpdateDetectionArtifact
     */
    readonly patchedDetectionArtifactRequest?: PatchedDetectionArtifactRequest;
}

/**
 * Request parameters for internalPartialUpdateDownload operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalPartialUpdateDownloadRequest
 */
export interface SigmaApiInternalPartialUpdateDownloadRequest {
    /**
     * A unique integer value identifying this download request.
     * @type {number}
     * @memberof SigmaApiInternalPartialUpdateDownload
     */
    readonly id: number;

    /**
     *
     * @type {PatchedInternalDownloadRequestRequest}
     * @memberof SigmaApiInternalPartialUpdateDownload
     */
    readonly patchedInternalDownloadRequestRequest?: PatchedInternalDownloadRequestRequest;
}

/**
 * Request parameters for internalPartialUpdateOrbit operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalPartialUpdateOrbitRequest
 */
export interface SigmaApiInternalPartialUpdateOrbitRequest {
    /**
     * A unique integer value identifying this tick.
     * @type {number}
     * @memberof SigmaApiInternalPartialUpdateOrbit
     */
    readonly id: number;

    /**
     *
     * @type {PatchedOpportunityRequest}
     * @memberof SigmaApiInternalPartialUpdateOrbit
     */
    readonly patchedOpportunityRequest?: PatchedOpportunityRequest;
}

/**
 * Request parameters for internalPartialUpdatePlan operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalPartialUpdatePlanRequest
 */
export interface SigmaApiInternalPartialUpdatePlanRequest {
    /**
     * A unique integer value identifying this plan.
     * @type {number}
     * @memberof SigmaApiInternalPartialUpdatePlan
     */
    readonly id: number;

    /**
     *
     * @type {PatchedPrivatePlanRequest}
     * @memberof SigmaApiInternalPartialUpdatePlan
     */
    readonly patchedPrivatePlanRequest?: PatchedPrivatePlanRequest;
}

/**
 * Request parameters for internalPartialUpdateReport operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalPartialUpdateReportRequest
 */
export interface SigmaApiInternalPartialUpdateReportRequest {
    /**
     * A unique integer value identifying this report.
     * @type {number}
     * @memberof SigmaApiInternalPartialUpdateReport
     */
    readonly id: number;

    /**
     *
     * @type {PatchedInternalReportRequest}
     * @memberof SigmaApiInternalPartialUpdateReport
     */
    readonly patchedInternalReportRequest?: PatchedInternalReportRequest;
}

/**
 * Request parameters for internalPartialUpdateSensor operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalPartialUpdateSensorRequest
 */
export interface SigmaApiInternalPartialUpdateSensorRequest {
    /**
     * A unique value identifying this sensor.
     * @type {number}
     * @memberof SigmaApiInternalPartialUpdateSensor
     */
    readonly oskId: number;

    /**
     *
     * @type {PatchedSensorRequest}
     * @memberof SigmaApiInternalPartialUpdateSensor
     */
    readonly patchedSensorRequest?: PatchedSensorRequest;
}

/**
 * Request parameters for internalPartialUpdateStateVector operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalPartialUpdateStateVectorRequest
 */
export interface SigmaApiInternalPartialUpdateStateVectorRequest {
    /**
     * A unique integer value identifying this state vector.
     * @type {number}
     * @memberof SigmaApiInternalPartialUpdateStateVector
     */
    readonly id: number;

    /**
     *
     * @type {number}
     * @memberof SigmaApiInternalPartialUpdateStateVector
     */
    readonly sensorPk: number;

    /**
     *
     * @type {PatchedStateVectorRequest}
     * @memberof SigmaApiInternalPartialUpdateStateVector
     */
    readonly patchedStateVectorRequest?: PatchedStateVectorRequest;
}

/**
 * Request parameters for internalPartialUpdateTask operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalPartialUpdateTaskRequest
 */
export interface SigmaApiInternalPartialUpdateTaskRequest {
    /**
     * A unique integer value identifying this plan task.
     * @type {number}
     * @memberof SigmaApiInternalPartialUpdateTask
     */
    readonly id: number;

    /**
     *
     * @type {number}
     * @memberof SigmaApiInternalPartialUpdateTask
     */
    readonly planPk: number;

    /**
     *
     * @type {PatchedPlanTaskRequest}
     * @memberof SigmaApiInternalPartialUpdateTask
     */
    readonly patchedPlanTaskRequest?: PatchedPlanTaskRequest;
}

/**
 * Request parameters for internalUpdateAoi operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalUpdateAoiRequest
 */
export interface SigmaApiInternalUpdateAoiRequest {
    /**
     * A unique integer value identifying this program aoi.
     * @type {number}
     * @memberof SigmaApiInternalUpdateAoi
     */
    readonly id: number;

    /**
     *
     * @type {ProgramAOIRequest}
     * @memberof SigmaApiInternalUpdateAoi
     */
    readonly programAOIRequest: ProgramAOIRequest;
}

/**
 * Request parameters for internalUpdateAsset operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalUpdateAssetRequest
 */
export interface SigmaApiInternalUpdateAssetRequest {
    /**
     * A unique integer value identifying this asset.
     * @type {number}
     * @memberof SigmaApiInternalUpdateAsset
     */
    readonly id: number;

    /**
     *
     * @type {PrivateAssetRequest}
     * @memberof SigmaApiInternalUpdateAsset
     */
    readonly privateAssetRequest: PrivateAssetRequest;
}

/**
 * Request parameters for internalUpdateDetection operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalUpdateDetectionRequest
 */
export interface SigmaApiInternalUpdateDetectionRequest {
    /**
     * A unique integer value identifying this detection.
     * @type {number}
     * @memberof SigmaApiInternalUpdateDetection
     */
    readonly pkId: number;

    /**
     *
     * @type {InternalDetectionRequest}
     * @memberof SigmaApiInternalUpdateDetection
     */
    readonly internalDetectionRequest: InternalDetectionRequest;
}

/**
 * Request parameters for internalUpdateDetectionArtifact operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalUpdateDetectionArtifactRequest
 */
export interface SigmaApiInternalUpdateDetectionArtifactRequest {
    /**
     * A unique integer value identifying this detection artifact.
     * @type {number}
     * @memberof SigmaApiInternalUpdateDetectionArtifact
     */
    readonly id: number;

    /**
     *
     * @type {DetectionArtifactRequest}
     * @memberof SigmaApiInternalUpdateDetectionArtifact
     */
    readonly detectionArtifactRequest: DetectionArtifactRequest;
}

/**
 * Request parameters for internalUpdateDownload operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalUpdateDownloadRequest
 */
export interface SigmaApiInternalUpdateDownloadRequest {
    /**
     * A unique integer value identifying this download request.
     * @type {number}
     * @memberof SigmaApiInternalUpdateDownload
     */
    readonly id: number;

    /**
     *
     * @type {InternalDownloadRequestRequest}
     * @memberof SigmaApiInternalUpdateDownload
     */
    readonly internalDownloadRequestRequest: InternalDownloadRequestRequest;
}

/**
 * Request parameters for internalUpdateOrbit operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalUpdateOrbitRequest
 */
export interface SigmaApiInternalUpdateOrbitRequest {
    /**
     * A unique integer value identifying this tick.
     * @type {number}
     * @memberof SigmaApiInternalUpdateOrbit
     */
    readonly id: number;

    /**
     *
     * @type {OpportunityRequest}
     * @memberof SigmaApiInternalUpdateOrbit
     */
    readonly opportunityRequest: OpportunityRequest;
}

/**
 * Request parameters for internalUpdatePlan operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalUpdatePlanRequest
 */
export interface SigmaApiInternalUpdatePlanRequest {
    /**
     * A unique integer value identifying this plan.
     * @type {number}
     * @memberof SigmaApiInternalUpdatePlan
     */
    readonly id: number;

    /**
     *
     * @type {PrivatePlanRequest}
     * @memberof SigmaApiInternalUpdatePlan
     */
    readonly privatePlanRequest: PrivatePlanRequest;
}

/**
 * Request parameters for internalUpdateReport operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalUpdateReportRequest
 */
export interface SigmaApiInternalUpdateReportRequest {
    /**
     * A unique integer value identifying this report.
     * @type {number}
     * @memberof SigmaApiInternalUpdateReport
     */
    readonly id: number;

    /**
     *
     * @type {InternalReportRequest}
     * @memberof SigmaApiInternalUpdateReport
     */
    readonly internalReportRequest: InternalReportRequest;
}

/**
 * Request parameters for internalUpdateSensor operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalUpdateSensorRequest
 */
export interface SigmaApiInternalUpdateSensorRequest {
    /**
     * A unique value identifying this sensor.
     * @type {number}
     * @memberof SigmaApiInternalUpdateSensor
     */
    readonly oskId: number;

    /**
     *
     * @type {SensorRequest}
     * @memberof SigmaApiInternalUpdateSensor
     */
    readonly sensorRequest: SensorRequest;
}

/**
 * Request parameters for internalUpdateStateVector operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalUpdateStateVectorRequest
 */
export interface SigmaApiInternalUpdateStateVectorRequest {
    /**
     * A unique integer value identifying this state vector.
     * @type {number}
     * @memberof SigmaApiInternalUpdateStateVector
     */
    readonly id: number;

    /**
     *
     * @type {number}
     * @memberof SigmaApiInternalUpdateStateVector
     */
    readonly sensorPk: number;

    /**
     *
     * @type {StateVectorRequest}
     * @memberof SigmaApiInternalUpdateStateVector
     */
    readonly stateVectorRequest: StateVectorRequest;
}

/**
 * Request parameters for internalUpdateTask operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalUpdateTaskRequest
 */
export interface SigmaApiInternalUpdateTaskRequest {
    /**
     * A unique integer value identifying this plan task.
     * @type {number}
     * @memberof SigmaApiInternalUpdateTask
     */
    readonly id: number;

    /**
     *
     * @type {number}
     * @memberof SigmaApiInternalUpdateTask
     */
    readonly planPk: number;

    /**
     *
     * @type {PlanTaskRequest}
     * @memberof SigmaApiInternalUpdateTask
     */
    readonly planTaskRequest: PlanTaskRequest;
}

/**
 * Request parameters for internalUpdateTle operation in SigmaApi.
 * @export
 * @interface SigmaApiInternalUpdateTleRequest
 */
export interface SigmaApiInternalUpdateTleRequest {
    /**
     * A unique integer value identifying this tle.
     * @type {number}
     * @memberof SigmaApiInternalUpdateTle
     */
    readonly id: number;

    /**
     *
     * @type {number}
     * @memberof SigmaApiInternalUpdateTle
     */
    readonly sensorPk: number;

    /**
     *
     * @type {TLERequest}
     * @memberof SigmaApiInternalUpdateTle
     */
    readonly tLERequest: TLERequest;
}

/**
 * Request parameters for listAssets operation in SigmaApi.
 * @export
 * @interface SigmaApiListAssetsRequest
 */
export interface SigmaApiListAssetsRequest {
    /**
     *
     * @type {number}
     * @memberof SigmaApiListAssets
     */
    readonly program: number;

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SigmaApiListAssets
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SigmaApiListAssets
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof SigmaApiListAssets
     */
    readonly ordering?: string;
}

/**
 * Request parameters for listCaptures operation in SigmaApi.
 * @export
 * @interface SigmaApiListCapturesRequest
 */
export interface SigmaApiListCapturesRequest {
    /**
     *
     * @type {number}
     * @memberof SigmaApiListCaptures
     */
    readonly program: number;

    /**
     *
     * @type {Geometry | GeometryCollection}
     * @memberof SigmaApiListCaptures
     */
    readonly aoi?: Geometry | GeometryCollection;

    /**
     *
     * @type {string}
     * @memberof SigmaApiListCaptures
     */
    readonly capturedAfter?: string;

    /**
     *
     * @type {string}
     * @memberof SigmaApiListCaptures
     */
    readonly capturedBefore?: string;

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SigmaApiListCaptures
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SigmaApiListCaptures
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof SigmaApiListCaptures
     */
    readonly ordering?: string;

    /**
     *
     * @type {Array<number>}
     * @memberof SigmaApiListCaptures
     */
    readonly sensor?: Array<number>;
}

/**
 * Request parameters for listDetections operation in SigmaApi.
 * @export
 * @interface SigmaApiListDetectionsRequest
 */
export interface SigmaApiListDetectionsRequest {
    /**
     *
     * @type {number}
     * @memberof SigmaApiListDetections
     */
    readonly program: number;

    /**
     * The area of interest (AOI) to search (accepts GeoGJSON and WKT).Limits detections to those that within the AOI
     * @type {Geometry | GeometryCollection}
     * @memberof SigmaApiListDetections
     */
    readonly aoi?: Geometry | GeometryCollection;

    /**
     * Filter detections to a single asset
     * @type {number}
     * @memberof SigmaApiListDetections
     */
    readonly asset?: number;

    /**
     * Call Type of this Detection
     * @type {string}
     * @memberof SigmaApiListDetections
     */
    readonly callType?: string;

    /**
     * Limits detections by the time it\&#39;s corresponding image was taken
     * @type {string}
     * @memberof SigmaApiListDetections
     */
    readonly detectedAfter?: string;

    /**
     * Limits detections by the time it\&#39;s corresponding image was taken
     * @type {string}
     * @memberof SigmaApiListDetections
     */
    readonly detectedBefore?: string;

    /**
     * One or more detection classifications to return
     * @type {Array<'alert' | 'default'>}
     * @memberof SigmaApiListDetections
     */
    readonly detectionClass?: Array<'alert' | 'default'>;

    /**
     * Type of this Detection
     * @type {string}
     * @memberof SigmaApiListDetections
     */
    readonly detectionType?: string;

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SigmaApiListDetections
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SigmaApiListDetections
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof SigmaApiListDetections
     */
    readonly ordering?: string;
}

/**
 * Request parameters for listDownloads operation in SigmaApi.
 * @export
 * @interface SigmaApiListDownloadsRequest
 */
export interface SigmaApiListDownloadsRequest {
    /**
     *
     * @type {number}
     * @memberof SigmaApiListDownloads
     */
    readonly program: number;

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SigmaApiListDownloads
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SigmaApiListDownloads
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof SigmaApiListDownloads
     */
    readonly ordering?: string;
}

/**
 * Request parameters for listPlans operation in SigmaApi.
 * @export
 * @interface SigmaApiListPlansRequest
 */
export interface SigmaApiListPlansRequest {
    /**
     *
     * @type {number}
     * @memberof SigmaApiListPlans
     */
    readonly program: number;

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SigmaApiListPlans
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SigmaApiListPlans
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof SigmaApiListPlans
     */
    readonly ordering?: string;
}

/**
 * Request parameters for listReports operation in SigmaApi.
 * @export
 * @interface SigmaApiListReportsRequest
 */
export interface SigmaApiListReportsRequest {
    /**
     *
     * @type {number}
     * @memberof SigmaApiListReports
     */
    readonly program: number;

    /**
     * The unique id for this report
     * @type {string}
     * @memberof SigmaApiListReports
     */
    readonly externalId?: string;

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SigmaApiListReports
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SigmaApiListReports
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof SigmaApiListReports
     */
    readonly ordering?: string;

    /**
     * The time for the end of the search. Limits reports to those covering a period starting before the end time.
     * @type {string}
     * @memberof SigmaApiListReports
     */
    readonly searchTimeEnd?: string;

    /**
     * The time for the start of the search. Limits reports to those covering a period ending after the start time.
     * @type {string}
     * @memberof SigmaApiListReports
     */
    readonly searchTimeStart?: string;
}

/**
 * Request parameters for listSearchAreas operation in SigmaApi.
 * @export
 * @interface SigmaApiListSearchAreasRequest
 */
export interface SigmaApiListSearchAreasRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SigmaApiListSearchAreas
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SigmaApiListSearchAreas
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof SigmaApiListSearchAreas
     */
    readonly ordering?: string;
}

/**
 * Request parameters for listSensors operation in SigmaApi.
 * @export
 * @interface SigmaApiListSensorsRequest
 */
export interface SigmaApiListSensorsRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SigmaApiListSensors
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SigmaApiListSensors
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof SigmaApiListSensors
     */
    readonly ordering?: string;
}

/**
 * Request parameters for partialUpdateAsset operation in SigmaApi.
 * @export
 * @interface SigmaApiPartialUpdateAssetRequest
 */
export interface SigmaApiPartialUpdateAssetRequest {
    /**
     * A unique integer value identifying this asset.
     * @type {number}
     * @memberof SigmaApiPartialUpdateAsset
     */
    readonly id: number;

    /**
     *
     * @type {number}
     * @memberof SigmaApiPartialUpdateAsset
     */
    readonly program: number;

    /**
     *
     * @type {PatchedPublicAssetRequest}
     * @memberof SigmaApiPartialUpdateAsset
     */
    readonly patchedPublicAssetRequest?: PatchedPublicAssetRequest;
}

/**
 * Request parameters for partialUpdateReport operation in SigmaApi.
 * @export
 * @interface SigmaApiPartialUpdateReportRequest
 */
export interface SigmaApiPartialUpdateReportRequest {
    /**
     * A unique integer value identifying this report.
     * @type {number}
     * @memberof SigmaApiPartialUpdateReport
     */
    readonly id: number;

    /**
     *
     * @type {number}
     * @memberof SigmaApiPartialUpdateReport
     */
    readonly program: number;

    /**
     *
     * @type {PatchedReportRequest}
     * @memberof SigmaApiPartialUpdateReport
     */
    readonly patchedReportRequest?: PatchedReportRequest;
}

/**
 * Request parameters for partialUpdateSearchArea operation in SigmaApi.
 * @export
 * @interface SigmaApiPartialUpdateSearchAreaRequest
 */
export interface SigmaApiPartialUpdateSearchAreaRequest {
    /**
     * A unique integer value identifying this search area.
     * @type {number}
     * @memberof SigmaApiPartialUpdateSearchArea
     */
    readonly id: number;

    /**
     *
     * @type {PatchedSearchAreaRequest}
     * @memberof SigmaApiPartialUpdateSearchArea
     */
    readonly patchedSearchAreaRequest?: PatchedSearchAreaRequest;
}

/**
 * Request parameters for searchOrbits operation in SigmaApi.
 * @export
 * @interface SigmaApiSearchOrbitsRequest
 */
export interface SigmaApiSearchOrbitsRequest {
    /**
     * The area of interest (AOI) to search (accepts GeoGJSON and WKT). Limits opportunities to those that intersect the satellite\&#39;s maximum field of view.
     * @type {Geometry | GeometryCollection}
     * @memberof SigmaApiSearchOrbits
     */
    readonly aoi?: Geometry | GeometryCollection;

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SigmaApiSearchOrbits
     */
    readonly limit?: number;

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SigmaApiSearchOrbits
     */
    readonly offset?: number;

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof SigmaApiSearchOrbits
     */
    readonly ordering?: string;

    /**
     * The time for the end of the search. Limits opportunities to those that start before the end time.
     * @type {string}
     * @memberof SigmaApiSearchOrbits
     */
    readonly searchTimeEnd?: string;

    /**
     * The time for the start of the search. Limits opportunities to those that end after the start time.
     * @type {string}
     * @memberof SigmaApiSearchOrbits
     */
    readonly searchTimeStart?: string;

    /**
     * The sensor to search for. Limits opportunities to specific sensor(s).
     * @type {number}
     * @memberof SigmaApiSearchOrbits
     */
    readonly sensor?: number;
}

/**
 * Request parameters for updateAsset operation in SigmaApi.
 * @export
 * @interface SigmaApiUpdateAssetRequest
 */
export interface SigmaApiUpdateAssetRequest {
    /**
     * A unique integer value identifying this asset.
     * @type {number}
     * @memberof SigmaApiUpdateAsset
     */
    readonly id: number;

    /**
     *
     * @type {number}
     * @memberof SigmaApiUpdateAsset
     */
    readonly program: number;

    /**
     *
     * @type {PublicAssetRequest}
     * @memberof SigmaApiUpdateAsset
     */
    readonly publicAssetRequest?: PublicAssetRequest;
}

/**
 * Request parameters for updateReport operation in SigmaApi.
 * @export
 * @interface SigmaApiUpdateReportRequest
 */
export interface SigmaApiUpdateReportRequest {
    /**
     * A unique integer value identifying this report.
     * @type {number}
     * @memberof SigmaApiUpdateReport
     */
    readonly id: number;

    /**
     *
     * @type {number}
     * @memberof SigmaApiUpdateReport
     */
    readonly program: number;

    /**
     *
     * @type {ReportRequest}
     * @memberof SigmaApiUpdateReport
     */
    readonly reportRequest: ReportRequest;
}

/**
 * Request parameters for updateSearchArea operation in SigmaApi.
 * @export
 * @interface SigmaApiUpdateSearchAreaRequest
 */
export interface SigmaApiUpdateSearchAreaRequest {
    /**
     * A unique integer value identifying this search area.
     * @type {number}
     * @memberof SigmaApiUpdateSearchArea
     */
    readonly id: number;

    /**
     *
     * @type {SearchAreaRequest}
     * @memberof SigmaApiUpdateSearchArea
     */
    readonly searchAreaRequest: SearchAreaRequest;
}

/**
 * SigmaApi - object-oriented interface
 * @export
 * @class SigmaApi
 * @extends {BaseAPI}
 */
export class SigmaApi extends BaseAPI {
    /**
     * API operations related to Download Requests, a set of raster images that has been requested by a user
     * @param {SigmaApiCreateDownloadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public createDownload(requestParameters: SigmaApiCreateDownloadRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .createDownload(requestParameters.program, requestParameters.downloadRequestRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiCreatePlanRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public createPlan(requestParameters: SigmaApiCreatePlanRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .createPlan(requestParameters.program, requestParameters.publicPlanRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiCreateReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public createReport(requestParameters: SigmaApiCreateReportRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .createReport(requestParameters.program, requestParameters.reportRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to user-created areas of interest.
     * @param {SigmaApiCreateSearchAreaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public createSearchArea(requestParameters: SigmaApiCreateSearchAreaRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .createSearchArea(requestParameters.searchAreaRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiDestroyReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public destroyReport(requestParameters: SigmaApiDestroyReportRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .destroyReport(requestParameters.id, requestParameters.program, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to user-created areas of interest.
     * @param {SigmaApiDestroySearchAreaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public destroySearchArea(requestParameters: SigmaApiDestroySearchAreaRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .destroySearchArea(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiGetAssetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public getAsset(requestParameters: SigmaApiGetAssetRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .getAsset(requestParameters.id, requestParameters.program, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to data pipeline results, the final output of the data pipeline of a single file
     * @param {SigmaApiGetCaptureRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public getCapture(requestParameters: SigmaApiGetCaptureRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .getCapture(requestParameters.id, requestParameters.program, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiGetDetectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public getDetection(requestParameters: SigmaApiGetDetectionRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .getDetection(requestParameters.id, requestParameters.program, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to Download Requests, a set of raster images that has been requested by a user
     * @param {SigmaApiGetDownloadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public getDownload(requestParameters: SigmaApiGetDownloadRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .getDownload(requestParameters.id, requestParameters.program, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiGetPlanRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public getPlan(requestParameters: SigmaApiGetPlanRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .getPlan(requestParameters.id, requestParameters.program, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiGetReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public getReport(requestParameters: SigmaApiGetReportRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .getReport(requestParameters.id, requestParameters.program, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to user-created areas of interest.
     * @param {SigmaApiGetSearchAreaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public getSearchArea(requestParameters: SigmaApiGetSearchAreaRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .getSearchArea(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to sensors. Supports create, read, update, and delete
     * @param {SigmaApiGetSensorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public getSensor(requestParameters: SigmaApiGetSensorRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .getSensor(requestParameters.oskId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method will return the most recently saved StateVector for sensor_id=pk
     * @param {SigmaApiGetStateVectorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public getStateVector(requestParameters: SigmaApiGetStateVectorRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .getStateVector(requestParameters.oskId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method will return the most recently saved TLE for sensor_id=pk
     * @param {SigmaApiGetTleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public getTle(requestParameters: SigmaApiGetTleRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .getTle(requestParameters.oskId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalCreateAoiRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalCreateAoi(requestParameters: SigmaApiInternalCreateAoiRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalCreateAoi(requestParameters.programAOIRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalCreateAssetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalCreateAsset(requestParameters: SigmaApiInternalCreateAssetRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalCreateAsset(requestParameters.privateAssetRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalCreateDetectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalCreateDetection(
        requestParameters: SigmaApiInternalCreateDetectionRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalCreateDetection(requestParameters.internalDetectionRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalCreateDetectionArtifactRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalCreateDetectionArtifact(
        requestParameters: SigmaApiInternalCreateDetectionArtifactRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalCreateDetectionArtifact(requestParameters.detectionArtifactRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to user-created areas of interest.
     * @param {SigmaApiInternalCreateOrbitRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalCreateOrbit(requestParameters: SigmaApiInternalCreateOrbitRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalCreateOrbit(requestParameters.opportunityRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalCreatePlanRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalCreatePlan(requestParameters: SigmaApiInternalCreatePlanRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalCreatePlan(requestParameters.privatePlanRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalCreateReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalCreateReport(requestParameters: SigmaApiInternalCreateReportRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalCreateReport(requestParameters.internalReportRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to sensors. Supports create, read, update, and delete
     * @param {SigmaApiInternalCreateSensorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalCreateSensor(requestParameters: SigmaApiInternalCreateSensorRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalCreateSensor(requestParameters.sensorRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalCreateStateVectorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalCreateStateVector(
        requestParameters: SigmaApiInternalCreateStateVectorRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalCreateStateVector(requestParameters.sensorPk, requestParameters.stateVectorRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalCreateTaskRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalCreateTask(requestParameters: SigmaApiInternalCreateTaskRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalCreateTask(requestParameters.planPk, requestParameters.planTaskRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalCreateTleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalCreateTle(requestParameters: SigmaApiInternalCreateTleRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalCreateTle(requestParameters.sensorPk, requestParameters.tLERequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalDestroyAoiRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalDestroyAoi(requestParameters: SigmaApiInternalDestroyAoiRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalDestroyAoi(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalDestroyAssetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalDestroyAsset(requestParameters: SigmaApiInternalDestroyAssetRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalDestroyAsset(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalDestroyDetectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalDestroyDetection(
        requestParameters: SigmaApiInternalDestroyDetectionRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalDestroyDetection(requestParameters.pkId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalDestroyDetectionArtifactRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalDestroyDetectionArtifact(
        requestParameters: SigmaApiInternalDestroyDetectionArtifactRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalDestroyDetectionArtifact(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to Download Requests, a set of raster images that has been requested by a user
     * @param {SigmaApiInternalDestroyDownloadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalDestroyDownload(
        requestParameters: SigmaApiInternalDestroyDownloadRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalDestroyDownload(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to user-created areas of interest.
     * @param {SigmaApiInternalDestroyOrbitRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalDestroyOrbit(requestParameters: SigmaApiInternalDestroyOrbitRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalDestroyOrbit(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalDestroyPlanRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalDestroyPlan(requestParameters: SigmaApiInternalDestroyPlanRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalDestroyPlan(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalDestroyReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalDestroyReport(
        requestParameters: SigmaApiInternalDestroyReportRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalDestroyReport(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to sensors. Supports create, read, update, and delete
     * @param {SigmaApiInternalDestroySensorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalDestroySensor(
        requestParameters: SigmaApiInternalDestroySensorRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalDestroySensor(requestParameters.oskId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalDestroyStateVectorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalDestroyStateVector(
        requestParameters: SigmaApiInternalDestroyStateVectorRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalDestroyStateVector(requestParameters.id, requestParameters.sensorPk, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalDestroyTaskRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalDestroyTask(requestParameters: SigmaApiInternalDestroyTaskRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalDestroyTask(requestParameters.id, requestParameters.planPk, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalDestroyTleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalDestroyTle(requestParameters: SigmaApiInternalDestroyTleRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalDestroyTle(requestParameters.id, requestParameters.sensorPk, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalGetAoiRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalGetAoi(requestParameters: SigmaApiInternalGetAoiRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalGetAoi(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalGetAssetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalGetAsset(requestParameters: SigmaApiInternalGetAssetRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalGetAsset(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalGetDetectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalGetDetection(requestParameters: SigmaApiInternalGetDetectionRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalGetDetection(requestParameters.pkId, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to user-created areas of interest.
     * @param {SigmaApiInternalGetOrbitRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalGetOrbit(requestParameters: SigmaApiInternalGetOrbitRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalGetOrbit(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalGetPlanRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalGetPlan(requestParameters: SigmaApiInternalGetPlanRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalGetPlan(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalGetReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalGetReport(requestParameters: SigmaApiInternalGetReportRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalGetReport(requestParameters.id, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalGetStateVectorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalGetStateVector(
        requestParameters: SigmaApiInternalGetStateVectorRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalGetStateVector(requestParameters.id, requestParameters.sensorPk, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalGetTaskRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalGetTask(requestParameters: SigmaApiInternalGetTaskRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalGetTask(requestParameters.id, requestParameters.planPk, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalGetTleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalGetTle(requestParameters: SigmaApiInternalGetTleRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalGetTle(requestParameters.id, requestParameters.sensorPk, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalListAoisRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalListAois(requestParameters: SigmaApiInternalListAoisRequest = {}, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalListAois(requestParameters.limit, requestParameters.offset, requestParameters.ordering, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalListAssetsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalListAssets(requestParameters: SigmaApiInternalListAssetsRequest = {}, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalListAssets(
                requestParameters.dist,
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.ordering,
                requestParameters.point,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalListDetectionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalListDetections(
        requestParameters: SigmaApiInternalListDetectionsRequest = {},
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalListDetections(
                requestParameters.aoi,
                requestParameters.asset,
                requestParameters.callType,
                requestParameters.detectedAfter,
                requestParameters.detectedBefore,
                requestParameters.detectionClass,
                requestParameters.detectionType,
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.ordering,
                requestParameters.reviewStatus,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalListPlansRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalListPlans(requestParameters: SigmaApiInternalListPlansRequest = {}, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalListPlans(requestParameters.limit, requestParameters.offset, requestParameters.ordering, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalListReportsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalListReports(
        requestParameters: SigmaApiInternalListReportsRequest = {},
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalListReports(
                requestParameters.externalId,
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.ordering,
                requestParameters.searchTimeEnd,
                requestParameters.searchTimeStart,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalListStateVectorsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalListStateVectors(
        requestParameters: SigmaApiInternalListStateVectorsRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalListStateVectors(
                requestParameters.sensorPk,
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.ordering,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalListTasksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalListTasks(requestParameters: SigmaApiInternalListTasksRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalListTasks(
                requestParameters.planPk,
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.ordering,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalListTlesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalListTles(requestParameters: SigmaApiInternalListTlesRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalListTles(
                requestParameters.sensorPk,
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.ordering,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalPartialUpdateAoiRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalPartialUpdateAoi(
        requestParameters: SigmaApiInternalPartialUpdateAoiRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalPartialUpdateAoi(requestParameters.id, requestParameters.patchedProgramAOIRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalPartialUpdateAssetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalPartialUpdateAsset(
        requestParameters: SigmaApiInternalPartialUpdateAssetRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalPartialUpdateAsset(requestParameters.id, requestParameters.patchedPrivateAssetRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalPartialUpdateDetectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalPartialUpdateDetection(
        requestParameters: SigmaApiInternalPartialUpdateDetectionRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalPartialUpdateDetection(
                requestParameters.pkId,
                requestParameters.patchedInternalDetectionRequest,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalPartialUpdateDetectionArtifactRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalPartialUpdateDetectionArtifact(
        requestParameters: SigmaApiInternalPartialUpdateDetectionArtifactRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalPartialUpdateDetectionArtifact(
                requestParameters.id,
                requestParameters.patchedDetectionArtifactRequest,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to Download Requests, a set of raster images that has been requested by a user
     * @param {SigmaApiInternalPartialUpdateDownloadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalPartialUpdateDownload(
        requestParameters: SigmaApiInternalPartialUpdateDownloadRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalPartialUpdateDownload(
                requestParameters.id,
                requestParameters.patchedInternalDownloadRequestRequest,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to user-created areas of interest.
     * @param {SigmaApiInternalPartialUpdateOrbitRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalPartialUpdateOrbit(
        requestParameters: SigmaApiInternalPartialUpdateOrbitRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalPartialUpdateOrbit(requestParameters.id, requestParameters.patchedOpportunityRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalPartialUpdatePlanRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalPartialUpdatePlan(
        requestParameters: SigmaApiInternalPartialUpdatePlanRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalPartialUpdatePlan(requestParameters.id, requestParameters.patchedPrivatePlanRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalPartialUpdateReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalPartialUpdateReport(
        requestParameters: SigmaApiInternalPartialUpdateReportRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalPartialUpdateReport(requestParameters.id, requestParameters.patchedInternalReportRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to sensors. Supports create, read, update, and delete
     * @param {SigmaApiInternalPartialUpdateSensorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalPartialUpdateSensor(
        requestParameters: SigmaApiInternalPartialUpdateSensorRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalPartialUpdateSensor(requestParameters.oskId, requestParameters.patchedSensorRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalPartialUpdateStateVectorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalPartialUpdateStateVector(
        requestParameters: SigmaApiInternalPartialUpdateStateVectorRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalPartialUpdateStateVector(
                requestParameters.id,
                requestParameters.sensorPk,
                requestParameters.patchedStateVectorRequest,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalPartialUpdateTaskRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalPartialUpdateTask(
        requestParameters: SigmaApiInternalPartialUpdateTaskRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalPartialUpdateTask(
                requestParameters.id,
                requestParameters.planPk,
                requestParameters.patchedPlanTaskRequest,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalUpdateAoiRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalUpdateAoi(requestParameters: SigmaApiInternalUpdateAoiRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalUpdateAoi(requestParameters.id, requestParameters.programAOIRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalUpdateAssetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalUpdateAsset(requestParameters: SigmaApiInternalUpdateAssetRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalUpdateAsset(requestParameters.id, requestParameters.privateAssetRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalUpdateDetectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalUpdateDetection(
        requestParameters: SigmaApiInternalUpdateDetectionRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalUpdateDetection(requestParameters.pkId, requestParameters.internalDetectionRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalUpdateDetectionArtifactRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalUpdateDetectionArtifact(
        requestParameters: SigmaApiInternalUpdateDetectionArtifactRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalUpdateDetectionArtifact(requestParameters.id, requestParameters.detectionArtifactRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to Download Requests, a set of raster images that has been requested by a user
     * @param {SigmaApiInternalUpdateDownloadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalUpdateDownload(
        requestParameters: SigmaApiInternalUpdateDownloadRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalUpdateDownload(requestParameters.id, requestParameters.internalDownloadRequestRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to user-created areas of interest.
     * @param {SigmaApiInternalUpdateOrbitRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalUpdateOrbit(requestParameters: SigmaApiInternalUpdateOrbitRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalUpdateOrbit(requestParameters.id, requestParameters.opportunityRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalUpdatePlanRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalUpdatePlan(requestParameters: SigmaApiInternalUpdatePlanRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalUpdatePlan(requestParameters.id, requestParameters.privatePlanRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalUpdateReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalUpdateReport(requestParameters: SigmaApiInternalUpdateReportRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalUpdateReport(requestParameters.id, requestParameters.internalReportRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to sensors. Supports create, read, update, and delete
     * @param {SigmaApiInternalUpdateSensorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalUpdateSensor(requestParameters: SigmaApiInternalUpdateSensorRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalUpdateSensor(requestParameters.oskId, requestParameters.sensorRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalUpdateStateVectorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalUpdateStateVector(
        requestParameters: SigmaApiInternalUpdateStateVectorRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .internalUpdateStateVector(
                requestParameters.id,
                requestParameters.sensorPk,
                requestParameters.stateVectorRequest,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalUpdateTaskRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalUpdateTask(requestParameters: SigmaApiInternalUpdateTaskRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalUpdateTask(
                requestParameters.id,
                requestParameters.planPk,
                requestParameters.planTaskRequest,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiInternalUpdateTleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public internalUpdateTle(requestParameters: SigmaApiInternalUpdateTleRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .internalUpdateTle(requestParameters.id, requestParameters.sensorPk, requestParameters.tLERequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiListAssetsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public listAssets(requestParameters: SigmaApiListAssetsRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .listAssets(
                requestParameters.program,
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.ordering,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to data pipeline results, the final output of the data pipeline of a single file
     * @param {SigmaApiListCapturesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public listCaptures(requestParameters: SigmaApiListCapturesRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .listCaptures(
                requestParameters.program,
                requestParameters.aoi,
                requestParameters.capturedAfter,
                requestParameters.capturedBefore,
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.ordering,
                requestParameters.sensor,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiListDetectionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public listDetections(requestParameters: SigmaApiListDetectionsRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .listDetections(
                requestParameters.program,
                requestParameters.aoi,
                requestParameters.asset,
                requestParameters.callType,
                requestParameters.detectedAfter,
                requestParameters.detectedBefore,
                requestParameters.detectionClass,
                requestParameters.detectionType,
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.ordering,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to Download Requests, a set of raster images that has been requested by a user
     * @param {SigmaApiListDownloadsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public listDownloads(requestParameters: SigmaApiListDownloadsRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .listDownloads(
                requestParameters.program,
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.ordering,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiListPlansRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public listPlans(requestParameters: SigmaApiListPlansRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .listPlans(
                requestParameters.program,
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.ordering,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiListReportsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public listReports(requestParameters: SigmaApiListReportsRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .listReports(
                requestParameters.program,
                requestParameters.externalId,
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.ordering,
                requestParameters.searchTimeEnd,
                requestParameters.searchTimeStart,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to user-created areas of interest.
     * @param {SigmaApiListSearchAreasRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public listSearchAreas(requestParameters: SigmaApiListSearchAreasRequest = {}, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .listSearchAreas(requestParameters.limit, requestParameters.offset, requestParameters.ordering, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to sensors. Supports create, read, update, and delete
     * @param {SigmaApiListSensorsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public listSensors(requestParameters: SigmaApiListSensorsRequest = {}, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .listSensors(requestParameters.limit, requestParameters.offset, requestParameters.ordering, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiPartialUpdateAssetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public partialUpdateAsset(requestParameters: SigmaApiPartialUpdateAssetRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .partialUpdateAsset(
                requestParameters.id,
                requestParameters.program,
                requestParameters.patchedPublicAssetRequest,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiPartialUpdateReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public partialUpdateReport(requestParameters: SigmaApiPartialUpdateReportRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .partialUpdateReport(
                requestParameters.id,
                requestParameters.program,
                requestParameters.patchedReportRequest,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to user-created areas of interest.
     * @param {SigmaApiPartialUpdateSearchAreaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public partialUpdateSearchArea(
        requestParameters: SigmaApiPartialUpdateSearchAreaRequest,
        options?: AxiosRequestConfig,
    ) {
        return SigmaApiFp(this.configuration)
            .partialUpdateSearchArea(requestParameters.id, requestParameters.patchedSearchAreaRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of opportunities for OSK\'s sensors, filtered for sensor, time, and area of interest  (AOI). Each opportunity is a 10-second piece of the orbit.
     * @summary Search orbital paths for opportunities
     * @param {SigmaApiSearchOrbitsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public searchOrbits(requestParameters: SigmaApiSearchOrbitsRequest = {}, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .searchOrbits(
                requestParameters.aoi,
                requestParameters.limit,
                requestParameters.offset,
                requestParameters.ordering,
                requestParameters.searchTimeEnd,
                requestParameters.searchTimeStart,
                requestParameters.sensor,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiUpdateAssetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public updateAsset(requestParameters: SigmaApiUpdateAssetRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .updateAsset(requestParameters.id, requestParameters.program, requestParameters.publicAssetRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {SigmaApiUpdateReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public updateReport(requestParameters: SigmaApiUpdateReportRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .updateReport(requestParameters.id, requestParameters.program, requestParameters.reportRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * API operations related to user-created areas of interest.
     * @param {SigmaApiUpdateSearchAreaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigmaApi
     */
    public updateSearchArea(requestParameters: SigmaApiUpdateSearchAreaRequest, options?: AxiosRequestConfig) {
        return SigmaApiFp(this.configuration)
            .updateSearchArea(requestParameters.id, requestParameters.searchAreaRequest, options)
            .then((request) => request(this.axios, this.basePath));
    }
}
