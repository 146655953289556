import { Id, Report, SigmaAPI } from 'oskcore';
import { AppDispatch } from '~/redux/store';

export type ProgramReport = Report;

export const SET_FETCHING_REPORTS = 'SET_FETCHING_REPORTS';
export function setFetchingReports(isFetching: boolean) {
    return { type: SET_FETCHING_REPORTS, payload: { isFetching } };
}

export const SET_PROGRAM_REPORTS = 'SET_PROGRAM_REPORTS';
export function setProgramReports(reports: Array<ProgramReport>) {
    return { type: SET_PROGRAM_REPORTS, payload: { reports } };
}

export function fetchProgramReportsAsync(programId: Id) {
    return (dispatch: AppDispatch) => {
        dispatch(setFetchingReports(true));

        SigmaAPI.listReports({ program: parseInt(`${programId}`) })
            .then((resp) => {
                if (resp.data.results) {
                    dispatch(setProgramReports(resp.data.results));
                }
            })
            .finally(() => {
                dispatch(setFetchingReports(false));
            });
    };
}

type ProgramReportState = {
    isFetching: boolean;
    reports: Array<ProgramReport>;
};

const initialState: ProgramReportState = {
    isFetching: false,
    reports: [],
};

export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        case SET_FETCHING_REPORTS: {
            const { isFetching } = action.payload;
            return {
                ...state,
                isFetching,
            };
        }

        case SET_PROGRAM_REPORTS: {
            const { reports } = action.payload;
            return {
                ...state,
                reports,
            };
        }

        default: {
            return { ...state };
        }
    }
}
