import React, { useCallback, useEffect, useState } from 'react';
import { IconButton, IconButtonProps } from '~/atoms';
import { AOIFormAttributes, AOISaveDialog } from '~/molecules';
import { FaSave } from 'react-icons/fa';
import { OSKGeoJson } from 'oskcore';

export type SaveAreaModalButtonType = Omit<IconButtonProps, 'onToggle'> & {
    area?: OSKGeoJson;
    disabled?: boolean;
    onToggle?: (isVisible: boolean) => void;
    saveAoi: (AreaName: string, geoJson: OSKGeoJson) => Promise<any>;
    reloadLibrary?: () => void;
};

export const SaveAreaModalButton = ({
    area,
    disabled,
    onToggle,
    reloadLibrary,
    saveAoi,
    ...props
}: SaveAreaModalButtonType) => {
    const [isLoading, setIsLoading] = useState(false);
    const [saveModalVisible, setSaveModalVisible] = useState(false);
    const [modalError, setModalError] = useState<string | undefined>(undefined);

    // Handle cancelling the modal
    const handleCancel = () => {
        setSaveModalVisible(false);
        setModalError(undefined);
    };

    // Handle showing the modal
    const showModal = () => {
        if (!disabled) {
            setSaveModalVisible(true);
        }
    };

    // Handle submitting the modal
    const handleModalSubmit = useCallback(
        ({ AreaName }: AOIFormAttributes) => {
            // Clear modal error
            setModalError(undefined);
            setIsLoading(true);

            // Basic validations
            if (AreaName === undefined) {
                setModalError('Please specify an area name');
                setIsLoading(false);
            } else if (area) {
                saveAoi(AreaName, area)
                    .then(() => {
                        setSaveModalVisible(false);
                        reloadLibrary && reloadLibrary();
                    })
                    .catch(() => {
                        // NOTE: This may not be the actual reason an exception happened.
                        setModalError('This name may already be in use');
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            } else {
                setIsLoading(false);
                setSaveModalVisible(false);
            }
        },
        [area, setIsLoading, setSaveModalVisible, setModalError, saveAoi, reloadLibrary],
    );

    useEffect(() => {
        onToggle && onToggle(saveModalVisible);
    }, [onToggle, saveModalVisible]);

    return (
        <React.Fragment>
            <AOISaveDialog
                isLoading={isLoading}
                errorMessage={modalError}
                onSubmit={handleModalSubmit}
                visible={saveModalVisible}
                onCancel={handleCancel}
            />
            <IconButton p={0} label="Save" icon={FaSave} onClick={showModal} hoverable {...props} />
        </React.Fragment>
    );
};
