import React from 'react';
import { NavComponent, OSKView } from 'oskcore';
import { WizardTemplate } from '~/templates';
import AOIStep from './AOIStep';
import ReviewStep from './ReviewStep';
import { DataSourceStep } from './DataSourceStep';
import { TimeFrameStep } from './TimeFrameStep';
import { Box, Form } from 'oskcomponents';
import AppShimmerBar from '~/organisms/AppShimmerBar';
import ActiveModeSwitcher from '~/organisms/ActiveModeSwitcher';
import { SigmaNavItems } from '~/organisms/SigmaNavItems';
export default () => {
    return (
        <OSKView constrained>
            <NavComponent>
                <Box style={{ paddingLeft: '10px' }}>
                    <SigmaNavItems />
                </Box>
            </NavComponent>
            <Box style={{ flexGrow: 1 }}>
                <AppShimmerBar />
                <ActiveModeSwitcher />
                <Form
                    onSubmit={(data) => {
                        // TODO: Handle the data submission here
                    }}
                    style={{ flexGrow: 1, height: '100%' }}
                >
                    <Box
                        m={20}
                        style={{ height: 'calc(100vh - 120px)', justifyContent: 'center', alignItems: 'center' }}
                        grow
                        col
                    >
                        <WizardTemplate
                            steps={[
                                {
                                    label: 'Data Source',
                                    view: <DataSourceStep />,
                                },
                                {
                                    label: 'Time Frame',
                                    view: <TimeFrameStep />,
                                },
                                {
                                    label: 'AOI Region',
                                    view: <AOIStep />,
                                },
                                {
                                    label: 'Review Order',
                                    view: <ReviewStep />,
                                },
                            ]}
                        />
                    </Box>
                </Form>
            </Box>
        </OSKView>
    );
};
