import React from 'react';
import { List } from 'oskcomponents';
import { CartGridItem } from '~/molecules/CartGridItem';
import { Capture, DownloadIntent, Sensor } from 'oskcore';

export type CartGridProps = {
    /** A collection of download intents, grouped by collectId */
    items: Array<DownloadIntent | undefined>;
    /** A map of ResultItems. Key is fileId value is PipelineResult file */
    resultItemMap: Record<string, Capture | undefined>;
    /** A list of all supported OSK sensors */
    sensors: Array<Sensor>;
    /** A method for updating a particular download intent (either to skip or include it) */
    onToggle: (fileId: string) => void;
};

export const CartGrid = ({ items, resultItemMap, sensors, onToggle }: CartGridProps) => {
    return (
        <List
            style={{
                flexWrap: 'wrap',
                alignContent: 'flex-start',
                justifyContent: 'flex-start',
                flexFlow: 'wrap',
                width: '100%',
            }}
            row
        >
            {items
                .filter((item) => item !== undefined && item !== null)
                .map((item) => (
                    <CartGridItem
                        p={10}
                        sensor={
                            sensors.filter(
                                (sensor) => sensor.osk_id === resultItemMap[item?.fileId || -1]?.sensor_id,
                            )[0]
                        }
                        item={resultItemMap[item?.fileId || -1]}
                        onToggle={() => {
                            onToggle(item?.fileId || '-1');
                        }}
                        checked={item?.skip !== true}
                        key={`cart_item_${item?.fileId || -1}`}
                    />
                ))}
        </List>
    );
};
