import React from 'react';
import { useTheme } from 'styled-components';
import { Box, BoxProps, OSKThemeType } from 'oskcomponents';
import CSS from 'csstype';

export type PartitionBlurbProps = {
    /** The label to render */
    label: string;
    /** Additional styling to apply */
    style?: CSS.Properties;
} & Omit<BoxProps, 'ref'>;

/** PartitionBlubr is a component which divides Time sections in the Timeline. */
export const PartitionBlurb = ({ label, style, ...props }: PartitionBlurbProps) => {
    const theme = useTheme() as OSKThemeType;
    return (
        <Box
            style={{
                fontSize: '1.15rem',
                color: theme.colors.accent,
                ...style,
            }}
            {...props}
        >
            {label}
        </Box>
    );
};
