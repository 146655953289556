import React from 'react';
import { Box, BoxProps } from '../Box';
import CSS from 'csstype';

export type SidePanelFooterProps = {
    /** Children elements to render in the footer */
    children?: React.ReactNode;
    /** Styles to override */
    style?: CSS.Properties;
} & Omit<BoxProps, 'ref'>;

/**
 * This represents a SidePanel footer element. For consistency, compose with
 * SidePanelBody and SidePanelHeader and SidePanel to create a full popup dialog.
 */
const SidePanelFooter = ({ children, style, ...props }: SidePanelFooterProps) => {
    return (
        <Box
            style={{
                ...style,
            }}
            {...props}
        >
            {React.Children.map(children, (child) => (
                <Box>{child}</Box>
            ))}
        </Box>
    );
};

export { SidePanelFooter };
