import React, { useEffect } from 'react';
import { OSKView } from 'oskcore';
import { Box, Heading, OSKThemeType, ResizableViewLayout, ResizableViewPanel } from 'oskcomponents';
import ActiveModeSwitcher from '~/organisms/ActiveModeSwitcher';
import { getTaskingItems, setTaskingList } from '~/redux/modules/tasking/app';
import { NavComponent } from 'oskcore';
import { SigmaNavItems } from '~/organisms/SigmaNavItems';
import TaskListView from './TaskListView';
import TaskDetailView from './TaskDetailView';
import { useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';

const DashboardView = () => {
    const dispatch = useDispatch();
    const theme = useTheme() as OSKThemeType;

    useEffect(() => {
        getTaskingItems().then((tasks) => {
            dispatch(setTaskingList(tasks));
        });
    }, [dispatch]);

    return (
        <OSKView>
            <NavComponent>
                <Box style={{ paddingLeft: '10px' }}>
                    <SigmaNavItems />
                </Box>
            </NavComponent>
            <Box style={{ flexGrow: 1 }}>
                <ActiveModeSwitcher />

                <Box style={{ margin: '20px' }} grow col>
                    <Heading variant="large" p={12}>
                        Tasking Dashboard
                    </Heading>
                    <Box
                        col
                        style={{
                            borderRadius: '30px',
                            backgroundColor: theme.colors.primary.transBg,
                            position: 'relative',
                            height: 'calc(100vh - 180px)',
                            overflow: 'hidden',
                        }}
                    >
                        <ResizableViewLayout orientation="vertical">
                            <ResizableViewPanel weight={100}>
                                <TaskListView />
                            </ResizableViewPanel>
                            <ResizableViewPanel weight={100}>
                                <TaskDetailView />
                            </ResizableViewPanel>
                        </ResizableViewLayout>
                    </Box>
                </Box>
            </Box>
        </OSKView>
    );
};

export default DashboardView;
