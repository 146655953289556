import React, { useEffect, useRef } from 'react';
import { flatMap, values } from 'lodash';
import { useTheme } from 'styled-components';
import { connect } from 'react-redux';
import { Box, OSKThemeType, Overlay, useClickAway } from 'oskcomponents';
import { CartIndicator } from '~/molecules/CartIndicator';
import { CartItemList } from '~/molecules/CartItemList';
import { CartActions } from '~/molecules/CartActions';
import { CartGrid } from '~/molecules/CartGrid';
import { ViewContainer } from '~/atoms/ViewContainer';
import { ViewContent } from '~/atoms/ViewContent';
import { ViewToggle } from '~/atoms/ViewToggle';
import {
    downloadFilesAsync,
    getAllItems,
    getSelectionCount,
    pruneCartItems,
    toggleFileIntent,
} from '~/redux/modules/data/cart';
import { AppDispatch, RootState } from '~/redux/store';
import { Capture, DownloadIntent, DownloadRequestRequest, FileVariant, Sensor } from 'oskcore';
import { FaList, FaThLarge } from 'react-icons/fa';
import { NavItemEntry, showNavItem } from '~/redux/modules/data/nav';
import { GlobalZIndex } from '~/constants';

type CartViewProps = {
    /** A list of download intent items which are queued for download */
    items: Array<DownloadIntent | undefined>;
    /** A list of Capture items. The key is FileId the value is PipelineResult file */
    resultItemMap: Record<string, Capture | undefined>;
    /** The number of active items which are queued for download */
    itemCount: number;
    /** A boolean indicating whether to show the overlay or not */
    showCart: boolean;
    /** An array of OSK supported sensors, used for presentation purposes */
    sensors: Array<Sensor>;
    /** A method to invoke when the cart is dismissed */
    onDismissed: () => void;
    /** A method to update whether the cart overlay is visible or not */
    setShowCart: (show: boolean) => void;
    /** A method for updating a particular download intent (either to skip or include it) */
    toggleIntent: (collectId: string) => void;
    /** downloadFile */
    downloadFiles: (request: DownloadRequestRequest) => void;
    /** Active program to bill against */
    programId: number;
};

const CartView = ({
    downloadFiles,
    onDismissed,
    resultItemMap,
    showCart,
    programId,
    sensors,
    itemCount,
    setShowCart,
    toggleIntent,
    items = [],
}: CartViewProps) => {
    const ref = useRef(null);
    const theme = useTheme() as OSKThemeType;
    useClickAway(ref, () => setShowCart(false), showCart);
    const hasItems = items.length > 0;

    const handleCartClick = () => {
        if ((hasItems && !showCart) || showCart) {
            setShowCart(!showCart);
        }
    };

    useEffect(() => {
        if (!showCart) {
            onDismissed();
        }
    }, [showCart, onDismissed]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleDownloadAction = (level: FileVariant) => {
        const downloadIntents = flatMap(values(items), (intent) => intent)
            .filter((item) => item !== undefined && item.skip !== true)
            .map((item) => parseInt(item?.fileId ?? '-1')) as Array<number>;

        const request: DownloadRequestRequest = {
            zip_file_loc: 'null',
            requested_captures: downloadIntents,
        };
        downloadFiles(request);
    };

    // TODO: find out why 35px is the magic number
    return (
        <Box ref={ref} col style={{ zIndex: GlobalZIndex.Cart }}>
            <CartIndicator onClick={handleCartClick} itemCount={itemCount} />
            <Box style={{ position: 'absolute', top: '55px', right: 0 }}>
                <Overlay
                    p={10}
                    animation={'fadeInDown'}
                    show={showCart}
                    style={{
                        width: '50vw',
                        height: 'calc(100vh - 300px)',
                        right: '0',
                    }}
                    col
                >
                    <ViewContainer
                        viewKey="cart"
                        bg={theme.colors.gray1a}
                        defaultView="list"
                        style={{ height: '100%' }}
                        persist={true}
                        grow
                        col
                    >
                        <Box
                            bg={theme.colors.gray0a}
                            style={{
                                borderBottom: `2px solid ${theme.colors.gray2}`,
                            }}
                            p={14}
                        >
                            <ViewToggle variant="primary" viewKey="list" icon={FaList} />
                            <ViewToggle variant="primary" viewKey="grid" icon={FaThLarge} />
                        </Box>
                        <Box p={10} style={{ overflow: 'auto' }} grow>
                            <ViewContent viewKey="list" grow>
                                <CartItemList items={items} resultItemMap={resultItemMap} onToggle={toggleIntent} />
                            </ViewContent>
                            <ViewContent viewKey="grid">
                                <CartGrid
                                    items={items}
                                    sensors={sensors}
                                    resultItemMap={resultItemMap}
                                    onToggle={toggleIntent}
                                />
                            </ViewContent>
                        </Box>
                    </ViewContainer>

                    <CartActions
                        itemCount={itemCount}
                        onDownload={(fileLevel) => {
                            handleDownloadAction(fileLevel);
                            setShowCart(false);
                        }}
                    />
                </Overlay>
            </Box>
        </Box>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        showCart: state.data.nav.showCart,
        items: getAllItems(state),
        itemCount: getSelectionCount(state),
        sensors: state.osk.sensors,
        programId: state.session.program_id ?? -1,
        resultItemMap: state.data.cart.enqueuedItemMap,
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        setShowCart: (show: boolean) => {
            dispatch(showNavItem(show ? NavItemEntry.Cart : NavItemEntry.None));
        },
        toggleIntent: (fileId: string) => {
            dispatch(toggleFileIntent(fileId));
        },
        onDismissed: () => {
            dispatch(pruneCartItems());
        },
        downloadFiles: (request: DownloadRequestRequest) => {
            dispatch<any>(downloadFilesAsync(request));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartView);
