import React, { useRef, useState } from 'react';
import { Box, OSKIcon, OSKThemeType, Overlay, useClickAway } from 'oskcomponents';
import styled, { useTheme } from 'styled-components';

export type BellProps = {
    className?: string;
};

export const Bell = styled(({ className }: BellProps) => {
    const [show, setShow] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const theme = useTheme() as OSKThemeType;
    useClickAway(ref, setShow.bind(this, false), show);

    return (
        <Box
            ref={ref}
            className={className}
            onClick={setShow.bind(this, !show)}
            style={{ paddingLeft: '20px', fontSize: '1.25rem' }}
        >
            <OSKIcon
                code={'bell'}
                fill="white"
                stroke="#ccc"
                strokeWidth={20}
                style={{
                    filter: 'drop-shadow(0px 2px 2px #00000011)',
                }}
            />
            <Overlay
                className="overlay"
                style={{
                    right: 0,
                    paddingTop: '48px',
                    padding: '20px',
                    borderRadius: '5px',
                    top: '30px',
                    color: theme.colors.white,
                }}
                show={show}
            >
                No Notifications
            </Overlay>
        </Box>
    );
})`
    &:hover {
        cursor: pointer;
    }

    .overlay {
        color: ${(props: any) => props.theme.colors.white};
        background-color: ${(props: any) => props.theme.colors.gray2ab};
    }
`;
