import { noop } from 'oskcore';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useEnsureProgram } from '~/hooks';
import { OSKContentView } from '~/molecules/OSKContentView';
import AssetDashboardView from '~/organisms/Monitor/AssetDashboardView';
import { DataProvider } from '~/redux';
import { Asset } from '~/redux/modules/monitor/app';
import { RootState } from '~/redux/store';
import { getProgramId } from '~/utils';

export type MonitorDashboardViewProps = {
    assets: Asset[];
};

const MonitorDashboardView = ({ assets }: MonitorDashboardViewProps) => {
    const navigate = useNavigate();
    const { assetId, programId } = useParams();

    useEnsureProgram();

    useEffect(() => {
        // Redirect to the first asset in the list when the assets are populated on load if there is
        // no selectedAssetId by default.
        if (!assetId && assets && assets.length > 0 && !location.href.includes('/grid') && programId) {
            const firstAssetId = assets[0].id;
            navigate(`/program/${programId}/monitor/${firstAssetId}/grid`);
        }
    }, [assets, assetId, programId, navigate]);

    return (
        <DataProvider programId={getProgramId()} assetId={assetId} features={['assets', 'detections']}>
            <OSKContentView>
                <AssetDashboardView selectedAssetId={assetId} />
            </OSKContentView>
        </DataProvider>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        assets: Object.values(state.monitor.app.assets),
    };
};

export { MonitorDashboardView };
export default connect(mapStateToProps, noop)(MonitorDashboardView);
