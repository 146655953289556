import { useLocation } from 'react-router-dom';

export type ActiveApp = 'data' | 'monitor' | 'tasking';

/**
 * This method will use the current path information to derive
 * which sigma product we are on.
 */
export function useCurrentApp(): ActiveApp {
    const { pathname } = useLocation();

    // Determine the active application
    if (pathname.includes('/monitor')) {
        return 'monitor';
    } else if (pathname.includes('/tasking')) {
        return 'tasking';
    } else {
        return 'data';
    }
}
