import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Box } from '../Box';
import { GlobalZIndex } from '../constants';
import { ResizableViewPanel, ResizableViewPanelProps } from './ResizableViewPanel';

export type PanelOrientation = 'vertical' | 'horizontal';
export type MouseDirection = 'left' | 'right' | 'top' | 'bottom';

const resizingElements: Record<number, boolean> = {};

type ResizeTargetProps = {
    /** The orientation in which the drag target will be rendered. Either horizontal or vertical. */
    orientation: PanelOrientation;
    /** The method invoked when the resize begins. */
    onResizeStart?: () => void;
    /** The method invoked when the resize target is dragged. */
    onResizing?: (delta: number) => void;
    /** The method invoked when the resize target has stopped being dragged. */
    onResizeEnd?: () => void;
    /** The className to append to the resize bar */
    className?: string;
};

const ResizeTarget = styled(({ className, orientation, onResizeStart, onResizing, onResizeEnd }: ResizeTargetProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const uuid = useRef(0);
    const startPosition = useRef([0, 0]);
    const currentOffsets = useRef([0, 0]);

    useEffect(() => {
        const handleResizeBegin = (evt: MouseEvent) => {
            evt.stopPropagation();
            evt.preventDefault();

            if (ref.current && ref.current.parentElement) {
                ref.current.parentElement.style.pointerEvents = 'none';
                ref.current.dataset['resizing'] = 'true';
            }

            // Generate a new UUID for this mouse transaction
            uuid.current = new Date().getTime();
            // Store the current position so we can compute the distance traveled
            startPosition.current = [evt.clientX, evt.clientY];
            // Mark this element as in-progress being resized
            resizingElements[uuid.current] = true;

            onResizeStart && onResizeStart();
        };

        const handleMouseMove = (evt: MouseEvent) => {
            // When a mousemove event is handled, check if it is relevant to this component.
            if (resizingElements[uuid.current]) {
                if (ref.current) {
                    document.body.style.cursor = ref.current.style.cursor;
                }

                // Calculate the next offsets based on distance mouse has traveled.
                const nextX = startPosition.current[0] - evt.clientX;
                const nextY = startPosition.current[1] - evt.clientY;

                // If it is relevant, compute the new offsets.
                currentOffsets.current = [nextX, nextY];

                if (orientation === 'horizontal') {
                    onResizing && onResizing(nextX);
                } else {
                    onResizing && onResizing(nextY);
                }

                // Tell my children... I've resized
                window.dispatchEvent(new Event('resize'));
            }
        };

        // When we are done handling the mouse move event, discontinue watching
        // for this transaction id.
        const handleResizeEnd = () => {
            if (resizingElements[uuid.current]) {
                resizingElements[uuid.current] = false;
                document.body.style.cursor = 'default';

                onResizeEnd && onResizeEnd();
            }

            if (ref && ref.current && ref.current.parentElement) {
                ref.current.parentElement.style.pointerEvents = '';
                ref.current.dataset['resizing'] = 'false';
            }
        };

        const currentRef = ref.current;
        if (currentRef) {
            currentRef.addEventListener('mousedown', handleResizeBegin);
            window.addEventListener('mouseup', handleResizeEnd);
            window.addEventListener('mousemove', handleMouseMove);

            return () => {
                currentRef.removeEventListener('mousedown', handleResizeBegin);
                window.removeEventListener('mouseup', handleResizeEnd);
                window.removeEventListener('mousemove', handleMouseMove);
            };
        }
    }, [ref, onResizeEnd, onResizeStart, onResizing, orientation]);

    return orientation === 'horizontal' ? (
        <Box
            className={className}
            data-resizing={resizingElements[uuid.current]}
            ref={ref}
            style={{ cursor: 'col-resize', position: 'relative' }}
        >
            <Box
                className="resize-bar"
                data-resizing={resizingElements[uuid.current]}
                style={{ width: '1px', padding: '1px' }}
            />
            <Box
                className="resize-target"
                style={{
                    position: 'absolute',
                    borderRadius: '10px',
                    height: '47px',
                    width: '8px',
                    top: 'calc(50% - 47px/2)',
                    left: 'calc(-8px/2 + 1px)',
                    cursor: 'col-resize',
                    zIndex: GlobalZIndex.ViewPanelResizeTarget /* This is used so the scroll handle-bar supercedes everything else */,
                }}
            />
        </Box>
    ) : (
        <Box
            className={className}
            data-resizing={resizingElements[uuid.current]}
            ref={ref}
            style={{ cursor: 'row-resize', position: 'relative' }}
        >
            <Box
                className="resize-bar"
                data-resizing={resizingElements[uuid.current]}
                style={{ height: '1px', width: '100%', padding: '1px' }}
            />
            <Box
                className="resize-target"
                style={{
                    cursor: 'row-resize',
                    position: 'absolute',
                    height: '8px',
                    width: '47px',
                    borderRadius: '10px',
                    top: 'calc(-8px / 2 + 1px)',
                    left: 'calc(50% - 47px/2)',
                    zIndex: GlobalZIndex.ViewPanelResizeTarget /* This is used so the scroll handle-bar supercedes everything else */,
                }}
            />
        </Box>
    );
})`
    & > .resize-bar {
        background-color: #bbb;
    }

    & > .resize-target {
        background-color: ${(props: any) => props.theme.colors.primary.fg};
    }

    &:hover > .resize-bar {
        background-color: ${(props: any) => props.theme.colors.primary.accent};
        transition: background-color 0.3s;
    }

    &[data-resizing='true'] > .resize-bar,
    & > .resize-bar[data-resizing='true'] {
        background-color: ${(props: any) => props.theme.colors.primary.accent};
    }
`;

export type ResizableViewLayoutProps = {
    /** Each child node is a panel. */
    children: React.ReactNode;
    /** The orientation (horizontal or vertical) in which the panels will be rendered */
    orientation: PanelOrientation;
};

export const ResizableViewLayout = ({ orientation, children }: ResizableViewLayoutProps) => {
    // Reference to the top-level container.
    const ref = useRef<HTMLDivElement>(null);
    // Qty of views to render.
    const maxDepth = React.Children.count(children);
    // Temporary variable
    const deltaPerc = useRef<number>(0.0);

    // Heights are the exact pixel heights each pane will take.
    // Computed from layout or real-time from mouse deltas.
    const [pixels, setPixels] = useState<Record<number, number>>({});
    // Layout is the % of space each pane will take.
    const layout = useRef<Record<number, number>>({});

    // This method takes the layout % and distributes it to the heights.
    const distributeLayout = useCallback(
        (layoutToDistribute) => {
            if (ref && ref.current && ref.current.parentElement) {
                const width = ref.current.parentElement.getBoundingClientRect().width;
                const height = ref.current.parentElement.getBoundingClientRect().height;
                const updatedPixels: Record<number, number> = {};

                for (let i = 0; i < maxDepth; i++) {
                    if (orientation === 'horizontal') {
                        updatedPixels[i] = width * layoutToDistribute[i];
                    } else {
                        updatedPixels[i] = height * layoutToDistribute[i];
                    }
                }

                setPixels(updatedPixels);
            }
        },
        [ref, maxDepth, orientation],
    );

    // Calculate the initial layout.
    useEffect(() => {
        // The weight distribution of the panels.
        const weights: Array<number> = [];
        React.Children.forEach(children, (child) => {
            weights.push(((child as ReactElement).props as ResizableViewPanelProps).weight ?? 1);
        });

        // Calculate total weight
        const totalWeight = weights.reduce((prev, cur) => prev + cur, 0);

        // Create the distributions
        for (let i = 0; i < weights.length; i++) {
            layout.current[i] = weights[i] / totalWeight;
        }

        distributeLayout(layout.current);
    }, [layout, children, distributeLayout, maxDepth]);

    const onResizeStart = useCallback(() => {
        deltaPerc.current = 0.0;
    }, []);

    const onResizing = useCallback(
        (idx: number, delta: number) => {
            if (ref.current && ref.current.parentElement) {
                const width = ref.current.parentElement.getBoundingClientRect().width;
                const height = ref.current.parentElement.getBoundingClientRect().height;

                // Calculate the % change this amount of pixels would incur
                // from the layout.
                const totalPixels = orientation === 'horizontal' ? width : height;
                deltaPerc.current = delta / totalPixels;

                // Calculate the minimum and maximum range that deltaPerc can safely be.
                const minPerc = 0.1 - layout.current[idx + 1];
                const maxPerc = layout.current[idx] - 0.1;

                // Clamp boundaries at 10%
                if (deltaPerc.current < minPerc) {
                    deltaPerc.current = minPerc;
                }

                if (deltaPerc.current > maxPerc) {
                    deltaPerc.current = maxPerc;
                }

                // Calculate shadow layout
                const shadowLayout = { ...layout.current };
                shadowLayout[idx] -= deltaPerc.current;
                shadowLayout[idx + 1] += deltaPerc.current;

                // Distribute the shadow layout so the user gets immediate feedback on the effects of their resize.
                distributeLayout(shadowLayout);
            }
        },
        [ref, orientation, distributeLayout, deltaPerc],
    );
    const handleResizeEnd = useCallback(
        (idx: number) => {
            layout.current[idx] -= deltaPerc.current;
            layout.current[idx + 1] += deltaPerc.current;
            distributeLayout(layout.current);
        },
        [layout, distributeLayout],
    );

    return (
        <Box ref={ref} col={orientation === 'vertical'} grow>
            {React.Children.toArray(children).map((cur, idx) => {
                if ((cur as ReactElement).type !== ResizableViewPanel) {
                    console.error(
                        `Invalid component specified to ResizableViewLayout. Please wrap all panes with a <ResizableViewPanel /> component.`,
                    );
                    return <React.Fragment />;
                } else {
                    return (
                        <React.Fragment key={`segment_${idx}`}>
                            <Box
                                col={orientation === 'vertical'}
                                grow
                                style={{ [orientation === 'vertical' ? 'height' : 'width']: pixels[idx] }}
                            >
                                {cur}
                            </Box>
                            {idx < maxDepth - 1 && (
                                <ResizeTarget
                                    onResizeStart={onResizeStart}
                                    onResizing={(delta) => onResizing(idx, delta)}
                                    onResizeEnd={() => handleResizeEnd(idx)}
                                    key={`resizer_${idx}`}
                                    orientation={orientation}
                                />
                            )}
                        </React.Fragment>
                    );
                }
            })}
        </Box>
    );
};

ResizableViewLayout.defaultProps = {
    orientation: 'horizontal',
};
