import React from 'react';
import { useTheme } from 'styled-components';
import { FaCircle, FaRegCircle } from 'react-icons/fa';
import { Box, BoxProps } from '../Box';
import { Text } from '../Typography';
import CSS from 'csstype';
import { ColorVariants, OSKThemeType } from '../DefaultThemeProvider';

export type StepProps = {
    /** If true, the step icon will be a filled circle */
    active?: boolean;
    /** Children elements to render to the side of the step component */
    children?: React.ReactNode;
    /** Custom style to apply to the step container */
    style?: CSS.Properties;
    /** Method to invoke when the step is clicked */
    onToggle?: () => void;
    /** Variant which describes the color of the step */
    variant?: ColorVariants;
    /** If true, a line will extend out the bottom of the segment */
    renderLine?: boolean;
} & Omit<BoxProps, 'ref'>;

/**
 * This component represents a single action or state within a wizard interface.
 * It is simply a circular icon with text. Use it in conjunction with the Stepper
 * component to create guided views, sliders, and other composites.
 */
export const Step = ({ active, children, renderLine, style, onToggle, variant = 'primary', ...props }: StepProps) => {
    const theme = useTheme() as OSKThemeType;
    return (
        <Box
            onClick={onToggle}
            style={{
                cursor: onToggle !== null && onToggle !== undefined ? 'pointer' : 'default',
                ...style,
            }}
            {...props}
        >
            <Text variant="small" color={theme.colors[variant].bg} style={{ display: 'flex' }}>
                <Box style={{ paddingRight: '4px', alignItems: 'center' }} col>
                    {active ? <FaCircle /> : <FaRegCircle />}
                    {renderLine && <Box bg={theme.colors.gray80} w={2} grow />}
                </Box>
            </Text>
            {children}
        </Box>
    );
};
