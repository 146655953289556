import React from 'react';
import { Box, OSKThemeType, Text } from 'oskcomponents';
import { DownloadRequestItem } from '~/molecules';
import { flatMap, keys } from 'lodash';
import { useTheme } from 'styled-components';
import { DownloadRequest } from 'oskcore';
import { parseISO } from 'date-fns';

export type DownloadRequestListProps = {
    items: Array<DownloadRequest>;
};

export const DownloadRequestList = ({ items }: DownloadRequestListProps) => {
    const theme = useTheme() as OSKThemeType;

    // Bucket the requests by the day so we can group them
    // visually in the list. Each day will get its own heading.
    const buckets: Record<string, Array<DownloadRequest>> = {};
    for (const item of items) {
        const key = parseISO(item.time_requested || '').toDateString();
        buckets[key] = buckets[key] || [];
        buckets[key].push(item);
    }

    // Each heading should be sorted temporally.
    const dates = keys(buckets);
    dates.sort((a, b) => new Date(b).getTime() - new Date(a).getTime());

    // Reverse items inside the buckets so newest is first.
    for (const item in buckets) {
        buckets[item].reverse();
    }

    // Iterate through each heading and create a structure that looks like this
    // [[heading, [items]]] and use flatMap to flatten it all out into a one
    // dimensional list of react components which are ultimately rendered.
    return (
        <Box col>
            {flatMap(
                dates.map((key) => {
                    return [
                        <Text color={theme.colors.orange50} key={key}>
                            {key}
                        </Text>,
                        buckets[key].map((item) => (
                            <DownloadRequestItem
                                key={item.id}
                                uri={item.zip_file_loc ?? ''}
                                requestedItems={item.requested_captures}
                                timeRequested={parseISO(item.time_requested ?? '')}
                                timeReady={parseISO(item.time_ready ?? '')}
                                isDownloading={item.time_ready === null || item.time_ready === undefined}
                                expired={false}
                            />
                        )),
                    ];
                }),
            )}
        </Box>
    );
};
