import React from 'react';
import { Box, BoxProps, Text } from 'oskcomponents';

export type StepScaffoldProps = {
    /** The title of the step. */
    title: string;
    /** The sub-title of the step. */
    subTitle: string;
    /** The children elements to render below the title area. */
    children: React.ReactNode;
} & Omit<BoxProps, 'ref'>;

export const StepScaffold = ({ title, subTitle, children, ...props }: StepScaffoldProps) => {
    return (
        <Box style={{ width: '100%', height: '100%' }} col grow>
            <Box col grow p={10} pt={20}>
                <Text variant="large" style={{ lineHeight: '1.5rem', fontWeight: 'bold' }}>
                    {title}
                </Text>
                <Text style={{ lineHeight: '1.5rem' }} variant="medium">
                    {subTitle}
                </Text>
            </Box>
            <Box style={{ height: '100%', width: '100%' }} p={10} {...props}>
                {children}
            </Box>
        </Box>
    );
};
