// NOTE: Disabling the following rule because it's enforced incorrectly.
// The property splat includes a key prop, but tsx doesn't see it.
/* eslint-disable react/jsx-key */

import React from 'react';
import { useSortBy, useTable } from 'react-table';
import styled from 'styled-components';
import { Box } from '../Box';
import { OSKIcon } from '../OSKIcon';
import CSS from 'csstype';

export type TableColumn = {
    Header: any;
    accessor: string;
    width?: number | string;
    cell?: any;
};

export type TableProps = {
    columns: Array<TableColumn>;
    className?: string;
    data: Array<Record<string, any>>;
    selectedIndex?: number;
    onRowClick?: (row: Record<string, any>, index?: number) => void;
    style: CSS.Properties;
    [key: string]: any;
};

export const Table = styled(({ columns, style, className, data, selectedIndex, onRowClick, ...props }: TableProps) => {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
        { columns, data, ...props },
        useSortBy,
    );

    // Create a hashmap of the columns indexed by accessor
    // so we can quickly lookup specific values that were
    // set by the user when they configured the header.
    //
    // If we didn't have this, we would have to rely on
    // the react-table interpretation of those default
    // values which sometimes overrides them based on
    // its own internal logic.
    const columnMap =
        columns.reduce((acc, item) => {
            acc[item.accessor] = item;
            return acc;
        }, {} as Record<string, any>) ?? {};

    return (
        <Box style={style} className={className}>
            <table className="table" {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column: any) => {
                                return (
                                    <th
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        style={{
                                            width: columnMap[column.id].width,
                                            cursor: column.disableSortBy ? 'default' : 'pointer',
                                        }}
                                    >
                                        <Box row>
                                            <Box style={{ paddingRight: '4px' }}>{column.render('Header')}</Box>

                                            {column.isSorted ? (
                                                <React.Fragment>
                                                    {column.isSortedDesc ? (
                                                        <OSKIcon code="arrow-down" />
                                                    ) : (
                                                        <OSKIcon code="arrow-up" />
                                                    )}
                                                </React.Fragment>
                                            ) : (
                                                <Box w={16} />
                                            )}
                                        </Box>
                                    </th>
                                );
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, idx) => {
                        prepareRow(row);
                        return (
                            <tr
                                onClick={() => onRowClick && onRowClick(row.original, idx)}
                                {...row.getRowProps()}
                                className={selectedIndex === idx ? 'selected-row' : row.getRowProps()?.className}
                            >
                                {row.cells.map((cell) => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </Box>
    );
})`
    &,
    thead,
    tbody,
    th,
    tr,
    td {
        font-family: '${(props: any) => props.theme.font}';
        text-align: left;
        font-size: 1rem;
        position: relative;
        background-color: ${(props: any) => props.theme.colors.transparent};
        color: ${(props: any) => props.theme.colors.primary.fg};
    }

    & {
        background-color: ${(props: any) => props.theme.colors.primary.bg};
        border-radius: 9px;
        padding: 10px 0 20px 0;
        width: 100%;
    }

    & .table {
        width: 100%;
        border-collapse: collapse;
    }

    th {
        top: 0;
        font-size: 0.875rem;
        font-weight: 700;
    }

    tbody tr:nth-child(odd) {
        background-color: ${(props: any) => props.theme.colors.primary.subtleAccent};
    }

    td,
    th {
        padding: 10px;
    }

    tbody tr:hover,
    tbody .selected-row {
        background-color: ${(props: any) => props.theme.colors.highlight} !important;
        cursor: ${(props: any) => (props.onRowClick ? 'pointer' : 'normal')} !important;
    }
`;
