import React, { useEffect, useRef, useState } from 'react';
import { Box, Divider, Heading, OSKThemeType, RatioLayout, RatioPanel, Select, Text } from 'oskcomponents';
import { RootState } from '~/redux/store';
import { connect } from 'react-redux';
import { capitalize, DetectionDetectionClassEnum, MapOf, noop } from 'oskcore';
import { useBoundingRect } from '~/hooks';
import { AlertCard } from '~/molecules/AlertCard';
import { IconHeader } from '~/molecules/Monitor';
import { useTheme } from 'styled-components';
import { Asset, AssetDetection, filterDetectionsByClass } from '~/redux/modules/monitor/app';
import { ActionBar } from '~/atoms/ActionBar';
import { Breadcrumb } from '~/atoms';
import { AppNames } from '~/constants';
import { useNavigate, useParams } from 'react-router-dom';
import { getProgramId } from '~/utils';

type ProgramAlertsProps = { assets: MapOf<Asset>; alerts: Array<AssetDetection> };

const ProgramAlerts = ({ assets, alerts }: ProgramAlertsProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const theme = useTheme() as OSKThemeType;
    const { assetId } = useParams();
    const [sampled, width, _] = useBoundingRect(ref);
    const [widthPerc, setWidthPerc] = useState(1 / 3);
    const [visible, setVisible] = useState(false);
    const navigate = useNavigate();
    const visibleAlerts = alerts.filter((alert) => {
        if (assetId) {
            return `${alert.asset}` === `${assetId}`;
        } else {
            return true;
        }
    });

    // Calculate the width of a card based on how many 300px-ish boxes will fit in the container.
    useEffect(() => {
        const cols = Math.trunc(width / 300);
        // Can't divide by 0
        if (cols > 0) {
            setWidthPerc(1 / cols);
        } else {
            // If there is zero columns, make the card take up whatever space is there.
            setWidthPerc(1.0);
        }
    }, [sampled, width]);

    useEffect(() => {
        setTimeout(() => {
            setVisible(true);
        }, 600);
    }, []);

    return (
        <Box style={{ visibility: visible ? 'visible' : 'hidden' }} data-testid="Alerts-Grid" col grow>
            <ActionBar left={<Breadcrumb nodes={[{ title: AppNames['monitor'], url: '/' }, { title: 'Alerts' }]} />} />
            <Box
                ref={ref}
                style={{
                    // flexWrap: 'wrap',
                    height: '100%',
                    overflowY: 'scroll',
                    placeContent: 'flex-start',
                }}
                grow
            >
                <RatioLayout m={14} grow>
                    <RatioPanel
                        style={{ borderRadius: '30px', margin: '0px 9px' }}
                        bg={theme.colors.primary.transBg}
                        data-testid="Assets-Section"
                        p={24}
                        weight={2}
                    >
                        <Box style={{ alignItems: 'center' }}>
                            <IconHeader title="Alerts" iconCode="alert" />
                            <Box grow />
                            <Box style={{ justifyContent: 'center', alignItems: 'flex-end' }} col>
                                <Text variant="large" strong>
                                    Asset
                                </Text>
                                <Box mt={10} style={{ border: `1px solid ${theme.colors.primary.halfTransBg}` }} grow>
                                    <Select
                                        name="asset"
                                        grow
                                        w={300}
                                        defaultValue={parseInt(`${assetId ?? -1}`)}
                                        onSelect={(option) => {
                                            if (option && option.value >= 0) {
                                                navigate(
                                                    `/program/${getProgramId()}/monitor/alerts/asset/${option?.value}`,
                                                    {
                                                        replace: true,
                                                    },
                                                );
                                            } else {
                                                navigate(`/program/${getProgramId()}/monitor/alerts`, {
                                                    replace: true,
                                                });
                                            }
                                        }}
                                        items={[
                                            { label: 'All', value: -1 },
                                            ...Object.values(assets).map((asset) => ({
                                                label: asset.name,
                                                value: asset.id,
                                            })),
                                        ]}
                                    />
                                </Box>
                            </Box>
                        </Box>
                        <Divider m={12} />
                        <Box grow style={{ flexWrap: 'wrap', alignContent: 'flex-start', overflowY: 'scroll' }}>
                            {visibleAlerts.length === 0 ? (
                                <Box
                                    style={{ justifyContent: 'center', alignItems: 'center', paddingTop: '50px' }}
                                    grow
                                >
                                    <Heading>There are no alerts for the selected criteria.</Heading>
                                </Box>
                            ) : (
                                visibleAlerts.map((alert, idx) => {
                                    const { id, artifacts, call_type } = alert;

                                    return (
                                        <Box
                                            key={`tile-${idx}`}
                                            style={{
                                                margin: '4px',
                                                flexShrink: 1,
                                                width: `calc(${widthPerc * 100}% - ${(width / 300) * 4}px)`,
                                            }}
                                        >
                                            <AlertCard
                                                id={id}
                                                assetId={alert.asset}
                                                assetName={assets[alert.asset]?.name}
                                                callType={call_type}
                                                images={Object.entries(artifacts)
                                                    .filter(
                                                        ([type, uri]) =>
                                                            uri.includes('.png') ||
                                                            uri.includes('.jpeg') ||
                                                            uri.includes('.jpg'),
                                                    )
                                                    .map(([type, uri]) => ({
                                                        uri,
                                                        subTitle: capitalize(type),
                                                    }))}
                                            />
                                        </Box>
                                    );
                                })
                            )}
                        </Box>
                    </RatioPanel>
                </RatioLayout>
            </Box>
        </Box>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        assets: state.monitor.app.assets,
        alerts: filterDetectionsByClass(state, DetectionDetectionClassEnum.Alert),
    };
};
export { ProgramAlerts };
export default connect(mapStateToProps, noop)(ProgramAlerts);
