import React from 'react';
import { useTheme } from 'styled-components';
import { BasicDialog, BasicDialogProps } from '~/molecules/BasicDialog';
import { Box, Text, OSKThemeType } from 'oskcomponents';

export type KMLUploadFinishedModalProps = {
    succeeded: number;
    failed: number;
    needsTitle: number;
} & Omit<BasicDialogProps, 'title'>;

const plural = (condition: boolean) => {
    return condition ? 's' : '';
};

const KMLUploadFinishedModal = ({ succeeded, failed, needsTitle, ...props }: KMLUploadFinishedModalProps) => {
    const theme = useTheme() as OSKThemeType;
    const total = succeeded + failed + needsTitle;
    return (
        <BasicDialog title={`${total} Section${plural(total > 1)} Finished`} {...props}>
            <Box style={{ paddingTop: '10px' }} grow col>
                {succeeded > 0 && (
                    <Text color="black">
                        {succeeded} KML section{plural(succeeded > 1)} uploaded successfully!
                    </Text>
                )}
                {needsTitle > 0 && (
                    <Text color={theme.colors.error}>
                        {needsTitle} KML section{plural(needsTitle > 1)} failed to upload because{' '}
                        {needsTitle == 1 ? 'it' : 'they'} need a title.
                    </Text>
                )}
                {failed > 0 && (
                    <Text color={theme.colors.error}>
                        {failed} KML section{plural(failed > 1)} failed to upload because {failed === 1 ? 'it' : 'they'}{' '}
                        already exist{plural(failed === 1)}.
                    </Text>
                )}
            </Box>
        </BasicDialog>
    );
};

export { KMLUploadFinishedModal };
