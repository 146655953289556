import { Box } from 'oskcomponents';
import { useRemountOnChange } from 'oskcore';
import React from 'react';
import { Footprint, MapCentroid } from '~/atoms';
import EditableMap from '~/organisms/map/EditableMap';
import { AOIProgress } from '~/redux/modules/tasking/app';

export type TaskCoverageTabProps = {
    /** A list of AOIProgress elements to render in the map */
    aoiList: Array<AOIProgress>;
};

export const TaskCoverageTab = ({ aoiList }: TaskCoverageTabProps) => {
    const [visible] = useRemountOnChange([aoiList]);

    return (
        <Box style={{ width: '100%' }} grow>
            <EditableMap>
                {visible && aoiList && aoiList.length > 0 ? (
                    <MapCentroid area={aoiList[0].aoi} />
                ) : (
                    <React.Fragment></React.Fragment>
                )}
                {aoiList.map((taskingSegment, id) =>
                    taskingSegment.aoi ? (
                        <Footprint color="#00d1ff" key={`${taskingSegment.name}_${id}`} shape={taskingSegment.aoi} />
                    ) : (
                        <React.Fragment></React.Fragment>
                    ),
                )}
            </EditableMap>
        </Box>
    );
};
