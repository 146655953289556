import React from 'react';
import { useTheme } from 'styled-components';
import CSS from 'csstype';
import '../fonts.css';
import '../style.css';
import { OSKThemeType } from '../DefaultThemeProvider';

/** List of typography element types used as a parameter into the dynamic typography component  */
export type ElementTypes = 'Heading' | 'Subtitle' | 'Text';

/** List of variant types which are mostly used for specifying relative size */
export type VariantTypes = 'large' | 'medium' | 'small' | 'tiny' | 'huge';

type TypographyProps = {
    /** This defines which variant to render, mostly used for specifying relative size */
    variant: VariantTypes;
    /** Internal prop used by react to specify children components */
    children?: React.ReactNode;
    /** If true, apply an italic style to the text */
    italic?: boolean;
    /** If true, apply a bold style to the text */
    strong?: boolean;
    /** Specify a class-based style */
    className?: string;
    /** Use this to override the component tag. For example, if you
       are dealing with a header you can specify whether it should
       be rendered as an H3 or H4 or H5 tag, etc. Regardless of default. */
    as?: string;
    /** Override default styles with an inline style object */
    style?: CSS.Properties;
    /** Override the default color */
    color?: string;
    /** Override the default font */
    font?: string;
    /** Width of component */
    w?: number;
    /** Height of component */
    h?: number;
    /** Margin of component */
    m?: number;
    /** Padding of component */
    p?: number;
    /** Padding top of component" */
    pt?: number;
} & JSX.IntrinsicElements['p'];

const variantLevel = {
    large: 0,
    medium: 1,
    small: 2,
    tiny: 3,
    huge: 4,
};

/**
 * This is a util method which takes an object and a variant and will return
 * either the nth element from the object (assuming it is an array)
 * or the raw value (assuming it is not an array). This is a convenience
 * helper to apply to variants which are defined in the ThemeProvider.
 *
 * For example:
 * Heading: {
 *  size: ['1.17rem', '1.04rem', '0.87rem']
 * }
 *
 * If you call getProperty(Heading.size, 'small')
 * It will return '0.87rem' because that's the 3rd element
 * which corresponds to the small variant.
 *
 * @param obj An array or single value to evaluate
 * @param variant A string representing the VariantTypes object
 * @returns The value from the obj evaluated for variant
 */
const getProperty = (obj: any, variant: VariantTypes) => {
    if (Array.isArray(obj)) {
        return obj[variantLevel[variant]];
    } else {
        return obj;
    }
};

const Typography = (
    el: ElementTypes,
    {
        as,
        color,
        className,
        w,
        h,
        p,
        pt,
        m,
        children,
        italic,
        strong,
        variant = 'medium',
        style,
        font,
        ...props
    }: TypographyProps,
) => {
    const theme = useTheme() as OSKThemeType;
    const configObject = theme.text[el];

    return React.createElement(
        as ?? getProperty(configObject.as, variant),
        {
            role: 'typography',
            className,
            style: {
                color,
                fontFamily: font ?? theme.font,
                fontSize: getProperty(configObject.size, variant),
                fontWeight: strong ? 'bold' : getProperty(configObject.weight, variant),
                fontStyle: italic ? 'italic' : 'normal',
                width: w ? w + 'px' : '',
                height: h ? h + 'px' : '',
                padding: p ? p + 'px' : '',
                paddingTop: pt ?? 'default',
                margin: m ? m + 'px' : '',
                ...style,
            },
            ...configObject.props,
            ...props,
        },
        children,
    );
};

const Heading = ({ ...props }: TypographyProps) => {
    return Typography('Heading', props);
};

Heading.defaultProps = {
    variant: 'medium',
};

const Subtitle = ({ ...props }: TypographyProps) => {
    return Typography('Subtitle', props);
};

Subtitle.defaultProps = {
    variant: 'medium',
};

const Text = ({ ...props }: TypographyProps) => {
    return Typography('Text', props);
};

Text.defaultProps = {
    variant: 'medium',
};

export type { TypographyProps };
export { Heading, Subtitle, Text };
