import React, { useEffect } from 'react';
import { noop } from 'oskcore';
import { connect } from 'react-redux';
import { RootState } from '~/redux/store';
import { useNavigate } from 'react-router-dom';

type CurrentProgramRedirectProps = {
    /** The id of the currently active program, received from redux. */
    activeProgramId?: number;
};
const CurrentProgramRedirect = ({ activeProgramId }: CurrentProgramRedirectProps) => {
    const navigate = useNavigate();

    useEffect(() => {
        if (activeProgramId) {
            navigate(`/program/${activeProgramId}`, { replace: true });
        }
    }, [navigate, activeProgramId]);

    return <React.Fragment></React.Fragment>;
};

const mapStateToProps = (state: RootState) => {
    return {
        activeProgramId: state.session.program_id,
    };
};

export default connect(mapStateToProps, noop)(CurrentProgramRedirect);
