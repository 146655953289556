import React, { forwardRef, ReactElement, useEffect, useRef, useState } from 'react';
import { Box, BoxProps, Tooltip, Text } from 'oskcomponents';
import styled from 'styled-components';
import { Property } from 'csstype';
import { OSKIcon } from 'oskcomponents/src/OSKIcon';

const STEP_MARKER_WIDTH = 30;

type TimelineBarProps = {
    /** The text to show above the timeline */
    description: string;
    /** If provided, renders the timeline in an 'error' state and provides this message as a tooltip */
    errorMessage?: string;
    /** The image to use in the status icon */
    icon?: JSX.Element;
} & Omit<BoxProps, 'ref'>;

type TimelineBarStepProps = {
    /** Title of a step to display below */
    title: string;
    /** Description of the step to display below */
    description?: string;
    /** A tooltip to display on hover */
    tooltip?: string;
    /** Marks the current step in this timeline */
    selected?: boolean;
    /** Error message passed from the main timeline component */
    errorMessage?: string;
    /** How the items should be flex-aligned vertically */
    align?: Property.AlignItems;
} & Omit<BoxProps, 'ref'>;

type TimelineBarActiveStepMarkerProps = {
    /** Element to render in the active step marker */
    icon?: JSX.Element;
    /** Whether or not we're in an error state */
    error?: boolean;
} & Omit<BoxProps, 'ref'>;

const TimelineDot = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.42);
    margin-bottom: 16px;
    position: relative;
`;

const TimelineBarActiveStepMarker = styled(
    forwardRef(({ className, icon }: TimelineBarActiveStepMarkerProps, ref: React.Ref<HTMLDivElement>) => {
        return (
            <Box ref={ref} className={className}>
                {icon}
            </Box>
        );
    }),
)`
    width: ${STEP_MARKER_WIDTH}px;
    height: ${STEP_MARKER_WIDTH}px;
    border: 2px solid white;
    background-color: ${(props: any) => (props.error ? 'red' : '#4954C0')};
    border-radius: 50%;
    position: relative;
    top: -12px;
    box-shadow: 0px 1px 5px -1px #000000;
    margin: 0px -5px -8px -5px;
    align-items: center;
    justify-content: center;
`;

/**
    The TimelineBar is used to render the current status of a multi-step process,
    by giving it a sequence of `<TimelineBarStep />` children, with the current step
    marked as 'selected'.

    If the last `<TimelineBarStep />` is selected, the timeline will display as 'complete'.

    TimelineBar can accept the following props:
    - description
      -  The name to display at the top of the element.
    - errorMessage
      -  If provided, the bar will render as an error state, with the error message
        as a tooltip over the selected TimelineBarStep.

    Example use:
    ```
    <TimelineBar description="Oil Fields">
        <TimelineBarStep title="Created" description="06/10/2019" />
        <TimelineBarStep title="Pending" description="06/10/2019" selected/>
        <TimelineBarStep title="Done" />
    </TimelineBar>
    ```
*/
const TimelineBar = styled(({ description, children, errorMessage, className, icon, ...props }: TimelineBarProps) => {
    const [progressBarWidth, setProgressBarWidth] = useState<number>(0);
    const selectedDot = useRef<HTMLDivElement>(null);
    const progressBar = useRef<HTMLDivElement>(null);

    let status = 'normal';
    let selectedIndex = -1;
    const childCount = React.Children.count(children);
    if (errorMessage) {
        selectedIndex = childCount - 1;
        status = 'error';
    } else {
        React.Children.forEach(children, (child, idx) => {
            if ((child as React.ReactElement).props.selected) {
                selectedIndex = idx;
                if (idx === childCount - 1) {
                    status = 'complete';
                }
            }
        });
    }
    const completedPercentage = Math.max(0, selectedIndex) / (childCount - 1);
    const updateProgressBar = () => {
        if (selectedDot.current && progressBar.current) {
            const dotOffset = selectedDot.current.getBoundingClientRect();
            const barOffset = progressBar.current.getBoundingClientRect();
            setProgressBarWidth(dotOffset.left - barOffset.left + STEP_MARKER_WIDTH / 2);
        }
    };
    useEffect(() => {
        updateProgressBar();
        window.addEventListener('resize', updateProgressBar);
        return () => {
            window.removeEventListener('resize', updateProgressBar);
        };
    }, []);

    return (
        <Box col p={18} className={`${className} timeline-container bar-status-${status}`}>
            <Text variant="medium" strong>
                {description}
            </Text>
            <Box className="timeline-completion">
                {(() => {
                    switch (status) {
                        case 'normal':
                            return (
                                <React.Fragment>
                                    <Text variant="large" color="#00d1ff" strong style={{ marginBottom: '-2px' }}>
                                        {(completedPercentage * 100).toFixed(0)}%
                                    </Text>
                                    &nbsp; Completion
                                </React.Fragment>
                            );
                        case 'error':
                            return 'Missed Opportunity';
                        case 'complete':
                            return 'AOI Complete';
                    }
                })()}
            </Box>
            <Box col style={{ width: '100%' }}>
                {status === 'normal' && <Box className="timeline-bar" />}
                {selectedIndex > -1 && (
                    <Box
                        ref={progressBar}
                        className="timeline-progress"
                        style={{
                            width: status === 'normal' ? `${progressBarWidth}px` : '100%',
                        }}
                    />
                )}
                <Box col style={{ zIndex: '1' }}>
                    <Box row className="timeline-step-container">
                        {React.Children.map(children, (child, idx) => {
                            let flexAlign = idx === 0 ? 'flex-start' : 'center';
                            let textAlign: Property.TextAlign = 'left';

                            if (idx === React.Children.count(children) - 1) {
                                textAlign = 'right';
                                flexAlign = 'flex-end';
                            }
                            const moreProps =
                                (child as React.ReactElement).type === TimelineBarStep
                                    ? {
                                          align: flexAlign,
                                      }
                                    : {};

                            if ((child as React.ReactElement).type !== TimelineBarStep) {
                                console.error(
                                    `Unsupported element in TimelineBar: ${
                                        (child as ReactElement).type
                                    }. Expected: TimelineBarStep.`,
                                );
                                return;
                            }
                            return (
                                <Box style={{ textAlign: textAlign }} key={(child as React.ReactElement).props.title}>
                                    {React.cloneElement(child as ReactElement, {
                                        ...moreProps,
                                        errorMessage: errorMessage,
                                        ref: selectedDot,
                                    })}
                                </Box>
                            );
                        })}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
})`
    &.timeline-container {
        height: fit-content;
        background-color: ${(props: any) => props.theme.colors.primary.transBg};
        border-radius: 17px;
        border: 1px solid ${(props: any) => props.theme.colors.primary.border};
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        margin: 6px 0px;

        .timeline-completion {
            display: flex;
            align-items: flex-end;
            font-size: 0.875rem;
            margin-bottom: 4px;
            padding: 12px 0px;

            .timeline-percent {
                margin-bottom: -4px;
            }
        }

        .timeline-bar {
            position: relative;
            background-color: white;
            border-radius: 10px;
            height: 18px;
            width: 100%;
            margin-bottom: -14px;
        }

        .timeline-progress {
            position: relative;
            height: 18px;
            top: -4px;
            margin-bottom: -18px;
            border-radius: 10px;
        }

        .timeline-step-container {
            position: relative;
            place-content: space-between;
            width: calc(100% - 8px);
            left: 4px;
        }

        .timeline-step {
            position: relative;
            width: 100%;
        }

        .timeline-label {
            font-size: 0.75rem;
        }
    }

    &.bar-status-normal {
        .timeline-progress {
            background-color: #f96621;
        }
    }

    &.bar-status-error {
        .timeline-completion {
            font-size: 1.5rem;
            font-weight: 700;
            color: #ff7575;
        }

        .timeline-progress {
            background-color: #ff7575;
        }
    }

    &.bar-status-complete {
        .timeline-completion {
            font-size: 1.5rem;
            font-weight: 700;
            color: #00ff66;
        }

        .timeline-progress {
            background-color: #00ff66;
        }
    }
`;

/**
     A single step passed into a `<TimelineBar />`. Can have a title, description, and tooltip.

    If marked as 'selected', it will be used as the current step in the timeline.
    If the last element is marked as 'selected', the timeline will be considered 'complete'.

    ```
    <TimelineBarStep title="Tasked" description="06/18/2019" tooltip="GHOSt 1&2 tasking in progress" selected />
    ```
 */
const TimelineBarStep = forwardRef(
    (
        { title, description, selected, errorMessage, tooltip, align = 'flex-start', ...props }: TimelineBarStepProps,
        ref: React.Ref<HTMLDivElement>,
    ) => {
        return (
            <Box
                key={title}
                className="timeline-step"
                style={{
                    alignItems: align,
                }}
                col
            >
                <Tooltip label={selected && errorMessage ? errorMessage : ''} position="top">
                    {selected ? (
                        <TimelineBarActiveStepMarker
                            ref={ref}
                            error={errorMessage !== '' && errorMessage !== undefined}
                            icon={
                                errorMessage ? (
                                    <div
                                        style={{
                                            fontSize: `${STEP_MARKER_WIDTH}px`,
                                            fontWeight: '700',
                                            position: 'relative',
                                            top: '-1px',
                                            left: '-1px',
                                        }}
                                    >
                                        x
                                    </div>
                                ) : (
                                    <OSKIcon
                                        code="ghost"
                                        fill="white"
                                        width={STEP_MARKER_WIDTH * 0.66}
                                        height={STEP_MARKER_WIDTH * 0.66}
                                        style={{ transform: 'rotate(45deg)' }}
                                    />
                                )
                            }
                        />
                    ) : (
                        <TimelineDot />
                    )}
                </Tooltip>
                <Tooltip label={tooltip} position="bottom">
                    <Box col className="timeline-label">
                        <Text strong variant="tiny">
                            {title}
                        </Text>
                        <Text variant="tiny">{description}</Text>
                    </Box>
                </Tooltip>
            </Box>
        );
    },
);

export { TimelineBar, TimelineBarStep };
export type { TimelineBarProps, TimelineBarStepProps };
