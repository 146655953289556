import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Form, PageScaffold, TextInput } from 'oskcomponents';
import { ProgramUserTable } from '~/organisms/tables/ProgramUserTable';
import { InviteUserDialog } from '~/molecules/InviteUserDialog';
import * as yup from 'yup';
import { Role, AdminAPI, ProgramUser, Program } from 'oskcore';
import { RemoveUserDialog } from '~/molecules';
import { PortalContentView } from '~/atoms';
import { AppNames } from '~/constants';

export type UserPanelProps = {
    data?: Program;
};

const InviteSchema = yup.object().shape({
    email: yup.string().email().required(),
});

export const UserPanel = ({ data }: UserPanelProps) => {
    const programId = data?.id;
    const [showInviteUser, setShowInviteUser] = useState(false);
    const [showRemoveUser, setShowRemoveUser] = useState(false);
    const [fetching, setFetching] = useState(false);

    const [inviteEmail, setInviteEmail] = useState('');
    const [email, setEmail] = useState('');
    const [userIdToremove, setUserIdToRemove] = useState(0);
    const [users, setUsers] = useState<Array<ProgramUser>>([]);
    const [roles, setRoles] = useState<Role[]>([]);

    const refreshData = useCallback(() => {
        if (programId) {
            setFetching(true);
            AdminAPI.listProgramUsers({ program: programId })
                .then((res) => {
                    AdminAPI.listProgramRoles({ program: programId })
                        .then((roleResult) => {
                            setUsers(res.data.results ?? []);
                            setRoles(roleResult.data.results ?? []);
                        })
                        .finally(() => {
                            setFetching(false);
                        });
                })
                .catch((ex) => {
                    setFetching(false);
                });
        }
    }, [programId]);

    useEffect(() => {
        refreshData();
    }, [refreshData]);

    const handleRoleChange = (user: number, prevRoles: Array<string>, finalRoles: Array<Role>) => {
        AdminAPI.updateProgramUser({
            id: user,
            program: programId ?? -1,
            programUserRequest: {
                roles: finalRoles.map((role) => role.name),
            },
        });
    };

    const beginUserRemove = useCallback(
        (user: number) => {
            setUserIdToRemove(user);
            setEmail(users.find((userEntry) => userEntry.id === user)?.email ?? '');
            setShowRemoveUser(true);
        },
        [setUserIdToRemove, users, setShowRemoveUser],
    );

    const handleUserRemove = useCallback(() => {
        // Hide the dialog
        setShowRemoveUser(false);
        AdminAPI.removeUserFromProgram({ id: userIdToremove, program: programId ?? -1 }).then(() => {
            // Artificially remove the user from the list
            const userList = [...users].filter((item) => item.id !== userIdToremove);
            setUsers(userList);
        });
    }, [setShowRemoveUser, userIdToremove, users, programId, setUsers]);

    const onBeginInvite = useCallback(
        (data: any) => {
            if (InviteSchema.validateSync(data)) {
                setShowInviteUser(true);
                setEmail(data.email);
            }
        },
        [setShowInviteUser, setEmail],
    );

    const doInviteUser = useCallback(() => {
        setShowInviteUser(false);

        // Find the member role
        AdminAPI.addUserToProgram({
            program: programId ?? -1,
            addUserToProgramRequest: {
                email,
                roles: ['General User'],
            },
        }).then(() => {
            refreshData();
            setInviteEmail('');
        });
    }, [email, programId, setInviteEmail, refreshData]);

    return (
        <PortalContentView
            crumbs={[
                { title: AppNames['monitor'], url: '/' },
                { title: data?.name ?? 'Program', url: `/program/${data?.id}` },
                { title: 'Users' },
            ]}
        >
            <PageScaffold title={<span>Users</span>}>
                <ProgramUserTable
                    onRoleChange={handleRoleChange}
                    onUserRemoved={beginUserRemove}
                    roles={roles}
                    readonly={false}
                    loading={fetching}
                    data={users}
                />
                <Form onSubmit={onBeginInvite}>
                    <Box style={{ display: 'flex', paddingTop: '20px', flexDirection: 'row' }}>
                        <TextInput
                            value={inviteEmail}
                            onChange={(evt) => {
                                setInviteEmail(evt.target.value);
                            }}
                            placeholder="email"
                            name="email"
                        />
                        <Button submit label="Invite User" variant="neutral" style={{ marginLeft: '20px' }} inverted />
                    </Box>
                </Form>
                <RemoveUserDialog
                    email={email}
                    onSubmit={handleUserRemove}
                    onCancel={() => setShowRemoveUser(false)}
                    visible={showRemoveUser}
                />
                <InviteUserDialog
                    email={email}
                    onSubmit={doInviteUser}
                    onCancel={() => setShowInviteUser(false)}
                    visible={showInviteUser}
                />
            </PageScaffold>
        </PortalContentView>
    );
};
