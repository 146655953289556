import React from 'react';
import { useTheme } from 'styled-components';
import { Box } from '../Box';
import { OSKThemeType } from '../DefaultThemeProvider';

export type ModalBodyProps = {
    /** Children elements to render in the modal body */
    children?: React.ReactNode;
};

/**
 * This is a ModalBody component which wraps the content area
 * of a modal with standardized padding and height requirements.
 */
const ModalBody = ({ children }: ModalBodyProps) => {
    const theme = useTheme() as OSKThemeType;
    return (
        <Box
            style={{
                padding: '15px',
                minHeight: '75px',
                backgroundColor: theme.colors.gray10,
            }}
        >
            {children}
        </Box>
    );
};

export { ModalBody };
