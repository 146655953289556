import { MapOf, noop, PublicAssetDetail } from 'oskcore';
import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { OSKContentView } from '~/molecules/OSKContentView';
import ValidAssetDetailMapView from '~/organisms/Monitor/AssetDetailMap/ValidAssetDetailMapView';
import { DataProvider } from '~/redux';
import { RootState } from '~/redux/store';
import { getProgramId } from '~/utils';

type MonitorMapViewProps = {
    assets?: MapOf<PublicAssetDetail>;
};
const MonitorMapView = ({ assets }: MonitorMapViewProps) => {
    const { assetId, alertId } = useParams();

    return (
        <DataProvider programId={getProgramId()} assetId={assetId} features={['assets', 'detections']}>
            <OSKContentView>
                {assets && assetId && assets[assetId] && (
                    <ValidAssetDetailMapView selectedAssetId={parseInt(`${assetId ?? -1}`)} selectedAlertId={alertId} />
                )}
            </OSKContentView>
        </DataProvider>
    );
};

export { MonitorMapView };
const mapStateToProps = (state: RootState) => {
    return {
        assets: state.monitor.app.assets as MapOf<PublicAssetDetail>,
    };
};
export default connect(mapStateToProps, noop)(MonitorMapView);
