import React from 'react';
import { Box, Text, MultiSelect, ListEntry, OSKThemeType } from 'oskcomponents';
import { AppDispatch } from '~/redux/store';
import { connect } from 'react-redux';
import { excludePlatform, includePlatform } from '~/redux/modules/data/search';
import styled, { useTheme } from 'styled-components';
import { AppState } from '@auth0/auth0-react';
import { Sensor } from 'oskcore';

export type DataSourceProps = {
    /** A function to invoke when including a platform in the list */
    includePlatform: (platform: number) => void;
    /** A function to invoke when excluding a platform in the list */
    excludePlatform: (platform: number) => void;
    /** Available Sensor options */
    sensors: Sensor[];
    /** List of selected sensors in redux store */
    selectedSensors: number[];
    className: string;
};

const DataSource = styled(
    ({ includePlatform, excludePlatform, sensors, selectedSensors, className }: DataSourceProps) => {
        const theme = useTheme() as OSKThemeType;
        const sensorOptions: ListEntry[] = sensors.map((sensor) => {
            const { osk_id, osk_sensor_name } = sensor;
            return { label: osk_sensor_name, value: osk_id };
        });
        return (
            <Box col className={className}>
                <Text m={10}>Select Data Source</Text>
                <MultiSelect
                    bg={theme.colors.primary.bg}
                    name="dataSource"
                    variant="primary"
                    items={sensorOptions}
                    defaultValues={selectedSensors}
                    inputStyle={{ border: '1px solid #808080', flexGrow: 0, borderRadius: '9px' }}
                    onChange={(selected, oldSelected) => {
                        oldSelected?.map((platform) => excludePlatform(platform.value));
                        selected.map((platform) => includePlatform(platform.value));
                    }}
                />
            </Box>
        );
    },
)`
    padding: 22.5px 70px 22.5px 17px;
    height: 141px;
    border-bottom: 1px solid #626264;
    padding-right: 70px;
`;

const mapStateToProps = (state: AppState) => {
    return {
        sensors: state.osk.sensors,
        selectedSensors: state.data.sensors,
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        includePlatform: (platformId: number) => {
            dispatch(includePlatform(platformId));
        },
        excludePlatform: (platformId: number) => {
            dispatch(excludePlatform(platformId));
        },
    };
};

export { DataSource };
export default connect(mapStateToProps, mapDispatchToProps)(DataSource);
