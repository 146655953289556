import React from 'react';
import { Box } from 'oskcomponents';

import { divIcon } from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import styled from 'styled-components';

type MarkerClusterIconBaseProps = {
    color?: string;
    value: number;
    className?: string;
};

const MarkerClusterIconBase = ({ className, color, value }: MarkerClusterIconBaseProps) => {
    return (
        <Box
            style={{ backgroundColor: color, color: 'white' }}
            className={`${className}`}
            center="horizontal"
            w={40}
            h={40}
        >
            <Box style={{ backgroundColor: color }} className="osk-marker-cluster-point" w={10} h={10} />
            <Box>{value}</Box>
        </Box>
    );
};

const StyledMarkerClusterIcon = styled(MarkerClusterIconBase)`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 1.3em;
    font-weight: bold;
    top: -40px;
    border: 2px solid white;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.4));

    .osk-marker-cluster-point {
        position: absolute;
        background-color: #f96621;
        border: solid white;
        border-width: 0px 2px 2px 0px;
        transform: rotate(45deg);
        top: 32px;
        z-index: -1;
    }
`;

const MarkerClusterIcon = (color: string) => (cluster: any) => {
    const childCount = cluster.getChildCount();
    const iconMarkup = renderToStaticMarkup(<StyledMarkerClusterIcon value={childCount} color={color} />);
    return divIcon({ html: iconMarkup, className: '' });
};

export { MarkerClusterIcon };
