import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Box, Button, Form, PageScaffold } from 'oskcomponents';
import { ProgramForm, ProgramSchema } from '~/organisms/forms/ProgramForm';
import { AdminAPI, mapToInt, Program, shallowEquals } from 'oskcore';
import { decrementBusyCount, incrementBusyCount } from '~/redux/modules/data/app';
import { PortalContentView } from '~/atoms';
import { AppNames } from '~/constants';

export type DetailsPanelProps = {
    data?: Program;
    className?: string;
};
export type DetailsStatus = 'loading' | 'none' | 'success' | 'error';

export const DetailsPanel = styled(({ data, className }: DetailsPanelProps) => {
    const dispatch = useDispatch();

    const [status, setStatus] = useState<DetailsStatus>('none');

    const handleSubmit = (props: any) => {
        const programId: number = data?.id as number;
        setStatus('loading');
        dispatch(incrementBusyCount());
        const request: Program = {
            ...(props as Program),
        };

        // Convert multi-select fields into actual arrays.
        if (props.sensor_data_access) {
            request.sensor_data_access = mapToInt((props.sensor_data_access ?? '').split(','));
        }

        if (props.data_level_access) {
            request.data_level_access = mapToInt((props.data_level_access ?? '').split(','));
        }

        AdminAPI.internalUpdateProgram({ id: programId ?? -1, programRequest: { ...request } }).then(() => {
            setStatus('none');
            dispatch(decrementBusyCount());
        });
    };

    return (
        <PortalContentView
            crumbs={[
                { title: AppNames['monitor'], url: '/' },
                { title: data?.name ?? 'Program', url: `/program/${data?.id}` },
                { title: 'Details' },
            ]}
        >
            <PageScaffold title={<span>Contract Details</span>}>
                <Form
                    className={className}
                    schema={ProgramSchema}
                    onSubmit={(formData) => {
                        shallowEquals(formData, data) && handleSubmit(formData);
                    }}
                >
                    <Box grow col pb={25}>
                        <ProgramForm data={data} readonly={false} />
                        <Box className="submit-container">
                            <Button
                                disabled={status === 'loading'}
                                className="button"
                                variant="neutral"
                                label="Update"
                                style={{
                                    alignSelf: 'flex-end',
                                }}
                                inverted
                                submit
                            />
                        </Box>
                    </Box>
                </Form>
            </PageScaffold>
        </PortalContentView>
    );
})`
    & .submit-container {
        justify-content: flex-end;
    }

    & .form-container {
        width: 500px;
        padding-bottom: 150px;
    }
`;
