import { reduce } from 'lodash';
import { RootState } from '../../store';
import { flatMap } from 'lodash';
import { OSKGeoJson } from 'oskcore';
import { createSelector } from '@reduxjs/toolkit';

export type FootprintEntry = {
    fileId: string;
    collectId: string;
    footprint: OSKGeoJson;
};

const SET_COORDINATES = 'SET_COORDINATES';
export function setCoordinates(lat: number, lon: number) {
    return {
        type: SET_COORDINATES,
        payload: {
            lat,
            lon,
        },
    };
}

const SET_EDIT_POLYGON = 'SET_EDIT_POLYGON';
export function setEditPolygon(enabled: boolean) {
    return {
        type: SET_EDIT_POLYGON,
        payload: {
            enabled,
        },
    };
}

const SET_FOOTPRINTS = 'SET_FOOTPRINTS';
export function setFootprints(footprints: Array<FootprintEntry>) {
    return {
        type: SET_FOOTPRINTS,
        payload: {
            footprints,
        },
    };
}

const SET_HIGHLIGHTED_FOOTPRINT = 'SET_HIGHLIGHTED_FOOTPRINT';
export function setHighlightedFootprint(fileId: string) {
    return {
        type: SET_HIGHLIGHTED_FOOTPRINT,
        payload: {
            fileId,
        },
    };
}

const CLEAR_HIGHLIGHTED_FOOTPRINT = 'CLEAR_HIGHLIGHTED_FOOTPRINT';
export function clearHighlightedFootprint(fileId: string) {
    return {
        type: CLEAR_HIGHLIGHTED_FOOTPRINT,
        payload: {
            fileId,
        },
    };
}

const TOGGLE_VISIBLE_FOOTPRINT = 'TOGGLE_VISIBLE_FOOTPRINT';
export function toogleVisibleFootprint(fileId: string) {
    return {
        type: TOGGLE_VISIBLE_FOOTPRINT,
        payload: {
            fileId,
        },
    };
}

/* Reducer */
type MapStateType = {
    editPolygon: boolean;
    footprints: Record<string, FootprintEntry>;
    highlightedFootprints: Record<string, boolean>;
    visibleFootprints: Record<string, boolean>;
    lat: number;
    lon: number;
};

const initialState: MapStateType = {
    editPolygon: false,
    footprints: {},
    highlightedFootprints: {},
    visibleFootprints: {},
    lat: 37.779,
    lon: -122.429,
};

export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        case SET_HIGHLIGHTED_FOOTPRINT: {
            const { fileId } = action.payload;
            return {
                ...state,
                highlightedFootprints: {
                    [fileId]: true,
                },
            };
        }

        case CLEAR_HIGHLIGHTED_FOOTPRINT: {
            const { fileId } = action.payload;
            return {
                ...state,
                highlightedFootprints: {
                    [fileId]: false,
                },
            };
        }

        case TOGGLE_VISIBLE_FOOTPRINT: {
            const { fileId } = action.payload;
            const { visibleFootprints } = state;
            const current = visibleFootprints[fileId];
            return {
                ...state,
                visibleFootprints: {
                    ...visibleFootprints,
                    [fileId]: !current,
                },
            };
        }

        case SET_FOOTPRINTS: {
            const { footprints } = action.payload;

            const visibleFootprints: Record<string, boolean> = {};
            const nextFootprints = reduce(
                footprints,
                (acc, cur) => {
                    const { fileId } = cur;
                    // Set visible in map
                    visibleFootprints[fileId] = true;
                    return {
                        ...acc,
                        [fileId]: cur,
                    };
                },
                {},
            );
            return {
                ...state,
                footprints: nextFootprints,
                visibleFootprints,
            };
        }

        case SET_COORDINATES: {
            const { lat, lon } = action.payload;
            return {
                ...state,
                lat,
                lon,
            };
        }

        case SET_EDIT_POLYGON: {
            const { enabled } = action.payload;
            return {
                ...state,
                editPolygon: enabled,
            };
        }

        default:
            return { ...state };
    }
}

/* Selectors */
export const getFootprints = createSelector(
    (state: RootState) => state.data.map.footprints,
    (items) => {
        return flatMap(items, (item) => item.footprint);
    },
);

export function getFootprint(state: RootState, footprintId: string): OSKGeoJson {
    return state.data.map.footprints[footprintId]?.footprint;
}
