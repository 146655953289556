import React from 'react';
import { Box, BoxProps, OSKThemeType, Table, Text } from 'oskcomponents';
import { OpportunityWindow } from '~/redux/modules/tasking/app';
import { format } from 'date-fns';
import { connect } from 'react-redux';
import { RootState } from '~/redux/store';
import { noop, Sensor } from 'oskcore';
import { useTheme } from 'styled-components';

export type OpportunityWindowTableProps = {
    data?: Array<OpportunityWindow>;
    sensors: Array<Sensor>;
    selectedRow?: number;
    onRowClick?: (rowData: OpportunityWindow) => void;
};

const DateCell = ({ row: { values } }: any) => {
    const theme = useTheme() as OSKThemeType;

    return (
        <Box style={{ justifyContent: 'center', alignItems: 'flex-start' }} col>
            <Text variant="small">{format(values.start_time, 'MM/dd/yyyy')}</Text>
            <Text variant="small" color={theme.colors.accent}>
                {format(values.start_time, 'HH:mm aa')}
            </Text>
        </Box>
    );
};

const SensorCell = ({ sensors, row: { values } }: { sensors: Array<Sensor>; row: any }) => {
    const sensorMatch = sensors.filter((sensor) => sensor.osk_id === values.sensor)[0];
    if (sensorMatch) {
        return sensorMatch.osk_sensor_name;
    } else {
        return 'Unknown';
    }
};

const AoiIcon = ({ style, ...props }: Omit<BoxProps, 'ref'>) => {
    return (
        <Box
            style={{
                backgroundImage: 'url(/images/aoi-temp.png)',
                width: '70px',
                height: '40px',
                border: '2px solid white',
                borderRadius: '10px',
                position: 'absolute',
                ...style,
            }}
            {...props}
        ></Box>
    );
};

const SegmentCell = ({
    row: {
        values: { ticks },
    },
}: any) => {
    return (
        <Box style={{ position: 'relative', top: '-20px' }}>
            <AoiIcon style={{ transform: 'rotate(8deg)' }} />
            <AoiIcon style={{ transform: 'rotate(-8deg)' }} />
            <AoiIcon />
            <Box
                style={{
                    position: 'absolute',
                    backgroundColor: '#F96621',
                    fontWeight: 'bold',
                    fontSize: '1.15rem',
                    width: '25px',
                    height: '25px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '30px',
                    left: '50px',
                    top: '-5px',
                }}
            >
                {ticks.length}
            </Box>
        </Box>
    );
};

export const OpportunityWindowTable = ({ onRowClick, selectedRow, sensors, data }: OpportunityWindowTableProps) => {
    const columns = React.useMemo(
        () => [
            { Header: 'Segments', accessor: 'ticks', Cell: SegmentCell },
            { Header: 'Start Time', accessor: 'start_time', Cell: DateCell },
            { Header: 'Sensor', accessor: 'sensor', Cell: SensorCell },
        ],
        [],
    );

    if (data) {
        return (
            <Box grow style={{ width: '100%' }}>
                <Table
                    onRowClick={onRowClick}
                    selectedIndex={selectedRow}
                    sensors={sensors}
                    columns={columns}
                    data={data ?? []}
                />
            </Box>
        );
    } else {
        return (
            <Box>
                <Text style={{ fontSize: '1.5rem' }}>There are no opportunities</Text>
            </Box>
        );
    }
};

const mapStateToProps = (state: RootState) => {
    return {
        sensors: state.osk.sensors,
    };
};
export default connect(mapStateToProps, noop)(OpportunityWindowTable);
