import React from 'react';
import { Box, Text, Table, TableColumn, Spinner, TableProps } from 'oskcomponents';

import { ColorIndicator } from '../../../atoms';
import DownloadButton from '../../DownloadButton';
import { TaskingInstance } from '~/redux/modules/tasking/app';
import { date_format, time_format } from '~/utils';

export type TaskListTableProps = {
    data?: TaskingInstance[];
} & Omit<TableProps, 'data'>;

export const TaskListTable = ({ data, selectedIndex, onRowClick }: TaskListTableProps) => {
    const loading = data ? false : true;

    const columns = React.useMemo<Array<TableColumn>>(
        () => [
            {
                Header: 'Task',
                accessor: 'userTaskName',
            },
            {
                Header: 'Created On',
                accessor: 'createdOn',
                Cell: ({ value }: any) => {
                    return <div>{new Date(value).toLocaleDateString()}</div>;
                },
            },
            { Header: 'Data Source', accessor: 'dataSource' },
            {
                Header: 'Start Date',
                accessor: 'startDate',
                Cell: ({ value }: any) => {
                    const dateString = date_format(value);
                    return <div>{dateString}</div>;
                },
            },
            {
                Header: 'End Date',
                accessor: 'endDate',
                Cell: ({ value }: any) => {
                    const dateString = date_format(value);
                    return <div>{dateString}</div>;
                },
            },
            {
                Header: 'Start Time',
                accessor: 'startTime',
                Cell: ({ row }: any) => {
                    const { startDate } = row.values;
                    const date = new Date(startDate);
                    const timeString = time_format(date);
                    return <div>{timeString}</div>;
                },
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: ({ value }: any) => {
                    const colorMap: any = {
                        Pending: '#D99C00',
                        Done: '#00D916',
                        Failed: '#FF0000',
                    };

                    return (
                        <Box row style={{ textAlign: 'left', alignItems: 'center' }}>
                            <ColorIndicator color={value in colorMap ? colorMap[value] : 'white'} p={12} />
                            {value}
                        </Box>
                    );
                },
                sortType: (a: any, b: any, id: string, desc: boolean) => {
                    // TODO: Figure out if 'status' is a number or string
                    // and move the map out of this method. Leaving it here
                    // as a placeholder for now.
                    const map: any = {
                        Pending: 0,
                        Done: 2,
                        Failed: 4,
                    };
                    const order = map[a.values.status] > map[b.values.status];
                    return desc ? !order : order;
                },
            },
            {
                Header: 'Download',
                accessor: 'fileId',
                Cell: ({ row }: any) => {
                    const { fileId } = row.values;
                    return <DownloadButton fileId={fileId} />;
                },
                disableSortBy: true,
            },
        ],
        [],
    );

    if (loading) {
        return (
            <Box style={{ alignItems: 'center', justifyContent: 'center' }}>
                <Spinner />
            </Box>
        );
    } else if (data && data.length > 0) {
        return <Table onRowClick={onRowClick} columns={columns} data={data} selectedIndex={selectedIndex} />;
    } else {
        return (
            <Box>
                <Text style={{ fontSize: '1.5rem' }}>There are no tasks to display at this time.</Text>
            </Box>
        );
    }
};
