/** A global list to keep track of large or negative zIndex values 
    Inherited values from packages/OSKComponents/src/constants.tsx:
    GradientBar: -1,
    ShadowImage: -1,
    Tooltip: 100,
    Overlay: 999,
    ViewPanelResizeTarget: 1000,
    DatePicker: 9001,
    ShadeShadow: 99999998,
    ShadeContent: 99999999,
 */
const GlobalZIndex = {
    SearchResultPanel: 400,
    Timeline: 400,
    MapSection: 401,
    SearchPanel: 403,
    OrderComplete: 999,
    LeafletEditingTools: 1000,
    Cart: 1001,
    Downloads: 1001,
    AppList: 9001,
    UserProfile: 9001,
    ActionBar: 10000000,
};

const AppNames = {
    monitor: 'Sigma Monitor',
    data: 'Sigma Data',
    explore: 'Sigma Explore',
    tasking: 'Sigma Tasking',
};

export { GlobalZIndex, AppNames };
