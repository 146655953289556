import React from 'react';

export type ResizableViewPanelProps = {
    /** The relative amount of space this panel should take up */
    weight?: number;
    /** The children element to contain within this panel */
    children: React.ReactNode;
};

export const ResizableViewPanel = ({ children }: ResizableViewPanelProps) => {
    return <React.Fragment>{children}</React.Fragment>;
};
