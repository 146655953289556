import React, { useState } from 'react';
import { Text, Box, DatePicker } from 'oskcomponents';
import { AppDispatch } from '~/redux/store';
import { connect } from 'react-redux';
import { setDateRange } from '~/redux/modules/data/search';
import { noop } from 'oskcore';

export type DateRangeProps = {
    /** A function to invoke when the date range changes */
    onDateChanged: (start: Date | null, end: Date | null) => void;
};

const DateRange = ({ onDateChanged }: DateRangeProps) => {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    return (
        <Box
            col
            style={{
                padding: '22.5px 70px 22.5px 17px',
                height: '194px',
                borderBottom: '1px solid #626264',
            }}
        >
            <Text m={10}>Set Time Frame</Text>
            <Box col pl={10}>
                <Box mb={5} style={{ justifyContent: 'space-between' }}>
                    <Text
                        style={{
                            alignSelf: 'center',
                            marginRight: '15px',
                        }}
                    >
                        Start
                    </Text>
                    <DatePicker
                        name="start-date"
                        variant="primary"
                        value={startDate}
                        onChange={(start: Date) => {
                            setStartDate(start);
                        }}
                    />
                </Box>
                <Box style={{ justifyContent: 'space-between' }}>
                    <Text
                        style={{
                            alignSelf: 'center',
                            marginRight: '22px',
                        }}
                    >
                        End
                    </Text>
                    <DatePicker
                        name="end-date"
                        variant="primary"
                        value={endDate}
                        onChange={(end: Date) => {
                            setEndDate(end);
                            onDateChanged(startDate, end);
                        }}
                    />
                </Box>
            </Box>
        </Box>
    );
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        onDateChanged: (startDate: Date | null, endDate: Date | null) => {
            dispatch(setDateRange(startDate, endDate));
        },
    };
};

export { DateRange };
export default connect(noop, mapDispatchToProps)(DateRange);
