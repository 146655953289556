import { Box, Divider, Heading, OSKThemeType, RatioLayout, RatioPanel } from 'oskcomponents';
import { noop } from 'oskcore';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTheme } from 'styled-components';
import { Breadcrumb, OSKHR } from '~/atoms';
import { ActionBar } from '~/atoms/ActionBar';
import { AppNames } from '~/constants';
import { IconHeader } from '~/molecules/Monitor';
import { OSKContentView } from '~/molecules/OSKContentView';
import { ProgramReportsTable } from '~/organisms/tables';
import { DataProvider } from '~/redux';
import { ProgramReport } from '~/redux/modules/monitor/reports';
import { RootState } from '~/redux/store';
import { getProgramId } from '~/utils';

export type MonitorReportsViewProps = {
    /** Whether the report list is actively being fetched */
    isLoading: boolean;
    /** List of reports to render in the ui */
    reports: Array<ProgramReport>;
};

type HeaderType = {
    children: React.ReactNode;
};

const Header = ({ children }: HeaderType) => {
    return (
        <Box style={{ paddingBottom: '20px', paddingTop: '10px' }} col>
            <Box m={20} h={40}>
                {children}
            </Box>
            <OSKHR />
        </Box>
    );
};

const MonitorReportsView = ({ isLoading, reports }: MonitorReportsViewProps) => {
    const theme = useTheme() as OSKThemeType;
    const [selectedReportIdx, setSelectedReportIdx] = useState(0);

    return (
        <DataProvider programId={getProgramId()} features={['reports']}>
            <OSKContentView>
                <Box grow col>
                    <ActionBar
                        left={<Breadcrumb nodes={[{ title: AppNames['monitor'], url: '/' }, { title: 'Reports' }]} />}
                    />
                    <Box style={{ padding: '14px 23px' }} grow>
                        <Box
                            style={{
                                backgroundColor: theme.colors.primary.transBg,
                                alignItems: 'center',
                                width: '100%',
                                height: '100%',
                                borderRadius: '30px',
                            }}
                            grow
                        >
                            <RatioLayout
                                style={{
                                    width: '100%',
                                    height: '100%',
                                }}
                                grow
                            >
                                <RatioPanel
                                    p={24}
                                    weight={1}
                                    grow
                                    style={{
                                        borderRight: `1px solid ${theme.colors.primary.border}`,
                                    }}
                                >
                                    <IconHeader title="Reports" iconCode="report" />
                                    <Divider m={14} />

                                    <ProgramReportsTable
                                        selectedIndex={selectedReportIdx}
                                        isLoading={isLoading}
                                        data={reports}
                                    />
                                </RatioPanel>
                                <RatioPanel
                                    style={{ borderTopRightRadius: '30px', borderBottomRightRadius: '30px' }}
                                    bg={theme.colors.primary.bg}
                                    weight={2}
                                    grow
                                >
                                    <Box
                                        style={{
                                            margin: '0px 18px',
                                        }}
                                        col
                                        grow
                                    >
                                        {reports.length > 0 && reports[selectedReportIdx] && (
                                            <React.Fragment>
                                                <Header>
                                                    <Heading>{reports[selectedReportIdx].external_id}</Heading>
                                                </Header>
                                                <object
                                                    data={reports[selectedReportIdx].s3_uri ?? ''}
                                                    type="application/pdf"
                                                    width="100%"
                                                    height="100%"
                                                >
                                                    <p>
                                                        <a
                                                            style={{ color: 'white', fontSize: '2rem' }}
                                                            href={reports[selectedReportIdx].s3_uri ?? ''}
                                                        >
                                                            Download the report here.
                                                        </a>
                                                    </p>
                                                </object>
                                            </React.Fragment>
                                        )}
                                    </Box>
                                </RatioPanel>
                            </RatioLayout>
                        </Box>
                    </Box>
                </Box>
            </OSKContentView>
        </DataProvider>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        isLoading: state.monitor.reports.isFetching,
        reports: state.monitor.reports.reports,
    };
};

export { MonitorReportsView };
export default connect(mapStateToProps, noop)(MonitorReportsView);
