import React, { useCallback } from 'react';
import { OSKView, fround, NavComponent } from 'oskcore';
import FootprintList from '~/organisms/map/FootprintList';
import Timeline from '~/organisms/search/Timeline';
import Cart from '~/organisms/Cart';
import Downloads from '~/organisms/Downloads';
import { useNavigate, useParams } from 'react-router-dom';
import { LeafletEvent, Map } from 'leaflet';
import { Box } from 'oskcomponents';
import { SigmaNavItems } from '~/organisms/SigmaNavItems';
import { useSessionStorage } from '~/hooks';
import AppShimmerBar from '~/organisms/AppShimmerBar';
import ActiveModeSwitcher from '~/organisms/ActiveModeSwitcher';
import SearchSidePanel from '~/organisms/SearchSidePanel';
import EditableMap from '~/organisms/map/EditableMap';
import { debounce } from 'lodash';

const HomeView = () => {
    const { position } = useParams() as any;
    const [, setSessionStorage] = useSessionStorage();
    const navigate = useNavigate();

    const saveMapCoordinates = useCallback(
        (evt: LeafletEvent) => {
            const map = evt.target as Map;
            const { lat, lng } = map.getCenter();
            const froundLat = fround(lat, 3);
            const froundLng = fround(lng, 3);
            const zoom = map.getZoom();

            setSessionStorage('map', { coords: [froundLat, froundLng], zoom: zoom });
            navigate(`/map/@${froundLat},${froundLng},${zoom}/`, { replace: true });
        },
        [navigate],
    );

    return (
        <OSKView constrained nopadding nogradient>
            <AppShimmerBar />
            <Box style={{ flexGrow: 1 }}>
                <ActiveModeSwitcher />
                <NavComponent>
                    <Box style={{ paddingLeft: '10px' }}>
                        <Downloads />
                        <Cart />
                        <SigmaNavItems />
                    </Box>
                </NavComponent>
                <EditableMap position={position} onMove={debounce(saveMapCoordinates, 300)}>
                    <SearchSidePanel />
                    <FootprintList />
                    <Timeline />
                </EditableMap>
            </Box>
        </OSKView>
    );
};

export { HomeView };
