import React from 'react';
import { Box, BoxProps, Button, SidePanelBody, SidePanelFooter, Text } from 'oskcomponents';
import styled from 'styled-components';
import { RootState } from '~/redux/store';
import { connect } from 'react-redux';
import { Capture, noop } from 'oskcore';
import { getGroupedFiles } from '~/redux/modules/data/search';
import FootprintLayersGroup from '~/organisms/Footprint/FootprintLayersGroup';

type FootprintLayersProps = {
    /** Map of collections key is collectionId and value list of footprint captures */
    captureGroups: Record<string, Capture[]>;
} & Omit<BoxProps, 'ref'>;

const FootprintLayers = styled(({ className, captureGroups }: FootprintLayersProps) => {
    return (
        <>
            <SidePanelBody p={15} style={{ height: '100%', overflow: 'hidden' }} col>
                <Text style={{ marginLeft: '5px', marginBottom: '10px' }}>Footprint Layers</Text>
                <Box col className={className}>
                    {captureGroups &&
                        Object.keys(captureGroups).map((collectionId, idx) => {
                            const collection: Capture[] = captureGroups[collectionId];

                            return <FootprintLayersGroup key={idx} captureId={collectionId} captures={collection} />;
                        })}
                </Box>
            </SidePanelBody>
            <SidePanelFooter col grow style={{ justifyContent: 'center', margin: '0 auto', height: '100px' }}>
                <Button
                    onClick={() => console.log('Dowload footprints')}
                    variant="action"
                    disabled={false}
                    style={{ height: '48px', width: '175px' }}
                >
                    Download
                </Button>
            </SidePanelFooter>
        </>
    );
})`
    & {
        border: 1px solid ${(props: any) => props.theme.colors.primary.fg};
        border-radius: 9px;
        padding: 27px;
        overflow-y: scroll;
        height: 100%;
    }
`;

const mapStateToProps = (state: RootState) => {
    return {
        captureGroups: getGroupedFiles(state),
    };
};

export { FootprintLayers };
export default connect(mapStateToProps, noop)(FootprintLayers);
