import React, { useState } from 'react';
import { useTheme } from 'styled-components';
import { Box, Button, Text, OSKThemeType, Select } from 'oskcomponents';
import { FileType, FileVariant } from 'oskcore';

export type CartActionProps = {
    /** The number of active items which are queued for download */
    itemCount: number;
    /** The method to invoke when the download button is pressed */
    onDownload: (level: FileVariant, type: FileType) => void;
};

const DEFAULT_VALUE: FileVariant = '2A';
const VariantOptions = [
    { label: 'Radiance', value: '1B' },
    { label: 'Reflectance', value: '2A' },
];

export const CartActions = ({ itemCount, onDownload }: CartActionProps) => {
    const theme = useTheme() as OSKThemeType;
    const [fileLevel, setFileLevel] = useState<FileVariant>(DEFAULT_VALUE);

    return (
        <Box
            style={{
                padding: '5px 20px',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderTop: `2px solid ${theme.colors.gray2}`,
            }}
            bg={theme.colors.gray0a}
        >
            <Text>{itemCount} file(s) selected</Text>
            <Select
                onSelect={(entry) => entry && setFileLevel(entry.value)}
                variant="primary"
                name="FileLevel"
                placeholder={'Radiance'}
                style={{ color: 'white' }}
                items={VariantOptions}
            />
            <Button
                variant="action"
                m={10}
                defaultValue={DEFAULT_VALUE}
                onClick={() => onDownload && onDownload(fileLevel, 'ENVI')}
            >
                Download
            </Button>
        </Box>
    );
};
