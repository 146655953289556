export * from './comparisons';
export * from './constants';
export * from './environment';
export * from './transforms';
export * from './numbers';
export * from './parsing';
export * from './storage';
export * from './watch';
/** A function which returns an empty object */
export const noop = () => {
    return {};
};
export const capitalize = (str: String) => {
    return str.substring(0, 1).toUpperCase() + str.substring(1);
};

/// Take a string and convert it to snake case
export const toSnakeCase = (str: String) => {
    return str.toLowerCase().replace(' ', '_');
};

/**
 * This method will take an HTMLElement and a ref.current element and
 * then walk up the DOM, looking for a match. Effectively, you can use this
 * to see if the evt.target is a sub-tree node of a ref. If it is, this
 * implies that the user has clicked inside whatever ref.current is.
 *
 * A good use case for this is - you open a pop-up window and want to
 * then listen for any click on the page. If the click is outside
 * the pop-up window, you know you can dismiss it.
 */
export function clickedInside(el: HTMLElement | null, ref: any): boolean {
    if (el === undefined || el === null) return false;
    if (el === ref) return true;
    return clickedInside(el.parentNode as HTMLElement, ref);
}

export const titleize = (str: String) => {
    const words = str.split('_');
    return words.map((word) => capitalize(word)).join(' ');
};

/** Returns true if the given element has a parent with the given id. False otherwise. */
export const hasParentWithId = (el: HTMLElement | null, id: string): boolean => {
    if (!el) return false;

    if (el.id === id) return true;
    if (hasParentWithId) return hasParentWithId(el.parentElement, id);
    return false;
};

/**
 * A randomized set of alphanumeric characters. Good for use with generating a nonce.
 */
export function randomString(length: number): string {
    const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz+/';
    const result: string[] = [];

    while (length > 0) {
        const bytes = new Uint8Array(16);
        const random = window.crypto.getRandomValues(bytes);

        random.forEach((c) => {
            if (length == 0) {
                return;
            }
            if (c < charset.length) {
                result.push(charset[c]);
                length--;
            }
        });
    }

    return result.join('');
}
