import { Box } from 'oskcomponents';
import { OSKGeoJson } from 'oskcore';
import React from 'react';
import { Footprint } from '~/atoms';
import { EditableMap } from '~/organisms/map/EditableMap';
import { useMap } from '~/hooks';

export type RegionAccessPanelProps = {
    /** GeoJSON representing the area this program has access to */
    data?: OSKGeoJson;
};

/**
 * This is a componnet which simply takes the GeoJSON data and
 * anchors the map to the coordinates.
 */
const PositionAnchor = ({ data }: RegionAccessPanelProps) => {
    const map = useMap();
    data && data.features.length > 0 && map.fitCoordinates([data]);
    return <React.Fragment />;
};

export const RegionAccessPanel = ({ data }: RegionAccessPanelProps) => {
    return (
        <Box grow col style={{ height: 'calc(100% - 50px)' }}>
            <EditableMap>
                {data && <Footprint selected={true} shape={data} />}
                {data && <PositionAnchor data={data} />}
            </EditableMap>
        </Box>
    );
};
