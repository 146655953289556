import { useProfileMetadata } from 'oskcore/src/hooks/useProfileMetadata';
import React, { useState } from 'react';
import { AbbreviationIcon, AbbreviationIconProps, PictureIcon } from '~/atoms';

export type UserProfileIconProps = Omit<AbbreviationIconProps, 'label'>;

export const UserProfileIcon = ({ ...props }: UserProfileIconProps) => {
    const [profile, _setProfile] = useProfileMetadata();
    const [imageReady, setImageReady] = useState(false);

    if (profile?.picture && imageReady) {
        return (
            <PictureIcon
                onImageLoaded={setImageReady.bind(this, true)}
                title={`${profile?.first_name} ${profile?.last_name}`}
                url={profile.picture}
                {...props}
            />
        );
    } else {
        return <AbbreviationIcon label={`${profile?.first_name} ${profile?.last_name}`} {...props} />;
    }
};
