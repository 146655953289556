import React from 'react';
import styled from 'styled-components';
import { Box, Heading, OSKIcon } from 'oskcomponents';
import { SidebarNavItem, SidebarNavItemType } from '~/atoms';

export type SidebarNavGroupProps = {
    activeItem?: SidebarNavItemType;
    className?: string;
    item: SidebarNavItemType;
    indent?: number;
    onClick?: (item: SidebarNavItemType) => void;
};

export const SidebarNavGroup = styled(({ activeItem, className, item, indent, onClick }: SidebarNavGroupProps) => {
    if (item.subItems) {
        // List mode
        return (
            <Box className={className} col>
                <Box className="sidebar-nav-group-title">
                    <Heading
                        variant="tiny"
                        className="text"
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'left',
                        }}
                    >
                        {item.iconCode && <OSKIcon code={item.iconCode} style={{ paddingRight: '8px' }} />}
                        {item.title}
                    </Heading>
                    <Box grow />
                    <Box className="signal" />
                </Box>

                <Box className="sidebar-nav-sub-group" col>
                    {item.subItems
                        .filter((subItem) => subItem.visible !== false)
                        .map((subItem, idx) => (
                            <SidebarNavGroup
                                indent={(indent ?? 0) + 20}
                                activeItem={activeItem}
                                onClick={onClick}
                                key={`${idx}_${item.title}`}
                                item={subItem}
                            />
                        ))}
                </Box>
            </Box>
        );
    } else {
        // Single mode
        return <SidebarNavItem onClick={onClick} indent={indent} active={item === activeItem} item={item} />;
    }
})`
    & {
        padding: 30px;
        flex-grow: 1;
        border-right: 1px solid ${(props: any) => props.theme.colors.primary.border};
    }

    & .sidebar-nav-group-title {
        padding-bottom: 10px;
        width: 160px;
        color: ${(props: any) => props.theme.colors.primary.fg};
    }
`;
