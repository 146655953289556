import React, { useContext } from 'react';
import { Box } from 'oskcomponents';
import { WizardContext } from '~/templates/WizardTemplate';
import CSS from 'csstype';

export type WizardPageProps = {
    /** The step index (0-based) which this page corresponds to. */
    stepNumber: number;
    /** The elements to render when this page is active in the wizard. */
    children?: React.ReactNode;
    /** Additional properties to style the container with. */
    style?: CSS.Properties;
};

export const WizardPage = ({ children, stepNumber, style }: WizardPageProps) => {
    const ctx = useContext(WizardContext);

    const hiddenStyle: CSS.Properties = {
        width: 0,
        height: 0,
        visibility: 'hidden',
        display: 'none',
    };

    const visibleStyle: CSS.Properties = {
        width: '100%',
        height: '100%',
        flexGrow: 1,
    };

    // Note: WizardPage uses the CSS visibility class rather than unloading the element
    // because this will allow us to "return" to steps that are complete. By keeping the
    // component alive (but invisible) we will not lose any state data between steps.
    return (
        <Box
            style={{
                ...style,
                ...(ctx.currentStep === stepNumber ? visibleStyle : hiddenStyle),
            }}
            grow
        >
            {children}
        </Box>
    );
};
