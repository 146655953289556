import React, { useEffect, useState, useRef } from 'react';
import { Box, OSKThemeType, Overlay, Spinner, Text, useClickAway } from 'oskcomponents';
import { DownloadPageIndicator, DownloadRequestList } from '~/molecules';
import { useTheme } from 'styled-components';
import { connect } from 'react-redux';
import { AppDispatch, RootState } from '~/redux/store';
import { NavItemEntry, showNavItem } from '~/redux/modules/data/nav';
import { DownloadRequest, PaginatedDownloadRequestList, SigmaAPI } from 'oskcore';
import { GlobalZIndex } from '~/constants';
import { getProgramId } from '~/utils';
import { setDownloads } from '~/redux/modules/osk';

export type DownloadsProps = {
    showOverlay: boolean;
    setShowOverlay: (show: boolean) => void;
    fetchDownloadList: () => Promise<PaginatedDownloadRequestList>;
};

export const Downloads = ({ fetchDownloadList, showOverlay, setShowOverlay }: DownloadsProps) => {
    const ref = useRef(null);
    useClickAway(ref, () => setShowOverlay(false), showOverlay);
    const [data, setData] = useState<Array<DownloadRequest>>([]);
    const [loading, setLoading] = useState(true);
    const theme = useTheme() as OSKThemeType;
    useEffect(() => {
        if (showOverlay) {
            setLoading(true);
            fetchDownloadList().then((resp) => {
                setData(resp.results ?? []);
                setLoading(false);
            });
        }
    }, [showOverlay, fetchDownloadList]);

    return (
        <Box ref={ref} style={{ zIndex: GlobalZIndex.Downloads }} col>
            <DownloadPageIndicator onToggle={() => setShowOverlay(!showOverlay)} />
            <Box style={{ position: 'absolute', top: '55px', right: 0 }}>
                <Overlay
                    show={showOverlay}
                    col
                    animation={'fadeInDown'}
                    p={14}
                    style={{
                        right: '0',
                        width: '450px',
                        height: '50vh',
                    }}
                >
                    <Box p={14} style={{ overflow: 'scroll' }} bg={theme.colors.gray0a} col>
                        {loading ? (
                            <Spinner style={{ alignSelf: 'center' }} variant="Box" size="Medium" />
                        ) : data.length === 0 ? (
                            <Box style={{ height: '150px', margin: '0 auto', alignItems: 'center' }}>
                                <Text variant="large"> No images available</Text>
                            </Box>
                        ) : (
                            <DownloadRequestList items={data} />
                        )}
                    </Box>
                </Overlay>
            </Box>
        </Box>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        showOverlay: state.data.nav.showDownloads,
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        setShowOverlay: (show: boolean) => {
            dispatch(showNavItem(show ? NavItemEntry.Downloads : NavItemEntry.None));
        },
        fetchDownloadList: () => {
            return SigmaAPI.listDownloads({ program: getProgramId() }).then((downloadRequestList) => {
                const { data } = downloadRequestList;
                const { results } = data;
                dispatch(setDownloads(results as DownloadRequest[]));
                return data;
            });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Downloads);
