import { noop } from '../index';

/**
 * Takes a promise and reruns the promise until it succeeds
 * This util is used to poll an api to check the status of a request.
 * Once the api resolves "watch" func will return a promise that allows the caller to handle the response and cancels the interval.
 * @param promise
 * @param timeoutMs
 */
export function watch(promiseGenerator: () => Promise<void>, timeoutMs: number) {
    return new Promise<void>((resolve) => {
        promiseGenerator()
            .then(resolve)
            .catch(() => {
                let finish = false;
                const timerId = setInterval(() => {
                    promiseGenerator()
                        .then(() => {
                            clearInterval(timerId);
                            // Used to cut off the watcher at the first resolution so that there isn't a queue of successful responses
                            if (!finish) {
                                finish = true;
                                resolve();
                            }
                        })
                        .catch(noop);
                }, timeoutMs);
            });
    });
}
