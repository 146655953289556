import { Opportunity } from 'oskcore';
import { Tick, OpportunityWindow } from '~/redux/modules/tasking/app';

/**
 * This method takes an array of ticks, a TLE, and an orbital period, and returns an array
 * of OpportunityWindow objects which are grouped by orbitalPeriod.
 */
export function convertToOpportunities(
    ticks: Array<Opportunity>,
    tle: string,
    orbitalPeriod: number,
): Array<OpportunityWindow> {
    const groups = [];
    const accumulator = [];

    let startTime = new Date(ticks[0].start_time).getTime();
    for (let i = 0; i < ticks.length; i++) {
        if (Math.abs(new Date(ticks[i].start_time).getTime() - startTime) < orbitalPeriod) {
            accumulator.push(ticks[i]);
        } else {
            if (accumulator.length > 0) {
                groups.push(Object.assign([], accumulator));
            }

            accumulator.splice(0, accumulator.length);
            accumulator.push(ticks[i]);
        }
        startTime = new Date(ticks[i].start_time).getTime();
    }

    if (accumulator.length > 0) {
        groups.push(accumulator);
    }
    return groups.map((group) => ({
        start_time: new Date(group[0].start_time),
        end_time: new Date(group[group.length - 1].end_time),
        sensor: group[0].sensor,
        ticks: group,
        tle,
    }));
}
