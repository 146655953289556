import React from 'react';
import { ThemeProvider } from 'styled-components';
import './fonts.css';
import './style.css';

export const Device = {
    Laptop: '1024px',
    Desktop: '2560px',
};

export type ColorVariants =
    | 'primary'
    | 'action'
    | 'inverted'
    | 'neutral'
    | 'selected'
    | 'disabled'
    | 'contrast'
    | 'danger';

export type ColorMode = 'dark' | 'light';

const colors = {
    primary: 'black',
    inverted: 'white',
    accent: '#e67c24', // Orbital Orange
    accentTrans: '#e67c2442', // Orbital Orange
    accent2: '#799FF7', // Lightsaber Blue
    highlight: 'rgba(0, 194, 255, 0.26)',
    darkAccent: '#f96621',
    red: '#EA0000',

    orange900: '#f96621',
    orange100: '#ffcbb2',
    orange50: '#e67c24',

    green900: '#017c0a',
    green100: '#9df3a4',

    black900: '#282828',
    black800: '#3B3B3B',
    black700: '#515151',
    black600: '#626262',
    black500: '#7E7E7E',
    black400: '#9E9E9E',
    black300: '#B1B1B1',
    black200: '#CFCFCF',
    black100: '#E1E1E1',
    black50: '#F7F7F7',

    blue900: '#1C1F24',
    blue800: '#243B53',
    blue700: '#334E68',
    blue600: '#486581',
    blue500: '#627D98',
    blue400: '#829AB1',
    blue300: '#9FB3C8',
    blue200: '#BCCCDC',
    blue100: '#D9E2Ec',
    blue50: '#F0F4F8',

    black75: '#000000BF',

    gray1: '#3B3B3B',
    gray2: '#515151',
    gray0a: 'rgba(0,0,0,0.75)',
    gray1a: 'rgba(28,28,28,0.9)',
    gray2a: 'rgba(0,0,0,0.25)',
    gray2ab: 'rgba(64,64,64,0.5)',
    gray3a: 'rgba(128,128,128,0.5)',
    gray4a: 'rgba(128,128,128,0.1)',

    gray110: '#303030',
    gray100: '#484848',
    gray80: '#6b6b6b',
    gray75: '#808080',
    gray50: '#c9c9c9',
    gray40: '#d6d6d6',
    gray25: '#e4e4e4',
    gray10: '#f1f1f1',

    bluegray300: '#424347',

    white0a: 'rgba(255, 255, 255, 0.9)',
    white1a: 'rgba(255, 255, 255, 0.5)',
    white2a: 'rgba(255, 255, 255, 0.25)',

    white: '#fff',
    black: '#000',
    error: '#a81313',
};

const defaultTheme = {
    chipSpacing: '10px',
    colors: {} as { [id: string]: any }, // Populated in GetTheme()
    edgeContentPadding: 15,
    navItemPadding: 10,
    navIconSize: 1.75,
    largeIconSize: 2.75,
    font: 'Oxygen',
    boxShadow: `0px 2px 6px ${colors.gray3a}`,
    monospaceFont: 'Roboto Mono',
    input: {
        borderColor: colors.black,
        errorBorderColor: colors.error,
        padding: '8px',
    },
    margin: {
        sm: '4px',
        md: '8px',
        lg: '12px',
    },
    padding: {
        sm: '4px',
        md: '8px',
        lg: '12px',
    },
    text: {
        color: colors.primary,
        size: '1.15rem',
        Heading: {
            as: ['h1', 'h2', 'h3', 'h4'],
            size: ['2rem', '1.75rem', '1.5rem', '1.25rem', '3rem'],
            weight: 'bold',
            props: {},
        },
        Subtitle: {
            size: ['1.35rem', '1.15rem', '1rem', '.83rem', '3rem'],
            as: 'div',
            weight: 'normal',
            props: {
                role: 'doc-subtitle',
            },
        },
        Text: {
            size: ['1.35rem', '1.15rem', '1rem', '.83rem', '3rem'],
            as: 'div',
            weight: 'normal',
            props: {},
        },
    },
    status: {
        none: '',
        loading: 'rgba(0,0,0,.15)',
        error: colors.red,
        success: colors.accent,
    },
};

const lightColors = {
    colors: {
        ...colors,
        primary: {
            bg: colors.gray4a,
            fg: colors.black800,
            invertedBg: colors.black,
            invertedFg: colors.white,
            accent: colors.accent,
            subtleAccent: colors.blue50,
            invertAccent: colors.accent2,
            border: colors.gray3a,
            borderAlt: 'transparent',
            hoverBorder: colors.accent,
            highlight: colors.white,
            transBg: colors.white0a,
            altTransBg: colors.gray0a,
            halfTransBg: colors.white2a,
            neutralBg: colors.gray2a,
        },
        inverted: {
            bg: colors.black,
            fg: colors.white,
            fgAlt: colors.white,
            invertedBg: colors.white,
            invertedFg: colors.black300,
            accent: colors.accent,
            border: colors.inverted,
            hoverBorder: colors.inverted,
            highlight: colors.highlight,
        },
        neutral: {
            bg: colors.gray75,
            fg: colors.inverted,
            fgAlt: colors.inverted,
            invertedBg: colors.gray100,
            invertedFg: colors.inverted,
            accent: colors.accent,
            border: colors.primary,
            hoverBorder: colors.inverted,
            highlight: colors.highlight,
        },
        hovered: {
            bg: colors.primary,
            fg: colors.inverted,
            fgAlt: colors.inverted,
            invertedBg: colors.inverted,
            invertedFg: colors.primary,
            accent: colors.primary,
            border: colors.primary,
            hoverBorder: colors.inverted,
            highlight: colors.highlight,
        },
        selected: {
            bg: colors.black900,
            fg: colors.orange50,
            fgAlt: colors.orange50,
            invertedBg: colors.white,
            invertedFg: colors.orange50,
            accent: colors.white,
            border: colors.inverted,
            hoverBorder: colors.inverted,
            highlight: colors.highlight,
        },
        action: {
            bg: colors.highlight,
            fg: colors.black800,
            fgAlt: colors.inverted,
            invertedBg: colors.accent2,
            invertedFg: colors.white,
            accent: colors.accent,
            border: colors.accent,
            hoverBorder: colors.accent,
            highlight: colors.highlight,
        },
        disabled: {
            bg: 'rgba(0,0,0,.15)',
            fg: colors.black400,
            fgAlt: colors.black400,
            invertedBg: colors.black500,
            invertedFg: colors.black300,
            accent: colors.gray50,
            border: 'rgba(0,0,0,.15)',
            hoverBorder: 'rgba(0,0,0,.15)',
            highlight: colors.highlight,
        },
        contrast: {
            bg: colors.white,
            fg: colors.black,
            fgAlt: colors.black,
            invertedBg: colors.black,
            invertedFg: colors.white,
            accent: colors.accent,
            border: colors.white,
            hoverBorder: colors.gray80,
            highlight: colors.highlight,
        },
        danger: {
            bg: colors.inverted,
            fg: colors.error,
            fgAlt: colors.error,
            invertedBg: colors.error,
            invertedFg: colors.inverted,
            accent: colors.accent,
            border: colors.error,
            hoverBorder: colors.error,
            highlight: colors.highlight,
        },
        clear: {
            bg: 'transparent',
            fg: colors.white,
            invertedBg: colors.gray2,
            invertedFg: colors.white,
            accent: colors.accent,
            border: colors.gray75,
            hoverBorder: colors.primary,
            highlight: colors.highlight,
        },
    },
};

const darkColors = {
    colors: {
        ...colors,
        primary: {
            bg: colors.black900,
            fg: colors.white,
            invertedBg: colors.white,
            invertedFg: colors.black,
            accent: colors.accent,
            invertAccent: colors.accent2,
            subtleAccent: colors.blue900,
            border: colors.gray3a,
            borderAlt: colors.gray110,
            hoverBorder: colors.primary,
            highlight: colors.highlight,
            altTransBg: colors.white2a,
            transBg: colors.gray1a,
            halfTransBg: colors.gray2a,
        },
        inverted: {
            bg: colors.black,
            fg: colors.white,
            fgAlt: colors.white,
            invertedBg: colors.white,
            invertedFg: colors.black300,
            accent: colors.accent,
            border: colors.inverted,
            hoverBorder: colors.inverted,
            highlight: colors.highlight,
        },
        neutral: {
            bg: colors.gray75,
            fg: colors.inverted,
            fgAlt: colors.inverted,
            invertedBg: colors.gray100,
            invertedFg: colors.inverted,
            accent: colors.accent,
            border: colors.primary,
            hoverBorder: colors.inverted,
            highlight: colors.highlight,
        },
        hovered: {
            bg: colors.primary,
            fg: colors.inverted,
            fgAlt: colors.inverted,
            invertedBg: colors.inverted,
            invertedFg: colors.primary,
            accent: colors.primary,
            border: colors.primary,
            hoverBorder: colors.inverted,
            highlight: colors.highlight,
        },
        selected: {
            bg: colors.black900,
            fg: colors.orange50,
            fgAlt: colors.orange50,
            invertedBg: colors.white,
            invertedFg: colors.orange50,
            accent: colors.white,
            border: colors.inverted,
            hoverBorder: colors.inverted,
            highlight: colors.highlight,
        },
        action: {
            bg: colors.accent,
            fg: colors.inverted,
            fgAlt: colors.inverted,
            invertedBg: colors.accent,
            invertedFg: colors.white,
            accent: colors.accent,
            border: colors.accent,
            hoverBorder: colors.accent,
            highlight: colors.highlight,
        },
        disabled: {
            bg: 'rgba(0,0,0,.15)',
            fg: colors.black400,
            fgAlt: colors.black400,
            invertedBg: colors.black500,
            invertedFg: colors.black300,
            accent: colors.gray50,
            border: 'rgba(0,0,0,.15)',
            hoverBorder: 'rgba(0,0,0,.15)',
            highlight: colors.highlight,
        },
        contrast: {
            bg: colors.white,
            fg: colors.black,
            fgAlt: colors.black,
            invertedBg: colors.black,
            invertedFg: colors.white,
            accent: colors.accent,
            border: colors.white,
            hoverBorder: colors.gray80,
            highlight: colors.highlight,
        },
        danger: {
            bg: colors.inverted,
            fg: colors.error,
            fgAlt: colors.error,
            invertedBg: colors.error,
            invertedFg: colors.inverted,
            accent: colors.accent,
            border: colors.error,
            hoverBorder: colors.error,
            highlight: colors.highlight,
        },
        clear: {
            bg: 'transparent',
            fg: colors.white,
            invertedBg: colors.gray2,
            invertedFg: colors.white,
            accent: colors.accent,
            border: colors.gray75,
            hoverBorder: colors.primary,
            highlight: colors.highlight,
        },
    },
};

const GetTheme = (mode: ColorMode = 'light') => {
    const colorMap = {
        light: lightColors,
        dark: darkColors,
    };

    return {
        ...defaultTheme,
        ...colorMap[mode],
    };
};

const ThemeModeStorageKey = 'themeMode';

const DefaultThemeProvider = ({ children }: any) => {
    const themeMode = localStorage.getItem(ThemeModeStorageKey) as ColorMode;
    return (
        <ThemeProvider theme={GetTheme(themeMode ?? 'dark')}>
            {children}

            {/* Some components in this library use portals to create stuff in the top-most level of the page.
            This div can be an effective target for such functionality. */}
            <div id="portal-me"></div>
        </ThemeProvider>
    );
};

type OSKThemeType = typeof defaultTheme;
const theme = defaultTheme;

export type { OSKThemeType };
export { DefaultThemeProvider, theme, ThemeModeStorageKey };
