import { Configuration } from '../api/generated';
import Storage from './storage';
import globalAxios from 'axios';

const ACCESS_TOKEN_KEY = 'accessToken';
const REFRESH_TOKEN_KEY = 'refreshToken';

// Figure out basePath.
// eslint-disable-next-line
let basePath = '/api';
try {
    // NOTE: This is required for reasons that are lost to history, but have to do
    // with the fact that vite string replace has issues.
    eval(`basePath = import.meta.env.VITE_API_LOCATION`);
} catch (ex) {}

let disableInterceptor = false;
const ApplicationConfiguration = new Configuration({
    // @ts-ignore I cannot get Vite's types to work no matter what I try.
    // https://vitejs.dev/guide/features.html#client-types
    basePath,
    baseOptions: {
        timeout: 70000,
        validateStatus: (status: number) => {
            // If the interceptor is enabled, we are configured to
            // deal with 401 errors and the retry logic that goes with it.
            // If, however, interceptors are disabled then we should treat
            // 401 as an error condition.
            const canHandle401 = !disableInterceptor;

            // This will make the status 401 valid, as well as 2xx and 3xx.
            // We want 401 valid because the interceptor will handle
            // retry and auto-token-refresh logic.
            return (canHandle401 && status === 401) || status / 100 < 4;
        },
    },
});

/**
 * This method will configure the API with a JWT and cache the subsequent
 * refresh token using the default caching scheme.
 *
 * It works because JavaScript is awesome. All API constructors accept a Configuration
 * object. That configuration object is defined in the local scope of this class. They all
 * use the same /refrence/ to the same object. So by changing the original object here
 * in the casual scope, it is automatically applied to any API which has been configured
 * properly.
 *
 * @param {string} accessToken - The currently validated JWT
 * @param {string} refreshToken - The refresh token which will be cached for future use on page reload
 */
export const setAccessToken = (accessToken: string, refreshToken: string) => {
    if (refreshToken) {
        Storage.put(REFRESH_TOKEN_KEY, refreshToken);
    }

    if (accessToken) {
        Storage.put(ACCESS_TOKEN_KEY, accessToken);
    }

    globalAxios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
};

// This mechanism can be used to listen for authentication expired events.
// Which are triggered when the authentication is well and truly non-recoverable.
const authExpiredIntercepters: Array<Function> = [];
export const registerAuthenticationExpiredInterceptor = (fn: Function) => {
    authExpiredIntercepters.push(fn);
};

/* This block of code is used to intercept all axios responses
   and check for Unathenticated responses. If a response is un-authenticated
   it will attempt to refresh the access token and automatically
   retry the failed request.
*/
globalAxios.interceptors.response.use(async (resp) => {
    // If the interceptor is disabled, short-circuit the logic
    if (disableInterceptor) return resp;
    // Do not attempt to refresh login requests.
    if (resp.config.url?.includes('/api/token')) {
        return resp;
    }

    switch (resp.status) {
        case 401: // Unathenticated
            try {
                // This interceptor uses the globalAxios instance to implement retry logic.
                // If we didn't short-circuit the logic (with disableInterceptor) then we
                // could get into an infinite loop if the refreshToken() failed because
                // that too is Unathorized. The interceptor would keep detecting unauthorized
                // and then retry forever.
                disableInterceptor = true;
                const { config } = resp;
                const retryConfig = { ...config };
                // There's no way around this. We need to hard-code the JWT here in this one spot.
                // Usually we rely on the "Config" to handle configuration of the request but at this particular
                // point, we are existing outside the API Clients. So we can't rely on their magic.
                // retryConfig.headers['Authorization'] = `Bearer ${access}`;

                try {
                    // Throw invalid refresh token which will cause the user to be logged out.
                    throw 'Invalid refresh token';
                    // If you want to retry the failed request, uncomment this line.
                    // return globalAxios(retryConfig);
                } catch (ex) {
                    throw 'Invalid refresh token';
                }
            } catch (ex) {
                // Redirect to login page
                for (const interceptor of authExpiredIntercepters) {
                    interceptor();
                }
            } finally {
                disableInterceptor = false;
            }
            return resp;
        default:
            return resp;
    }
});

setAccessToken(Storage.get(ACCESS_TOKEN_KEY), Storage.get(REFRESH_TOKEN_KEY));
export const APIConfiguration = ApplicationConfiguration;

/** This method returns true if the instance of the webapp is
 *  running in localhost (i.e. developer mode).
 */
export function isDev() {
    return window.location.host.includes('localhost') || window.location.host.includes('127.0.0.1');
}

/** This method returns true if the instance of the webapp is
 * running in production.
 */
export function isProd() {
    return window.location.host.includes('sigma.prod.orbitalsk.com');
}

/**
 * This method will return the base API path as configured
 * in vite.
 */
export function getBaseApi() {
    return basePath;
}
