import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Box, Button, Form, PageScaffold } from 'oskcomponents';
import { AssetForm, AssetSchema } from '~/organisms/forms/AssetForm';
import { PublicAssetDetail, PrivateAssetRequest, SigmaAPI } from 'oskcore';
import { decrementBusyCount, incrementBusyCount } from '~/redux/modules/data/app';
import { PortalContentView } from '~/atoms';
import { useEnsureProgram } from '~/hooks';
import { getProgramId } from '~/utils';
import { AppNames } from '~/constants';

export type PublicAssetDetailWithEmails = PublicAssetDetail;
export type AssetPanelProps = {
    data?: PublicAssetDetailWithEmails;
    className?: string;
};
export type AssetStatus = 'loading' | 'none' | 'success' | 'error';

export const AssetPanel = styled(({ data, className }: AssetPanelProps) => {
    const dispatch = useDispatch();
    useEnsureProgram();

    const [status, setStatus] = useState<AssetStatus>('none');

    const handleSubmit = useCallback(
        (props: any) => {
            const assetId: number = data?.id as number;
            setStatus('loading');
            dispatch(incrementBusyCount());

            // TODO: Call public update asset method
            SigmaAPI.internalGetAsset({ id: assetId }).then((resp) => {
                const request: PrivateAssetRequest = {
                    ...(props as PrivateAssetRequest),
                    alert_emails: props.alert_emails ? props.alert_emails.split(',') : [],
                    report_emails: props.report_emails ? props.report_emails.split(',') : [],
                    program: getProgramId(),
                    aoi: resp.data.aoi,
                };
                SigmaAPI.internalUpdateAsset({ id: assetId, privateAssetRequest: request }).then(() => {
                    setStatus('none');
                    dispatch(decrementBusyCount());
                });
                setStatus('none');
                dispatch(decrementBusyCount());
            });
        },
        [data, dispatch],
    );

    return (
        <PortalContentView crumbs={[{ title: AppNames['monitor'], url: '/' }]}>
            <PageScaffold title={<span>{data?.name} Details</span>}>
                <Form
                    className={className}
                    schema={AssetSchema}
                    onSubmit={(formData) => {
                        handleSubmit(formData);
                    }}
                >
                    <Box grow col pb={25}>
                        <AssetForm data={data} readonly={false} />
                        <Box className="submit-container">
                            <Button
                                disabled={status === 'loading'}
                                className="button"
                                variant="neutral"
                                label="Update"
                                style={{
                                    alignSelf: 'flex-end',
                                }}
                                inverted
                                submit
                            />
                        </Box>
                    </Box>
                </Form>
            </PageScaffold>
        </PortalContentView>
    );
})`
    & .submit-container {
        justify-content: flex-end;
    }

    & .form-container {
        width: 500px;
        padding-bottom: 150px;
    }
`;
