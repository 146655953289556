import { reduce, some, filter, values } from 'lodash';
import {
    Capture,
    DownloadIntent,
    DownloadRequest,
    DownloadRequestRequest,
    DownloadStatus,
    SigmaAPI,
    watch,
} from 'oskcore';
import { getProgramId } from '~/utils';
import { AppDispatch, RootState } from '../../store';
import { cacheFile } from '../osk';
import { decrementBusyCount, incrementBusyCount } from './app';

export type DownloadCollectGroup = Record<string, Array<DownloadIntent | undefined>>;

export type DownloadFileMethod = (
    request: DownloadRequestRequest,
    onDownloadFinished?: (data: DownloadRequest) => void,
) => void;

export type WatchFileDownloadMethod = (
    requestId: number,
    onFinished?: ((data: DownloadRequest) => void) | undefined,
) => void;

export type FetchDownloadedFileMethod = (
    fileIds: number[],
    onDownloadFinished?: (data: DownloadRequest | undefined) => void,
) => void;

const ENQUEUE_FILE = 'ENQUEUE_FILE';
export function enqueueFile(request: DownloadIntent, item: Capture) {
    return {
        type: ENQUEUE_FILE,
        payload: {
            request,
            item,
        },
    };
}

const DEQUEUE_FILE = 'DEQUEUE_FILE';
export function dequeueFile(fileId: string) {
    return {
        type: DEQUEUE_FILE,
        payload: {
            fileId,
        },
    };
}

const TOGGLE_FILE_INTENT = 'TOGGLE_FILE_INTENT';
export function toggleFileIntent(fileId: string) {
    return {
        type: TOGGLE_FILE_INTENT,
        payload: {
            fileId,
        },
    };
}

const SET_SHOW_CART = 'SET_SHOW_CART';
export function setShowCart(showCart: boolean) {
    return {
        type: SET_SHOW_CART,
        payload: {
            showCart,
        },
    };
}

const PRUNE_CART_ITEMS = 'PRUNE_CART_ITEMS';
export function pruneCartItems() {
    return {
        type: PRUNE_CART_ITEMS,
        payload: {},
    };
}

const SET_IS_DOWNLOADING = 'IS_DOWNLOADING';
export function setIsDownloading(isDownloading: boolean) {
    return {
        type: SET_IS_DOWNLOADING,
        payload: {
            isDownloading,
        },
    };
}

export function downloadFilesAsync(
    /** Request data used to kick off a new download request */
    request: DownloadRequestRequest,
    /** Method that's fired when the download has been finished */
    onDownloadFinished?: (data: DownloadRequest) => void,
) {
    return (dispatch: AppDispatch) => {
        // Action to change loading state to "true"
        dispatch(incrementBusyCount());

        // Cache a facsimile of the file so we can update the UI immediately
        dispatch(
            cacheFile({
                id: -1,
                requested_by: -1,
                requested_for: -1,
                time_requested: new Date().toISOString(),
                ...request,
            }),
        );

        // Make a request to download selected files
        SigmaAPI.createDownload({ program: getProgramId(), downloadRequestRequest: request })
            .then(async (resp) => {
                const { id } = resp.data;

                // Add the new downloda to the file cache
                dispatch(cacheFile(resp.data));

                // Polling to check the status of download request with request ID
                watch(
                    () =>
                        new Promise((resolve, reject) => {
                            // Backend request that is made every 1500 ms to check status of download
                            SigmaAPI.getDownload({ program: getProgramId(), id })
                                .then((response) => {
                                    // Handle success or failed response
                                    if (response.data.time_ready) {
                                        resolve();

                                        if (onDownloadFinished) {
                                            // Update the file information in the cache
                                            dispatch(cacheFile(response.data));

                                            // Notify the request creator.
                                            onDownloadFinished(response.data);
                                        }
                                    } else {
                                        reject();
                                    }
                                })
                                .catch(reject);
                        }),
                    1500,
                ).then(() => {
                    // After done loading fire action to set loading to "false"
                    dispatch(decrementBusyCount());
                });
                return resp;
            })
            .catch(() => {
                dispatch(decrementBusyCount());
            });
    };
}

/** Retrieve existing DownloadRequest for a given fileId */
export function fetchDownloadedFileAsync(fileIds: number[], onFinished?: (data: DownloadRequest | undefined) => void) {
    return () => {
        watch(
            () =>
                new Promise((resolve, reject) => {
                    SigmaAPI.listDownloads({ program: getProgramId() })
                        .then((response) => {
                            if (onFinished) {
                                const download = response.data.results?.find((request) => {
                                    const now = new Date();
                                    const expireDate = new Date(request.time_expired ?? '');
                                    const expired: boolean = now > expireDate;
                                    return (
                                        request.requested_captures.length === fileIds.length &&
                                        request.requested_captures.every((id, idx) => {
                                            return id === fileIds[idx];
                                        }) &&
                                        !expired
                                    );
                                });
                                onFinished(download);
                            }
                            resolve();
                        })
                        .catch(reject);
                }),
            1500,
        );
    };
}

/* Reducer */
export type DownloadQueueState = {
    /** An object of DownloadIntents. Key is fileId, value is intent */
    enqueued: Record<string, DownloadIntent | undefined>;
    /** An object containing PipelineResult items. Key is fileId, value is result obj */
    enqueuedItemMap: Record<string, Capture | undefined>;
    recentlyEnqueuedCollect: string | null;
    requestId?: string;
    status: DownloadStatus;
    showCart: boolean;
};

const initialState: DownloadQueueState = {
    enqueued: {},
    enqueuedItemMap: {},
    recentlyEnqueuedCollect: null,
    requestId: undefined,
    status: DownloadStatus.Idle,
    showCart: false,
};

export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        case TOGGLE_FILE_INTENT: {
            const { fileId } = action.payload;
            const nextState = Object.assign({}, state);

            // Find out which intents are associated with the collect
            const downloadIntents = values(state.enqueued).filter(
                (intent) => intent !== undefined && intent?.fileId === fileId,
            );
            const shouldSkip = !some(downloadIntents, (intent) => intent?.skip);

            // Update the intents
            downloadIntents.forEach((intent) => {
                if (intent !== undefined && nextState.enqueued[intent.fileId] !== undefined) {
                    (nextState.enqueued[intent.fileId] as any).skip = shouldSkip;
                }
            });

            return nextState;
        }

        case ENQUEUE_FILE: {
            const { request, item } = action.payload;
            return {
                ...state,
                recentlyEnqueuedCollect: request.collectId,
                enqueued: {
                    ...state.enqueued,
                    [`${request.fileId}`]: request,
                },
                // NOTE: We are copying the file data here. There is an important reason for this:
                // when a new search happens, the ResultItem map gets cleared. As such, we would
                // no longer be able to render metadata about this thing if a new search
                // overwrites the underlying datasource. We want selected items to persist
                // between searches. So copying it here is the best way to ensure the information
                // survives between search instances.
                enqueuedItemMap: {
                    ...state.enqueuedItemMap,
                    [`${request.fileId}`]: item,
                },
            };
        }

        case DEQUEUE_FILE: {
            const payload = action.payload as DownloadIntent;

            // Check if we still have anything enqeuued which is part of the original collectId
            const collectStillRelevant = some(
                values(state.enqueued),
                (intent) => intent?.fileId !== payload.fileId && intent?.collectId === state.recentlyEnqueuedCollect,
            );

            return {
                ...state,
                // If we are still interacting with the same collect, retain the value. Otherwise reset it.
                recentlyEnqueuedCollect: collectStillRelevant ? state.recentlyEnqueuedCollect : null,
                enqueued: {
                    ...state.enqueued,
                    [`${payload.fileId}`]: undefined,
                },
                enqueuedItemMap: {
                    ...state.enqueuedItemMap,
                    [`${payload.fileId}`]: undefined,
                },
            };
        }

        case SET_SHOW_CART: {
            const { showCart } = action.payload;
            return {
                ...state,
                showCart,
            };
        }

        case PRUNE_CART_ITEMS: {
            const nextState = { ...state };
            for (const file in nextState.enqueued) {
                const intent = nextState.enqueued[file];
                if (intent !== undefined && intent.skip === true) {
                    delete nextState.enqueued[file];
                    delete nextState.enqueuedItemMap[file];
                }
            }

            return nextState;
        }

        default:
            return { ...state };
    }
}

/* Selectors */
export function getItemsByCollect(state: RootState): DownloadCollectGroup {
    return reduce(
        getAllItems(state),
        (acc, item) => {
            if (item !== undefined) {
                acc[item.collectId] = acc[item.collectId] || [];
                acc[item.collectId].push(item);
            }
            return acc;
        },
        {} as DownloadCollectGroup,
    );
}

export function getAllItems(state: RootState): Array<DownloadIntent | undefined> {
    return filter(values(state.data.cart.enqueued), (enqueued) => enqueued !== undefined);
}

export function getVisibleItems(state: RootState): Array<DownloadIntent | undefined> {
    return filter(values(state.data.cart.enqueued), (enqueued) => enqueued !== undefined && enqueued.skip !== true);
}

export function isItemSelected(state: RootState, fileId: string | undefined): boolean {
    return (
        fileId !== undefined &&
        state.data.cart.enqueued[fileId] !== undefined &&
        state.data.cart.enqueued[fileId]?.skip !== true
    );
}

export function getSelectionCount(state: RootState) {
    return filter(values(state.data.cart.enqueued), (enqueued) => enqueued && enqueued.skip !== true).length;
}
