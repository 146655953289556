import React, { useState, useEffect } from 'react';
import { Box } from 'oskcomponents';
import { AppDispatch, RootState } from '~/redux/store';
import { connect } from 'react-redux';
import { MapToolsPanel } from '~/molecules';
import { OSKGeoJson, SearchArea, SigmaAPI } from 'oskcore';
import { MapMode, MapModes } from '~/atoms';
import {
    deleteCustomAreaAsync,
    doFetchCustomAreasAsync,
    getCustomAreas,
    getLibraryLoading,
} from '~/redux/modules/data/library';
import { Polygon } from 'react-leaflet';
export type AOISectionProps = {
    /** Boolean to indicate whether the library is loading */
    libraryLoading: boolean;
    /** A list of SearchArea elements to show in the library */
    libraryItems: Array<SearchArea>;
    /** Method to invoke when an item is deleted from the library */
    deleteLibraryItem: (id: number) => void;
    /** Method to invoke when we wish to re-fetch the library items */
    reloadLibrary: () => void;
    /** Method to invoke when we save an AoI */
    saveAoi: (areaName: string, geoJson: OSKGeoJson) => Promise<any>;
    /** The currently drawn AoI on the map, in case user wants to save. */
};

const AOISection = ({ libraryLoading, libraryItems, deleteLibraryItem, reloadLibrary, saveAoi }: AOISectionProps) => {
    const [taskingArea, setTaskingArea] = useState<OSKGeoJson>(new OSKGeoJson());
    const [mode, setMode] = useState<MapModes>('None');

    useEffect(() => {
        reloadLibrary();
    }, []);

    return (
        <Box mt={22.5}>
            <MapMode
                mode={mode}
                onBeforeEdit={() => {
                    if (!taskingArea.isEmpty()) {
                        setTaskingArea(new OSKGeoJson());
                    }
                }}
                onSave={(geoJson) => {
                    setMode('None');
                    setTaskingArea(geoJson);
                }}
            />
            <Polygon positions={taskingArea.toLeafletCoordinates()} />
            <MapToolsPanel
                currentAoi={taskingArea}
                mode={mode}
                onPoint={() => {
                    setMode('Point');
                }}
                onDraw={() => {
                    setMode('Polygon');
                }}
                onClear={() => {
                    setMode('Clear');
                }}
                setMapRoi={(area) => {
                    setMode('Clear');
                    // setMode('clear') will cause taskingArea to be wiped out
                    // so we need a bit of a delay before repopulating it and
                    // becuase of react, there is no real way to guarantee
                    // the operation has been completed or not.
                    // So we use a setTimeout with wreckless abandon.
                    setTimeout(() => {
                        setTaskingArea(area);
                    }, 150);
                }}
                libraryLoading={libraryLoading}
                libraryItems={libraryItems}
                deleteLibraryItem={deleteLibraryItem}
                reloadLibrary={reloadLibrary}
                saveAoi={saveAoi}
            />
        </Box>
    );
};

const mapStateToProps = (state: RootState) => {
    return { libraryLoading: getLibraryLoading(state), libraryItems: getCustomAreas(state) };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        reloadLibrary: () => {
            dispatch<any>(
                doFetchCustomAreasAsync({
                    offset: 0,
                    limit: 1000,
                    silent: false,
                }),
            );
        },

        deleteLibraryItem: (id: number) => {
            dispatch<any>(deleteCustomAreaAsync(id, { silent: true }));
        },

        saveAoi: (AreaName: string, geoJson: OSKGeoJson) => {
            return SigmaAPI.createSearchArea({
                searchAreaRequest: {
                    name: AreaName,
                    area: JSON.stringify(geoJson.toAPIGeometry()) as any,
                },
            });
        },
    };
};

export { AOISection };
export default connect(mapStateToProps, mapDispatchToProps)(AOISection);
