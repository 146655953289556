import React from 'react';
import { Box, BoxProps } from '../../Box';
import { MONTHS_ABBRV } from '../utils';
import styled from 'styled-components';

export type MonthPickerProps = {
    className?: string;
    onMonthSelect?: (month: number) => void;
} & Omit<BoxProps, 'ref'>;
export const MonthPicker = styled(({ className, onMonthSelect, ...props }: MonthPickerProps) => {
    return (
        <Box className={className} {...props}>
            {Object.values(MONTHS_ABBRV).map((month, entryIdx) => (
                <Box
                    key={month}
                    onClick={() => {
                        onMonthSelect && onMonthSelect(entryIdx);
                    }}
                >
                    {month}
                </Box>
            ))}
        </Box>
    );
})`
    & {
        display: flex;
        flex-wrap: wrap;
        font-size: 1.25rem;
        padding: 0px 10px;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
    }

    & div {
        width: 25%;
        padding: 0.5rem 0px;
        justify-content: center;
    }

    & div:hover {
        cursor: pointer;
        color: ${(props: any) => props.theme.colors.accent};
    }
`;
