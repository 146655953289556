import React, { useEffect } from 'react';
import { Box } from '../Box';
import CSS from 'csstype';
import { createPortal } from 'react-dom';
import { GlobalZIndex } from '../constants';

export type ShadeProps = {
    /** Children components to render on top of the shade */
    children?: React.ReactNode;
    /** Override the default amount of opacity the shade enforces. Defaults to 0.5 */
    opacity?: number;
    /** Add additional style to the shade element */
    style?: CSS.Properties;
};

const Shade = ({ children, opacity = 0.5, style }: ShadeProps) => {
    // This effect will hide top-level scrollbars while the shade is deployed.
    // Upon dismissal, the original overflow value will be reinstated.
    useEffect(() => {
        const originalOverflow = document.body.style.overflow;
        document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.overflow = originalOverflow;
        };
    });

    return createPortal(
        <React.Fragment>
            <Box
                style={{
                    top: 0,
                    left: 0,
                    position: 'absolute',
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100vw',
                    height: '100vh',
                    zIndex: GlobalZIndex.ShadeContent,
                }}
            >
                {children}
            </Box>
            <Box
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    backgroundColor: '#303030',
                    opacity,
                    zIndex: GlobalZIndex.ShadeShadow,
                    ...style,
                }}
            />
        </React.Fragment>,
        document.getElementById('portal-me') ?? document.body.children[0] ?? document.body,
    );
};

export { Shade };
