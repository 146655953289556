import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getProgramId } from '~/utils';

/***
 * This method will ensure that the url starts with /program/:programId/something
 * and if it's missing, it'll redirect to the correct thing. This should be
 * generally used in all top-level views. But be warned: it can cause race conditions
 * if other things also try to manipulate the URL.
 *
 * An example of a tricky scenario would be the /monitor route:
 * This should redirect to /monitor/firstAsset/grid but if we
 * ensure program, it really should go like this:
 *
 * /program/programId/monitor ->
 * /program/programId/monitor/firstAsset/grid
 *
 * So just be aware of that possible race condition.
 */
export function useEnsureProgram() {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!location.pathname.includes('/program')) {
            navigate(`/program/${getProgramId()}${window.location.pathname}`);
        }
    }, [location, navigate]);
}
