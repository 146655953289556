import React, { useRef, useState } from 'react';
import { FileUpload } from 'oskcomponents';
import { OSKGeoJson } from 'oskcore';
import { FaUpload } from 'react-icons/fa';
import { IconButton, IconButtonProps } from '~/atoms';
import { KMLUploadFinishedModal } from '~/molecules';

export type UploadKMLModalButtonProps = {
    disabled?: boolean;
    onDialogClosed?: () => void;
    onDialogOpened?: () => void;
    reloadLibrary: () => void;
    saveAoi: (AreaName: string, geoJson: OSKGeoJson) => Promise<any>;
} & Omit<IconButtonProps, 'disabled'>;

const emptyKmlOutput = {
    succeeded: 0,
    lackingNames: 0,
    failed: 0,
};

export const UploadKMLModalButton = ({
    disabled,
    onDialogClosed,
    onDialogOpened,
    reloadLibrary,
    saveAoi,
    ...props
}: UploadKMLModalButtonProps) => {
    const [showDialog, setShowDialog] = useState(false);
    const kmlOutput = useRef({ ...emptyKmlOutput });

    const handleUpload = async (files: FileList | null) => {
        if (files) {
            kmlOutput.current = { ...emptyKmlOutput };
            const data = await files[0].text();
            const oskJson = OSKGeoJson.fromKML(data);
            const promises = [];

            for (const feature of oskJson.features) {
                if (feature.name) {
                    promises.push(
                        saveAoi(feature.name, OSKGeoJson.fromFeature(feature))
                            .then(() => {
                                kmlOutput.current.succeeded++;
                                reloadLibrary();
                            })
                            .catch(() => {
                                kmlOutput.current.failed++;
                            }),
                    );
                } else {
                    kmlOutput.current.lackingNames++;
                }
            }

            await Promise.all(promises);
            onDialogOpened && onDialogOpened();
            setShowDialog(true);
        }
    };

    const handleDialogClose = () => {
        onDialogClosed && onDialogClosed();
        setShowDialog(false);
    };

    return (
        <React.Fragment>
            <KMLUploadFinishedModal
                visible={showDialog}
                succeeded={kmlOutput.current.succeeded}
                failed={kmlOutput.current.failed}
                needsTitle={kmlOutput.current.lackingNames}
                onSubmit={handleDialogClose}
            />
            <FileUpload disabled={disabled} fileTypes=".kml" onFilesChosen={handleUpload}>
                <IconButton disabled={disabled} p={0} label="Upload" icon={FaUpload} {...props} />
            </FileUpload>
        </React.Fragment>
    );
};
