import React from 'react';
import { FaDrawPolygon, FaLocationArrow, FaUndo } from 'react-icons/fa';
import { Box, Text } from 'oskcomponents';
import { IconButton, MapModes } from '~/atoms';
import { OSKGeoJson, SearchArea } from 'oskcore';
import { SaveAreaModalButton, UploadKMLModalButton, LibraryPanel } from '~/molecules';

export type MapToolsPanelProps = {
    /** Current map mode */
    mode: MapModes;
    /** Method to invoke when map enters draw polygon mode */
    onDraw: () => void;
    /** Method to invoke when map enters draw point mode */
    onPoint: () => void;
    /** Method to invoke when map is cleared */
    onClear: () => void;
    /** Boolean to indicate whether the library is loading */
    libraryLoading: boolean;
    /** A list of SearchArea elements to show in the library */
    libraryItems: Array<SearchArea>;
    /** Method to invoke when an item is deleted from the library */
    deleteLibraryItem: (id: number) => void;
    /** Method to invoke when we wish to re-fetch the library items */
    reloadLibrary: () => void;
    /** Method to invoke when a library item has been selected */
    setMapRoi: (area: OSKGeoJson) => void;
    /** Method to invoke when we save an AoI */
    saveAoi: (areaName: string, geoJson: OSKGeoJson) => Promise<any>;
    /** The currently drawn AoI on the map, in case user wants to save. */
    currentAoi?: OSKGeoJson;
};

export const MapToolsPanel = ({
    mode,
    currentAoi,
    onDraw,
    onPoint,
    onClear,
    libraryLoading,
    libraryItems,
    setMapRoi,
    deleteLibraryItem,
    reloadLibrary,
    saveAoi,
}: MapToolsPanelProps) => {
    return (
        <Box style={{ padding: '10px 20px' }} grow col>
            <Text style={{ fontWeight: 'bold' }} pt={5}>
                AOI Tools
            </Text>
            <Box mt={15} style={{ justifyContent: 'space-between' }}>
                <IconButton
                    onClick={onDraw}
                    p={0}
                    variant="action"
                    inverted={mode === 'Polygon'}
                    hoverable
                    icon={FaDrawPolygon}
                    label="Draw"
                />
                <IconButton
                    onClick={onPoint}
                    p={0}
                    variant="action"
                    hoverable
                    inverted={mode === 'Point'}
                    icon={FaLocationArrow}
                    label="Point"
                />
                <IconButton onClick={onClear} p={0} variant="action" hoverable icon={FaUndo} label="Clear" />
                <Box style={{ border: '1px solid gray', marginRight: '10px' }} />
                <UploadKMLModalButton hoverable variant="action" reloadLibrary={reloadLibrary} saveAoi={saveAoi} />
                <SaveAreaModalButton
                    disabled={false}
                    reloadLibrary={reloadLibrary}
                    variant="action"
                    hoverable
                    area={currentAoi}
                    saveAoi={saveAoi}
                />
            </Box>
            <LibraryPanel
                isLoading={libraryLoading}
                items={libraryItems}
                deleteItem={deleteLibraryItem}
                setMapRoi={setMapRoi}
            />
        </Box>
    );
};
