import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, DatePicker, OSKRangedCalendar, OSKThemeType, TextInput } from 'oskcomponents';
import { RangedSelection, EVENT_TYPE } from 'react-infinite-calendar';
import { WizardContext } from '~/templates';
import { useBoundingRect } from '~/hooks';
import { StepScaffold } from '../StepScaffold';
import { useTheme } from 'styled-components';
interface StringRangedSelection {
    start: Date;
    end: Date;
}

const defaultDateRange: StringRangedSelection = {
    start: new Date(),
    end: new Date(),
};

export const TimeFrameStep = () => {
    const theme = useTheme() as OSKThemeType;
    const ctx = useContext(WizardContext);
    const calBoxRef = useRef<HTMLDivElement>(null);
    const [taskName, setTaskName] = useState<string>('New Task');
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    useEffect(() => {
        if (
            startDate === null ||
            endDate === null ||
            (!isNaN(startDate.getDate()) && !isNaN(startDate.getDate()) && !(startDate.getTime() > endDate.getTime()))
        ) {
            ctx.setValidity(true);
        } else {
            ctx.setValidity(false);
        }
    }, [startDate, endDate]);

    const [sampled, _, calendarHeight] = useBoundingRect(calBoxRef);

    return (
        <StepScaffold title="Define AOI" subTitle="Input a time & name for this task." col={false}>
            <Box
                ref={calBoxRef}
                style={{
                    height: '100%',
                    maxHeight: '600px',
                    flexShrink: 1,
                    paddingRight: '25px',
                    marginRight: '25px',
                    maxWidth: '450px',
                    borderRight: '2px solid ' + theme.colors.black800,
                }}
                grow
            >
                {sampled && (
                    <OSKRangedCalendar
                        width={450}
                        height={calendarHeight - 130}
                        startDate={startDate}
                        endDate={endDate}
                        onSelect={(selection: RangedSelection) => {
                            switch (selection.eventType) {
                                case EVENT_TYPE.START:
                                    setStartDate(selection.start);
                                    setEndDate(selection.start);
                                    break;

                                case EVENT_TYPE.END: {
                                    // This allows them to select inverted dates and it still ends up being correct.
                                    const min = Math.min(selection.start.getTime(), selection.end.getTime());
                                    const max = Math.max(selection.end.getTime(), selection.start.getTime());

                                    setStartDate(new Date(min));
                                    setEndDate(new Date(max));
                                    break;
                                }
                            }
                        }}
                    />
                )}
            </Box>
            <Box style={{ maxWidth: '350px' }} col grow>
                <Box
                    col
                    style={{
                        justifyContent: 'center',
                        placeItems: 'space-between',
                        overflow: 'hidden',
                        paddingBottom: '10px',
                    }}
                >
                    Task Name
                    <TextInput placeholder="New Task" name="task-name" onChange={(e) => setTaskName(e.target.value)} />
                </Box>
                <Box col grow>
                    Opportunity Window
                    <Box style={{ paddingBottom: '14px' }}>
                        <DatePicker
                            name="start-date"
                            variant="primary"
                            value={startDate}
                            onChange={(start: Date) => {
                                setStartDate(start);
                            }}
                        />
                        &nbsp;-&nbsp;
                        <DatePicker
                            name="end-date"
                            variant="primary"
                            value={endDate}
                            onChange={(end: Date) => {
                                setEndDate(end);
                            }}
                        />
                    </Box>
                </Box>
            </Box>

            <input type="hidden" name="tasking_start_date" value={new Date(startDate).getTime()} />
            <input type="hidden" name="tasking_end_date" value={new Date(endDate).getTime()} />
            <input type="hidden" name="task_name" value={taskName} />
        </StepScaffold>
    );
};
