import React, { useCallback } from 'react';
import { Box, Button, Text, Tooltip } from 'oskcomponents';
import { DownloadRequest, DownloadRequestRequest } from 'oskcore';
import { connect } from 'react-redux';
import { AppDispatch, RootState } from '~/redux/store';
import { DownloadFileMethod, downloadFilesAsync } from '~/redux/modules/data/cart';

/** Style map for the different button states */
const VariantMap = {
    download: 'action',
    missing: 'action',
    wait: 'clear',
    error: 'danger',
};

const DownloadPendingTooltip = () => (
    <Box col w={250}>
        <Text strong p={4}>
            In Progress
        </Text>
        <Text variant="tiny" p={4}>
            Your files are being prepared. Your download will become available when they are ready.
        </Text>
    </Box>
);

const DownloadUnavailableTooltip = () => (
    <Box col w={250}>
        <Text strong p={4}>
            File Unavailable
        </Text>
        <Text variant="tiny" p={4}>
            This file is not available yet. Please check back later.
        </Text>
    </Box>
);

type DownloadButtonProps = {
    /** The id of the file used in the downloadFiles request */
    fileId: number;
    /** The id of the program which this download may be associated with */
    programId?: number;
    /** The download request object as seen from the database. */
    originalRequest?: DownloadRequest;
    /** Initiate a download request */
    initiateDownload?: DownloadFileMethod;
    /** Actually download a file */
    downloadFile?: (uri: string) => void;
};

type DownloadButtonState = 'download' | 'wait' | 'error' | 'missing';

const DownloadButton = ({
    fileId,
    programId,
    originalRequest,
    initiateDownload,
    downloadFile,
}: DownloadButtonProps) => {
    const computeButtonState = useCallback((): DownloadButtonState => {
        // Check for expired
        const expired =
            originalRequest && originalRequest.time_expired
                ? new Date(originalRequest.time_expired).getTime() < new Date().getTime()
                : false;

        if (expired) {
            return 'missing';
        } else if (originalRequest && originalRequest.zip_file_loc) {
            return 'download';
        } else if (originalRequest) {
            return 'wait';
        } else {
            return 'missing';
        }
    }, [originalRequest]);

    // Compute the button state
    const state = computeButtonState();
    const disabled = state === 'wait';

    // Compute the tooltip message
    let tooltipMessage;
    if (state === 'error') {
        tooltipMessage = <DownloadUnavailableTooltip />;
    } else if (state === 'wait') {
        tooltipMessage = <DownloadPendingTooltip />;
    } else {
        tooltipMessage = '';
    }

    return (
        <Tooltip label={tooltipMessage}>
            <Button
                variant={VariantMap[state]}
                disabled={disabled}
                style={{ alignItems: 'center', justifyContent: 'center' }}
                onClick={() => {
                    switch (state) {
                        case 'missing':
                            initiateDownload &&
                                initiateDownload({
                                    requested_captures: [fileId],
                                });
                            break;
                        case 'download':
                            if (originalRequest && originalRequest.zip_file_loc) {
                                downloadFile && downloadFile(originalRequest.zip_file_loc);
                            }
                            break;
                    }
                }}
            >
                <Box row style={{ alignItems: 'center', justifyContent: 'center' }}>
                    {(() => {
                        switch (state) {
                            case 'download':
                                return <Text variant="small">Download</Text>;
                            case 'missing':
                                return <Text variant="small">Generate Files</Text>;
                            case 'wait':
                                return <Text variant="small">Generating...</Text>;
                            case 'error':
                                return <Text variant="small">Failed</Text>;
                            default:
                                return 'Unavailable';
                        }
                    })()}
                </Box>
            </Button>
        </Tooltip>
    );
};

const mapStateToProps = (state: RootState, props: DownloadButtonProps) => {
    return {
        originalRequest: state.osk.fileCache[props.fileId],
        programId: state.session.program_id ?? -1,
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        initiateDownload: (request: DownloadRequestRequest, onDownloadFinished?: (data: DownloadRequest) => void) => {
            dispatch<any>(downloadFilesAsync(request, onDownloadFinished));
        },
        downloadFile: (uri: string) => {
            window.open(uri);
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DownloadButton);
export { DownloadButton };
export type { DownloadButtonProps };
