import { format, utcToZonedTime } from 'date-fns-tz';
import { LatLng } from 'leaflet';
import { fround } from 'oskcore';
import { PROGRAM_ID_LOCAL_STORAGE_KEY } from './redux/modules/session';

export type DateLike = string | Date | number | null | undefined;

/**
 * This method will take an HTMLElement and a ref.current element and
 * then walk up the DOM, looking for a match. Effectively, you can use this
 * to see if the evt.target is a sub-tree node of a ref. If it is, this
 * implies that the user has clicked inside whatever ref.current is.
 *
 * A good use case for this is - you open a pop-up window and want to
 * then listen for any click on the page. If the click is outside
 * the pop-up window, you know you can dismiss it.
 */
export function clickedInside(el: HTMLElement | null, ref: any): boolean {
    if (el === undefined || el === null) return false;
    if (el === ref) return true;
    return clickedInside(el.parentNode as HTMLElement, ref);
}

/* This method takes an object that *can* be a date and
    formats it MM-DD-YYY. */
export function date_format(datelike?: DateLike) {
    if (datelike === undefined || datelike === null) return '';
    const date = utcToZonedTime(datelike, 'UTC');
    return format(date, 'M/d/yyyy');
}

/* This method takes an object that *can* be a date and
    formats it Mon DD YYYY. */
export function date_format_long(datelike?: DateLike) {
    if (datelike === undefined || datelike === null) return '';
    const date = utcToZonedTime(datelike, 'UTC');
    return format(date, 'MMM d yyyy');
}

/* This method takes an object that *can* be a date and
    formats the time as HH:MM AM/PM. */
export function time_format(datelike?: DateLike) {
    if (datelike === undefined || datelike === null) return '';
    const date = utcToZonedTime(datelike, 'UTC');
    return format(date, 'hh:mm a');
}

const DEFAULT_MAP_ZOOM = 13;
export function LatLngToPosition(pos?: LatLng) {
    if (!pos) return '';

    return `@${pos.lat ?? 0},${pos.lng ?? 0},${pos.alt ?? DEFAULT_MAP_ZOOM}`;
}

export function coord_format(coords: number[]): string {
    return `${fround(coords[1], 3)}, ${fround(coords[0], 3)}`;
}

// TODO: Refactor this ASAP
export function getProgramId() {
    // First, check the url
    const urlPaths = window.location.pathname.split('/');
    // NOTE: The -1 here is because we do i and i+1. This prevents NPE.
    for (let i = 0; i < urlPaths.length - 1; i++) {
        const firstTerm = urlPaths[i];
        const secondTerm = parseInt(`${urlPaths[i + 1]}`);

        // If we encounte program/number then we can safely assume
        // that number is the programId because all of our routes
        // (at the time of this writing) follow such a pattern.
        if (firstTerm.toLowerCase() === 'program' && !isNaN(secondTerm)) {
            localStorage.setItem(PROGRAM_ID_LOCAL_STORAGE_KEY, `${secondTerm}`);
            return secondTerm;
        }
    }

    const activeProgramId = localStorage.getItem(PROGRAM_ID_LOCAL_STORAGE_KEY);
    if (activeProgramId !== null) {
        return parseInt(activeProgramId);
    } else {
        return -1;
    }
}
