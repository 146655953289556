import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { FaCheckSquare, FaSquare } from 'react-icons/fa';
import { Box, Text } from '..';

export type SelectItemProps = {
    /** The method to invoke when this element is clicked. */
    onClick?: (evt: any) => void;
    /** If true, the element will be rendered in "selected" mode with a checkmark next to it */
    selected: boolean;
    /** The text or ReactNode to render inside this ListItem */
    text: string | ReactNode;
};

export const SelectItem = styled(({ onClick, selected, text }: SelectItemProps) => {
    return (
        <Box onClick={onClick} p={10} style={{ width: '100%', alignItems: 'center' }} grow>
            {selected ? <FaCheckSquare /> : <FaSquare />}
            <Text style={{ paddingLeft: '10px' }} variant="medium">
                {text}
            </Text>
        </Box>
    );
})``;
