import React from 'react';
import { List } from 'oskcomponents';
import { CartRowItem } from '~/molecules/CartRowItem';
import { Capture, DownloadIntent } from 'oskcore';

export type CartItemListProps = {
    /** A collection of download intents, grouped by collectId */
    items: Array<DownloadIntent | undefined>;
    /** A map of ResultItems. Key is fileId value is PipelineResult file */
    resultItemMap: Record<string, Capture | undefined>;
    /** A method for updating a particular download intent (either to skip or include it) */
    onToggle: (fileId: string) => void;
};

export const CartItemList = ({ items, resultItemMap, onToggle }: CartItemListProps) => {
    return (
        <List col>
            {items
                .filter((item) => item !== undefined && item !== null)
                .map((item) => (
                    <CartRowItem
                        item={resultItemMap[item?.fileId || -1]}
                        onToggle={() => {
                            onToggle(item?.fileId || '-1');
                        }}
                        checked={item?.skip !== true}
                        key={`cart_item_${item?.fileId || -1}`}
                    />
                ))}
        </List>
    );
};
