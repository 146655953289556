import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_OSK_POSITION, encodeOSKPosition, OSKPosition } from 'oskcore';
import { useSessionStorage } from '~/hooks';

/// This view has one purpose. If it is rendered, it will redirect to a specific map
/// view with the location that was most recently saved in SessionStorage or, alternatively,
/// the default osk location.
///
/// It will take this default position and replace it on the navigation stack,
/// effectively acting as a temporary route updater.
export const RedirectToHomeView = () => {
    const navigate = useNavigate();
    const [getSessionStorage] = useSessionStorage();
    const cachedPosition = getSessionStorage<OSKPosition>('map');
    const defaultPosition = cachedPosition
        ? encodeOSKPosition(cachedPosition)
        : encodeOSKPosition(DEFAULT_OSK_POSITION);

    useEffect(() => {
        navigate(`/map/${defaultPosition}`, { replace: true });
    }, [navigate, defaultPosition]);
    return <React.Fragment />;
};
