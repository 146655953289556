import { Box, OSKThemeType, Spinner } from 'oskcomponents';
import { AdminAPI, NavComponent, OSKGeoJson, OSKView, Program } from 'oskcore';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import AppShimmerBar from '~/organisms/AppShimmerBar';
import { SigmaNavItems } from '~/organisms/SigmaNavItems';
import { PortalTemplate, ViewItem } from '~/templates/PortalTemplate';
import { DetailsPanel } from './DetailsPanel';
import { RegionAccessPanel } from './RegionAccessPanel';
import { UserPanel } from './UserPanel';
import { AssetsPanel } from './AssetsPanel';

export const ProgramView = () => {
    const theme = useTheme() as OSKThemeType;
    const { id } = useParams() as any;
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<Program>();

    useEffect(() => {
        AdminAPI.getProgram({ id })
            .then((resp) => {
                if (resp.data) {
                    setData(resp.data);
                }
            })
            .catch((ex) => console.error(ex))
            .finally(() => {
                setLoading(false);
            });
    }, [id]);

    const programOptions: Array<ViewItem> = [
        {
            title: `${data?.name}`,
            subItems: [
                { title: 'Details', iconCode: 'list', component: <DetailsPanel data={data} /> },
                { title: 'Users', iconCode: 'user', component: <UserPanel data={data} /> },
                {
                    title: 'Region Access',
                    iconCode: 'map',
                    component: <RegionAccessPanel data={OSKGeoJson.fromAPIGeometry(data?.region_access)} />,
                },
                {
                    title: 'Assets',
                    iconCode: 'report',
                    component: <AssetsPanel data={data} />,
                    visible: true, // TODO: Replace this with permissions-based value
                },
            ],
        },
    ];

    return (
        <OSKView
            headerStyle={{
                boxShadow: theme.boxShadow,
            }}
            nogradient
        >
            <NavComponent>
                <SigmaNavItems />
            </NavComponent>
            <AppShimmerBar />
            {loading ? (
                <Box style={{ justifyContent: 'center', paddingTop: '50px' }}>
                    <Spinner variant="Box" size="Large" />
                </Box>
            ) : (
                <PortalTemplate defaultViewItem="details" viewItems={programOptions} />
            )}
        </OSKView>
    );
};
