import React, { useState } from 'react';
import CSS from 'csstype';
import { Box, BoxProps, Checkbox } from 'oskcomponents';
import { Capture, Sensor } from 'oskcore';
import { Image } from '~/atoms/Image';
import { FaInfo } from 'react-icons/fa';
import { format } from 'date-fns';

export type CartGridItemProps = {
    item?: Capture;
    thumbnailUri?: string;
    checked: boolean;
    sensor: Sensor;
    onToggle: () => void;
} & Omit<BoxProps, 'ref'>;

const widgetStyle = {
    position: 'relative',
    background: 'black',
    height: '25px',
    width: '25px',
    top: '125px',
    zIndex: 2,
} as CSS.Properties;

export const CartGridItem = ({ item, thumbnailUri, checked, onToggle, sensor, ...props }: CartGridItemProps) => {
    const [flipped, setFlipped] = useState(false);

    if (item === undefined) {
        return <React.Fragment />;
    } else {
        return (
            <Box m={5} w={150} h={150} bg={'black'} style={{ cursor: 'pointer' }} onClick={onToggle} {...props}>
                <Box style={{ position: 'absolute', zIndex: 1 }}>
                    {!flipped ? (
                        <Image w={150} h={150} cover src={'/images/satimg2.png'} />
                    ) : (
                        <Box col>
                            <Box>Id: {item?.id}</Box>
                            <Box>{format(new Date(item.collect_start_time), 'yyyy-MM-dd')}</Box>
                            <Box>Sensor: {sensor.osk_sensor_name}</Box>
                        </Box>
                    )}
                </Box>
                <Box
                    onClick={(evt) => {
                        evt.preventDefault();
                        evt.stopPropagation();
                        setFlipped(!flipped);
                    }}
                    style={{ ...widgetStyle, left: '0px', color: 'white', fontSize: '0.8rem' }}
                >
                    <Box style={{ padding: '5px', paddingTop: '7px' }}>
                        <FaInfo />
                    </Box>
                </Box>
                <Box style={{ ...widgetStyle, left: '105px', zIndex: 2 }}>
                    <Checkbox
                        style={{ justifyContent: 'center', alignItems: 'center' }}
                        w={28}
                        id={`${item?.id}_toggle`}
                        name={`${item?.id}_toggle`}
                        defaultChecked={checked}
                        onChange={onToggle}
                    />
                </Box>
            </Box>
        );
    }
};
