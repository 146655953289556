import { Box, CarouselImage, LightboxCarousel, OSKIcon, OSKThemeType, Text } from 'oskcomponents';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { OSKHR } from '~/atoms';
import { date_format, getProgramId } from '~/utils';

type AlertCardProps = {
    id: string;
    assetId: string | number;
    callType: string;
    subType?: string;
    assetName?: string;
    detectedAt?: Date;
    images: CarouselImage[];
};

const AlertCard = ({ id, assetId, images, detectedAt, assetName, callType, subType }: AlertCardProps) => {
    const theme = useTheme() as OSKThemeType;
    const navigate = useNavigate();

    return (
        <Box
            w={250}
            style={{
                backgroundColor: theme.colors.primary.bg,
                marginBottom: '20px',
                borderRadius: '9px',
                borderTopRightRadius: '10px',
                borderTopLeftRadius: '10px',
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            }}
            col
        >
            <LightboxCarousel
                style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
                images={images}
                width={250}
                height={150}
            />

            <Box p={15} mb={10} col>
                <Box style={{ justifyContent: 'center', alignItems: 'center' }}>
                    <Box col>
                        {detectedAt && (
                            <Text color={theme.colors.primary.accent} variant="small">
                                {date_format(detectedAt)}
                            </Text>
                        )}
                        <Text pt={5} strong>
                            {id}
                        </Text>
                    </Box>
                    <Box grow />
                    <OSKIcon
                        onClick={() => {
                            navigate(`/program/${getProgramId()}/monitor/${assetId}/map/alert/${id}`);
                        }}
                        code="map"
                    />
                </Box>
                <OSKHR mt={10} />
                <Text variant="small" pt={5}>
                    {callType}
                </Text>
                <Text variant="small" pt={5}>
                    {assetName}
                </Text>
                <OSKHR mt={10} />
            </Box>
        </Box>
    );
};

export { AlertCard };
