import React from 'react';
import { Text, TypographyProps } from 'oskcomponents';

export type NoPermissionProps = {
    text?: string;
} & Omit<TypographyProps, 'variant'>;

/**
 * This component is used to render a no-permission text
 */
export const NoPermission = ({ text, ...props }: NoPermissionProps) => {
    return (
        <Text style={{ fontSize: '1.5rem', ...props?.style }} {...props}>
            {text ?? 'You do not have the permissions to view this resource.'}
        </Text>
    );
};
