import React from 'react';
import { useTheme } from 'styled-components';
import { Box, DatePicker, ListEntry, MultiSelect, OSKThemeType, Select, Text, TextInput } from 'oskcomponents';
import { isValid } from 'date-fns';

export type FormElementProps = {
    label: string;
    name: string;
    type: 'input' | 'dropdown' | 'multiselect' | 'date' | 'email' | 'number';
    options?: Array<ListEntry>;
    readonly?: boolean;
    defaultValue?: string | number | Date | boolean | null | undefined;
    defaultValues?: Array<string | number> | undefined;
};

export const FormElement = ({
    label,
    name,
    type,
    options,
    readonly,
    defaultValue,
    defaultValues,
}: FormElementProps) => {
    const theme = useTheme() as OSKThemeType;
    return (
        <Box col style={{ paddingBottom: '14px', paddingTop: '14px' }}>
            <Text variant="medium" style={{ marginBottom: '10px' }}>
                {label}
            </Text>
            {['input', 'email', 'number'].includes(type) && (
                <TextInput
                    disabled={readonly}
                    name={name}
                    type={type === 'input' ? 'text' : type}
                    value={defaultValue ?? undefined}
                />
            )}
            {['date'].includes(type) && (
                <DatePicker
                    name={name}
                    disabled={readonly}
                    defaultValue={defaultValue && isValid(defaultValue) ? new Date(defaultValue.toString()) : undefined}
                    variant={'primary'}
                />
            )}
            {['dropdown'].includes(type) && (
                <Select
                    variant={readonly ? 'disabled' : 'primary'}
                    disabled={readonly}
                    defaultValue={defaultValue ?? undefined}
                    name={name}
                    items={options ?? []}
                />
            )}
            {['multiselect'].includes(type) && (
                <MultiSelect
                    readonly={readonly}
                    name={name}
                    defaultValues={defaultValues ?? undefined}
                    variant={readonly ? 'disabled' : 'primary'}
                    items={options ?? []}
                />
            )}
        </Box>
    );
};
