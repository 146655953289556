import React from 'react';
import { AOIProgress } from '../../redux/modules/tasking/app';
import { TimelineBar, TimelineBarStep } from '../../atoms';
import { date_format, time_format } from '../../utils';

type TimelineBarListProps = {
    data: AOIProgress[];
};

const TimelineBarList = ({ data }: TimelineBarListProps) => {
    return (
        <React.Fragment>
            {data.map((aoi) => (
                <TimelineBar key={`timeline-${aoi.name}`} description={aoi.name} errorMessage={aoi.errorReason}>
                    {aoi.steps.map((step) => {
                        return (
                            <TimelineBarStep
                                key={`step-${step.name}`}
                                title={step.name}
                                description={`${date_format(step.timestamp)} ${time_format(step.timestamp)}`}
                                selected={step.current}
                            />
                        );
                    })}
                </TimelineBar>
            ))}
        </React.Fragment>
    );
};

export type { TimelineBarListProps };
export { TimelineBarList };
